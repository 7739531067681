import "./emptyWidgetView.scss";

import { DS } from "~/libs";

import { Config } from "../../../config";
import { Widget } from "../../../utils/rtbf/models";
import { WidgetView } from "../widgetView";

/**
 * View title to display missing widget during development
 */
export class EmptyWidgetView extends WidgetView {
  constructor(widget: Widget) {
    super(widget.id, "emptyWidget");
    const titleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });
    Config.locale$.didChange(
      () => {
        // when local changes, we reset our text - which is dependent on this locale
        titleElement.innerText = `${widget.title}`;
      },
      this,
      true
    );
  }
}
