import { Identifiable, IListenable, IView } from ".";

export interface ISwitchComponentParams<M extends Identifiable, V extends IView> {
  id: string;
  className: string;
  model: M[];
  viewFactory: (item: M) => V;
  initialViewId: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface ISwitchComponent<M extends Identifiable, V extends IView> extends IView {
  /** the DOM element managed by this view
   */
  readonly rootElement: HTMLDivElement;
  show(id: string): void;
  modelFromId(id?: string): M | undefined;
  viewFromId(id?: string): V | undefined;

  readonly shownId$: IListenable<string | undefined>;
  readonly shownView$: IListenable<V | undefined>;
  readonly focused$: IListenable<boolean>;
  readonly shown$: IListenable<boolean>;
}
