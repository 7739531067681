import "./stickyPlayer.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";

import { IPlayer } from "../../../tools/player";
import { TextHelper } from "../../../tools/textHelper";
import { AudioAnimation } from "../../../tools/uiHelper";

export class StickyPlayer extends AcceptsMouseFocusView {
  private _player;

  constructor(player: IPlayer) {
    super("StickyPlayer", "stickyPlayer");

    this._player = player;

    const background = DS.DOMHelper.createElement({ tagName: "img", parent: this.rootElement, className: "icon" });
    this.rootElement.appendChild(new AudioAnimation(this._player, "sticky").rootElement);
    const titleElt = TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "title",
      content: "",
    });
    const subtitleElt = TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "subtitle",
      content: "",
    });

    player.asset$.didChange(
      value => {
        if (value?.resource) {
          background.src = value.resource.background.s;
          let title = "";
          let subtitle = "";

          if (value.resource.radio === undefined) {
            title = value.resource.title;
            subtitle = value.resource.subtitle;
          } else {
            title = value.resource.radio?.extdata?.NomChaine ?? value.resource.ramRadio?.channel?.label ?? "";
            if (
              value.resource.radio?.extdata?.TitreEmission !== undefined &&
              value.resource.radio?.extdata?.TitreEmission !== ""
            )
              title += ` - ${value.resource.radio.extdata.TitreEmission}`;
            if (
              value.resource.radio?.extdata?.Presentateur !== undefined &&
              value.resource.radio?.extdata?.Presentateur !== ""
            )
              title += ` (${t("player.radio.presented")} ${value.resource.radio.extdata.Presentateur})`;

            subtitle = "";
            if (value.resource.radio?.song !== undefined && value.resource.radio?.song !== "") {
              subtitle = value.resource.radio.song;
              if (value.resource.radio?.artist !== undefined && value.resource.radio?.artist !== "") {
                subtitle += ` (${value.resource.radio.artist})`;
              }
            } else if (
              value.resource.radio?.extdata?.SloganChaine !== undefined &&
              value.resource.radio?.extdata?.SloganChaine !== ""
            ) {
              subtitle = value.resource.radio.extdata.SloganChaine;
            }
          }

          titleElt.update(title);
          subtitleElt.update(subtitle);
        }
      },
      this,
      true
    );

    player.binge({
      context: "stickyPlayer",
    });
  }
}
