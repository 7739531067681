import { Identifiable } from "../typings";
import { View } from "./view";
import { IViewBounds } from "./viewBounds";

/**
 * @internal
 * Creates a string value based on string, number or {id:string|number}. Used in listComponent to generate IDs
 * @param object - objects of values to be used as IDs
 * @returns
 */
export const keyGenerator = (model: Identifiable): string => {
  const id = typeof model === "object" ? model.id : model;
  return typeof id === "number" ? id.toString() : id;
};

/**
 * @internal
 * Filters and reduces object size based on a set of params
 * @param obj - original object to be filtered
 * @param predicate - params to filter object by
 * @returns filtered object
 */
export function objFilter<T>(obj: { [key: string]: T }, predicate: (key: string, value: T) => boolean) {
  return Object.keys(obj)
    .filter(key => predicate(key, obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});
}

/**
 * @internal
 * Basecomponent is a class extended by ListComponent and SwitchComponent.\
 * It contains the methods and properties to manage the views, the models and the boundsMap
 */
export class BaseComponent<M, V> extends View {
  // our list of ids for every elemeent
  ids: string[] = [];
  // a map for the views we're displaying - by (unique) ids
  viewMap: { [id: string]: V } = {};
  modelMap: { [id: string]: M } = {};
  boundsMap: { [id: string]: IViewBounds } = {};

  /**
   * generic component used throughout the UI Lib
   * @param id - string to be used as HTML tag 'id'
   * @param className - string to be used as HTML tag 'class'
   */
  constructor(id: string, className: string) {
    super(id, className);
  }

  /**
   * retrieves a 'view' component based on ID
   * @param id - string id of target view
   * @returns
   */
  viewFromId = (id?: string): V | undefined => {
    if (id === undefined) return undefined;
    const view = this.viewMap[id];
    return view;
  };

  /**
   * retrieves a data model used in a 'viewFactory' based on ID
   * @param id - string id of target data model
   * @returns
   */
  modelFromId = (id?: string): M | undefined => {
    if (id === undefined) return undefined;
    const model = this.modelMap[id];
    return model;
  };

  /**
   * retrieves numerical index of an item based on string ID
   * @param id - string id of target item
   * @returns
   */
  indexFromId = (id?: string): number | undefined => {
    if (id === undefined) return undefined;
    const index = this.ids.indexOf(id);
    if (index === -1) {
      // Log.ui.error(`no views with id ${id} on ${this.componentId}`);
      return;
    }
    return index;
  };

  /**
   * retrieves a 'view' component based on index
   * @param index - number index of target view
   * @returns
   */
  viewFromIndex = (index?: number): V | undefined => {
    if (index === undefined || this.ids.length === 0) return undefined;

    if (index < 0 || index > this.ids.length - 1) {
      // Log.ui.warn(`index ${index} out of bounds on ${this.componentId} [0 - ${this.ids.length - 1}]`);
      return;
    }
    return this.viewFromId(this.ids[index]);
  };

  /**
   * retrieves a data model used in a 'viewFactory' based on index
   * @param index - number index of target view
   * @returns
   */
  modelFromIndex = (index?: number): M | undefined => {
    if (index === undefined || this.ids.length === 0) return undefined;

    if (index < 0 || index > this.ids.length - 1) {
      // Log.ui.warn(`index ${index} out of bounds on ${this.componentId} [0 - ${this.ids.length - 1}]`);
      return;
    }
    return this.modelFromId(this.ids[index]);
  };

  /**
   * retrieves bounds based on index
   * @param index - number index of target view
   * @returns
   */
  boundsFromIndex = (index?: number) => {
    if (index === undefined || this.ids.length === 0) return undefined;

    if (index < 0 || index > this.ids.length - 1) {
      // Log.ui.warn(`index ${index} out of bounds on ${this.componentId} [0 - ${this.ids.length - 1}]`);
      return undefined;
    }
    return this.boundsMap[this.ids[index]];
  };
}
