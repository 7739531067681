import "./aboutPage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { FocusTracker } from "../../../components/focusTracker";
import { SettingsLongButtonView } from "../../../components/views/settings/settingsLongButtonView/settingsLongButtonView";
import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { RootMenuPage } from "../../../rootMenu/rootMenuPage";
import { CookiesPage } from "../cookies/cookiesPage";
import { GeneralConditionPage } from "../generalCondition/generalConditionPage";
import { MentionLegalePage } from "../mentionLegale/mentionLegalePage";
import { PrivacyPage } from "../privacy/privacyPage";
import { RightsPage } from "../rights/rightsPage";

export enum AboutButtons {
  legal = "legal",
  condition = "condition",
  cookies = "cookies",
  right = "right",
  private = "private",
}

class AboutPageView extends AcceptsMouseFocusView implements DS.IPage {
  focusTracker?: FocusTracker;
  constructor() {
    super("aboutPageView", "aboutPageView settingsPages");
    const pageLogo = require("@assets/images/mentionLegale/mentionLegale_logo.png");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${pageLogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("about.title"),
    });

    this.delegate = DS.createListComponent(
      {
        id: `about/list`,
        className: "aboutList",
        modelSource$: new DS.ModelSource([
          AboutButtons.legal,
          AboutButtons.condition,
          AboutButtons.cookies,
          AboutButtons.right,
          AboutButtons.private,
        ]),
        viewFactory: button => {
          return new SettingsLongButtonView(button);
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: button => {
          switch (button) {
            case AboutButtons.legal:
              navigationStack.pushPage(new MentionLegalePage());
              return true;
            case AboutButtons.right:
              navigationStack.pushPage(new RightsPage());
              return true;
            case AboutButtons.cookies:
              navigationStack.pushPage(new CookiesPage());
              return true;
            case AboutButtons.condition:
              navigationStack.pushPage(new GeneralConditionPage());
              return true;
            case AboutButtons.private:
              navigationStack.pushPage(new PrivacyPage());
              return true;
            default:
              return true;
          }
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this.focusTracker.onRelease);
        void list.setFocusOnIndex(0);
      }
    );
  }
}

export class AboutPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("LanguageSubtitletitlePage", "RootMenuPage", () => {
      return new AboutPageView();
    });
  }
}
