import "./mosaicWidgetView.scss";

import { MediaTileView } from "~/components/tiles/media/mediaTileView";
import { ProgramTileView } from "~/components/tiles/program/programTileView";
import { TVLiveTileView } from "~/components/tiles/tvLive/tvLiveTileView";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";
import { GenericPage } from "~/pages/generic/genericPage";
import {
  FilterTypes,
  MediaCard,
  MediaPremiumCard,
  ProgramCard,
  TvLiveCard,
  Widget,
  WidgetFavoritesMosaicResponse,
  WidgetMosaic,
  WidgetMosaicFavorite,
  WidgetMosaicPaginatedResponse,
  WidgetMosaicPlayHistory,
  WidgetPlayHistoryMosaicResponse,
} from "~/utils/rtbf/models";

import { Config } from "../../../config";
import { PaginatedModelSource } from "../../../datas/paginatedModelSource";
import { navigationStack } from "../../../main";
import { FocusManager } from "../../../tools/focusHelper";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { WidgetView } from "../widgetView";

export type MosaicWidgetType = "mosaicFavorites" | "mosaicPlayHistory";
type Card = ProgramCard | MediaCard | TvLiveCard | MediaPremiumCard;

/**
 * Category widget, built by the GenericPage.
 * @param widget The category widget.
 */
export class MosaicWidgetView extends WidgetView implements DS.INavigable {
  private _focusTracker?: FocusTracker;
  private _focusManager?: FocusManager;
  private _list: DS.IListComponent<Card, TVLiveTileView | MediaTileView | ProgramTileView>;
  private _init = false;
  private _mosaicType;
  modelSource: PaginatedModelSource<Card>;
  private _pageHasFilters;
  private _crossSectionWidth = 4;

  constructor(widget: Widget, pageFilterType: FilterTypes, mosaicType?: MosaicWidgetType) {
    super(widget.id, `mosaicWidget ${pageFilterType !== false && "filters-" + pageFilterType}`);
    this._mosaicType = mosaicType;
    this._setAnalytics(widget);
    this._pageHasFilters = pageFilterType;

    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "mosaicList",
        modelSource$: (this.modelSource = this._modelSource(widget.contentPath ?? "")),
        viewFactory: (card: Card) => {
          if (this._init === false) {
            this._crossSectionWidth = 4;
            if (card.resourceType === "PROGRAM" || card.resourceType === "MEDIA_PREMIUM") {
              this._crossSectionWidth = 6;
            }
            if (card.resourceType === "MEDIA_PREMIUM") {
              this.rootElement.classList.add("premiumFocus");
            }
            (
              this._list as DS.IListComponent<any, DS.IView> & { params: { crossSectionWidth: number } }
            ).params.crossSectionWidth = this._crossSectionWidth;
            this._init = true;
          }
          switch (card.resourceType) {
            case "LIVE":
              return new TVLiveTileView(card, "mosaicSize");
            case "MEDIA":
              return new MediaTileView(card, "mosaicSize");
            case "MEDIA_PREMIUM":
            case "PROGRAM":
              return new ProgramTileView(card, "mosaicSize");
          }
        },
        loadingPlaceholderFactory: () => new LoadingTile(),
        modelSourcePageLength: 18,
        scrollingMode: {
          type: DS.ScrollingType.page,
          horizontal: false,
        },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
          wheelScrollBy: 382,
        },
        crossSectionWidth: 4,
        onSelect: (card: Card, index) => {
          if (card.resourceType === "LIVE") {
            super._analyticsCards({
              card_order: index,
              media_id: card.id,
              media_title: card.title,
              media_type: card.type === "VIDEO" ? "live_video" : "live_audio",
              media_category: card.categoryLabel,
              media_duration: card.duration,
            });
          }
          if (card.resourceType === "MEDIA" || card.resourceType === "MEDIA_PREMIUM") {
            super._analyticsCards({
              card_order: index,
              media_id: card.id,
              media_title: card.title,
              media_type: card.type === "VIDEO" ? "vod" : "aod",
              media_category: card.categoryLabel,
              media_duration: card.duration,
            });
          }
          if (card.resourceType === "PROGRAM") {
            super._analyticsCards({
              card_order: index,
              media_id: card.id,
              media_program: card.title,
              media_type: "vod",
            });
          }
          if (card.path != null) {
            navigationStack.pushPage(new GenericPage(card.path));
          }
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this._focusManager = new FocusManager(this._focusTracker);
        this.collectRelease(this._focusTracker.onRelease);

        list.focusedIndex$.didChange(
          (index: number | undefined) => {
            if (index === undefined) return;
            const card = list.modelFromIndex(index);

            if (card === undefined) return;
            this._focusManager?.manageFocus(card);
          },
          this,
          true
        );
      }
    );

    const empty_search = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement.parentElement ?? this.rootElement,
      className: "emptySearch",
      innerText: t("generic.empty_search"),
    });

    // if we are fetching a page we show a spinner and the navigation down should be block
    const spinnerElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "mosaicWidgetSpinner",
    });
    this.modelSource.fetching$.didChange(
      fetching => {
        spinnerElt.style.visibility = fetching ? "visible" : "hidden";
      },
      this,
      true
    );

    this.modelSource.isComplete$.didChange(
      isComplete => {
        // Hide the widget if the modelSource is complete and no data
        // TODO: may be display a "empty search" message will be better
        if (pageFilterType !== false && isComplete === true && this.modelSource.value.length === 0) {
          this.rootElement.style.visibility = "hidden";
          empty_search.style.visibility = "visible";
        } else {
          empty_search.style.visibility = "hidden";
        }
      },
      this,
      true
    );
  }

  rejectsFocus = () => {
    // Reject the focus if the modelSource is not complete and e have filter or if the modelSource is complete and no data
    const isComplete = this.modelSource.isComplete$.value;
    return (
      (this.modelSource.fetching$.value && this._pageHasFilters) || (isComplete && this.modelSource.value.length === 0)
    );
  };

  onNav = (key: DS.Keys) => {
    // blocking down navigation if we are fetching a page
    if (key === DS.Keys.down && this.modelSource.fetching$.value) {
      return true;
    }
    return false;
  };

  private _modelSource(contentPath: string): PaginatedModelSource<Card> {
    switch (this._mosaicType) {
      case "mosaicFavorites":
        return APIAuvio.widgetPaginated(contentPath, WidgetFavoritesMosaicResponse, WidgetMosaicFavorite);
      case "mosaicPlayHistory":
        return APIAuvio.widgetPaginated(contentPath, WidgetPlayHistoryMosaicResponse, WidgetMosaicPlayHistory);
      default:
        return APIAuvio.widgetPaginated(contentPath, WidgetMosaicPaginatedResponse, WidgetMosaic);
    }
  }
}
