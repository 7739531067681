import "./contentDetailsTitleView.scss";

import { DS } from "~/libs";

export class ContentDetailsTitleView extends DS.View {
  constructor(title: string) {
    super("", "contentDetailsTitleView");
    this.rootElement.innerText = title;
  }

  rejectsFocus() {
    return true;
  }
}
