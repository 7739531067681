import "./progressBarView.scss";

import { DS } from "~/libs";

export class ProgressBarView extends DS.View {
  private _progressionElt: HTMLDivElement;
  private _duration;

  constructor(type: "media" | "live", mediaDuration?: number) {
    super(`progressBar_${Math.random().toString(36).substring(2, 9)}`, `progressBarView ${type} background`);

    this._duration = mediaDuration;

    this._progressionElt = DS.DOMHelper.createElement({
      tagName: "div",
      className: "progression",
      parent: this.rootElement,
    });
  }

  updateCurrenOffset(currentOffset: number, duration?: number) {
    if (duration !== undefined) {
      this._duration = duration;
    }
    if (this._duration !== undefined && this._duration > 0) {
      this.setPercentageProgression((currentOffset / this._duration) * 100);
    } else {
      this.setPercentageProgression(0);
    }
  }

  setPercentageProgression(percentage: number) {
    this._progressionElt.style.width = `${Math.min(Math.max(0, percentage), 100)}%`;
  }
}
