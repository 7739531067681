import { IDelegate } from ".";

/** define the lifecycle of a view in the lib */
export const enum IViewPersistency {
  /** a view won't be cached, always recreated when model changes */
  none = "none",
  /** a view will be cached when it can, will still be released when parent is destroyed */
  cache = "cache",
  /** a view will persist and will never be released */
  static = "static",
}

export interface IView extends IDelegate {
  /** the DOM element managed by this view
   */
  readonly rootElement: HTMLDivElement;
  /** onRelease will be called on this view depending on this value
   */
  persistency?: IViewPersistency;
  /** the delegate which will handle release, focus, show, hide, nav functions if the view doesn't implement them
   */
  delegate?: IDelegate;
}

export interface IPage extends IView {
  /** for a page to show up on top of another one, without hiding the previous one, it needs to be a "popup".
   * define this function to return true if it's the wanted behavior
   * @default false if not defined
   */
  isPopup?: () => boolean;
}
