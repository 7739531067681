import "./lockView.scss";

import { DS } from "~/libs";

export class LockView extends DS.View {
  constructor(parent: HTMLDivElement) {
    super("", "lockView");
    const lock = require("@assets/images/icons/lock.png");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: parent,
      className: "lock",
      style: {
        backgroundImage: `url(${lock})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }
}
