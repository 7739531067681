import "./modifyLevelPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";

import { ParentalButtonView } from "../../../components/views/parental/parentalButtonView";
import { WidgetView } from "../../../components/widgets/widgetView";
import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GIGYA } from "../../../utils/gigya";
import { ValidateLevelParentalPopupPage } from "../parentalPopupPage";

export class ModifyLevelPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _currentParentalControlLevel: GIGYA.ParentalControlLevel;
  private _modifyLevelWidgetView?: ModifyLevelWidgetView;

  constructor() {
    super("ModifyLevelView", "modifyLevelView");

    this._currentParentalControlLevel = ParentalControlHelper.currentParentalControl();

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "modifyLevelTitle",
      innerText: t("parentalControl.modifyLevel.pageTitle"),
    });

    this.delegate = DS.createListComponent<"widget" | "button", ModifyLevelWidgetView | ParentalButtonView>(
      {
        id: "modifyLevelWidget",
        className: "modifyLevelWidgetList",
        modelSource$: new DS.ModelSource(["widget", "button"]),
        viewFactory: item => {
          switch (item) {
            case "widget":
              return (this._modifyLevelWidgetView = new ModifyLevelWidgetView(this._currentParentalControlLevel));
            case "button":
              return new ParentalButtonView(false);
          }
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: item => {
          if (item === "button" && this._modifyLevelWidgetView !== undefined) {
            void this.validateParentalControlChange(
              this._currentParentalControlLevel,
              this._modifyLevelWidgetView.currentSelectedParentalControlLevel
            );
          }
          return true;
        },
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }

  async validateParentalControlChange(
    currentParentalControlLevel: GIGYA.ParentalControlLevel,
    newParentalControlLevel: GIGYA.ParentalControlLevel
  ) {
    navigationStack.removePage(navigationStack.topPage);

    if (currentParentalControlLevel === newParentalControlLevel) return;

    const isDowngrade = ParentalControlHelper.isParentalControlLevelDowngrade(
      currentParentalControlLevel,
      newParentalControlLevel
    );

    if (isDowngrade) {
      await ParentalControlHelper.updateParental(undefined, newParentalControlLevel, new Date(0));
      return;
    }

    navigationStack.pushPage(new ValidateLevelParentalPopupPage(newParentalControlLevel));
  }

  isPopup = () => true;
}
class ModifyLevelItemWidgetView extends AcceptsMouseFocusView {
  private _id: string;
  private _csa?: HTMLImageElement;
  private _radioButtonIconElt: HTMLImageElement;
  constructor(id: string, selected = false) {
    super(id, "modifyLevelItemWidgetView");
    this._id = id;
    const filterItem = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "filterMenuItem",
    });

    const radiobutton = DS.DOMHelper.createElement({
      tagName: "span",
      parent: filterItem,
      className: "radioButton",
    });
    this._radioButtonIconElt = DS.DOMHelper.createElement({
      tagName: "img",
      parent: radiobutton,
      className: "radioButtonIcon",
    });

    if (id === "all") {
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: filterItem,
        className: "labelRadioButton",
        innerText: t("parentalControl.modifyLevel.deactivated"),
      });
    } else {
      const label = DS.DOMHelper.createElement({
        tagName: "span",
        parent: filterItem,
        className: "labelRadioButton",
      });
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: label,
        className: "text",
        innerText: t("parentalControl.modifyLevel.limitedTo"),
      });
      this._csa = DS.DOMHelper.createElement({
        tagName: "img",
        parent: label,
        className: "textLogo",
      });
      this._csa.src = require("@assets/images/csa/csa" + id + ".png");
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: label,
        className: "text",
        innerText: t("parentalControl.modifyLevel.yearsOld"),
      });
    }

    this.setState(selected);
  }

  setState(active: boolean) {
    this._radioButtonIconElt.src = active
      ? require("@assets/images/radioButton/selected.png")
      : require("@assets/images/radioButton/notSelected.png");
    if (active) this.rootElement.classList.add("selected");
    else this.rootElement.classList.remove("selected");
    return true;
  }

  getId() {
    return this._id;
  }

  onFocused() {
    if (this._csa) this._csa.src = require(`@assets/images/csa/csa${this._id}-black.png`);
  }

  onUnfocused() {
    if (this._csa) this._csa.src = require(`@assets/images/csa/csa${this._id}.png`);
  }
}

class ModifyLevelWidgetView extends WidgetView {
  private _descriptionContainer: HTMLElement;
  private _list: DS.IListComponent<GIGYA.ParentalControlLevel, ModifyLevelItemWidgetView>;
  private _currentSelectedParentalControlLevel: GIGYA.ParentalControlLevel;

  constructor(currentParentalControlLevel: GIGYA.ParentalControlLevel) {
    super("ModifyLevelWidgetView", "modifyLevelWidgetView");

    this._currentSelectedParentalControlLevel = currentParentalControlLevel;

    this._descriptionContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "modifyLevelWidgetTitle",
      innerText: t("parentalControl.modifyLevel.deactivated_description"),
    });

    this.setDescription(ParentalControlHelper.currentParentalControl());

    this.delegate = this._list = DS.createListComponent({
      id: "modifyLevelWidget",
      className: "modifyLevelWidget",
      modelSource$: new DS.ModelSource([
        GIGYA.ParentalControlLevel.all,
        GIGYA.ParentalControlLevel.sixteen,
        GIGYA.ParentalControlLevel.twelve,
      ]),
      viewFactory: item => {
        return new ModifyLevelItemWidgetView(item, ParentalControlHelper.currentParentalControl() === item);
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
      onSelect: item => {
        this._list.viewFromId(item)?.setState(true);
        this._list.viewFromId(this._currentSelectedParentalControlLevel)?.setState(false);
        this.setDescription(item);
        this._currentSelectedParentalControlLevel = item;
        return true;
      },
    });
  }

  get currentSelectedParentalControlLevel() {
    return this._currentSelectedParentalControlLevel;
  }

  setDescription(idLevel: GIGYA.ParentalControlLevel) {
    if (idLevel === GIGYA.ParentalControlLevel.all) {
      this._descriptionContainer.innerText = t("parentalControl.modifyLevel.deactivated_description");
    } else {
      this._descriptionContainer.innerHTML = "";
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: this._descriptionContainer,
        className: "modifyLevelDescText",
        innerText: t("parentalControl.modifyLevel.description"),
      });

      const PCLevels = Object.values(GIGYA.ParentalControlLevel).slice(
        Object.values(GIGYA.ParentalControlLevel).indexOf(idLevel),
        -1
      );
      PCLevels.forEach((level, index) => {
        try {
          DS.DOMHelper.createElement({
            tagName: "img",
            parent: this._descriptionContainer,
            className: "modifyLevelDescLogo",
          }).src = require("@assets/images/csa/csa" + level + "-orange.png");
        } catch (error: unknown) {
          Log.app.error("Can't find the csa with: ", level);
        }

        if (index < PCLevels.length - 1) {
          DS.DOMHelper.createElement({
            tagName: "span",
            parent: this._descriptionContainer,
            className: "modifyLevelDescText icons",
            innerText: t("parentalControl.modifyLevel.and"),
          });
        }
      });

      DS.DOMHelper.createElement({
        tagName: "span",
        parent: this._descriptionContainer,
        className: "modifyLevelDescText icons yellow",
        innerText: t("parentalControl.modifyLevel.yearsOld"),
      });
    }
  }
}
