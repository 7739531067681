import "./incentivePage.scss";

import { CallFlow } from "~/callflow";
import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { Config } from "~/config";
import { DS } from "~/libs";
import { RegistrationPageView } from "~/pages/incentive/registration/registrationPage";
import { deeplinkManager } from "~/tools/deeplinkManager";
import { TextHelper } from "~/tools/textHelper";
import { CallbackVoid } from "~/typings";

import { navigationStack } from "../../main";
import { DevicePreferenceHelper } from "../../tools/devicePreferencesManager";
import { SettingsHelper } from "../../tools/settingsHelper";
import { GenericPage } from "../generic/genericPage";
import { PairingPageView } from "../pairing/pairingPage";

export enum IncentiveType {
  launchConnexion = "launchConnexion",
  profileNotConnected = "profileNotConnected",
  myContent = "mycontent",
  connexion = "connexion",
  logout = "logout",
}

enum IncentiveButtonType {
  connect = "connect",
  registration = "registration",
  notNow = "notNow",
  logoutYes = "logoutYes",
  logoutNo = "logoutNo",
}

class IncentiveButton extends AcceptsMouseFocusView {
  constructor(buttonType: string) {
    super(buttonType + "Action", "actionButtonContainer");

    const interstitiel = SettingsHelper.instance.data?.copywriting.loginInterstitiel;
    const button = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "actionButton",
    });

    if (buttonType === IncentiveButtonType.connect && interstitiel !== undefined)
      button.innerText = interstitiel.loginLabel;
    else if (buttonType === IncentiveButtonType.notNow && interstitiel !== undefined)
      button.innerText = interstitiel.cancelLabel;
    else button.innerText = t(`incentive.button.${buttonType}Button`);
  }
}

export class GenericIncentivePageFull extends AcceptsMouseFocusView implements DS.IPage {
  private _loginSuccessCallback;
  private _type;

  constructor(type: IncentiveType, loginSuccessCallback: CallbackVoid | undefined = undefined) {
    super("incentivePageView", `incentivePageView ${type}`);
    this._type = type;
    this._loginSuccessCallback = loginSuccessCallback;

    const interstitiel = SettingsHelper.instance.data?.copywriting.loginInterstitiel;

    const incentiveLogo = require("@assets/images/incentive/logo.png");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "incentiveLogo",
      style: {
        backgroundImage: `url(${incentiveLogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    // Title is displayed only only for Mon Auvio
    if (type === IncentiveType.myContent) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "title",
        innerText: t("generic.my_content"),
      });
    }

    const descContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "descContainer",
    });

    if (type === IncentiveType.logout) {
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: descContainer,
        className: "desc",
        innerText: t("incentive.logout"),
      });
    } else {
      const descriptionElement = DS.DOMHelper.createElement({
        tagName: "span",
        className: "desc",
      });
      // Create description text and replace Auvio occurences with a yellow span
      const splittedDescription = TextHelper.sanitizeString(interstitiel?.title ?? "").split("Auvio");
      splittedDescription.forEach((item, index) => {
        DS.DOMHelper.createElement({
          tagName: "span",
          parent: descriptionElement,
          innerText: TextHelper.sanitizeString(item),
        });
        if (index < splittedDescription.length - 1) {
          DS.DOMHelper.createElement({
            tagName: "span",
            parent: descriptionElement,
            className: "boldDesc",
            innerText: "Auvio",
          });
        }
      });
      descContainer.appendChild(descriptionElement);
    }

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "incentiveImg",
      style: {
        backgroundImage: `url(${interstitiel?.logoUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    const modelSource = {
      launchConnexion: [IncentiveButtonType.connect, IncentiveButtonType.registration, IncentiveButtonType.notNow],
      profileNotConnected: [IncentiveButtonType.connect, IncentiveButtonType.registration],
      mycontent: [IncentiveButtonType.connect],
      connexion: [IncentiveButtonType.connect, IncentiveButtonType.registration, IncentiveButtonType.notNow],
      logout: [IncentiveButtonType.logoutYes, IncentiveButtonType.logoutNo],
    };

    this.delegate = DS.createListComponent(
      {
        id: "buttonsContainer",
        className: "buttonsContainer",
        modelSource$: new DS.ModelSource(modelSource[type]),
        viewFactory: item => {
          return new IncentiveButton(item);
        },
        onSelect: item => {
          switch (item) {
            case IncentiveButtonType.connect:
              navigationStack.pushPage(new PairingPageView(this._loginSuccessCallback));
              return true;
            case IncentiveButtonType.registration:
              navigationStack.pushPage(new RegistrationPageView());
              return true;
            case IncentiveButtonType.notNow:
              this._notNowAction();
              return true;
            case IncentiveButtonType.logoutNo:
              navigationStack.removePage(this);
              return true;
            case IncentiveButtonType.logoutYes:
              void this.logout();
              return true;
            default:
              return true;
          }
        },
        scrollingMode: {
          type: DS.ScrollingType.anchored,
          horizontal: false,
        },
        mouseSupport: Config.mouseSupport,
        crossSectionWidth: 2,
        noTransform: true,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }

  async logout() {
    await CallFlow.logout();
  }

  isPopup = () => {
    return true;
  };

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.back:
        if (this._type === IncentiveType.launchConnexion) {
          this._notNowAction();
          return true;
        }
    }
    return false;
  }

  private _notNowAction() {
    navigationStack.removePage(this);

    if (typeof deeplinkManager.callback === "function") {
      deeplinkManager.callback();
    } else if (this._type === IncentiveType.launchConnexion) {
      if (!DevicePreferenceHelper.kidsEnable$.value) {
        navigationStack.pushPage(new GenericPage("/home"));
      } else {
        navigationStack.pushPage(new GenericPage("/kids"));
      }
    }
  }
}
