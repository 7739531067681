import "./contentView.scss";

import { AcceptsMouseFocusView } from "../views/common/mouseSupport/acceptsMouseFocusView";

export class ContentView extends AcceptsMouseFocusView {
  constructor(id: string, classname: string) {
    super(id, `content ${classname}`);
  }
}

export enum ContentItem {
  subtitle = "subtitle",
  categoryChannel = "categoryChannel",
  description = "description",
  casting = "casting",
  tags = "tags",
  contentButtonsContainer = "contentButtonsContainer",
  counter = "counter",
  timeline = "timeline",
  title = "title",
  titleGraphic = "titleGraphic",
  meta = "meta",
}
