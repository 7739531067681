import { APIRedbee } from "~/datas";
import { DS } from "~/libs";
import { ApiAccountPurchase } from "~/utils/redbee/models";
import { ProductCard } from "~/utils/rtbf/models";
import { ProductOffering } from "~/utils/rtbf/models/base";

import { APIGigyaOIDC } from "../datas/api/apiGigyaOIDC";

/**
 * PremiumHelper
 * Used to keep and refresh purchase list in a global state, with functions related to those purchases
 *
 */
export class PremiumManager {
  private static _instance?: PremiumManager;
  private _activePurchases$ = new DS.Listenable<ApiAccountPurchase[]>([]);
  private _consumedProductOfferingDiscounts$ = new DS.Listenable<string[]>([]);

  static get instance() {
    return (PremiumManager._instance = PremiumManager._instance ?? new PremiumManager());
  }

  /**
   * Fetch user consumed free periods and purchases
   * @returns {Promise<void>}
   */
  async updatePurchases(type: "" | "purchase" | "purchases" = ""): Promise<void> {
    try {
      if (type === "" || type === "purchase") {
        void APIRedbee.getPurchase().then(value => {
          this._consumedProductOfferingDiscounts$.value = value.consumedProductOfferingDiscounts;
        });
      }
      if (type === "" || type === "purchases") {
        void APIRedbee.getAccountPurchases().then(value => {
          this._activePurchases$.value = this._filterPurchases(value);
        });
      }
    } catch (error) {
      Log.app.error("Error while fetching purchases", error);
    }
  }

  /**
   * Filter a list of purchases based on from and until dates
   * @param {ApiAccountPurchase[]} purchases List of purchases
   * @returns {string[]} Array of active productIds
   */
  private _filterPurchases(purchases: ApiAccountPurchase[]): ApiAccountPurchase[] {
    return purchases.filter(purchase => {
      if (purchase.from === undefined || purchase.until === undefined) return false;
      if (new Date(purchase.from) < new Date() && new Date() < new Date(purchase.until)) return true;
      return false;
    });
  }

  /**
   * Check if a product offering has been purchased
   * @param {productOffering} productOffering
   * @returns {boolean} Is the product offering purchased
   */
  isProductOfferingPurchased(productOffering: ProductOffering): boolean {
    if (APIGigyaOIDC.isConnected() === false) return false;

    return this._getPurchasedProductOfferings().includes(productOffering.productOfferingId);
  }

  /**
   * Return the list of purchased listenables
   * @returns {DS.IListenable<ApiAccountPurchase[]>} Listenable of actives purchases
   */
  getPurchases(): DS.IListenable<ApiAccountPurchase[]> {
    return this._activePurchases$;
  }

  /**
   * Return only the ids of purchases
   * @returns {string[]}
   */
  getPurchasedIds(): string[] {
    return this._activePurchases$.value.map(purchase => purchase.productIds).flat();
  }

  /**
   * Return only the productOfferingIds of purchases
   * @returns {string[]}
   */
  private _getPurchasedProductOfferings(): string[] {
    // Since TypeScript doesn't figure out when undefined is filtered in .filter(), undefined values are transformed to empty string that are filtered after
    return this._activePurchases$.value
      .map(purchase => purchase.productOfferingId ?? "")
      .filter(purchase => purchase !== "");
  }

  /**
   * Check if a list of products match a subscription
   * @param {ProductCard[]} products
   * @returns {boolean}
   */
  isPurchased(products: ProductCard[]): boolean {
    let isPurchased = false;
    products.forEach(product => {
      this.getPurchasedIds().forEach(purchasedId => {
        if (purchasedId === product.id) isPurchased = true;
      });
    });
    return isPurchased;
  }

  /**
   * Check if the free period of a product offering was consumed
   * @param {string} productOfferingId
   * @returns {boolean}
   */
  isFreePeriodConsumed(productOfferingId?: string): boolean {
    if (productOfferingId === undefined) return false;
    return this._consumedProductOfferingDiscounts$.value.includes(productOfferingId);
  }

  /**
   * Reset the list of purchases
   */
  clearPurchases(): void {
    this._activePurchases$.value = [];
    this._consumedProductOfferingDiscounts$.value = [];
  }

  /**
   * Return a formatted price from a product offering
   * @param {ProductOffering} productOffering
   * @returns {string}
   */
  static priceFromProductOffering(productOffering: ProductOffering): string {
    const price = productOffering.discount?.price ?? productOffering.offeringPrice.price;
    if (price === undefined || price?.amount === undefined) return "";

    const amount =
      price.fractionDigits !== undefined ? price.amount / Math.pow(10, price.fractionDigits) : price.amount;

    return `${new Intl.NumberFormat("fr-BE").format(amount)}${price.currency === "EUR" && "€"}`;
  }
}

export const PremiumHelper = PremiumManager.instance;
