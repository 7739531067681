import { z } from "zod";

export const ProgramChangedData = z.object({
  program: z
    .object({
      asset: z
        .object({
          assetId: z.string().nullish(),
        })
        .nullish(),
    })
    .nullish(),
  upnext: z
    .object({
      asset: z
        .object({
          assetId: z.string().nullish(),
        })
        .nullish(),
    })
    .nullish(),
});

const ApiAsset = z.object({
  assetId: z.string(),
});

export const ApiChannelAsset = z.object({
  asset: ApiAsset,
  startTime: z.string(),
  endTime: z.string(),
});
export type ApiChannelAsset = z.infer<typeof ApiChannelAsset>;

export const ApiChannelOnNowResponse = z.object({
  assets: z.array(ApiChannelAsset),
});
export type ApiChannelOnNowResponse = z.infer<typeof ApiChannelOnNowResponse>;
