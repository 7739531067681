import "./contentTitleGraphicView.scss";

import { DS } from "~/libs";

export class ContentTitleGraphicView extends DS.View {
  constructor(link: string | undefined) {
    super("", "titleGraphicView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentTitleGraphic",
      style: {
        backgroundImage: `url(${link})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }

  rejectsFocus() {
    return true;
  }
}
