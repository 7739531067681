import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { navigationStack } from "../../main";
import { ITextElement, TextHelper } from "../../tools/textHelper";
import { RTBF } from "../../utils/rtbf";
import { FilterCategoryPageView, FilterGenrePageView } from "./filterPage";

class FilterButton extends AcceptsMouseFocusView {
  protected _id: string | undefined;
  protected _label: ITextElement;
  constructor() {
    super("", "filterAction");
    this._label = TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "filterButtonTitle",
      content: "",
    });
  }
}

export class FilterCategoryButton extends FilterButton {
  private _selectedCategory$: DS.Listenable<string>;
  categories: RTBF.CategoryTable;
  constructor(categories: RTBF.CategoryTable = [], selectedCategory$: DS.Listenable<string>) {
    super();
    this.categories = categories;
    this._selectedCategory$ = selectedCategory$;

    this.id = undefined;
  }

  onSelect() {
    navigationStack.pushPage(new FilterCategoryPageView(this));
  }

  set id(id: string | undefined) {
    this._id = id ?? this.categories[0].id;
    this.categories.forEach(category => {
      if (category.id === this._id) this._label.update(category.label);
    });
    this._selectedCategory$.value = this._id;
  }

  get id() {
    return this._id;
  }
}

export class FilterGenreButton extends FilterButton {
  widgets: RTBF.Widget[];
  title: string;
  constructor(widgets: RTBF.Widget[], title: string) {
    super();
    this.widgets = widgets;
    this.title = title;
    this._label.update(title);
    this._id = this.widgets[0].id;
  }

  onSelect() {
    navigationStack.pushPage(new FilterGenrePageView(this));
  }

  set id(id: string | undefined) {
    this._id = id ?? this.widgets[0].id;
    this.widgets.forEach(item => {
      if (item.id === this._id) this._label.update(item.title);
    });
    // this.selectedCategory$.value = this.id;
  }

  get id(): string | undefined {
    return this._id;
  }
}
