import { DS } from "../..";
import { createListComponent } from "../../ui/components/createListComponent";
import { ModelSource } from "../../ui/components/modelSource";
import { View } from "../../ui/components/view";
import * as DOMHelper from "../../ui/helpers/DOMHelper";
import { IDelegate, IPage, Keys } from "../../ui/typings";
import { IListenable } from "../../ui/typings/iListenable";
import { LogFactory } from "../logFactory";
import { LogInfo } from "../logInfo";
import { logNavigationStack } from "../logNavigationStack";
import { outputConsoleRe } from "../outputs/outputConsoleRe";
import { outputRemoteJS } from "../outputs/outputRemoteJS";
import { ICustomMenuEntry, LogOutput, StatsPane } from "../types";
import { CodePopup } from "./codePopup";
import { LogQR } from "./QR";
import { LogStats } from "./stats";

DOMHelper.addStyleSheet(`
.DSMenu {
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1002;
  position: absolute;
  left: 2%;
  top: 3.5%;
  width: 320px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(51, 51, 51, 0.9);
  box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.3);
}

.DSMenuList {
  height: 100vh;
}

.DSMenuItem {
  width: 300px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  margin-bottom: 1px;
  border-radius: 2px;
  background-color: rgba(34, 34, 34, 0.5);
  color: white;
}
.DSMenuItem.focused {
  background-color: darkred;
}

.DSMenuItem .title {
  position: absolute;
  left: 2px;
}

.DSMenuItem .desc {
  position: absolute;
  right: 28px;
  color: gray;
}

.DSMenuItem #checkbox {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 18px;
  height: 18px;
  border: 1px solid #555;
  background-color: #333;
}
.DSMenuItem #checkbox.selected {
  background-color: white;
}
`);

type MainMenuID = "Log Output" | "Stats Widget" | "Show Session QR";

interface IMenuItem {
  id: string;
  title: string;
  desc?: string;
  selectedId$?: IListenable<string>;
}

class MenuItemView extends View {
  focusable = false;
  constructor(menuItem: IMenuItem, focusable = false) {
    super(menuItem.id, "DSMenuItem");
    this.focusable = focusable;

    DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "title",
      innerText: menuItem.title[0].toUpperCase() + menuItem.title.substr(1),
    });

    if (menuItem.selectedId$) {
      const checkBox = DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        id: "checkbox",
        className: menuItem.id === menuItem.selectedId$.value ? "selected" : undefined,
      });

      menuItem.selectedId$.didChange(
        id => {
          if (id === menuItem.id) {
            checkBox.classList.add("selected");
          } else {
            checkBox.classList.remove("selected");
          }
        },
        this,
        true
      );
    }

    DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "desc",
      innerText: menuItem.desc,
    });
  }

  rejectsFocus = () => this.focusable;
}

class LogMenuItemView extends MenuItemView {
  constructor(menuItem: IMenuItem) {
    super(menuItem);

    const descElement = DOMHelper.createElement({ tagName: "div", parent: this.rootElement, className: "desc" });
    const updateDesc = () => {
      switch (menuItem.id) {
        case "default":
          descElement.innerText = `${LogFactory._appOutput}`;
          break;

        case "consoleRe":
          descElement.innerText = outputConsoleRe.attached$.value ? outputConsoleRe.channel ?? "" : "";
          break;

        case "remoteJS":
          descElement.innerText = outputConsoleRe.attached$.value ? outputRemoteJS.channel ?? "" : "";
          break;
      }
    };

    updateDesc();
    LogFactory._code$.didChange(updateDesc, this);
    outputRemoteJS.attached$.didChange(updateDesc, this);
    outputConsoleRe.attached$.didChange(updateDesc, this);
  }
}

class SubMenu extends View implements IDelegate {
  constructor(id: string, originElement: HTMLElement) {
    super(id, "DSMenu");
    const rect = originElement.getBoundingClientRect();
    this.rootElement.style.left = `${rect.right * DS.platform.screenScale()}px`;
    // padding
    this.rootElement.style.top = `${rect.top * DS.platform.screenScale() - 5}px`;
  }

  isPopup = () => true;

  onNav = (key: Keys) => {
    if (key === Keys.left) {
      logNavigationStack?.removePage(this);
      return true;
    }
    return false;
  };
}
class LogMenu extends SubMenu {
  constructor(originElement: HTMLElement) {
    super("LogMenu", originElement);

    this.delegate = createListComponent(
      {
        id: "LogMenu/list",
        className: "DSMenuList",
        modelSource$: new ModelSource<LogOutput | "default">([
          "default",
          "buffer",
          "console",
          "overlay",
          "consoleRe",
          "remoteJS",
        ]),
        viewFactory: itemId =>
          new LogMenuItemView({
            id: itemId ?? "default",
            title: itemId ?? "App Default",
            selectedId$: LogFactory._storageOutput$,
          }),
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        onSelect: itemId => {
          switch (itemId) {
            case "remoteJS":
              if (outputRemoteJS.attached$.value) {
                // don't ask code again if already connected
                LogFactory._storageOutput$.value = itemId;
              } else {
                logNavigationStack?.pushPage(
                  new CodePopup("RemoteJS code?", (code?: string) => {
                    if (code !== undefined) {
                      LogFactory._code$.value = code;
                      LogFactory._storageOutput$.value = itemId;
                    }
                  })
                );
              }
              break;

            case "consoleRe":
              if (outputConsoleRe.attached$.value) {
                // don't ask code again if already connected
                LogFactory._storageOutput$.value = itemId;
              } else {
                logNavigationStack?.pushPage(
                  new CodePopup("ConsoleRe code?", (code?: string) => {
                    if (code !== undefined) {
                      LogFactory._code$.value = code;
                      LogFactory._storageOutput$.value = itemId;
                    }
                  })
                );
              }
              break;

            default:
              LogFactory._storageOutput$.value = itemId;
              return true;
          }
          return false;
        },
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }
}

class StatsMenu extends SubMenu {
  constructor(originElement: HTMLElement) {
    super("StatsMenu", originElement);

    this.delegate = createListComponent(
      {
        id: "StatsMenu/list",
        className: "DSMenuList",
        modelSource$: new ModelSource<StatsPane>(["Off", "DOM Count", "FPS", "Memory"]),
        viewFactory: itemId => new MenuItemView({ id: itemId, title: itemId, selectedId$: LogStats.mode$ }),
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        onSelect: itemId => {
          switch (itemId) {
            default:
              LogStats.setMode(itemId);
              return true;
          }
          return false;
        },
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }
}

export class MainMenu extends View implements IPage {
  list;
  customEntries: Record<string, ICustomMenuEntry>;

  constructor(customEntries: Record<string, ICustomMenuEntry>) {
    super("main", "DSMenu");

    this.customEntries = customEntries;
    this.delegate = this.list = createListComponent(
      {
        id: "mainMenu/list",
        className: "DSMenuList",
        modelSource$: new ModelSource([
          "appInfo",
          "sessionId",
          "Log Output",
          "Stats Widget",
          "Show Session QR",
          ...Object.keys(customEntries).map(id => `custom_${id}`),
        ]),
        viewFactory: menuId => {
          switch (menuId) {
            case "appInfo":
              return new MenuItemView(
                {
                  id: menuId,
                  title: "app",
                  desc: `${LogInfo.appName}${LogInfo.appVersion ? " v" + LogInfo.appVersion : ""}${
                    LogInfo.appHash ? " (" + LogInfo.appHash + ")" : ""
                  }`,
                },
                true
              );
            case "sessionId":
              return new MenuItemView({ id: menuId, title: "sessionId", desc: `${LogInfo.sessionId}` }, true);
            case "Log Output":
            case "Stats Widget":
              return new MenuItemView({ id: menuId, title: menuId, desc: ">" });

            case "Show Session QR":
              return new MenuItemView({
                id: "true",
                title: menuId,
                selectedId$: LogQR.visible$,
              });

            // custom entries
            default:
              return new MenuItemView(customEntries[menuId.replace("custom_", "")]);
          }
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        onSelect: this.onSelect,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }

  onSelect = (menuId: string) => {
    switch (menuId) {
      case "Log Output":
        logNavigationStack?.pushPage(new LogMenu(this.list.viewFromId(menuId)!.rootElement));
        break;

      case "Stats Widget":
        logNavigationStack?.pushPage(new StatsMenu(this.list.viewFromId(menuId)!.rootElement));
        break;

      case "Show Session QR":
        LogQR.visible$.value = LogQR.visible$.value === "true" ? "false" : "true";
        break;

      default:
        this.customEntries[menuId.replace("custom_", "")]?.onSelect();
        logNavigationStack?.destroyStack();
        break;
    }
    return false;
  };

  onNav = (key: Keys) => {
    switch (this.list.focusedId$.value as MainMenuID | undefined) {
      case "Log Output":
      case "Stats Widget":
        if (key === Keys.right) {
          this.onSelect(this.list.focusedId$.value as MainMenuID);
          return true;
        }
        break;
    }
    return false;
  };
}
