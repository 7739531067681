import * as DOMHelper from "../../ui/helpers/DOMHelper";
import { ILogEntry, LogLevel } from "../types";
import { outputBuffer } from "./outputBuffer";

// from the log style, we know we can have at the most 76 single lines
const VISIBLE_LINES = 76;

DOMHelper.addStyleSheet(`
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

#DSLogOverlay {
  z-index: 1000;
  width: 95%;
  height: 93%;
  left: 2%;
  top: 3.5%;
  opacity: 0.8;
  font-size: 15px;
  line-height: 15px;
  position: absolute;
  background-color: #444;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: -1px;
  color: #FFF;
  padding: 4px;
  overflow: hidden;
  transform: scaleY(-1);
  pointer-events: none;
}

#DSLogOverlay div {
  word-break: break-all;
  transform: scaleY(-1);
}
#DSLogOverlay div span {
  color: #FFF;
  font-weight: bold; 
}

#DSLogOverlay div span.caller {
  color: #777;
  right: 0px;
  position: absolute;
}

#DSLogOverlay div.error {
  color: rgb(255, 68, 68);
  font-weight: bold;  
}

#DSLogOverlay div.warn {
  color: #FF4;
}

#DSLogOverlay div.info {
  color: #8FF;
}

#DSLogOverlay div.debug {
    color: #8BB;
}

#DSLogOverlay div.trace {
  color: #799;
}
`);

const logLevelToStyle = {
  [LogLevel.off]: "off",
  [LogLevel.trace]: "trace",
  [LogLevel.debug]: "debug",
  [LogLevel.info]: "info",
  [LogLevel.warn]: "warn",
  [LogLevel.error]: "error",
};
class OutputOverlay {
  overlayElement: HTMLDivElement;

  constructor() {
    this.overlayElement = DOMHelper.createElement({ tagName: "div", id: "DSLogOverlay" });
    this.overlayElement.dir = "ltr";
  }

  trace = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.trace, namespace, ...data) &&
      this.logEntry(outputBuffer.buffer[outputBuffer.buffer.length - 1]);
  };
  debug = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.debug, namespace, ...data) &&
      this.logEntry(outputBuffer.buffer[outputBuffer.buffer.length - 1]);
  };
  log = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.info, namespace, ...data) &&
      this.logEntry(outputBuffer.buffer[outputBuffer.buffer.length - 1]);
  };
  info = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.info, namespace, ...data) &&
      this.logEntry(outputBuffer.buffer[outputBuffer.buffer.length - 1]);
  };
  warn = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.warn, namespace, ...data) &&
      this.logEntry(outputBuffer.buffer[outputBuffer.buffer.length - 1]);
  };
  error = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.error, namespace, ...data);
    this.logEntry(outputBuffer.buffer[outputBuffer.buffer.length - 1]);
  };

  /**
   * used internally to build message DOM and display it in DOM
   * @param entry - the log message
   */
  logEntry(entry: ILogEntry) {
    if (entry.level === LogLevel.off) return;

    const htmlElement = DOMHelper.createElement({ tagName: "div", className: logLevelToStyle[entry.level] });
    DOMHelper.createElement({ tagName: "span", parent: htmlElement, innerText: entry.timestampAsString });
    htmlElement.appendChild(
      document.createTextNode(` ${entry.levelAsString} [${entry.namespace.toUpperCase()}] ${entry.text}`)
    );
    entry.caller &&
      DOMHelper.createElement({
        tagName: "span",
        parent: htmlElement,
        className: "caller",
        innerText: `${entry.caller.file} L${entry.caller.line}:${entry.caller.col}`,
      });
    this.pushHTMLElement(htmlElement);
  }

  /**
   * used internally to push an HTMLElement in Overlay
   * @param element - HTMLElement to push in Overlay
   */
  pushHTMLElement(element: HTMLElement) {
    this.overlayElement?.insertBefore(element, this.overlayElement?.firstElementChild);
    while (this.overlayElement.lastElementChild && this.overlayElement.childElementCount > VISIBLE_LINES)
      this.overlayElement.removeChild(this.overlayElement.lastElementChild);
  }

  show = async () => {
    document.getElementById("DSLogContainer")?.appendChild(this.overlayElement);
    this.overlayElement.hidden = false;
    outputBuffer.buffer.forEach(entry => {
      this.logEntry(entry);
    });
  };

  hide = () => {
    this.overlayElement.parentNode?.removeChild(this.overlayElement);
    this.overlayElement.innerHTML = "";
  };
}

export const outputOverlay = new OutputOverlay();
