import "../common.scss";
import "./tvLiveTileView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { ProgressBarView } from "~/components/views/common/progressBar/progressBarView";
import { DS } from "~/libs";
import { capitalize } from "~/tools/textHelper";

import {
  addDays,
  getFormatDate,
  getFormatTime,
  getLongName,
  getMonthName,
  getProgressPercentage,
  isSameDay,
} from "../../../tools/time";
import { getClassType } from "../../../tools/uiHelper";
import { RTBF } from "../../../utils/rtbf";
import { ClassType } from "../../views/content/contentButtonView/contentButtonView";
import { CsaRatingView } from "../../views/csaView/csaRatingView";
import { FavorisView } from "../../views/favorisView/favorisView";
import { StampView } from "../../views/stampView/stampView";

export class TVLiveTileView extends AcceptsMouseFocusView {
  private _setDuration: number | undefined;

  constructor(tvLive: RTBF.TvLiveCard, widgetSize: "mosaicSize" | "listSize") {
    super(tvLive.id, `tvLiveTileView ${widgetSize}`);

    let classType: ClassType;
    const cardContainerElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    const tileElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "cover",
      style: {
        backgroundImage: `url(${tvLive.illustration?.s})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "title",
      innerText: tvLive.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "subtitle",
      innerText: tvLive.subtitle,
    });
    const categoryElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "category",
    });
    const dateDiffusionTopElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "dateDiffusionTop",
    });
    const dateDiffusionBottomElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "dateDiffusionBottom",
    });
    const durationElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "duration",
    });

    if (tvLive.scheduledFrom != null && tvLive.scheduledTo != null) {
      const now = new Date();
      const startDate: Date = new Date(tvLive.scheduledFrom);
      const endDate = new Date(tvLive.scheduledTo);
      classType = getClassType(startDate, endDate);
      categoryElt.classList.add(classType);
      durationElt.classList.add(classType);

      if (classType === ClassType.futureLive) {
        // En bas à droite la durée du contenu
        durationElt.innerText = getFormatTime(tvLive.duration);

        // Diffusion date between image and title
        if (isSameDay(startDate, now) === true) {
          // if we are today format
          dateDiffusionTopElt.innerText = `${t("generic.today")} ${t("generic.time_at")} ${getFormatDate(startDate)}`;
        } else if (isSameDay(startDate, addDays(now, 1)) === true) {
          // if we are tomorrow format
          dateDiffusionTopElt.innerText = `${t("generic.tomorrow")} ${t("generic.time_at")} ${getFormatDate(
            startDate
          )}`;
        } else {
          // if neither of the above
          dateDiffusionTopElt.innerText = `${capitalize(
            getLongName(startDate, "day")
          )} ${startDate.getDate()} ${getMonthName(startDate)} ${t("generic.time_at")} ${getFormatDate(startDate)}`;
        }

        // Diffusion date at thebottom of the card
        dateDiffusionBottomElt.innerText = tvLive.releaseDate ?? "";
      } else if (classType === ClassType.currentLive) {
        // En bas à gauche rien
        // En bas à droite le temps restant

        const progressBarView = new ProgressBarView("live");
        cardContainerElt.appendChild(progressBarView.rootElement);
        progressBarView.setPercentageProgression(getProgressPercentage(startDate, tvLive.duration));

        categoryElt.innerText =
          tvLive.channelLabel !== "" ? `${tvLive.channelLabel} - ${tvLive.categoryLabel}` : tvLive.categoryLabel;
        this._setDuration = window.setInterval(() => {
          progressBarView.setPercentageProgression(getProgressPercentage(startDate, tvLive.duration));
          const now = new Date();
          const remainingTime = (endDate.getTime() - now.getTime()) / 1000;
          if (remainingTime > 60) {
            durationElt.innerText = getFormatTime(remainingTime) + t("generic.timeLefts");
            if (remainingTime < 120) durationElt.innerText = getFormatTime(remainingTime) + t("generic.timeLeft");
          } else {
            window.clearInterval(this._setDuration);
            durationElt.parentNode?.removeChild(durationElt);
          }
        }, 1000);
      }
    }

    if (tvLive.rating !== undefined) {
      new CsaRatingView(tvLive.rating, tileElt);
    }

    if (tvLive.stamp !== undefined) {
      tileElt.appendChild(new StampView(tvLive.stamp).rootElement);
    }

    tileElt.appendChild(new FavorisView(tvLive.id).rootElement);
  }

  onRelease() {
    super.onRelease();
    window.clearInterval(this._setDuration);
  }
}
