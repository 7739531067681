import "./contentDescriptionView.scss";

import { DS } from "~/libs";

export class ContentDescriptionView extends DS.View {
  private _rejectFocus;

  constructor(
    description: string,
    options = {
      rejectFocus: true,
      hideOverflow: true,
    }
  ) {
    super("", `contentDescriptionView ${options.hideOverflow === true ? "hideOverflow" : ""}`);
    this._rejectFocus = options.rejectFocus;
    this.rootElement.innerText = description;
  }

  rejectsFocus() {
    return this._rejectFocus;
  }
}
