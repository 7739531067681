import "./radioLiveWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";
import { RadioLiveCard, Widget, WidgetRadioLive } from "~/utils/rtbf/models";

import { Config } from "../../../config";
import { PlayerPage } from "../../../pages/player/playerPage";
import { getProgressPercentage } from "../../../tools/time";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { WidgetView } from "../widgetView";

class RadioLiveTileView extends AcceptsMouseFocusView {
  constructor(radioLive: RadioLiveCard) {
    super(radioLive.title ?? `radio${Math.random()}`, "tile");
    Log.app.debug("radiolive", radioLive);

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileCover",
      style: {
        backgroundImage: `url(${radioLive.channel.logo && radioLive.channel.logo.light.png})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });

    const titleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
    });
    const subtitleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileSubtitle",
    });

    const elem = document.createElement("progress");
    if (radioLive.duration != null && radioLive.scheduledFrom != null) {
      const startTime = new Date(radioLive.scheduledFrom);
      elem.setAttribute(
        "value",
        radioLive.duration === 0 ? "100" : getProgressPercentage(startTime, radioLive.duration).toString()
      );
      elem.setAttribute("max", "100");
      elem.setAttribute("class", "w3-red");
    }

    cardContainer.appendChild(elem);

    Config.locale$.didChange(
      () => {
        // when local changes, we reset our text - which is dependent on this locale
        titleElement.innerText = `${radioLive.title}`;
        subtitleElement.innerText = `${radioLive.subtitle}`;
      },
      this,
      true
    );
  }
}

/**
 * View title to display missing widget during development
 */
export class RadioLiveWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;

  constructor(widget: Widget) {
    super(widget.id, "radioLiveWidget");
    this._setAnalytics(widget);
    // Widget title
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });
    this._addSubtitleIfAvailable(widget);
    // Widget list of content
    this.delegate = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget))),
        viewFactory: radioLive => new RadioLiveTileView(radioLive),
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          void this._loadRadio(card.mediaId);
          super._analyticsCards({
            card_order: index,
            media_id: card.mediaId,
            media_title: card.title ?? "",
            media_type: "live_audio",
            media_category: "",
            media_program: "",
            media_duration: card.duration,
            broadcast_title: card.title ?? "",
            action: "click",
          });
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRectRed", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
      }
    );
  }

  private async _loadRadio(id: string) {
    void PlayerPage.playAsset(id, "AUDIO", "LIVE", false, { loadOptions: { isRadioLive: true } });
  }

  /**
   * Fetch radio list and return it, if contentPath is defined
   *
   * @param {Widget} widget
   * @return {Promise<RadioLiveCard[]>} List of items
   */
  private async _content(widget: Widget): Promise<RadioLiveCard[]> {
    try {
      return widget.contentPath !== undefined
        ? (await APIAuvio.widget(widget.contentPath, WidgetRadioLive)).content
        : [];
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }
}
