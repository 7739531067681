import "./contentSubtitleView.scss";

import { DS } from "~/libs";

export class ContentSubtitleView extends DS.View {
  constructor(subtitle: string) {
    super("", "contentSubtitleView");
    const subtitleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentSubtitle",
    });
    subtitleElement.innerText = subtitle;
  }

  rejectsFocus() {
    return true;
  }
}
