import "./carrouselTrackerView.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";

export enum CarrouselTrackerType {
  LIVE = "live",
  DEFAULT = "default",
}
export class CarrouselTrackerView extends DS.View {
  private _chipArray: HTMLElement[] = [];

  constructor(carrouselTypeItems: CarrouselTrackerType[]) {
    super("", "carrouselTrackerView");

    for (let i = 0; i < carrouselTypeItems.length; i++) {
      const chip = new CarrouselTrackerItem();
      this.rootElement.appendChild(chip.rootElement);
      carrouselTypeItems[i] !== CarrouselTrackerType.DEFAULT && chip.rootElement.classList.add(carrouselTypeItems[i]);
      this._chipArray.push(chip.rootElement);
    }

    this.toggleActiveChip(0);
  }
  /**
   * this funciton specify which chip card is active
   * @param index number
   */
  toggleActiveChip(index: number) {
    this._chipArray.forEach(element => {
      element.classList.remove("active");
    });
    this._chipArray[index].classList.add("active");
  }

  indexFromElement(elt: HTMLElement) {
    return this._chipArray.findIndex(e => e === elt);
  }
}

class CarrouselTrackerItem extends AcceptsMouseFocusView {
  constructor() {
    super("", "carrouselTrackerItem");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "chip",
    });
  }
}
