import "./emptySearchWidgetView.scss";

import { DS } from "~/libs";

import { CustomWidget } from "../../../pages/generic/genericPage";
import { WidgetView } from "../widgetView";

export const emptySearchWidget: CustomWidget = {
  id: "emptySearchWidget",
  customType: "EMPTY_SEARCH",
};

/**
 * View title to display missing widget during development
 */
export class EmptySearchWidgetView extends WidgetView {
  constructor() {
    super("emptySearchWidget", "emptySearchWidget");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: "Pas de résultats !",
    });
  }
}
