/**
 * creates a promise and timeout to delay an async function for a given period
 * @param time - time to delay in milliseconds
 * @returns
 */
export const delay = (time: number): Promise<void> => new Promise(resolve => setTimeout(resolve, time));

/**
 * creates a debounced function
 *
 * Deboucing delays the call of the input function until a certain amount of time elapsed.
 *
 * For example, providing a 1000ms period means the execution of the input function will happen only after 1000ms has passed without calls to the debounced function
 *
 * Useful to wait on search input field before doing the API call - "waiting for things to settle"
 * @param func - the function to be debounced
 * @param waitfor - the minimum amount of time that needs to elapsed without calls to the debounced func to call the input func
 * @returns the debounced function to be consumed
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
): ((...args: Parameters<F>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IThrottleHandler<F extends (...args: any[]) => any> {
  func: (...args: Parameters<F>) => void;
  abort: () => void;
}

export type ThrottleType = "immediate" | "onTimeout";
/**
 * creates a throttled function + abort structure from an external function
 * Throttling implies setting a limit on the frequency of calls of the provided function
 * For example, providing a 1000ms limit will create a function that will be called at most once every second
 * @param func - the function to be throttled
 * @param limit - the minimum amount of time between each execution of the provided function
 * @param type - there are two potential patterns on throttling.
 *
 * "immediate" will enable the func to be executed right away after creating the throttled func
 *
 * "onTimeout" will call the func at the expiry of the limit, with the latest known value
 * @returns a structure to be consumed with func as the throttled function, and abort as a way to abort throttling
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle = <F extends (...args: any[]) => any>(
  func: F,
  limit: number,
  type: ThrottleType
): IThrottleHandler<F> => {
  // no limit => no throttle
  if (limit === 0)
    return {
      func,
      abort: () => {},
    };

  let timeout: ReturnType<typeof setTimeout> | null = null;
  let latestArgs: Parameters<F> | undefined;

  const throttledFunc =
    type === "immediate"
      ? (...args: Parameters<F>) => {
          if (timeout === null) {
            func(...args);
            timeout = setTimeout(() => {
              timeout = null;
            }, limit);
          }
        }
      : (...args: Parameters<F>) => {
          // eslint-disable-next-line no-empty
          latestArgs = args;
          timeout =
            timeout ??
            setTimeout(() => {
              timeout = null;
              func(latestArgs);
            }, limit);
        };

  return {
    func: throttledFunc,
    abort: () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ITimeoutHandler<F extends (...args: any[]) => any> {
  func: (...args: Parameters<F>) => void;
  abort: () => void;
}
