export const REDBEE_ANALYTICS_EVENT = {
  CREATED: "Playback.Created",
  PLAYER_READY: "Playback.PlayerReady",
  PLAYING: "Playback.Started",
  PAUSED: "Playback.Paused",
  SEEKED: "Playback.ScrubbedTo",
  START_CASTING: "Playback.StartCasting",
  STOP_CASTING: "Playback.StopCasting",
  START_AIRPLAY: "Playback.StartAirplay",
  STOP_AIRPLAY: "Playback.StopAirplay",
  HANDSHAKE: "Playback.HandshakeStarted",
  RESUME: "Playback.Resumed",
  BITRATE_CHANGED: "Playback.BitrateChanged",
  DRM_SESSION_UPDATED: "Playback.DRM",
  ENDED: "Playback.Completed",
  ERROR: "Playback.Error",
  ABORTED: "Playback.Aborted",
  BUFFERING: "Playback.BufferingStarted",
  BUFFERED: "Playback.BufferingEnded",
  HEARTBEAT: "Playback.Heartbeat",
  AD_STARTED: "Playback.AdStarted",
  AD_ENDED: "Playback.AdCompleted",
  DROPPED_FRAMES: "Playback.DroppedFrames",
  PROGRAM_CHANGED: "Playback.ProgramChanged",
};

export const enum PlayerEvents {
  LOADED = "loaded",
  LOADING = "loading",
  DRM_UPDATE = "drm_update",
  START = "start",
  PAUSE = "pause",
  SEEKED = "seeked",
  RESUME = "resume",
  BUFFERING = "buffering",
  BUFFERED = "buffered",
  BITRATE_CHANGED = "bitrate_changed",
  ENDED = "ended",
  STOP = "stop",
  ERROR = "error",
  CAST_START = "cast_start",
  CAST_STOP = "cast_ stop",
  AIRPLAY_START = "airplay_start",
  AIRPLAY_STOP = "airplay_stop",
  AD_START = "ad_start",
  AD_COMPLETE = "ad_complete",
  DROPPED_FRAMES = "dropped_frames",
  PROGRAM_CHANGED = "program_changed",
}
