import "./contentCategoryChannelView.scss";

import { DS } from "~/libs";

import { ClassType } from "../contentButtonView/contentButtonView";

export class ContentCategoryChannelView extends DS.View {
  constructor(type: ClassType | null, channel?: string, category?: string) {
    super("", "contentCategoryChannelView");
    const categoryChannelElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: `contentCategoryChannel ${type}`,
    });
    if (channel !== null && channel !== undefined) {
      categoryChannelElement.innerText += channel;
      if (category !== null && category !== undefined) categoryChannelElement.innerText += " - ";
    }
    if (category !== null && category !== undefined) categoryChannelElement.innerText += category;
  }

  rejectsFocus() {
    return true;
  }
}
