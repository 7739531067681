import "./registrationPage.scss";

import { DS } from "~/libs";

import { createQRCode } from "../../../tools/uiHelper";

export class RegistrationPageView extends DS.View implements DS.IPage {
  constructor() {
    super("registrationPageView", "registrationPageView");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "auviologo",
      style: {
        backgroundImage: `url(${require("@assets/images/connection/auvioLogo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "scanQrCode",
      style: {
        backgroundImage: `url(${require("@assets/images/incentive/scan_qrcode.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    this.rootElement.appendChild(createQRCode(t("incentive.registration.url"), "qrCode"));
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "pageTitle",
      innerText: t("incentive.registration.title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "pageTitleRight",
      innerText: t("incentive.registration.titleRight"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "registrationText",
      innerHTML: `${t("incentive.registration.createAccountText")}
<span class="yellow">auvio.rtbf.be/inscription</span>
OU

${t("incentive.registration.createAccountTextScanQrCode")}`,
    });
  }
}
