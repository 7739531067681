import { IPoint, IRect } from "../typings";

/**
 * indicates if a given X/Y point is included in a rect
 * @param point
 * @param rect
 * @returns
 */
export function pointInRect(point: IPoint | undefined, rect: IRect | undefined): boolean {
  return (
    point !== undefined &&
    rect !== undefined &&
    point.x >= rect.origin.x &&
    point.x < rect.origin.x + rect.size.width &&
    point.y >= rect.origin.y &&
    point.y < rect.origin.y + rect.size.height
  );
}

/**
 * calculates the center point of a rect
 * @param rect
 * @returns
 **/
export function centerOf(rect?: IRect): IPoint | undefined {
  if (!rect) return undefined;
  return { x: rect.origin.x + rect.size.width / 2, y: rect.origin.y + rect.size.height / 2 };
}

function SQR(x: number, y: number) {
  return x * x + y * y;
}

/**
 * return square distance between points\
 * to compare distances between UI elements it's much faster to use this than the actual distance\
 * as there's no need to do a Math.sqrt
 * @param point1 - X/Y coordinates
 * @param point2 - X/Y coordinates
 * @returns
 */
export function distanceBetweenPointsSQR(point1?: IPoint, point2?: IPoint): number {
  if (!point1 || !point2) return Infinity;

  return SQR(point1.x - point2.x, point1.y - point2.y);
}

/**
 * return square distance between a point and the nearest point on a rectangle
 * to compare distances between UI elements it's much faster to use this than the actual distance
 * as there's no need to do a Math.sqrt
 * @param point - X/Y coordinates
 * @param rect
 * @returns
 */
export function distanceBetweenPointAndRectSQR(point?: IPoint, rect?: IRect): number {
  if (!point || !rect) return Infinity;

  const x_min = rect.origin.x;
  const x_max = rect.origin.x + rect.size.width;
  const y_min = rect.origin.y;
  const y_max = rect.origin.y + rect.size.height;

  if (point.x < x_min) {
    if (point.y < y_min) return SQR(x_min - point.x, y_min - point.y);
    if (point.y <= y_max) return x_min - point.x;
    return SQR(x_min - point.x, y_max - point.y);
  } else if (point.x <= x_max) {
    if (point.y < y_min) return y_min - point.y;
    if (point.y <= y_max) return 0;
    return point.y - y_max;
  } else {
    if (point.y < y_min) return SQR(x_max - point.x, y_min - point.y);
    if (point.y <= y_max) return point.x - x_max;
    return SQR(x_max - point.x, y_max - point.y);
  }
}

/**
 * return distance between points\
 * for faster comparisons between distances use {@link distanceBetweenPointsSQR}\
 * it's much faster to use this than the actual distance as there's no need to do a Math.sqrt
 * @param point1 - X/Y coordinates
 * @param point2 - X/Y coordinates
 */
export function distanceBetweenPoints(point1?: IPoint, point2?: IPoint): number {
  if (!point1 || !point2) return Infinity;
  return Math.sqrt(distanceBetweenPointsSQR(point1, point2));
}

/**
 * return  distance between a point and the nearest point on a rectangle\
 * for faster comparisons between distances use {@link distanceBetweenPointAndRectSQR}\
 * it's much faster to use this than the actual distance as there's no need to do a Math.sqrt
 * @param point - X/Y coordinates
 * @param rect
 * @returns
 */
export function distanceBetweenPointAndRect(point?: IPoint, rect?: IRect): number {
  if (!point || !rect) return Infinity;
  return Math.sqrt(distanceBetweenPointAndRectSQR(point, rect));
}
