import "./contentCastingView.scss";

import { DS } from "~/libs";

import { Casting } from "../../../../utils/rtbf/models";
import { ClassType } from "../contentButtonView/contentButtonView";

export class ContentCastingView extends DS.View {
  constructor(type: ClassType, castings: Casting[]) {
    super("", `castingView ${type}`);

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: type,
      className: "castingTitle",
      innerText: t("generic.casting_title"),
    });
    const castingItemContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "castingItemContainer",
    });

    castings.forEach(casting => {
      const castingItem = DS.DOMHelper.createElement({
        tagName: "div",
        parent: castingItemContainer,
        className: "castingItem",
      });
      DS.DOMHelper.createElement({ tagName: "div", parent: castingItem, className: "castingItemTitle" }).innerText =
        casting.role;
      DS.DOMHelper.createElement({ tagName: "div", parent: castingItem, className: "castingItemContent" }).innerText =
        casting.name;
    });
  }
}
