import "./programTileView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { FavorisView } from "~/components/views/favorisView/favorisView";
import { DS } from "~/libs";
import { MediaPremiumCard, ProgramCard } from "~/utils/rtbf/models";

import { PremiumHelper } from "../../../tools/premiumHelper";
import { AudioIconView } from "../../views/audioIcon/audioIconView";
import { CsaRatingView } from "../../views/csaView/csaRatingView";
import { LockView } from "../../views/lockView/lockView";
import { StampView } from "../../views/stampView/stampView";

type Card = ProgramCard | MediaPremiumCard;

export class ProgramTileView extends AcceptsMouseFocusView {
  constructor(card: Card, widgetSize: "mosaicSize" | "listSize") {
    super(card.id, `programTileView ${widgetSize}`);

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
      style: {
        backgroundImage: card.illustration !== null ? `url(${card.illustration.s})` : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    if (card.illustration === null)
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "title",
        innerText: `${card.title}`,
      });

    if (card.resourceType === "MEDIA_PREMIUM") {
      const iconsContainerElt = DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "iconsContainer",
      });

      if (card.stamp !== undefined) this.rootElement.appendChild(new StampView(card.stamp).rootElement);

      if (PremiumHelper.isPurchased(card.products) === false) {
        new LockView(this.rootElement);
      }

      if (card.type === "AUDIO") {
        iconsContainerElt.appendChild(new AudioIconView().rootElement);
      }

      if (card.rating !== undefined) {
        new CsaRatingView(card?.rating, iconsContainerElt);
        this.rootElement.classList.add("hasRating");
      }
    }

    this.rootElement.appendChild(new FavorisView(card.id).rootElement);
  }
}
