import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";

import { APIGigyaOIDC } from "../datas/api/apiGigyaOIDC";
import { MediaCard, MediaPremiumCard, PlayHistoryIdsCard } from "../utils/rtbf/models";

type CurrentOffSetListener = {
  id: string;
  currentOffset$: DS.Listenable<number>;
};

class PlayHistoryManager {
  private static _instance?: PlayHistoryManager;
  private _list: PlayHistoryIdsCard[] = [];
  private _currentOffsetList: CurrentOffSetListener[] = [];

  static get instance() {
    return (PlayHistoryManager._instance = PlayHistoryManager._instance ?? new PlayHistoryManager());
  }

  public setList(playHistoryList: PlayHistoryIdsCard[]) {
    this._list = playHistoryList;
    this.initOffsetListeners();
  }

  /**
   * Init currentOffsetlisteners
   */
  public initOffsetListeners() {
    this._currentOffsetList = this._list.map(({ mediaId, currentOffset }) => ({
      id: mediaId,
      currentOffset$: new DS.Listenable(currentOffset),
    }));
  }

  /**
   * Update offset listener
   * @param mediaId id of media
   * @param offset
   */
  createOrUpdateOffset(mediaId: string, offset: number): DS.IListenable<number> {
    const index = this._currentOffsetList.findIndex(({ id }) => mediaId === id);
    if (index >= 0) {
      const offset$ = this._currentOffsetList[index].currentOffset$;
      offset$.value = offset;
      return offset$;
    }
    this._currentOffsetList.push({
      id: mediaId,
      currentOffset$: new DS.Listenable(offset),
    });
    return this._currentOffsetList[this._currentOffsetList.length - 1].currentOffset$;
  }

  /**
   * Get currentOffset for the media id
   * @param mediaId if of the media
   * @returns currentOffset
   */
  getCurrentOffset(mediaId: string): DS.IListenable<number> {
    const media = this._currentOffsetList.find(({ id }) => id === mediaId);
    if (media === undefined) {
      return this.createOrUpdateOffset(mediaId, 0);
    } else {
      // if media simply return the existing offset listener
      return media.currentOffset$;
    }
  }

  /**
   * Get data for the play button if no data return default media data
   * @param programId of the program
   * @param defaultMedia default media data
   * @returns media
   */
  async getPlayButtonMedia(programId: string, defaultMedia: MediaCard | MediaPremiumCard) {
    if (!APIGigyaOIDC.isConnected()) return defaultMedia;
    const programMediasHistoryList = await APIAuvio.getProgramMediasHistory(programId);
    if (programMediasHistoryList?.length > 0 && programMediasHistoryList[0].media !== null) {
      const { media, currentOffset } = programMediasHistoryList[0];

      this.createOrUpdateOffset(media.id, currentOffset);
      return media ?? defaultMedia;
    }
    return defaultMedia;
  }

  /**
   * clear the history list
   */
  clearlist() {
    this._list = [];
    this._currentOffsetList = [];
    PlayHistoryManager._instance = undefined;
  }
}

export const PlayHistoryHelper = PlayHistoryManager.instance;
