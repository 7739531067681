import "./contentIconMetaView.scss";

import { DS } from "~/libs";

export class ContentIconMetaView extends DS.View {
  constructor(parent: HTMLElement, hasMultilingual: boolean, hasSubtitle: boolean, hasAudio: boolean) {
    super("", "iconMetaView");
    const multilingualIcon = require("@assets/images/iconsMeta/multilingual.png");
    const subtitleIcon = require("@assets/images/iconsMeta/subtitle.png");
    const audioIcon = require("@assets/images/iconsMeta/audio.png");

    const iconMetaSpanElement = DS.DOMHelper.createElement({
      tagName: "span",
      parent: parent,
      className: "contentIcon",
    });

    if (hasMultilingual)
      DS.DOMHelper.createElement({
        tagName: "img",
        parent: iconMetaSpanElement,
        className: "multilingual",
      }).src = multilingualIcon;
    if (hasSubtitle)
      DS.DOMHelper.createElement({
        tagName: "img",
        parent: iconMetaSpanElement,
        className: "subtitle",
      }).src = subtitleIcon;
    if (hasAudio)
      DS.DOMHelper.createElement({
        tagName: "img",
        parent: iconMetaSpanElement,
        className: "audio",
      }).src = audioIcon;
  }
}
