import { DS } from "dslib-tv";

import { APIAuvio } from "~/datas/api/apiAuvio";

import { APIGigyaOIDC } from "../datas/api/apiGigyaOIDC";
import { navigationStack } from "../main";
import { GenericIncentivePageFull, IncentiveType } from "../pages/incentive/incentivePage";
import { PageMediaContent, PageProgramContent } from "../utils/rtbf/models";
import { Toast } from "./uiHelper";

class FavoriteManager {
  private static _instance?: FavoriteManager;
  private _favoriteIds: string[] = [];

  length$ = new DS.Listenable<number>(0);

  static get instance() {
    return (FavoriteManager._instance = FavoriteManager._instance ?? new FavoriteManager());
  }

  public setIds(favoriteIds: string[]) {
    this._favoriteIds = favoriteIds;
  }

  async addFavorites(asset: PageProgramContent | PageMediaContent): Promise<boolean> {
    if (APIGigyaOIDC.isConnected() === false) {
      navigationStack.pushPage(new GenericIncentivePageFull(IncentiveType.connexion));
      return false;
    }

    const programId =
      asset.pageType === "MEDIA" && asset.program !== null && !this._favoriteIds.includes(asset.program?.id)
        ? asset.program?.id
        : undefined;
    const response = await APIAuvio.addFavorite(asset.pageType, asset.id, programId);
    if (response === true) {
      this._favoriteIds.push(asset.id);
      if (programId !== undefined) this._favoriteIds.push(programId);
      this.length$.value = this._favoriteIds.length;
      Toast(t(`toaster.addFav_${asset.pageType}_text`));
    } else {
      Toast(t(`toaster.addFav_error_text`));
    }

    return response;
  }

  async removeFavorites(asset: PageProgramContent | PageMediaContent): Promise<boolean> {
    const response = await APIAuvio.removeFavorite(asset.pageType, asset.id);

    if (response === true) {
      const index = this._favoriteIds.indexOf(asset.id);
      if (index > -1) {
        this._favoriteIds.splice(index, 1);
        this.length$.value = this._favoriteIds.length;
      }
      Toast(t(`toaster.delFav_${asset.pageType}_text`));
    } else {
      Toast(t(`toaster.delFav_error_text`));
    }

    return response;
  }

  isFavorite(favoriteId: string) {
    return this._favoriteIds.includes(favoriteId);
  }

  clearFavoriteList() {
    this._favoriteIds = [];
    this.length$.value = 0;
  }
}

export const FavoriteHelper = FavoriteManager.instance;
