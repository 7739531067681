import "./pauseButtonPlayer.scss";

import { DS } from "~/libs";

import { IPlayer, PlayerState } from "../../../../tools/player";
import { ButtonPlayer } from "./buttonPlayer";

export class PauseButtonPlayer extends ButtonPlayer {
  imgDOM: HTMLElement;
  constructor(player: IPlayer) {
    super(player, "pauseButtonPlayer", t("player.button.pause"));
    this.imgDOM = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "pauseImg img",
    });

    player.state$.value === PlayerState.PLAYING ? this._callbackOnPlay() : this._callbackOnPause();

    player.state$.didChange(
      value => {
        switch (value) {
          case PlayerState.PLAYING:
            this._callbackOnPlay();
            break;
          case PlayerState.PAUSED:
            this._callbackOnPause();
        }
      },
      this,
      true
    );
  }

  private _callbackOnPlay() {
    this.imgDOM.classList.remove("playImg");
    this.imgDOM.classList.add("pauseImg");
    this.text = "Pause";
  }

  private _callbackOnPause() {
    this.imgDOM.classList.remove("pauseImg");
    this.imgDOM.classList.add("playImg");
    this.text = "Lecture";
  }

  onSelect() {
    if (this.player.isPlaying() === true) {
      this.player.pause(true);
      this._callbackOnPause();
    } else {
      this.player.play();
      this._callbackOnPlay();
    }
    return true;
  }
}
