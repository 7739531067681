// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/images/keyboard/delete/search/delete.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@assets/images/keyboard/delete/search/delete_focus.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".numericKeyboardView {\n  position: absolute;\n  width: 1920px;\n  height: 166px;\n  bottom: 0px;\n  background-color: #282828;\n}\n\n.numericKeyboardViewList {\n  position: absolute;\n  margin-top: 29px;\n  width: 1920px;\n  height: 137px;\n  left: 468px;\n}\n.numericKeyboardViewList #Delete_key {\n  width: 76px;\n  margin-left: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.numericKeyboardViewList .key {\n  margin-right: 42px;\n}\n.numericKeyboardViewList .keyView.focused #Delete_key {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}", "",{"version":3,"sources":["webpack://./src/components/views/keyboard/numericKeyboardView.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;EACA,iBAAA;EACA,yDAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AAEI;EACE,yDAAA;AAAN","sourcesContent":[".numericKeyboardView {\n  position: absolute;\n  width: 1920px;\n  height: 166px;\n  bottom: 0px;\n  background-color: #282828;\n}\n\n.numericKeyboardViewList {\n  position: absolute;\n  margin-top: 29px;\n  width: 1920px;\n  height: 166px - 29px;\n  left: 468px;\n  #Delete_key {\n    width: 76px;\n    margin-left: 10px;\n    background-image: url(\"@assets/images/keyboard/delete/search/delete.png\");\n  }\n  .key {\n    margin-right: 42px;\n  }\n\n  .keyView.focused {\n    #Delete_key {\n      background-image: url(\"@assets/images/keyboard/delete/search/delete_focus.png\");\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
