import "./premiumContentView.scss";

import { DS } from "../../../libs";
import { PageProductContent } from "../../../utils/rtbf/models";
import { ContentView } from "../contentView";

export class PremiumContentView extends ContentView {
  constructor(content: PageProductContent) {
    super(content.id, "premiumContentView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentTitle",
      innerText: content.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentSubtitle",
      innerText: content.subtitle,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentDesc",
      innerText: content.description,
    });

    // // Subscription price
    // const subElem = DS.DOMHelper.createElement({
    //   tagName: "div",
    //   parent: this.rootElement,
    //   id:  "",
    //   className:  "contentSubscriptionPrice",
    // });;
    // DS.DOMHelper.createElement(subElem, "", "price", "7,99€");
    // DS.DOMHelper.createElement({
    //   tagName: "div",
    //   parent: subElem,
    //   id:  "",
    //   className:  "end",
    //   innerText:  "/mois",
    // });;
    // // If the user is not connected
    // DS.DOMHelper.createElement(
    //   this.rootElement,
    //   "",
    //   "contentSubscriptionDesc",
    //   "Il n’est malheuresement pas possible de s’abonner depuis cet appareil."
    // );
  }

  rejectsFocus() {
    return true;
  }
}
