import { IPlatform, PlatformType } from "../../typings";

/**
 * Some globals that aren't already defined (tizen ones are already typed from tizen-tv-webapis and tizen-common-web packages)
 */
// declare global {
//   // eslint-disable-next-line @typescript-eslint/naming-convention
//   interface Window {
//     tizen?: {
//       tvinputdevice: {
//         registerKey: (_: string) => void;
//       };
//     };
//   }
// }

export function init(): (Partial<IPlatform> & { type: PlatformType.tizen }) | undefined {
  // careful! any code here needs to work *without* polyfills!!
  // String.includes for example doesn't. So do an initial first pass on the platform type to set the polyfills
  if (navigator.userAgent.match(new RegExp("Tizen", "i"))) {
    require("core-js/es");
    // now we have polyfills in place

    try {
      // on tizen, this needs to be added
      // warning: I think it's an async op - webapis might
      // not be filled right after the script has been added to head
      const scriptElement = document.createElement("script");
      scriptElement.src = "$WEBAPIS/webapis/webapis.js";
      document.head.appendChild(scriptElement);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("couldn't require webapis", error?.stack ?? error);
    }
    // Register keys for Tizen
    const usedKeys = [
      "MediaPause",
      "MediaPlay",
      "MediaPlayPause",
      "MediaFastForward",
      "MediaRewind",
      "MediaStop",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "ColorF0Red",
      "ColorF1Green",
      "ColorF2Yellow",
      "ColorF3Blue",
      "ChannelUp",
      "ChannelDown",
    ];

    usedKeys.forEach(function (keyName) {
      window.tizen.tvinputdevice.registerKey(keyName);
    });

    const version = navigator.userAgent.match(new RegExp("Tizen ([^;)]*)"))?.[1] ?? undefined;

    const alternativeCSS = parseInt(version?.[0] ?? "0") <= 2;

    const exit = () => {
      window.tizen.application.getCurrentApplication().exit();
    };

    return {
      type: PlatformType.tizen,
      version,
      alternativeCSS,
      exit,
    };
  }
}
