import "./playbackSettingsPage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { FocusTracker } from "../../../components/focusTracker";
import { LongSettingsButtonView } from "../../../components/settings/longButtonView/longSettingsButtonView";
import { Config } from "../../../config";
import { RootMenuPage } from "../../../rootMenu/rootMenuPage";
import { DevicePreferenceHelper } from "../../../tools/devicePreferencesManager";

enum PlaybackSettingsOptions {
  binge = "binge",
}

enum PlaybackSettingsLeftIcon {
  next = "next",
}

class PlaybackSettingsPageView extends AcceptsMouseFocusView implements DS.IPage {
  focusTracker?: FocusTracker;

  constructor() {
    super("playbackSettingsPageView", "playbackSettingsPageView settingsPages");

    // BASIC PAGE STYLE
    const pageLogo = require("@assets/images/settings/playbackSettings.png");
    const icon = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${pageLogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    icon.style.backgroundSize = "contain";
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("playbackSettings.title"),
    });

    const devicePreferences = DevicePreferenceHelper.cachedPreferences();
    // BUTTONS LIST
    const buttonList = {
      binge: new LongSettingsButtonView(
        t("playbackSettings.binge"),
        {
          present: true,
          isOn: devicePreferences.bingeEnable,
        },
        PlaybackSettingsLeftIcon.next
      ),
    };

    // LIST CREATION
    this.delegate = DS.createListComponent(
      {
        id: `playbackSettings/list`,
        className: "playbackSettingsList",
        modelSource$: new DS.ModelSource([PlaybackSettingsOptions.binge]),
        viewFactory: button => {
          return buttonList[button];
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: button => {
          const switchButton = buttonList[button].toggle();
          devicePreferences.bingeEnable = switchButton;
          DevicePreferenceHelper.save(devicePreferences);
          return true;
        },
      },
      list => {
        if (!this.focusTracker) {
          this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        }
        this.collectRelease(this.focusTracker.onRelease);
      }
    );
  }
}

export class PlaybackSettingsPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("playbackSettingsPage", "RootMenuPage", () => {
      return new PlaybackSettingsPageView();
    });
  }
}
