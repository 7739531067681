import "./buttonPlayer.scss";

import { Config } from "~/config";
import { DS } from "~/libs";

import { IPlayer } from "../../../../tools/player";
import { ITextElement, TextHelper } from "../../../../tools/textHelper";

export class ButtonPlayer extends DS.View {
  player: IPlayer;
  txtDOM: ITextElement;
  constructor(player: IPlayer, classname: string, name: string) {
    super("", `${classname} buttonPlayer`);
    this.player = player;
    this.txtDOM = TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "buttonPlayerName",
      content: name,
    });
  }

  set text(value: string) {
    this.txtDOM.update(value);
  }

  onSelect(): boolean {
    return false;
  }

  acceptsMouseFocus = (): boolean => true;
}

export abstract class MoveButtonPlayer extends ButtonPlayer {
  private _lastPress = Date.now();
  private _timeout = -1;

  protected isFocused$ = new DS.Listenable<boolean>(false);

  protected selectPressed$ = new DS.Listenable<boolean>(false);
  private _selectInterval = -1;
  private _selectReleased = false;

  private static _TIME_BETWEEN_CLICK = 1000;
  protected _isDisabled = false;

  constructor(player: IPlayer, classname: string, name: string, isDisabled: boolean) {
    super(player, classname, name);

    this._isDisabled = isDisabled;
    this._onKeyUp = this._onKeyUp.bind(this);
    window.addEventListener("keyup", this._onKeyUp);

    if (Config.mouseSupport === true) {
      const onMouseUp = () => {
        if (this.selectPressed$.value === true) this.selectPressed$.value = false;
      };
      this.collectRelease(() => {
        window.removeEventListener("mouseup", onMouseUp);
      });
      window.addEventListener("mouseup", onMouseUp);
    }

    this.selectPressed$.didChange(value => {
      window.clearInterval(this._selectInterval);
      if (value) {
        this._select();
        this._selectInterval = window.setInterval(() => {
          if (this._selectReleased !== true) {
            this._select();
          } else {
            window.clearInterval(this._selectInterval);
          }
        }, 200);
      }
    }, this);
  }

  onRelease() {
    super.onRelease();
    window.removeEventListener("keyup", this._onKeyUp);
    this._selectReleased = true;
    window.clearInterval(this._selectInterval);
  }

  onFocused() {
    this.isFocused$.value = true;
  }

  onUnfocused() {
    this.isFocused$.value = false;
  }

  onSelect(): boolean {
    this.selectPressed$.value = true;
    return true;
  }

  /**
   * Simulate a press on the button. But will calculate when the user
   * is releasing the key so we can make sone speed navigation.
   * @returns true
   */
  private _select(): boolean {
    if (this._lastPress + MoveButtonPlayer._TIME_BETWEEN_CLICK >= Date.now()) {
      window.clearTimeout(this._timeout);
      this.action(true);
      this._timeout = window.setTimeout(() => {
        this.action(false, true);
      }, MoveButtonPlayer._TIME_BETWEEN_CLICK);
    } else {
      this.action();
    }
    this._lastPress = Date.now();
    return true;
  }

  protected abstract _onKeyUp(ev: KeyboardEvent): void;
  protected abstract action(preview?: boolean, released?: boolean): void;

  rejectsFocus = (): boolean => this._isDisabled;
  acceptsMouseFocus = (): boolean => !this.rejectsFocus();
}
