function makeid(length: number) {
  const result: string[] = [];
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join("");
}

export const LogInfo = {
  appName: "dotscreen",
  appVersion: "#none#",
  appHash: "#none#",
  sessionId: `${makeid(4)}.${makeid(4)}`,
  qrEncodedText: "",
};
