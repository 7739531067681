console.clear();

require("~/libs/ui/platform");
// then we need log to be defined ASAP
require("./log");
// the t() function also needs to be defined ASAP as it's global
require("./translation/index");

// startup app main
require("./main");
