import { v4 as uuid } from "uuid";

import { DS } from "~/libs";

/**
 * Search for a saved UUID, if not create it and save it, then return it
 * @returns A string UUID
 */
export const getDeviceUUID = function (): string {
  let deviceUUID = DS.Storage.getItem("deviceUUID");
  if (deviceUUID === null) {
    deviceUUID = uuid();
    DS.Storage.setItem("deviceUUID", deviceUUID);
  }
  return deviceUUID;
};

export const isTizen8 = function (): boolean {
  return navigator.userAgent.match(new RegExp("Tizen 8", "i")) !== null;
};
