import "./favorisView.scss";

import { DS } from "~/libs";

import { FavoriteHelper } from "../../../tools/favoriteHelper";

export class FavorisView extends DS.View {
  constructor(ressourceId: string) {
    super("", "favorisView");

    const favorisLogo = require("@assets/images/icons/FAVORIS.png");
    const icon = DS.DOMHelper.createElement({
      tagName: "img",
      parent: this.rootElement,
      id: "favorisIconView_" + ressourceId,
      className: "favorisIcon",
    });
    icon.src = favorisLogo;

    FavoriteHelper.length$.didChange(
      () => {
        if (FavoriteHelper.isFavorite(ressourceId)) {
          icon.style.display = "block";
        } else {
          icon.style.display = "none";
        }
      },
      this,
      true
    );
  }
}
