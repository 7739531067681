import { inputMode$ } from "../const";
import { pointInRect } from "../helpers";
import { firstNonFocusRejectedElement } from "../helpers/delegate";
import * as DOMHelper from "../helpers/DOMHelper";
import { Listenable } from "../helpers/Listenable";
import {
  IArrowView,
  Identifiable,
  IListComponentParams,
  IMousable,
  InputMode,
  IOnClick,
  IOnHover,
  IPoint,
  IView,
} from "../typings";
import { UILog } from "../uiLog";
import { isOnClick, isOnHover } from "./isOfType";
import { ListComponent } from "./listComponent";

interface IMouseParamsMandatory {
  focusRange: "page" | "visible";
  wheelScrollBy?: number;
  arrows?: {
    factory: (position: "prev" | "next") => IArrowView;
    scrollMethod: IOnClick | IOnHover;
    showOnBoundaries: boolean;
  };
}

export class MouseListComponent<M extends Identifiable, V extends IView>
  extends ListComponent<M, V>
  implements IMousable
{
  readonly mouseParams: IMouseParamsMandatory;
  lastMousePos: IPoint = { x: 0, y: 0 };
  arrowPrev?: IArrowView;
  arrowNext?: IArrowView;
  hovered$ = new Listenable(false);
  focusedArrow$ = new Listenable<IView | undefined>(undefined);

  constructor(params: IListComponentParams<M, V>) {
    super(params);

    if (params.mouseSupport === undefined) throw new Error("mouseSupport not defined but required!");
    // if a boolean was provided (convenient) - then "transform" this so it can be read with default values later
    if (typeof params.mouseSupport === "boolean") params.mouseSupport = params.mouseSupport ? {} : undefined;
    this.mouseParams = {
      arrows: params.mouseSupport?.arrows
        ? {
            factory: params.mouseSupport.arrows.factory,
            scrollMethod: params.mouseSupport.arrows.scrollMethod ?? { onClick: { scrollBy: 250 } },
            showOnBoundaries: params.mouseSupport.arrows.showOnBoundaries ?? false,
          }
        : undefined,
      wheelScrollBy: params.mouseSupport?.wheelScrollBy,
      focusRange: params.mouseSupport?.focusRange ?? "page",
    };

    this.arrowPrev = this.mouseParams.arrows?.factory?.("prev");
    if (this.arrowPrev) {
      this.arrowPrev.rootElement.onmouseenter = () => {
        this.focusedArrow$.value = this.arrowPrev;
      };
      this.arrowPrev.rootElement.onmouseleave = () => {
        this.focusedArrow$.value = undefined;
      };
      this.rootElement.appendChild(this.arrowPrev?.rootElement);
    }

    this.arrowNext = this.mouseParams.arrows?.factory?.("next");
    if (this.arrowNext) {
      this.arrowNext.rootElement.onmouseenter = () => {
        this.focusedArrow$.value = this.arrowNext;
      };
      this.arrowNext.rootElement.onmouseleave = () => {
        this.focusedArrow$.value = undefined;
      };
      this.rootElement.appendChild(this.arrowNext?.rootElement);
    }

    // no need to keep the unregister func, the listenable is our own so it'll be destroyed along with listeners
    const scrollMethod = this.mouseParams.arrows?.scrollMethod;
    if (scrollMethod && this.arrowPrev && this.arrowNext) {
      // if we have an anHover method, handle the mecanism here
      let hoverTimer: number | undefined;
      let hoverInterval: number | undefined;

      this.focusedArrow$.didChange((focusedArrow, prevFocusedArrow) => {
        prevFocusedArrow?.rootElement.classList.remove("focused");
        focusedArrow?.rootElement.classList.add("focused");

        // whenever the focus arrow changes, stop the timer
        window.clearTimeout(hoverTimer);
        window.clearInterval(hoverInterval);

        if (focusedArrow && isOnHover(scrollMethod)) {
          hoverTimer = window.setTimeout(() => {
            UILog.ui_list.log("hover timer delay over, scrolling");

            const onHoverScroll = () => {
              void this.setScrollPositionFromMouse(
                (this.scrollPosition$.value ?? 0) +
                  (focusedArrow === this.arrowPrev ? -1 : 1) * scrollMethod.onHover.scrollBy
              );
            };

            // first scroll trigger right away and set interval to trigger the next ones
            onHoverScroll();
            hoverInterval = window.setInterval(() => {
              UILog.ui_list.log("hover interval, scrolling");
              onHoverScroll();
            }, scrollMethod.onHover.interval);
          }, scrollMethod.onHover.delay);
        }
      }, this);

      this.rootElement.onmouseenter = () => {
        if (firstNonFocusRejectedElement(this.rootElement) === this.rootElement) this.hovered$.value = true;
      };
      this.rootElement.onmouseleave = () => {
        this.hovered$.value = false;
      };

      this.hovered$.didChange(this.updateArrowsVisibility, this);
      this.scrollPosition$.didChange(this.updateArrowsVisibility, this);

      inputMode$.didChange(
        inputMode => {
          switch (inputMode) {
            case InputMode.keys:
              this.focusedArrow$.value = undefined;
              break;

            case InputMode.pointer:
              break;
          }
          void this.updateArrowsVisibility();
        },
        this,
        true
      );
    }

    // manage switching back to input
    if (!this.params.scrollingMode.horizontal)
      inputMode$.didChange(
        inputMode => {
          switch (inputMode) {
            case InputMode.keys:
              {
                // when exiting pointer mode, move back the focus inside the page
                // need to find the subview & do a mousemove on it
                const nearestIndex = this._viewIndexNearPoint(this.lastMousePos, "page");
                if (nearestIndex !== this.focusedIndex$.value) {
                  console.log("input back to keys", this.rootElement.id, nearestIndex, this.focusedIndex$.value);
                  void this.setFocusOnIndex(nearestIndex, { focus: true });
                }
              }
              break;

            case InputMode.pointer:
              break;
          }
        },
        this,
        true
      );
  }

  /* Warning: DON'T meomize _maxScrollPosition, unless you put this method in ListComponent and reset the value in the refresh() method */
  private _maxScrollPosition = () => {
    if (
      this._lastCreatedIndex === this.params.modelSource$.value.length - 1 &&
      this.params.modelSource$.isComplete$.value === true
    ) {
      const lastViewBounds = this.boundsFromIndex(this._lastCreatedIndex);
      if (lastViewBounds?.along.end !== undefined) {
        return lastViewBounds.along.end - this._listBounds().page.length;
      }
    }

    return Number.MAX_SAFE_INTEGER;
  };

  async setScrollPositionFromMouse(scrollPosition: number, mouseWheel = false): Promise<void> {
    // bound the scrollPosition differently when scrolling with mouse
    const boundedScrollPosition = Math.max(0, Math.min(scrollPosition, this._maxScrollPosition()));
    const oldScrollPosition = this.scrollPosition$.value;
    await this.setScrollPosition(boundedScrollPosition, true);

    if (boundedScrollPosition !== undefined && oldScrollPosition !== undefined) {
      if (!mouseWheel) {
        // keep the focus "near" the arrow
        if (boundedScrollPosition < oldScrollPosition) this.focusedIndex$.value = this.pageRange$.value.first;
        if (boundedScrollPosition > oldScrollPosition) this.focusedIndex$.value = this.pageRange$.value.last;
      }
    }
  }

  showArrow(arrow: IView | undefined, show: boolean) {
    if (!arrow) return;
    if (show) {
      arrow.rootElement.style.pointerEvents = "";
      if (arrow.rootElement.style.opacity !== "1") {
        arrow.rootElement.style.opacity = "1";
      }
    } else {
      arrow.rootElement.style.pointerEvents = "none";
      if (arrow.rootElement.style.opacity !== "0") {
        arrow.rootElement.style.opacity = "0";
        // need to clear the focusedArrow if it went away
        if (this.focusedArrow$.value === arrow) {
          this.focusedArrow$.value = undefined;
        }
      }
    }
  }

  updateArrowsVisibility = () => {
    if (inputMode$.value === InputMode.keys) {
      this.showArrow(this.arrowPrev, false);
      this.showArrow(this.arrowNext, false);
      return;
    }

    if (!this.hovered$.value) {
      this.showArrow(this.arrowPrev, false);
      this.showArrow(this.arrowNext, false);
      return;
    }

    // check if we are showing all the available elements in the first page
    if (
      this.visibleRange$.value.first === 0 &&
      this.pageRange$.value.last === this.params.modelSource$.value.length - 1 &&
      this.params.modelSource$.isComplete$.value === true
    ) {
      this.showArrow(this.arrowPrev, false);
      this.showArrow(this.arrowNext, false);
      return;
    }

    // prev arrow
    // if we've reach the beginning of the list, hide the arrow
    this.showArrow(this.arrowPrev, (this.scrollPosition$.value ?? 0) > 0);

    // next arrow
    // if we've reached the end of the list, hide the arrow
    this.showArrow(this.arrowNext, (this.scrollPosition$.value ?? 0) < this._maxScrollPosition());
  };

  rejectsFocus(fromChildElement?: HTMLElement): boolean {
    if (!fromChildElement) return super.rejectsFocus();
    if (this.mouseParams.focusRange === "visible") return false;

    let rejectsFocus = true;
    this.forEveryView("page", index => {
      if (this.viewFromIndex(index)?.rootElement === fromChildElement) {
        rejectsFocus = false;
        return true;
      }
    });
    return rejectsFocus;
  }

  onMouseMove(point: IPoint, target: HTMLElement) {
    this.lastMousePos = point;
    this.focusFromSubView(target, this.mouseParams.focusRange, true);
    return true;
  }

  onMouseDown(point: IPoint): boolean {
    this.lastMousePos = point;
    switch (this.focusedArrow$.value ?? false) {
      case this.arrowPrev:
      case this.arrowNext:
        if (this.mouseParams.arrows?.scrollMethod && isOnClick(this.mouseParams.arrows?.scrollMethod)) {
          void this.setScrollPositionFromMouse(
            (this.scrollPosition$.value ?? 0) +
              (this.focusedArrow$.value === this.arrowPrev ? -1 : 1) *
                this.mouseParams.arrows.scrollMethod.onClick.scrollBy
          );
        }
        return true;

      default: {
        // we need to make sure the nearest view contains the cursor to do select
        const nearestIndex = this._viewIndexNearPoint(this.lastMousePos, this.mouseParams.focusRange);
        const nearestModel = this.modelFromIndex(nearestIndex);
        const nearestSubView = this.viewFromIndex(nearestIndex);

        return (
          (nearestModel !== undefined &&
            pointInRect(this.lastMousePos, DOMHelper.screenRectOf(nearestSubView?.rootElement)) &&
            this.params.onSelect?.(nearestModel, nearestIndex ?? 0)) ||
          false
        );
      }
    }
  }

  onMouseWheel(deltaY: number, point: IPoint, target: HTMLElement): boolean {
    this.lastMousePos = point;
    const wheelScrollBy = this.mouseParams.wheelScrollBy;

    // if list is vertical and wheelScrollBy is defined we scroll by
    // else return false to propagate the event
    if (!this.params.scrollingMode.horizontal && wheelScrollBy !== undefined) {
      // If the list is already at the top and the scroll direction is up, return false to propagate the event
      if (this.scrollPosition$.value === 0 && deltaY < 0) return false;
      void (async () => {
        await this.setScrollPositionFromMouse(
          (this.scrollPosition$.value ?? 0) + (deltaY < 0 ? -1 : 1) * wheelScrollBy,
          true
        );
      })();
      return true;
    } else return false;
  }
}
