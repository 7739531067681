import "./eyeIconView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

export class EyeIconView extends AcceptsMouseFocusView {
  private _elt: HTMLImageElement;
  constructor(buttonState$: DS.IListenable<boolean>) {
    super("", "eyeIconView");
    this._elt = DS.DOMHelper.createElement({
      tagName: "img",
      parent: this.rootElement,
      id: "connectionEye",
      className: "connectionEye",
    });

    const hiddenOffIcon = require("@assets/images/connection/iconEye.png");
    const hiddenOnIcon = require("@assets/images/connection/iconEyeClose.png");

    buttonState$.didChange(
      value => {
        this._elt.src = value ? hiddenOffIcon : hiddenOnIcon;
      },
      this,
      true
    );
  }
}
