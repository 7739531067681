import { z } from "zod";

export type RtbfEventName =
  | "card_interaction"
  | "page_view"
  | "widget_interaction"
  | "widget_shown"
  | "user_properties"
  | "widget_trailer_action"
  | "player_ui_interaction"
  | "search"
  | "search_select"
  | "menu_item_selected"
  | "loading_finished"
  | "loading_cancel";

// BASE

const TrackingBase = z.object({
  platform: z.string(),
  user_id: z.string().optional(),
  version: z.string(),
  interface: z.enum(["remote_control"]),
  page_id: z.string(),
  page_type: z.string(),
  page_referrer: z.string(),
  page_title: z.string(),
});
type TrackingBase = z.infer<typeof TrackingBase>;

// WIDGETS

export const TrackingWidget = TrackingBase.extend({
  widget_id: z.string(),
  widget_title: z.string(),
  widget_type: z.string(),
  widget_order: z.number().optional(),
  action: z.string(),
  area: z.string(),
});
type TrackingWidget = z.infer<typeof TrackingWidget>;

const TrackingWidgetBanner = TrackingWidget.extend({
  media_id: z.string(),
  promo_title: z.string(),
  promo_subtitle: z.string(),
  redirection_ressource: z.enum(["page", "external"]),
});
type TrackingWidgetBanner = z.infer<typeof TrackingWidgetBanner>;

const TrackingWidgetTrailer = TrackingWidget.extend({
  media_type: z.enum(["trailer_video"]),
  media_program: z.string(),
  media_title: z.string(),
  media_id: z.string(),
  action: z.enum(["call_to_action", "play", "pause", "sound_on", "sound_off"]),
  area: z.enum(["button", "video"]),
});
type TrackingWidgetTrailer = z.infer<typeof TrackingWidgetTrailer>;

// CARDS

const TrackingCard = TrackingWidget.extend({
  card_order: z.number(),
});
type TrackingCard = z.infer<typeof TrackingCard>;

const TrackingMediaCard = TrackingCard.extend({
  media_id: z.string(),
  media_title: z.string(),
  media_type: z.enum(["live_video", "live_audio", "vod", "aod", "premium_vod", "trailer_video"]),
  media_category: z.string(),
  media_program: z.string(),
  media_duration: z.number(),
});
type TrackingMediaCard = z.infer<typeof TrackingMediaCard>;

const TrackingMediaCardChannel = TrackingMediaCard.extend({
  channel_id: z.string(),
  channel_name: z.string(),
  broadcast_title: z.string(),
});
type TrackingMediaCardChannel = z.infer<typeof TrackingMediaCardChannel>;

const TrackingCategoryCard = TrackingCard.extend({
  category_id: z.string(),
  category_name: z.string(),
});
type TrackingCategoryCard = z.infer<typeof TrackingCategoryCard>;

const TrackingProductPremiumCard = TrackingCard.extend({
  product_id: z.string(),
  product_name: z.string(),
});
type TrackingProductPremiumCard = z.infer<typeof TrackingProductPremiumCard>;

const TrackingQuickLinkCard = TrackingCard.extend({
  string_value: z.string(),
});
type TrackingQuickLinkCard = z.infer<typeof TrackingQuickLinkCard>;

const TrackingPromoBoxCard = TrackingCard.extend({
  promo_title: z.string(),
  promo_subtitle: z.string(),
  media_id: z.string(),
});
type TrackingPromoBoxCard = z.infer<typeof TrackingPromoBoxCard>;

// PLAYER

const TrackingPlayer = TrackingBase.extend({
  action: z.string(),
  integer_value: z.number().nullish(),
  string_value: z.string().nullish(),
  boolean_value: z.boolean().nullish(),
  media_type: z.enum(["live_video", "live_audio", "vod", "aod", "premium_vod", "trailer_video"]),
  media_category: z.string(),
  media_program: z.string(),
  media_duration: z.string(),
  media_title: z.string(),
  media_id: z.string(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  media_current_audiostream: z.string().nullish(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  media_current_quality: z.string().nullish(),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  media_current_subtitle: z.string().nullish(),
});
type TrackingPlayer = z.infer<typeof TrackingPlayer>;

// EXPLORER

const TrackingSearch = TrackingBase.extend({
  search_term: z.string(),
  submission: z.null(),
});
type TrackingSearch = z.infer<typeof TrackingSearch>;

// MENU

const TrackingMenu = TrackingBase.extend({
  menu_entry: z.string(),
});
type TrackingMenu = z.infer<typeof TrackingMenu>;

// SPLASH

const TrackingSplashFinished = TrackingBase.extend({
  duration: z.number(),
});
type TrackingSplashFinished = z.infer<typeof TrackingSplashFinished>;

export type TrackingData =
  | TrackingBase
  | TrackingWidget
  | TrackingWidgetBanner
  | TrackingWidgetTrailer
  | TrackingCard
  | TrackingMediaCard
  | TrackingMediaCardChannel
  | TrackingCategoryCard
  | TrackingProductPremiumCard
  | TrackingQuickLinkCard
  | TrackingPromoBoxCard
  | TrackingPlayer
  | TrackingSearch
  | TrackingMenu
  | TrackingSplashFinished;
