import "./searchKeyboardView.scss";

import { DS } from "~/libs";

import { Config } from "../../../config";
import { Keyboard, KeyboardSpecialKey, KeyboardType, KeyboardView } from "./keyboardView";
import { KeyView } from "./keyView";

let keyboardsSwitch: DS.ISwitchComponent<Keyboard, SearchKeyboardView>;

export class SearchKeyboardView extends DS.View {
  alphabet = new DS.ModelSource<string>([]);

  constructor(textToSearch$: DS.Listenable<string>) {
    super("searchKeyboardWidget", "searchKeyboardWidget");
    this.delegate = DS.createListComponent(
      {
        id: "",
        className: "searchKeyboardViewList",
        modelSource$: this.alphabet,
        viewFactory: id => new KeyView(id),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: id => {
          switch (id) {
            case KeyboardSpecialKey.Space:
              if (textToSearch$.value.charAt(textToSearch$.value.length - 1) !== " ") {
                textToSearch$.value = textToSearch$.value + " ";
              }
              return true;
            case KeyboardSpecialKey.Delete:
              textToSearch$.value = textToSearch$.value.slice(0, -1);
              return true;
            case KeyboardSpecialKey.Digits:
              keyboardsSwitch.show(KeyboardType.Digits);
              return true;
            case KeyboardSpecialKey.Lowercase:
              keyboardsSwitch.show(KeyboardType.Lowercase);
              return true;
            default:
              textToSearch$.value = textToSearch$.value + id;
              return true;
          }
        },
        crossSectionWidth: 11,
      },
      list => {
        void list.refresh();
        void list.setFocusOnIndex(0);
      }
    );
  }
}

class LowercaseSearchKeyboardView extends SearchKeyboardView {
  constructor(textToSearch$: DS.Listenable<string>) {
    super(textToSearch$);
    this.alphabet.value = [
      KeyboardSpecialKey.Digits,
      ..."abcdefghi".split(""),
      KeyboardSpecialKey.Delete,
      KeyboardSpecialKey.NoneButton,
      ..."jklmnopqr".split(""),
      KeyboardSpecialKey.Space,
      KeyboardSpecialKey.NoneButton,
      ..."stuvwxyz".split(""),
    ].map((elem, ind) => {
      // making NoneButton and NoneLetter with a unique id for the modelSource
      if (elem === KeyboardSpecialKey.NoneButton || elem === KeyboardSpecialKey.NoneLetter) return `${elem}${ind}`;
      return elem;
    });
  }
}

class DigitsSearchKeyboardView extends SearchKeyboardView {
  constructor(textToSearch$: DS.Listenable<string>) {
    super(textToSearch$);
    this.alphabet.value = [
      KeyboardSpecialKey.Lowercase,
      ..."123456789".split(""),
      KeyboardSpecialKey.Delete,
      KeyboardSpecialKey.NoneButton,
      ..."0?!,;\\$£€".split(""),
      KeyboardSpecialKey.Space,
      KeyboardSpecialKey.NoneButton,
      ..."~¡¿^“”‘’".split(""),
    ].map((elem, ind) => {
      // making NoneButton and NoneLetter with a unique id for the modelSource
      if (elem === KeyboardSpecialKey.NoneButton || elem === KeyboardSpecialKey.NoneLetter) return `${elem}${ind}`;
      return elem;
    });
  }
}

export class SearchKeyboard extends KeyboardView {
  constructor(textToSearch$: DS.Listenable<string>) {
    super(
      "search",
      textToSearch$,
      DS.createSwitchComponent(
        {
          id: "",
          className: "keyboardList",
          model: [
            {
              id: KeyboardType.Lowercase,
            },
            {
              id: KeyboardType.Digits,
            },
          ],
          initialViewId: KeyboardType.Lowercase,
          viewFactory: model => {
            switch (model.id) {
              case KeyboardType.Lowercase:
                return new LowercaseSearchKeyboardView(textToSearch$);
              case KeyboardType.Digits:
                return new DigitsSearchKeyboardView(textToSearch$);
              default:
                return new DigitsSearchKeyboardView(textToSearch$);
            }
          },
        },
        switchComponent => {
          switchComponent.show(KeyboardType.Lowercase);
          keyboardsSwitch = switchComponent;
        }
      )
    );
  }
}
