import { DS } from "~/libs";
import { LogoFast } from "~/utils/rtbf/models";

export class FastTVLogo extends DS.View {
  constructor(logo: LogoFast | undefined | null) {
    super("FastTVLogo");
    if (logo === undefined || logo === null) return;

    this.rootElement.style.backgroundImage = `url(${logo.url})`;
    this.rootElement.style.width = logo.width + "px";
    this.rootElement.style.height = logo.height + "px";
    this.rootElement.style.top = logo.position?.y + "px";
    this.rootElement.style.left = logo.position?.x + "px";
    this.rootElement.style.position = "absolute";
    this.rootElement.style.backgroundSize = "contain";
    this.rootElement.style.backgroundRepeat = "no-repeat";
  }

  rejectsFocus = () => true;
}
