import { IRedBeeAnalyticsOptions, RedBeeAnalytics } from "~/analytics/RedBeeAnalytics";

export interface IConnectorOptions extends IRedBeeAnalyticsOptions {
  playerName?: string;
  playerVersion?: string;
  debug?: boolean;
}

export abstract class BaseConnector {
  protected customer: string;
  protected businessUnit: string;
  /**
   * @deprecated exposureBaseUrl is deprecated and will be removed in a future release. Please  use analyticsBaseUrl instead.
   */
  protected exposureBaseUrl: string;
  protected analyticsBaseUrl: string;
  protected sessionToken: string;
  protected playerName: string;
  protected playerVersion: string;
  protected sessionId: string;

  protected rbmAnalytics: RedBeeAnalytics | null;

  protected constructor({
    customer,
    businessUnit,
    exposureBaseUrl = "",
    analyticsBaseUrl = "",
    sessionToken,
    playerName = "javascript-player",
    playerVersion = "",
    device,
    debug = false,
  }: IConnectorOptions) {
    this.customer = customer;
    this.businessUnit = businessUnit;
    this.exposureBaseUrl = exposureBaseUrl;
    this.analyticsBaseUrl = analyticsBaseUrl;
    this.sessionToken = sessionToken;

    this.playerName = playerName;
    this.playerVersion = playerVersion;

    this.sessionId = device.id; //getDeviceId();

    const rbmAnalytics = new RedBeeAnalytics({
      customer: this.customer,
      businessUnit: this.businessUnit,
      sessionToken: this.sessionToken,
      exposureBaseUrl,
      analyticsBaseUrl,
      device,
      debug,
    });
    this.rbmAnalytics = rbmAnalytics;
  }

  /**
   * @deprecated setExposureBaseUrl is deprecated and will be removed a future release. Use setAnalyticsBaseUrl instead.
   */
  setExposureBaseUrl(exposureBaseUrl: string): void {
    this.exposureBaseUrl = exposureBaseUrl;
    this.rbmAnalytics?.setAnalyticsBaseUrl(exposureBaseUrl);
  }

  setAnalyticsBaseUrl(analyticsBaseUrl: string): void {
    this.analyticsBaseUrl = analyticsBaseUrl;
    this.rbmAnalytics?.setAnalyticsBaseUrl(analyticsBaseUrl);
  }

  // resolveFormatType(url: string): FormatType | null {
  //   if (url.includes(".mpd")) {
  //     return FormatType.DASH;
  //   }
  //   if (url.includes(".m3u8")) {
  //     return FormatType.HLS;
  //   }
  //   if (url.includes(".ism")) {
  //     return FormatType.SMOOTHSTREAMING;
  //   }
  //   if (url.includes(".mp4")) {
  //     return FormatType.MP4;
  //   }
  //   if (url.includes(".mp3")) {
  //     return FormatType.MP3;
  //   }
  //   return null;
  // }

  public abstract connect(): void;

  public abstract disconnect(): void;

  public destroy() {
    this.rbmAnalytics?.destroy();
    this.rbmAnalytics = null;
  }
}
