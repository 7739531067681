import { DS } from "dslib-tv";

import { getCurrentTimeRelativeToNow } from "./time";
import { Toast } from "./uiHelper";

interface INotificationStorage {
  id: number;
  title: string;
  scheduledFrom: string;
}

class Notification {
  private _localStorageID = "notifications";
  private _debug = false;
  private _debugTime = 20000;

  private _listeners: number[] = [];

  constructor() {
    // Init if don't exist
    if (DS.Storage.getItem(this._localStorageID) === null) {
      this._data = [];
    }
    // Restore old notifs
    this._listeners = [];
    this._restore();
  }

  private get _data() {
    return JSON.parse(DS.Storage.getItem(this._localStorageID) ?? JSON.stringify([])) as INotificationStorage[];
  }

  private set _data(notifs: INotificationStorage[]) {
    DS.Storage.setItem(this._localStorageID, JSON.stringify(notifs));
  }

  /**
   * Restore all timeout from localStorage
   */
  private _restore() {
    this._data = this._data.map(elem => {
      const id = this._createTimeout(elem.title, new Date(elem.scheduledFrom));
      return { ...elem, id };
    });
  }

  /**
   * Save in local the notification
   * @param id The timeout ID, to delete it when launched
   * @param title The title displayed
   * @param scheduledFrom The time scheduled
   */
  private _save(id: number, title: string, scheduledFrom: Date) {
    const data = this._data;
    data.push({
      id,
      title,
      scheduledFrom: scheduledFrom.toISOString(),
    });
    this._data = data;
  }

  /**
   * Remove the following timeout ID from localStorage
   * @param id The id of the timeout
   */
  private _remove(id: number) {
    const data = this._data;
    const ind = data.findIndex(elem => elem.id === id);
    if (ind !== -1) data.splice(ind, 1);
    this._data = data;
  }

  /**
   * Create a timeout and return the ID
   * @param title The title of the content
   * @param scheduledFrom Time at which the content will be displayed
   * @returns The timeout ID
   */
  private _createTimeout(title: string, scheduledFrom: Date): number {
    const time = Math.abs(getCurrentTimeRelativeToNow(scheduledFrom));
    const id = window.setTimeout(() => {
      Toast(`${title} ${t("notification.starting")}`);
      this._remove(id);
    }, time);
    this._listeners.push(id);
    return id;
  }

  /**
   * Configure a new notification
   * @param title The title of the movie about to start
   * @param scheduledFrom The time at which the notification will display
   */
  set(title: string, scheduledFrom: Date) {
    if (this._debug) scheduledFrom = new Date(Date.now() + this._debugTime);
    const id = this._createTimeout(title, scheduledFrom);
    this._save(id, title, scheduledFrom);
    Toast(`${t("notification.saved")}`);
  }

  /**
   * Check if a notification will be displayed for this content
   * @param title The title of the program
   * @param scheduledFrom
   * @returns Boolean
   */
  check(title: string): boolean {
    return this._data.find(elem => elem.title === title) ? true : false;
  }

  /**
   * Clear all notifications.
   */
  clear() {
    this._listeners.forEach(elem => {
      window.clearTimeout(elem);
    });
    this._listeners = [];
  }
}

export const NotificationsManager = new Notification();
