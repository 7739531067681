import { DS } from "dslib-tv";

import { APIGigyaOIDC } from "../datas/api/apiGigyaOIDC";
import { areArraysEqual } from "./arrayHelper";

type STYLEtype = "STYLEA" | "STYLEB" | "STYLEC" | "STYLED";
type SIZEtype = "SMALL" | "MEDIUM" | "BIG";
type KidsData = {
  UID: string;
  onBoardingShown: boolean;
  isSecured: boolean;
  securedPageShown: boolean;
}[];

export type SubtitlePersonnalizationType = Record<string, string | SIZEtype | STYLEtype> & {
  position: string;
  size: SIZEtype;
  style: STYLEtype;
};

type DevicePreferencesOptions = Record<string, boolean | string | SubtitlePersonnalizationType | KidsData> & {
  bingeEnable: boolean;
  audio: string;
  subtitle: string;
  subtitlePersonnalization: SubtitlePersonnalizationType;
  kidsEnable: boolean;
  kidsSecuredData: KidsData;
};

class DevicePreferencesManager {
  // preferences that can be change in the app by the player for exemple
  private _preferences: DevicePreferencesOptions = {
    audio: "FR",
    subtitle: "DISABLE",
    bingeEnable: true,
    kidsEnable: false,
    kidsSecuredData: [
      {
        UID: "anonymous",
        onBoardingShown: false,
        isSecured: false,
        securedPageShown: false,
      },
    ],
    subtitlePersonnalization: {
      position: "BOTTOM",
      size: "MEDIUM",
      style: "STYLEA",
    },
  };
  // change only when we save to localstorage; actually represent values of preferences in localStorage
  private _cachedPreferences: DevicePreferencesOptions;

  subtitlePersonnalizationListener$: DS.Listenable<SubtitlePersonnalizationType>;

  languageSelectionAudioValues = ["FR", "QAA", "QAD", "EN"];
  languageSelectionSubtitlesValues = ["DISABLE", "FR", "EN", "QHH"];

  kidsEnable$: DS.Listenable<boolean>;

  localStorageID = "deviceManager";

  constructor() {
    const localStorageData = DS.Storage.getItem(this.localStorageID);
    if (localStorageData !== null) {
      this._cachedPreferences = JSON.parse(localStorageData) as DevicePreferencesOptions;
      !this._arePreferencesUpToDate() && this._registerMissingPreferences();
      this._preferences = { ...this._cachedPreferences };
    } else {
      DS.Storage.setItem(this.localStorageID, JSON.stringify(this._preferences));
      this._cachedPreferences = { ...this._preferences };
    }

    this.kidsEnable$ = new DS.Listenable<boolean>(this._cachedPreferences.kidsEnable);
    this.subtitlePersonnalizationListener$ = new DS.Listenable<SubtitlePersonnalizationType>(
      this._cachedPreferences.subtitlePersonnalization
    );
  }

  /**
   * Function that update preferences in the app and in the localstorage of the device
   * @param type type of the setting to set
   * @param value the value of the setting
   */
  save(preferences: DevicePreferencesOptions) {
    DS.Storage.setItem(this.localStorageID, JSON.stringify(preferences));
    this._preferences = preferences;
    this._cachedPreferences = { ...preferences };
    this.subtitlePersonnalizationListener$.value = preferences.subtitlePersonnalization;
  }

  /**
   * return the application preference
   * @returns preferences
   */
  preferences() {
    return this._preferences;
  }

  /**
   * return local storage preference
   * @returns cachedPreferences
   */
  cachedPreferences() {
    return this._cachedPreferences;
  }

  /**
   * set audio preference whenever we change track in the player ;
   * @param lang new Language
   */
  setPlayerAudioPreference(lang: string) {
    const newLang = lang.toUpperCase();
    this._preferences.audio = newLang;
    if (this.languageSelectionAudioValues.includes(newLang)) {
      this.save({
        ...this._preferences,
        audio: newLang,
      });
    }
  }

  /**
   * set subtitle preference whenever we change track in the player;
   * @param lang new Language
   */
  setPlayerSubtitlePreference(lang: string) {
    const newLang = lang.toUpperCase();
    this._preferences.subtitle = newLang;
    if (this.languageSelectionSubtitlesValues.includes(newLang)) {
      this.save({
        ...this._preferences,
        subtitle: newLang,
      });
    }
  }

  /**
   *  refresh our preferences based on the cachedPreferences.
   */
  refreshPreference() {
    this._preferences = { ...this._cachedPreferences };
  }

  /**
   * Check if localhost object have all required properties
   */
  private _arePreferencesUpToDate() {
    const localHostPreferences = Object.keys(this._cachedPreferences);
    const defaultPreferences = Object.keys(this._preferences);
    return areArraysEqual(localHostPreferences, defaultPreferences);
  }

  /**
   * Register missing preferences with their default values in the localhost
   */
  private _registerMissingPreferences() {
    const defaultPreferences = Object.keys(this._preferences);
    defaultPreferences.forEach(preference => {
      if (!(preference in this._cachedPreferences)) {
        this._cachedPreferences[preference] = this._preferences[preference];
        DS.Storage.setItem(this.localStorageID, JSON.stringify(this._cachedPreferences));
      }
    });
    DS.Storage.setItem(this.localStorageID, JSON.stringify(this._cachedPreferences));
  }

  /**
   * To switch in and out of kids
   */
  switchKids(enabled: boolean) {
    this.save({
      ...this._cachedPreferences,
      kidsEnable: enabled,
    });
    this.kidsEnable$.value = enabled;
  }

  /**
   * check if the current user kids is secured or not
   * @returns true or false
   */
  isUserKidsSecured() {
    const userKidsSecurityData = DevicePreferenceHelper.cachedPreferences().kidsSecuredData.find(
      item => item.UID === (APIGigyaOIDC.userInfo$.value?.uid ?? "anonymous")
    );
    return userKidsSecurityData !== undefined && userKidsSecurityData.isSecured;
  }

  /**
   * check if the securedOnboarding have already been shown
   * @returns true or false
   */
  hasOnBoardingBeenShowned() {
    const userKidsSecurityData = DevicePreferenceHelper.cachedPreferences().kidsSecuredData.find(
      item => item.UID === (APIGigyaOIDC.userInfo$.value?.uid ?? "anonymous")
    );
    return userKidsSecurityData !== undefined && userKidsSecurityData.onBoardingShown;
  }

  hasSecuredOnBoardingBeenShowned() {
    const userKidsSecurityData = DevicePreferenceHelper.cachedPreferences().kidsSecuredData.find(
      item => item.UID === (APIGigyaOIDC.userInfo$.value?.uid ?? "anonymous")
    );
    return userKidsSecurityData !== undefined && userKidsSecurityData.securedPageShown;
  }

  /**
   * Function to enable kids security for anonymous and connected
   * @param params
   */
  enableKidsSecurity(params: { onBoardingShown: boolean; isSecured: boolean; securedPageShown: boolean }) {
    const kidsSecuredData = DevicePreferenceHelper.cachedPreferences().kidsSecuredData;
    const index = kidsSecuredData.findIndex(item => item.UID === (APIGigyaOIDC.userInfo$.value?.uid ?? "anonymous"));
    const enableSecurityValue = {
      UID: APIGigyaOIDC.userInfo$.value?.uid ?? "anonymous",
      onBoardingShown: params.onBoardingShown,
      isSecured: params.isSecured,
      securedPageShown: params.securedPageShown,
    };
    if (index !== -1) kidsSecuredData[index] = enableSecurityValue;
    else kidsSecuredData.push(enableSecurityValue);

    DevicePreferenceHelper.save({
      ...DevicePreferenceHelper.cachedPreferences(),
      kidsSecuredData,
    });
  }

  disableKidsSecurity() {
    DevicePreferenceHelper.save({
      ...DevicePreferenceHelper.cachedPreferences(),
      kidsSecuredData: [
        {
          UID: "anonymous",
          onBoardingShown: false,
          isSecured: false,
          securedPageShown: false,
        },
      ],
    });
  }
}

export const DevicePreferenceHelper = new DevicePreferencesManager();
