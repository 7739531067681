import "./contentPlayLargeButtonView.scss";

import { DS } from "dslib-tv";

import { MediaPremiumCard } from "~/utils/rtbf/models";

import { PlayHistoryHelper } from "../../../../tools/playHistoryHelper";
import { getFormatDate, getFormatTime, getProgressPercentage } from "../../../../tools/time";
import { RTBF } from "../../../../utils/rtbf";
import { AcceptsMouseFocusView } from "../../common/mouseSupport/acceptsMouseFocusView";
import { ProgressBarView } from "../../common/progressBar/progressBarView";

class ContentPlayLargeButtonView extends AcceptsMouseFocusView {
  protected _statusElt;
  protected _subtitleElt;
  protected _publishedFromElt;
  protected _durationElt;
  protected _remainingTimeElt;
  protected _cardContainer;

  constructor(id: string, className: string) {
    super(id, `contentPlayLargeButtonView ${className}`);

    this._cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "buttonContainer",
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this._cardContainer,
      className: "playIcon",
      style: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    const titleElt = DS.DOMHelper.createElement({
      tagName: "span",
      parent: this._cardContainer,
      className: "title",
    });

    this._statusElt = DS.DOMHelper.createElement({
      tagName: "span",
      parent: titleElt,
      className: "status",
    });

    this._subtitleElt = DS.DOMHelper.createElement({
      tagName: "span",
      parent: titleElt,
      className: "titlemedia",
    });

    const metadataElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this._cardContainer,
      className: "metadata",
    });

    this._publishedFromElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: metadataElt,
      className: "date",
    });

    this._durationElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: metadataElt,
      className: "duration",
    });

    this._remainingTimeElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this._cardContainer,
      className: "remainingTime",
    });
  }
}

/*
  Large play button
*/
export class ContentPlayMediaLargeButtonView extends ContentPlayLargeButtonView {
  protected _loaderElt;
  protected _loading = true;

  constructor(programId: string, media$: DS.IListenable<RTBF.MediaCard | MediaPremiumCard | undefined>) {
    super(`buttonPlay${programId}`, "contentPlayMediaLargeButtonView");

    this._loaderElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "loadingContainer",
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this._loaderElt,
      className: "spinner",
    });

    const progressBarView = new ProgressBarView("media");
    this._cardContainer.appendChild(progressBarView.rootElement);

    media$.didChange(
      media => {
        if (media === undefined) {
          this._loading = true;
          return false;
        }

        const currentOffset$ = PlayHistoryHelper.getCurrentOffset(media.id);

        this._statusElt.innerText =
          currentOffset$.value > 0
            ? t("playButton.replay_title")
            : media.type === "AUDIO"
            ? t("playButton.play_audio_title")
            : t("playButton.play_title");

        this._subtitleElt.innerText = media.subtitle ?? "";

        if (media.publishedFrom !== undefined) {
          this._publishedFromElt.innerText =
            media.releaseDate ?? getFormatDate(new Date(media.publishedFrom), "dd/mm/yyyy");
        } else {
          this._publishedFromElt.innerText = "";
        }

        this.displayLoader(false);

        this._durationElt.innerText = getFormatTime(media.duration);

        currentOffset$.didChange(
          value => {
            if (value === 0) {
              this._cardContainer.classList.add("notPlayed");
            } else if (value > 0) {
              this._cardContainer.classList.remove("notPlayed");

              const remainingTime = media.duration - value;
              if (remainingTime > 0) {
                this._remainingTimeElt.innerText = "-" + getFormatTime(remainingTime);
              }
              progressBarView.updateCurrenOffset(value, media.duration);
            }
          },
          this,
          true
        );
      },
      this,
      true
    );
  }

  displayLoader(show: boolean) {
    this._loaderElt.style.opacity = show === true ? "1" : "0";
    this._loading = show;
  }

  rejectsFocus() {
    return this._loading;
  }
}

export class ContentPlayLiveLargeButtonView extends ContentPlayLargeButtonView {
  private _intervalID: number | undefined;
  private _liveSchedledFromDate: Date;
  private _liveSchedledToDate: Date;
  private _liveDuration: number;
  private _progressBarView: ProgressBarView;

  constructor(programId: string, live: RTBF.TvLiveCard | undefined) {
    super(`buttonPlay${programId}`, "contentPlayLiveLargeButtonView");

    this._progressBarView = new ProgressBarView("live");
    this._cardContainer.appendChild(this._progressBarView.rootElement);

    this._statusElt.innerText = t("playButton.live_title");

    this._subtitleElt.innerText = live?.subtitle ?? "";

    if (live?.scheduledFrom !== undefined) {
      this._publishedFromElt.innerText = getFormatDate(new Date(live?.scheduledFrom), "dd/mm/yyyy");
    } else {
      this._publishedFromElt.innerText = "";
    }

    this._durationElt.innerText = getFormatTime(live?.duration ?? 0);

    this._liveSchedledFromDate = new Date(live?.scheduledFrom ?? 0);
    this._liveSchedledToDate = new Date(live?.scheduledTo ?? 0);
    this._liveDuration = live?.duration ?? 1;

    this._updateTime();
    this._intervalID = window.setInterval(() => this._updateTime(), 15000);
  }

  private _updateTime() {
    this._progressBarView.setPercentageProgression(
      getProgressPercentage(this._liveSchedledFromDate, this._liveDuration)
    );

    const secondsLeft = Math.floor((this._liveSchedledToDate.getTime() - new Date().getTime()) / 1000);
    if (secondsLeft > 0) {
      const remainingTime = getFormatTime(secondsLeft);
      this._remainingTimeElt.innerText = remainingTime !== "" ? "-" + getFormatTime(secondsLeft) : "";
    }
  }

  onRelease() {
    super.onRelease();
    clearInterval(this._intervalID);
  }
}
