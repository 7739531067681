import { SettingsData } from "../utils/rtbf/models";

export class SettingsHelper {
  private static _instance?: SettingsHelper;
  private _data?: SettingsData;

  static get instance() {
    return (SettingsHelper._instance = SettingsHelper._instance ?? new SettingsHelper());
  }

  get data() {
    return this._data;
  }

  public setData(data: SettingsData) {
    this._data = data;
  }
}
