export const loadJS = (src: string) => {
  return new Promise<unknown | void>((resolve, reject) => {
    if (document.querySelector(`head > script[src="${src}"]`) !== null) {
      return resolve(undefined);
    }
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
    script.onload = resolve;
    script.onerror = e => {
      Log.app.error(e);
      reject();
    };
  });
};
