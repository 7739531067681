import "./pairingPage.scss";

import { CallFlow } from "~/callflow";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { createQRCode } from "~/tools/uiHelper";
import { CallbackVoid } from "~/typings";

export class PairingPageView extends DS.View implements DS.IPage {
  private _qrCodeSectionView: QrCodeSectionView | undefined;
  private _computerSectionView: ComputerSectionView | undefined;

  constructor(loginSuccessCallback: CallbackVoid | undefined = undefined) {
    super("", "PairingPageView");

    // Reset this callback if it's not explicitly defined to avoid side effects
    CallFlow.loginSuccessCallback = loginSuccessCallback;

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "pageTitle",
      innerText: t("pairing.pageTitle"),
    });

    void CallFlow.initialPairing();

    APIGigyaOIDC.deviceAuthorization$.didChange(
      value => {
        if (value === undefined) return;

        if (this._qrCodeSectionView === undefined) {
          this.rootElement.appendChild(
            (this._qrCodeSectionView = new QrCodeSectionView(value.verification_uri_complete)).rootElement
          );
          this.rootElement.appendChild(new SeparatorView().rootElement);
          this.rootElement.appendChild(
            (this._computerSectionView = new ComputerSectionView(value.user_code, value.verification_uri)).rootElement
          );
        } else {
          this._qrCodeSectionView?.updatePairingCode(value.verification_uri_complete);
          this._computerSectionView?.updatePairingCode(value.user_code);
        }
      },
      this,
      true
    );
  }

  onRelease() {
    super.onRelease();
    APIGigyaOIDC.endDeviceAuthorization();
  }
}

class QrCodeSectionView extends DS.View {
  private _qrCodeElt: HTMLDivElement | undefined;
  private _qrTextElt: HTMLDivElement;
  constructor(url: string) {
    super("", "section qr");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "sectionTitle",
      innerText: t("pairing.sectionTitleQr"),
    });

    this.rootElement.appendChild((this._qrCodeElt = createQRCode(url, "qrCodeImage")));

    this._qrTextElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "qrCodeText",
      innerText: t("pairing.qrSectionText"),
    });
  }

  updatePairingCode(url: string) {
    this._qrCodeElt?.parentElement?.removeChild(this._qrCodeElt);
    this.rootElement.insertBefore((this._qrCodeElt = createQRCode(url, "qrCodeImage")), this._qrTextElt);
  }

  rejectsFocus() {
    return true;
  }
}

class ComputerSectionView extends DS.View {
  private _pairingCodeElt;

  constructor(pairingCode: string, pairingUrl: string) {
    super("", "section computer");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "sectionTitle",
      innerText: t("pairing.sectionTitleUrl"),
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "urlSectionText one",
      innerText: t("pairing.subtitleUrl"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "urlSectionText two",
      innerText: pairingUrl.replace("https://", ""),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "urlSectionText three",
      innerText: t("pairing.subtitleCode"),
    });
    this._pairingCodeElt = DS.DOMHelper.createElement({
      tagName: "p",
      parent: this.rootElement,
      className: "pairingCode",
      innerText: pairingCode,
    });
  }

  updatePairingCode(code: string) {
    this._pairingCodeElt.innerText = code;
  }

  rejectsFocus() {
    return true;
  }
}

class SeparatorView extends DS.View {
  constructor() {
    super("", "separator");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "text",
      innerText: t("pairing.separator"),
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "verticalLine",
    });
  }
}
