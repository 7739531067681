import "./toaster.scss";

import { DS } from "~/libs";

export class Toaster extends DS.View {
  constructor() {
    super("toaster", "toaster");
  }

  rejectsFocus() {
    return true;
  }
}

export class ToasterTile extends DS.View {
  constructor(text: string) {
    super("toasterTile", "toasterTile");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "toasterText",
      innerText: text,
    });

    window.setTimeout(async () => {
      this.rootElement.parentNode?.removeChild(this.rootElement);
    }, 5000);
  }

  rejectsFocus() {
    return true;
  }
}
