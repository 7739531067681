import { FocusTracker } from "../components/focusTracker";
import { RTBF } from "../utils/rtbf";

type Card =
  | RTBF.MediaCard
  | RTBF.MediaPremiumCard
  | RTBF.TvLiveCard
  | RTBF.CtaCard
  | RTBF.ProgramCard
  | RTBF.FavoriteCardWithResource
  | RTBF.FavoriteCardWithResource
  | RTBF.CtaCard
  | RTBF.GuestCard
  | RTBF.EmptyCard;

/** Helper class to make it easier to manage tracker focus color according to card type */
export class FocusManager {
  private _focusTracker: FocusTracker;

  /**
   * Create a focus manager
   * @param focusTracker
   */
  constructor(focusTracker: FocusTracker) {
    this._focusTracker = focusTracker;
  }

  /**
   * Function that dynamically change the color of the focus depending on card type
   * @param card Card
   */
  public manageFocus(card: Card) {
    switch (card.resourceType) {
      case "LIVE":
        this._handleTVLiveFocus(card);
        break;
      case "MEDIA_PREMIUM":
        this._handlePremiumFocus();
        break;
      case "FAVORITE":
        if (card?.resource?.resourceType === "MEDIA_PREMIUM") {
          this._handlePremiumFocus();
          return;
        }
        this._handleDefaultFocus();
        break;
      default:
        this._handleDefaultFocus();
        break;
    }
  }

  private _handleTVLiveFocus(card: RTBF.TvLiveCard) {
    const now = new Date();

    const startDate: Date = new Date(card.scheduledFrom);
    const endDate: Date = new Date(card.scheduledTo);

    if (startDate < now && endDate > now) {
      // current live
      this._focusTracker?.setFocusRectStyle("focusRectRed");
    } else if (startDate >= now) {
      // future live
      this._focusTracker?.setFocusRectStyle("focusRectOrange");
    } else if (now >= endDate) {
      // past live
      this._focusTracker?.setFocusRectStyle("focusRect");
    }
  }

  private _handlePremiumFocus() {
    this._focusTracker?.setFocusRectStyle("focusRectPremium");
  }

  private _handleDefaultFocus() {
    this._focusTracker.setFocusRectStyle("focusRect");
  }
}
