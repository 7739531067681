import { IListenable } from "../ui/typings";
import { Keys } from "../ui/typings";

/* eslint-disable prefer-const */
export type LogOutput = "console" | "consoleRe" | "buffer" | "overlay" | "remoteJS";

export enum LogLevel {
  off,
  error,
  warn,
  info,
  debug,
  trace,
}

export interface ILogLevels {
  [namespace: string]: LogLevel;
}

export interface ILogger {
  trace: (...msg: unknown[]) => void;
  debug: (...msg: unknown[]) => void;
  info: (...msg: unknown[]) => void;
  log: (...msg: unknown[]) => void;
  warn: (...msg: unknown[]) => void;
  error: (...msg: unknown[]) => void;
}

export interface INamespacedLog {
  [key: string]: ILogger;
}

export interface IConfigParams {
  /** name of the app - will be used for the remoteJS & consoleRe urls */
  appName: string;
  /** provide an array of string arrays (multiple sequences)
   *
   * the type of string are the key values defines in the WWWC
   * https://developer.mozilla.org/fr/docs/Web/API/KeyboardEvent/key/Key_Values
   *
   * @default famous konami code: blue yellow green blue green (or control for browsers)
   */
  triggerSequences: string[][];
  /** the root element in which the overlay, popups & menus are going to be shown
   * @default undefined - which means the body will be used (not working on some boxes like Movistar)
   */
  rootElement: HTMLElement;
}

export interface ILogCaller {
  col: string;
  file: string;
  line: string;
}

export interface ILogEntry {
  caller?: ILogCaller;
  level: LogLevel;
  levelAsString: string;
  namespace: string;
  timestamp: number;
  timestampAsString: string;
  text: string;
}

export interface ILogOutput {
  appendBufferLogs: () => void;
}

export interface ILog {
  readonly entries: ILogEntry[];
  readonly namespaces: string[];
  readonly consoleNamespace?: string;
  readonly output$: IListenable<LogOutput>;

  /**
   * add namespaces on the log
   * @example
   * providing ["namespace1", "namespace2", "namespaceN"] will generate a namespaceLog object in the following form:
   *
   * \{
   *  namespace1: ILogger;
   *  namespace2: ILogger;
   *  namespaceN: ILogger;
   * \}
   *
   * where ILogger is the equivalent of a console object (with debug, trace, info, log, warning and error logging functions)
   */
  create: <T extends string>(namespaces: readonly T[], levels?: ILogLevels) => Record<T, ILogger>;
  /**
   * should the global console be mapped automatically to a namespace?
   * if specified, reroutes console calls to the provided namespace
   *
   * @default "undefined" - no remapping, console stays untouched
   */
  setConsoleNamespace: (consoleNamespace?: string) => void;
  setOutput: (output: LogOutput, code?: string, persist?: boolean) => void;
}

export interface ICustomMenuEntry {
  id: string;
  title: string;
  desc?: string;
  onSelect: () => void;
}

export interface ILogUI {
  menuTriggerSequences: string[][];
  overlayToggleKey: Keys;
  keyHandler: (key: string, type: "keydown" | "keyup") => boolean;
  addCustomMenuEntry: (entry: ICustomMenuEntry) => boolean;
}

export type StatsPane = "Off" | "DOM Count" | "FPS" | "Memory";
export interface ILogStats {
  mode$: IListenable<StatsPane>;
  setMode(mode: StatsPane): void;
}

export interface IDSTools {
  appName: string;
  appVersion?: string;
  appHash?: string;

  readonly sessionId: string;
  readonly logLibVersion: string;
}
