import { z } from "zod";

/**
 * Used to parse shaka error responses and have similar errors as RedBee js player
 */
export const RedBeeError = z.object({
  code: z.number().optional(),
  status: z.number().optional(),
  message: z.string(),
});
export type RedBeeError = z.infer<typeof RedBeeError>;
