export enum SnowplowPlayerEventName {
  abort = "abort",
  ended = "ended",
  init = "init",
  pause = "pause",
  recommendation_click = "recommendation_click",
  resume = "resume",
  seeking = "seeking",
  sought = "sought",
  start = "start",
}

export const SnowplowPlayerEventVersions: { [key in SnowplowPlayerEventName]: string } = {
  abort: "1-0-2",
  ended: "1-0-2",
  init: "1-0-1",
  pause: "1-0-2",
  recommendation_click: "1-0-2",
  resume: "1-0-0",
  seeking: "1-0-2",
  sought: "1-0-2",
  start: "1-0-2",
};

type SnowplowEventInit = {
  timestamp: string;
  media_id: string;
  is_live: boolean;
  is_audioonly: boolean;
  is_highlight: boolean;
  user_id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pseudo_user_id: string;
  client_ip: string;
  platform: string;
  useragent: string;
  device: string;
  product: string;
  contexte: number;
  pageurl: string;
  page_referrer: string;
};

type SnowplowEventOffset = {
  offset: number;
};

type SnowplowEventRecommandation = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  recommended_media_id: string;
};

export type SnowplowPlayerEvents = SnowplowEventRecommandation | SnowplowEventInit | SnowplowEventOffset;
