import "./audioIconView.scss";

import { DS } from "~/libs";

export class AudioIconView extends DS.View {
  constructor() {
    super("", "audioIconView");

    const audioIconPath = require("@assets/images/icons/podcast.png");
    this.rootElement.style.backgroundImage = `url(${audioIconPath})`;
  }
}
