import { platform } from "../platform";
import { Keys, PlatformType } from "../typings";
import { UILog } from "../uiLog";
import { trycatch } from "./trycatch";

declare global {
  // extending keyboard event for LG
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface KeyboardEvent {
    keyIdentifier: string;
  }
}

interface IKeyMap {
  [key: string]: Keys;
}
interface IKeycodeMap {
  [key: number]: Keys;
}

const keyCodeMapHdmiCeCDefault: IKeycodeMap = {
  133: Keys.rewind,
  134: Keys.forward,
  135: Keys.pause,
  178: Keys.stop,
  179: Keys.play,
  217: Keys.playPause,
};

/* eslint-disable @typescript-eslint/naming-convention */
const keyCodeMapDefault: IKeycodeMap = {
  8: Keys.back,
  65: Keys.back,
  57396: Keys.back,
  13: Keys.select,
  57397: Keys.select,

  37: Keys.left,
  38: Keys.up,
  39: Keys.right,
  40: Keys.down,

  48: Keys.zero,
  49: Keys.one,
  50: Keys.two,
  51: Keys.three,
  52: Keys.four,
  53: Keys.five,
  54: Keys.six,
  55: Keys.seven,
  56: Keys.eight,
  57: Keys.nine,

  403: Keys.red,
  404: Keys.green,
  405: Keys.yellow,
  406: Keys.blue,

  33: Keys.rewind,
  34: Keys.forward,
  36: Keys.play,
  35: Keys.pause,
  83: Keys.stop,

  73: Keys.info,
};

const keyCodeMapWebOS: IKeycodeMap = {
  461: Keys.back,

  // ???
  502: Keys.yellow,

  33: Keys.pageUp,
  34: Keys.pageDown,

  412: Keys.rewind,
  417: Keys.forward,
  415: Keys.play,
  19: Keys.pause,
  413: Keys.stop,
};

const keyCodeMapTizen: IKeycodeMap = {
  10009: Keys.back,

  427: Keys.pageUp,
  428: Keys.pageDown,

  412: Keys.rewind,
  417: Keys.forward,
  415: Keys.play,
  19: Keys.pause,
  413: Keys.stop,
  10252: Keys.playPause,

  457: Keys.info,
};

const keyCodeMapHisense: IKeycodeMap = {
  502: Keys.yellow,
};

const keyCodeMapPS4: IKeycodeMap = {
  // HDMI-CEC support
  122: Keys.previous,
  123: Keys.next,
  126: Keys.forward,
  127: Keys.rewind,
  128: Keys.play,
  129: Keys.stop,
  130: Keys.pause,
};

const keyCodeMapPS5: IKeycodeMap = {
  27: Keys.back,

  // HDMI-CEC support
  133: Keys.rewind,
  134: Keys.forward,
  135: Keys.pause,
  178: Keys.stop,
  179: Keys.play,
  217: Keys.playPause,
};

const keyMap: IKeyMap = {
  Up: Keys.up,
  Down: Keys.down,
  Left: Keys.left,
  Right: Keys.right,
  ArrowUp: Keys.up,
  ArrowDown: Keys.down,
  ArrowLeft: Keys.left,
  ArrowRight: Keys.right,
  Enter: Keys.select,
  Backspace: Keys.back,
  Escape: Keys.back,
  Esc: Keys.back,

  PageUp: Keys.pageUp,
  PageDown: Keys.pageDown,

  ChannelUp: Keys.pageUp,
  ChannelDown: Keys.pageDown,

  i: Keys.info,

  // webOS 3.x ?
  "U+0030": Keys.zero,
  "U+0031": Keys.one,
  "U+0032": Keys.two,
  "U+0033": Keys.three,
  "U+0034": Keys.four,
  "U+0035": Keys.five,
  "U+0036": Keys.six,
  "U+0037": Keys.seven,
  "U+0038": Keys.eight,
  "U+0039": Keys.nine,
  "U+0193": Keys.red,
  "U+0194": Keys.green,
  "U+0195": Keys.yellow,
  "U+0196": Keys.blue,
  "U+01CD": Keys.back,

  // webOS 5.x ?
  RCUBack: Keys.back,
  Pause: Keys.pause,
  "U+019F": Keys.play,

  // LG 2020
  GoBack: Keys.back,

  // desktop
  F1: Keys.red,
  F2: Keys.green,
  F3: Keys.yellow,
  F4: Keys.blue,
  "U+0042": Keys.back, // B for back - support for windows chromium 38

  F7: Keys.rewind,
  F8: Keys.playPause,
  F9: Keys.forward,

  // Tizen 5.x
  XF86Back: Keys.back,
  XF86Red: Keys.red,
  XF86Green: Keys.green,
  XF86Yellow: Keys.yellow,
  XF86Blue: Keys.blue,
  XF86AudioPause: Keys.pause,
  XF86AudioPlay: Keys.play,
  XF86AudioStop: Keys.stop,
  XF86AudioRewind: Keys.rewind,
  XF86AudioNext: Keys.forward,
  XF86PlayBack: Keys.playPause,
  XF86NextChapter: Keys.next,
  XF86PreviousChapter: Keys.previous,

  // tizen 3.x, keyIdentifier
  "U+280C": Keys.playPause,
  MediaPause: Keys.pause,
  MediaPlay: Keys.play,
  MediaStop: Keys.stop,
  MediaRewind: Keys.rewind,
  MediaFastForward: Keys.forward,

  //hisense
  VK_PLAY: Keys.play,
  VK_PAUSE: Keys.pause,
  VK_PLAY_PAUSE: Keys.playPause,
  VK_STOP: Keys.stop,
  VK_BACK_SPACE: Keys.back,

  //tivo
  FastFwd: Keys.forward,
};
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * map key codes based on 'keyCodeMapDefault'\
 * identifies if key event is supported
 * @param ev - KeyboardEvent returned by browser
 * @returns
 */
export function mapKeyboardEvent(ev: KeyboardEvent): string {
  return (
    trycatch("mapKeyboardEvent caught an exception", () => {
      const keyFromKeyMap = keyMap[ev.keyIdentifier ?? ev.key] as Keys | undefined;
      const keyFromKeycodeMap = (() => {
        switch (platform.type) {
          case PlatformType.webos:
            return keyCodeMapWebOS[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

          case PlatformType.tizen:
            return keyCodeMapTizen[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

          case PlatformType.hisense:
            return keyCodeMapHisense[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

          case PlatformType.ps4:
            return keyCodeMapPS4[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

          case PlatformType.ps5:
            return keyCodeMapPS5[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

          default:
            return keyCodeMapDefault[ev.keyCode];
        }
      })() as Keys | undefined;
      const key = keyFromKeyMap ?? keyFromKeycodeMap ?? ev.key;
      UILog.keys.info(
        `mapKeyboardEvent: key: ${ev.key}, keyIdentifier(LG): ${ev.keyIdentifier}, keyCode: ${ev.keyCode}   -> ${key}`
      );
      return key;
    }) ?? ""
  );
}
