import { Listenable } from "../ui/helpers/Listenable";
import { LogInfo } from "./logInfo";
import { outputBuffer } from "./outputs/outputBuffer";
import { outputConsole } from "./outputs/outputConsole";
import { outputConsoleRe } from "./outputs/outputConsoleRe";
import { outputOverlay } from "./outputs/outputOverlay";
import { outputRemoteJS } from "./outputs/outputRemoteJS";
import { consoleDebug, consoleError, consoleInfo, consoleLog, consoleTrace, consoleWarn } from "./systemConsole";
import { ILog, ILogger, ILogLevels, INamespacedLog, LogLevel, LogOutput } from "./types";

declare global {
  // declaring this here this way makes sure any user of this library also knows about those type extensions
  // having those in a global.d.ts would require importing those types in the consumer, while it's already importing this file, so it feels like a duplication
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Console {
    re: Console;
  }
}

const _noop = () => {};
const createLogger = (namespace: string, logOutput: LogOutput, level: LogLevel): ILogger => {
  switch (logOutput) {
    case "console": {
      const NAMESPACE = namespace.toUpperCase();
      return {
        trace: level >= LogLevel.trace ? consoleTrace.bind(console, `[${NAMESPACE}]`) : _noop,
        debug: level >= LogLevel.debug ? consoleDebug.bind(console, `[${NAMESPACE}]`) : _noop,
        // log <-> info
        log: level >= LogLevel.info ? consoleLog.bind(console, `[${NAMESPACE}]`) : _noop,
        info: level >= LogLevel.info ? consoleInfo.bind(console, `[${NAMESPACE}]`) : _noop,
        warn: level >= LogLevel.warn ? consoleWarn.bind(console, `[${NAMESPACE}]`) : _noop,
        error: level >= LogLevel.error ? consoleError.bind(console, `[${NAMESPACE}]`) : _noop,
      } as ILogger;
    }

    case "remoteJS":
      if (console.log === consoleLog) {
        return createLogger(namespace, "buffer", level);
      } else {
        return {
          trace: (...data: unknown[]) => (level >= LogLevel.trace ? outputRemoteJS.trace(namespace, ...data) : _noop),
          debug: (...data: unknown[]) => (level >= LogLevel.debug ? outputRemoteJS.debug(namespace, ...data) : _noop),
          // log <-> info
          log: (...data: unknown[]) => (level >= LogLevel.info ? outputRemoteJS.info(namespace, ...data) : _noop),
          info: (...data: unknown[]) => (level >= LogLevel.info ? outputRemoteJS.info(namespace, ...data) : _noop),
          warn: (...data: unknown[]) => (level >= LogLevel.warn ? outputRemoteJS.warn(namespace, ...data) : _noop),
          error: (...data: unknown[]) => (level >= LogLevel.error ? outputRemoteJS.error(namespace, ...data) : _noop),
        } as ILogger;
      }

    case "consoleRe":
      return {
        trace: (...data: unknown[]) => (level >= LogLevel.trace ? outputConsoleRe.trace(namespace, ...data) : _noop),
        debug: (...data: unknown[]) => (level >= LogLevel.debug ? outputConsoleRe.debug(namespace, ...data) : _noop),
        // log <-> info
        log: (...data: unknown[]) => (level >= LogLevel.info ? outputConsoleRe.info(namespace, ...data) : _noop),
        info: (...data: unknown[]) => (level >= LogLevel.info ? outputConsoleRe.info(namespace, ...data) : _noop),
        warn: (...data: unknown[]) => (level >= LogLevel.warn ? outputConsoleRe.warn(namespace, ...data) : _noop),
        error: (...data: unknown[]) => (level >= LogLevel.error ? outputConsoleRe.error(namespace, ...data) : _noop),
      } as ILogger;

    case "buffer":
      return {
        trace: (...data: unknown[]) => (level >= LogLevel.trace ? outputBuffer.trace(namespace, ...data) : _noop),
        debug: (...data: unknown[]) => (level >= LogLevel.debug ? outputBuffer.debug(namespace, ...data) : _noop),
        // log <-> info
        log: (...data: unknown[]) => (level >= LogLevel.info ? outputBuffer.info(namespace, ...data) : _noop),
        info: (...data: unknown[]) => (level >= LogLevel.info ? outputBuffer.info(namespace, ...data) : _noop),
        warn: (...data: unknown[]) => (level >= LogLevel.warn ? outputBuffer.warn(namespace, ...data) : _noop),
        error: (...data: unknown[]) => (level >= LogLevel.error ? outputBuffer.error(namespace, ...data) : _noop),
      } as ILogger;

    case "overlay":
      return {
        trace: (...data: unknown[]) => (level >= LogLevel.trace ? outputOverlay.trace(namespace, ...data) : _noop),
        debug: (...data: unknown[]) => (level >= LogLevel.debug ? outputOverlay.debug(namespace, ...data) : _noop),
        // log <-> info
        log: (...data: unknown[]) => (level >= LogLevel.info ? outputOverlay.info(namespace, ...data) : _noop),
        info: (...data: unknown[]) => (level >= LogLevel.info ? outputOverlay.info(namespace, ...data) : _noop),
        warn: (...data: unknown[]) => (level >= LogLevel.warn ? outputOverlay.warn(namespace, ...data) : _noop),
        error: (...data: unknown[]) => (level >= LogLevel.error ? outputOverlay.error(namespace, ...data) : _noop),
      } as ILogger;
  }
};

export class LogFactoryImpl implements ILog {
  _namespaces: string[] = ["_"]; // _ always there, to be used in the log itself
  _namespacedLog: INamespacedLog = {};
  _consoleNamespace?: string;
  _appOutput: LogOutput = "buffer";
  _storageOutput$: Listenable<LogOutput | "default">;
  _output$ = new Listenable<LogOutput>("buffer");
  _code$ = new Listenable<string>(localStorage.getItem("remoteCode") ?? "0000");
  _levels: ILogLevels = {};

  get entries() {
    return outputBuffer.buffer;
  }
  get namespaces() {
    return this._namespaces;
  }
  get consoleNamespace() {
    return this._consoleNamespace;
  }

  get output$() {
    return this._output$;
  }

  constructor() {
    const storageOutput = localStorage.getItem("logOutput");
    switch (storageOutput) {
      case "console":
      case "consoleRe":
      case "buffer":
      case "overlay":
      case "remoteJS":
        this._storageOutput$ = new Listenable<LogOutput | "default">(storageOutput);
        break;

      default:
        this._storageOutput$ = new Listenable<LogOutput | "default">("default");
    }

    this._storageOutput$.didChange(output => {
      if (output) {
        localStorage.setItem("logOutput", output);
      } else {
        localStorage.removeItem("logOutput");
      }
      this._output$.value = output === "default" ? this._appOutput : output;
    }, null);

    this._code$.didChange(code => {
      if (code) {
        localStorage.setItem("remoteCode", code);
      } else {
        localStorage.removeItem("remoteCode");
      }
      switch (this._output$.value) {
        case "remoteJS":
          void outputRemoteJS.attach(this._code$.value);
          break;

        case "consoleRe":
          outputConsoleRe.attach(this._code$.value);
          break;
      }
    }, null);

    this._output$.didChange((output, oldOutput) => {
      // previous state cleanup
      switch (oldOutput) {
        case "console":
          outputConsole.lastTimestamp =
            outputBuffer.buffer.length && outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp;
          break;

        case "overlay":
          outputOverlay.hide();
          break;
        case "consoleRe":
          outputConsoleRe.detach();
          break;

        case "remoteJS":
          outputRemoteJS.detach();
          break;
      }

      // new state setting
      switch (output) {
        case "buffer":
          this.refreshNamespacedLog(output);
          break;
        case "console":
          this.refreshNamespacedLog(output);
          outputConsole.appendEarlierLogs();
          break;
        case "overlay":
          void outputOverlay.show();
          this.refreshNamespacedLog(output);
          break;
        case "consoleRe":
          // default to console at the beginning for remote logging - as it's async
          outputConsoleRe.attach(this._code$.value);
          this.refreshNamespacedLog("consoleRe");
          outputBuffer.buffer.length &&
            (outputConsoleRe.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp);
          break;

        case "remoteJS":
          // default to console at the beginning for remote logging - as it's async
          this.refreshNamespacedLog("console");
          void (async () => {
            console.warn("will attach remoteJS");
            await outputRemoteJS.attach(this._code$.value);
            console.warn("did attach remoteJS");
            // things have changed, reset the Log object!
            this.refreshNamespacedLog("remoteJS");
          })();
          break;
      }
    }, null);

    this.refreshNamespacedLog(this._output$.value);

    this._namespacedLog["_"].warn("initial log output :", this._output$.value);
    this._namespacedLog["_"].warn("initial log code   :", this._code$.value);
    this._namespacedLog["_"].warn("initial log appName:", LogInfo.appName);
  }

  create = <T extends string>(namespaces: readonly T[], levels?: ILogLevels): Record<T, ILogger> => {
    // merge new namespaces with existing ones
    this._namespaces = Array.from(new Set([...this._namespaces, ...namespaces]));
    this._levels = { ...(this._levels ?? {}), ...(levels ?? {}) };
    this.refreshNamespacedLog(this._output$.value);
    return this._namespacedLog as Record<T, ILogger>;
  };

  setConsoleNamespace = (consoleNamespace?: string) => {
    // remove existing
    this._namespaces = this._namespaces.filter(namespace => namespace !== this._consoleNamespace);
    this._consoleNamespace = consoleNamespace;
    // add to namespaces
    consoleNamespace !== undefined && this._namespaces.push(consoleNamespace);
    this.refreshNamespacedLog(this._output$.value);
  };

  // called by app. This sets the app request for an output. It could be overriden by the localStorage
  setOutput = (output: LogOutput, code?: string) => {
    this._appOutput = output;
    this._output$.value = this._storageOutput$.value === "default" ? output : this._storageOutput$.value;
    code !== undefined && (this._code$.value = code);
  };

  refreshNamespacedLog = (output: LogOutput) => {
    this._namespaces.forEach(namespace => {
      this._namespacedLog[namespace] = createLogger(namespace, output, this._levels[namespace] ?? LogLevel.info);
    });
    switch (output) {
      case "remoteJS":
        // console logs have been overriden already by remoteJS, don't change
        break;

      default:
        if (this._consoleNamespace !== undefined) {
          console.trace = this._namespacedLog[this._consoleNamespace].trace;
          console.debug = this._namespacedLog[this._consoleNamespace].debug;
          console.log = this._namespacedLog[this._consoleNamespace].log;
          console.info = this._namespacedLog[this._consoleNamespace].info;
          console.warn = this._namespacedLog[this._consoleNamespace].warn;
          console.error = this._namespacedLog[this._consoleNamespace].error;
        } else {
          console.trace = consoleTrace;
          console.debug = consoleDebug;
          console.log = consoleLog;
          console.info = consoleInfo;
          console.warn = consoleWarn;
          console.error = consoleError;
        }
        break;
    }
  };
}

export const LogFactory = new LogFactoryImpl();
