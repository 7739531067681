import { UILog } from "../uiLog";
export interface IRequestOptions {
  method?: "get" | "post" | "put" | "delete";
  queryParams?: Record<string, string | number | boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  ignoreCache?: boolean;
  headers?: { [key: string]: string };
  // 0 (or negative) to wait forever
  timeout?: number;
}

export class RequestResult {
  url: string;
  requestOptions?: IRequestOptions;
  status: number;
  statusText: string;
  data: string;
  responseHeader: string;
  withCredentials: boolean;

  /**
   * creates a universal format for all HTTP responses
   * @param url - requested url
   * @param requestOptions - IRequestOptions includes request specific settings
   * @param xhr - standard XMLHttpRequest object
   */
  constructor(url: string, requestOptions: IRequestOptions | undefined, xhr: XMLHttpRequest) {
    this.url = url;
    this.status = xhr.status;
    this.statusText = xhr.statusText;
    this.requestOptions = requestOptions;
    this.responseHeader = xhr.getAllResponseHeaders();
    this.data = xhr.responseText;
    this.withCredentials = xhr.withCredentials;
  }
  json = () => {
    try {
      return JSON.parse(this.data);
    } catch (error) {
      UILog.net.error("JSON: parsing failed, URL:", this.url);
      UILog.net.error("Request options", JSON.stringify(this.requestOptions));
      UILog.net.error(
        "XHR",
        JSON.stringify({
          status: this.status,
          statusText: this.statusText,
          response: this.data,
          responseHeaders: this.responseHeader,
          withCredentials: this.withCredentials,
        })
      );
      throw error;
    }
  };
}

export class RequestError extends RequestResult implements Error {
  name: string;
  message: string;
  timedOut: boolean;

  /**
   * extension of RequestResult to include all data returned from a failed XMLHttpRequest
   * @param name - string request name
   * @param message - error message from service
   * @param timedOut - boolean if request timed out
   * @param url - request url
   * @param requestOptions - IRequestOptions includes request specific settings
   * @param xhr - standard XMLHttpRequest object
   */
  constructor(
    name: string,
    message: string | undefined,
    timedOut: boolean,
    url: string,
    requestOptions: IRequestOptions | undefined,
    xhr: XMLHttpRequest
  ) {
    super(url, requestOptions, xhr);
    this.timedOut = timedOut;
    this.name = name;
    this.message = message ?? xhr.statusText ?? "unknown";
  }
}
