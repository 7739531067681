import "./pinView.scss";

import { DS } from "~/libs";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { NumericKeyboardPopupPage } from "../../../pages/numericKeyboardPopup/numericKeyboardPopupPage";
import { ITextElement, TextHelper } from "../../../tools/textHelper";
import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";

export type PinCallback = (pin: string | undefined) => void;

export class PinView extends AcceptsMouseFocusView implements DS.IPage {
  private _textContainer: ITextElement;
  private _textContainer$ = new DS.Listenable("____");
  private _rejectFocus = false;
  private _callback: PinCallback;
  private _showPin$?: DS.IListenable<boolean>;

  constructor(callback: PinCallback, showPin$?: DS.IListenable<boolean>, label?: string) {
    super("PinView", "pinView");
    this._callback = callback;
    this._showPin$ = showPin$;

    if (label !== undefined) {
      TextHelper.createTextElement({
        rootElement: this.rootElement,
        classname: "titlePinContainer",
        content: t(label ?? "parentalControl.pinContainer.codePIN"),
      });
    }

    this._textContainer = TextHelper.createTextElement({
      rootElement: DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "pinContainer",
      }),
      classname: "textContainer",
      content: this._textContainer$.value,
    });

    this._textContainer$.didChange(value => {
      this._renderPinCode(value);
      if (!value.match(/_/) && value.length === 4) this._update(value);
      if (value === "____") this._update(undefined);
    }, this);

    showPin$?.didChange(_ => {
      this._renderPinCode(this._textContainer$.value);
    }, this);
  }

  private _renderPinCode(value: string) {
    this._textContainer.update(this._showPin$?.value === true ? value : value.replace(/[0-9]/g, "*"));
  }

  private _update(value: string | undefined): void {
    this._callback(value);
  }

  resetPinCode() {
    this._textContainer$.value = "____";
    this._textContainer.update(this._textContainer$.value);
    this._update(undefined);
  }

  rejectsFocus() {
    return this._rejectFocus;
  }

  enable() {
    this._rejectFocus = false;
  }

  disable() {
    this._rejectFocus = true;
  }

  deleteOne() {
    const firstLetterIndex = this._textContainer$.value.replace(/_/g, "").length - 1;
    if (firstLetterIndex >= 0) {
      const newValue =
        this._textContainer$.value.substring(0, firstLetterIndex) +
        "_" +
        this._textContainer$.value.substring(firstLetterIndex + 1);

      this._textContainer$.value = newValue;
    }
  }

  private _numericKeyboardPopupPage?: NumericKeyboardPopupPage;

  onUnfocused() {
    if (this._numericKeyboardPopupPage && Config.platformShowNumericKeyboard) {
      navigationStack.removePage(this._numericKeyboardPopupPage);
    }
  }

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.select:
        return this._numericKeyboard();
      case DS.Keys.zero:
      case DS.Keys.one:
      case DS.Keys.two:
      case DS.Keys.three:
      case DS.Keys.four:
      case DS.Keys.five:
      case DS.Keys.six:
      case DS.Keys.seven:
      case DS.Keys.eight:
      case DS.Keys.nine:
        this._textContainer$.value = this._textContainer$.value.replace(/_/, key);
        return true;
      case DS.Keys.back:
        if (this._textContainer$.value !== "____") {
          this._textContainer$.value = "____";
          return true;
        }
    }
    return false;
  }

  onMouseDown() {
    return this._numericKeyboard();
  }

  private _numericKeyboard(): boolean {
    if (Config.platformShowNumericKeyboard) {
      this._numericKeyboardPopupPage = new NumericKeyboardPopupPage(
        () => {
          return this.onNav(DS.Keys.back);
        },
        key => {
          if (key === "Delete") {
            this.deleteOne();
            return true;
          } else {
            return this.onNav(key);
          }
        }
      );
      navigationStack.pushPage(this._numericKeyboardPopupPage);
      return true;
    } else {
      return false;
    }
  }
}
