import { z } from "zod";

export const ApiResponse = z.object({
  status: z.number().optional(),
  json: z.unknown(),
});
export type ApiResponse = z.infer<typeof ApiResponse>;

export const ApiErrorResponse = z.object({
  status: z.number().optional(),
});
export type ApiErrorResponse = z.infer<typeof ApiErrorResponse>;
