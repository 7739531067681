import "./mentionLegalePage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { RootMenuPage } from "../../../rootMenu/rootMenuPage";
import { createQRCode } from "../../../tools/uiHelper";

class MentionLegalePageView extends AcceptsMouseFocusView implements DS.IPage {
  constructor() {
    super("mentionLegalePageView", "mentionLegalePageView settingsPages");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("mentionLegale.mentionLegale_title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${require("@assets/images/mentionLegale/mentionLegale_logo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    const mentionLegalePageDesc = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageDesc",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: mentionLegalePageDesc,
      className: "pageSubtitle",
      innerText: t("mentionLegale.mentionLegale_desc"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: mentionLegalePageDesc,
      className: "pageText1",
      innerText: t("generic.scanQRCode_text"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: mentionLegalePageDesc,
      className: "pageWebsite",
      innerText: t("mentionLegale.mentionLegale_website"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: mentionLegalePageDesc,
      className: "pageText1",
      innerText: t("mentionLegale.mentionLegale_desc2"),
    });

    this.rootElement.appendChild(createQRCode(t("mentionLegale.mentionLegale_website"), "settingsQRCode"));
  }
}

export class MentionLegalePage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("MentionLegalePage", "RootMenuPage", () => {
      return new MentionLegalePageView();
    });
  }
}
