import { Config } from "../config";

const locales = ["en", "fr"] as const;
// the fallback locale
const defaultLocale: Locale = "en";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  type Locale = typeof locales[number];
  type Strings = {
    [key in Locale]: {
      [key: string]: string;
    };
  };
  function t(key: string, force?: keyof Strings): string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    t: (key: string) => string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _sf_async_config: any;
  }
}

const translationStrings = {
  en: require("./en.json"),
  fr: require("./fr.json"),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function retrieveString(strings: { [key: string]: any } | undefined, path: string): string | undefined {
  if (strings === undefined) return undefined;
  // first check if the whole key (with dots) contains something
  if (strings[path] !== undefined) return strings[path];
  const [topKey, ...remainingPath] = path.split(".");
  if (remainingPath.length) {
    return retrieveString(strings[topKey], remainingPath.join("."));
  } else {
    return strings[topKey];
  }
}

window.t = (key: string, forcedLocale?: keyof Strings): string => {
  // use uppercase no matter what.
  // JSON *is* case-sensitive, but it'd be super mistake-prone to
  const value = retrieveString(translationStrings[forcedLocale ?? Config.locale$.value], key);
  // in case fallback to default language is wanted. Shouldn't be active in set to be able to figure which keys are missing from translation files
  // if (value === undefined) {
  //   value = retrieveString(translationStrings[defaultLocale], key);
  // }
  return value ?? `#${key}#`;
};
