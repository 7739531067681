import { IPlatform, PlatformType } from "../../../typings";

export function init(): (Partial<IPlatform> & { type: PlatformType.ps4 }) | undefined {
  // careful! any code here needs to work *without* polyfills!!
  // String.includes for example doesn't. So do an initial first pass on the platform type to set the polyfills
  if (navigator.userAgent.match(new RegExp("PlayStation 4", "i"))) {
    require("core-js/es");
    // now we have polyfills in place

    const version = navigator.userAgent.match(/WebMAF\/v([^-]+)/i)?.[1];

    const exit = () => {};

    return {
      type: PlatformType.ps4,
      version,
      exit,
    };
  }
}
