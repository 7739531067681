import { z } from "zod";

const PlayFormat = z.object({
  mediaLocator: z.string().optional(),
  format: z.string().optional(),
  drm: z
    .object({
      "com.microsoft.playready": z.object({ licenseServerUrl: z.string() }).optional(),
      "com.widevine.alpha": z
        .object({ licenseServerUrl: z.string(), certificateUrl: z.string().optional() })
        .optional(),
    })
    .optional(),
});

const SpriteFormat = z.object({
  vtt: z.string().optional(),
  width: z.number(),
  offsetInMs: z.number().optional(),
});

export type ApiPlayResponse = z.infer<typeof ApiPlayResponse>;
export type SpritesPlayResponse = Array<z.infer<typeof SpriteFormat>>;
export const ApiPlayResponse = z.object({
  formats: z.array(PlayFormat).optional(),
  bookmarks: z.object({ lastViewedOffset: z.number().optional() }),
  streamInfo: z
    .object({
      live: z.boolean().optional(),
    })
    .optional(),
  playSessionId: z.string().optional(),
  requestId: z.string().optional(),
  cdn: z
    .object({
      provider: z.string().optional(),
    })
    .optional(),
  analytics: z
    .object({
      postInterval: z.number().optional(),
      bucket: z.number().optional(),
      tag: z.string().optional(),
      baseUrl: z.string().optional(),
      percentage: z.number().optional(),
    })
    .optional(),
  sprites: z.array(SpriteFormat).optional(),
});
