import "./contentDurationView.scss";

import { DS } from "~/libs";

import { getFormatDate, getFormatTime } from "../../../../tools/time";

export class ContentDurationView extends DS.View {
  constructor(duration: number, text: string, date?: string, publishedTo?: { text: string; unavailableSoon: boolean }) {
    super("", "durationView");

    if (duration > 0) {
      this.rootElement.innerText += getFormatTime(duration);

      if (text !== "") {
        this.rootElement.innerText += " | ";
        this.rootElement.appendChild(
          DS.DOMHelper.createElement({
            tagName: "span",
            className: "additionalText",
            innerText: `${text}`,
          })
        );
      } else if (date !== undefined) {
        this.rootElement.innerText += ` | ${getFormatDate(new Date(date), "dd/mm/yyyy")}`;
      }

      if (publishedTo !== undefined && publishedTo?.text !== "") {
        this.rootElement.innerText += " | ";
        this.rootElement.appendChild(
          DS.DOMHelper.createElement({
            tagName: "span",
            className: `publishedTo ${publishedTo.unavailableSoon && "unavailableSoon"}`,
            innerText: `${publishedTo.text}`,
          })
        );
      }
    }
  }

  rejectFocus() {
    return true;
  }
}
