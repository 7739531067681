import "./contentButtonView.scss";

import { DS } from "~/libs";
import { IActionButton, IGenericLink } from "~/tools/interfaces";

import { Config } from "../../../../config";
import { navigationStack } from "../../../../main";
import { GenericPageFull } from "../../../../pages/generic/genericPage";
import { PlayerPage } from "../../../../pages/player/playerPage";
import { DevicePreferenceHelper } from "../../../../tools/devicePreferencesManager";
import { FavoriteHelper } from "../../../../tools/favoriteHelper";
import { NotificationsManager } from "../../../../tools/notifications";
import { playerAudio } from "../../../../tools/player";
import { PlayHistoryHelper } from "../../../../tools/playHistoryHelper";
import { ITextElement, TextHelper } from "../../../../tools/textHelper";
import {
  EmbedType,
  MediaCard,
  MediaPremiumCard,
  MediaType,
  PageLiveContent,
  PageMediaContent,
  PageProgramContent,
  Rating,
  TvLiveCard,
} from "../../../../utils/rtbf/models";
import { FocusTracker } from "../../../focusTracker";
import { AcceptsMouseFocusView } from "../../common/mouseSupport/acceptsMouseFocusView";
import { ContentDetailsView } from "../contentDetailsView/contentDetailsView";

export enum ContentButtonWidgetType {
  playContentButton = "playContentButton",
  stovContentButton = "stovContentButton",
  infoContentButton = "infoContentButton",
  favoriteContentButton = "favoriteContentButton",
  genericContentButton = "genericContentButton",
  liveContentButton = "liveContentButton",
  alertContentButton = "alertContentButton",
}

export enum ClassType {
  currentLive = "currentLive",
  passedLive = "passedLive",
  futureLive = "futureLive",
  serie = "serie",
  media = "media",
  setting = "setting",
  profil = "profil",
  premium = "premium",
}

export type ContentButton = { id: string } & (
  | {
      type: ContentButtonWidgetType.playContentButton | ContentButtonWidgetType.favoriteContentButton;
      content: PageProgramContent | PageMediaContent;
      media?: MediaCard;
    }
  | {
      type: ContentButtonWidgetType.stovContentButton | ContentButtonWidgetType.infoContentButton;
      content: PageProgramContent | PageLiveContent | PageMediaContent;
    }
  | {
      type: ContentButtonWidgetType.genericContentButton;
      genericLink: IGenericLink;
    }
  | {
      type: ContentButtonWidgetType.liveContentButton | ContentButtonWidgetType.alertContentButton;
      content: PageLiveContent;
    }
);

type ContentButtonWidgetView =
  | PlayContentButtonView
  | StovContentButtonView
  | InfoContentButtonView
  | FavoriteContentButtonView
  | GenericContentButtonView
  | LiveContentButtonView
  | AlertContentButtonView;

export class ContentButtonsContainerView extends AcceptsMouseFocusView {
  _list: DS.IListComponent<ContentButton, ContentButtonWidgetView>;
  private _focusTracker?: FocusTracker;
  private _classType;
  private _modelSource$;
  private _media: MediaCard | MediaPremiumCard | undefined;

  constructor(
    modelSource: ContentButton[],
    content: PageProgramContent | PageLiveContent | PageMediaContent | undefined,
    classType: ClassType,
    hasCurrentLive = false
  ) {
    super("contentButtonWidget", `contentButtonWidget ${classType ?? ""}`);
    this._classType = classType;

    this.delegate = this._list = DS.createListComponent(
      {
        id: `contentButtonWidgetList${content?.id}`,
        className: "contentButtonWidgetList",
        modelSource$: (this._modelSource$ = new DS.ModelSource(modelSource)),
        viewFactory: item => {
          switch (item.type) {
            case ContentButtonWidgetType.playContentButton:
              return new PlayContentButtonView(item.content);
            case ContentButtonWidgetType.stovContentButton:
              return new StovContentButtonView(item.content, hasCurrentLive, this._media);
            case ContentButtonWidgetType.infoContentButton:
              return new InfoContentButtonView(item.content, this._classType);
            case ContentButtonWidgetType.favoriteContentButton:
              return new FavoriteContentButtonView(item.content);
            case ContentButtonWidgetType.genericContentButton:
              return new GenericContentButtonView(item.genericLink);
            case ContentButtonWidgetType.liveContentButton:
              return new LiveContentButtonView(item.content);
            case ContentButtonWidgetType.alertContentButton:
              return new AlertContentButtonView(item.content);
          }
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
        },
        onSelect: (item, index) => {
          const elem = this._list.viewFromIndex(index);
          if (elem) {
            void elem.action?.();
            if (item.type === ContentButtonWidgetType.alertContentButton) {
              // at this point this button reject the focus, so we set the focus to 0, this will focused the first focusable button
              void this._list.setFocusOnIndex(0);
            }
            return true;
          }
          return false;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "contentButtonContainer");
        this.collectRelease(this._focusTracker.onRelease);
      }
    );
  }

  prependButtons = (buttons: ContentButton[]) => {
    this._modelSource$.value = [...buttons, ...this._modelSource$.value];
  };

  updateButtons = (buttons: ContentButton[], media?: MediaCard | MediaPremiumCard) => {
    this._media = media;
    this._modelSource$.value = buttons;
  };
}

abstract class ContentButtonView extends AcceptsMouseFocusView implements IActionButton {
  label: string;
  private _buttonElement: HTMLDivElement;
  protected _icon;
  protected _titleElement: ITextElement;
  protected _iconElement: HTMLDivElement;
  protected _disabled = false;
  private _logo;

  constructor(label: string, icon: string, additionalClass?: string) {
    super("contentButton", `contentButton ${additionalClass}`);
    this.label = label;
    this._icon = icon;

    this._buttonElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentButtonContainer",
    });

    try {
      this._logo = require("@assets/images/buttons/" + icon + ".png");
    } catch (error) {
      this._logo = require("@assets/images/buttons/generic.png");
    }
    this._iconElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this._buttonElement,
      className: "img",
      style: {
        backgroundImage: `url(${this._logo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    this._titleElement = TextHelper.createTextElement({
      rootElement: this._buttonElement,
      classname: "tileTitle",
      content: this.label,
    });
  }

  async action() {
    return;
  }

  rejectsFocus() {
    return this._disabled;
  }

  initPlayerPreferences(assetID: string, type: string | undefined) {
    if ((type === "AUDIO" && playerAudio.asset$.value?.resource.assetId !== assetID) || type === "VIDEO") {
      DevicePreferenceHelper.refreshPreference();
    }
  }
}

class PlayContentButtonView extends ContentButtonView {
  private _assetId;
  private _type: MediaType;
  private _rating: Rating;

  constructor(content: PageProgramContent | PageMediaContent) {
    super(t("playButton.play_title"), "play");

    if (content.pageType === "PROGRAM") {
      this._assetId = content.media?.assetId ?? "";
      this._type = content.media?.type ?? "VIDEO";
      this._rating = content.media?.rating ?? undefined;
    } else {
      this._assetId = content.id;
      this._type = content.type;
      this._rating = content.rating ?? undefined;
    }

    const offsetData = PlayHistoryHelper.getCurrentOffset(content.id);
    this._titleElement.update(
      offsetData.value > 0
        ? t("playButton.replay_title")
        : this._type === "AUDIO"
        ? t("playButton.play_audio_title")
        : t("playButton.play_title")
    );
  }

  async action() {
    if (this._assetId === undefined || this._type === undefined) return;
    this.initPlayerPreferences(this._assetId, this._type);
    void PlayerPage.playAsset(this._assetId, this._type, "MEDIA", false, {
      rating: this._rating,
    });
  }
}

class StovContentButtonView extends ContentButtonView {
  private _assetId;
  private _type: MediaType;
  private _rating: Rating;
  private _embedType: EmbedType;

  constructor(
    content: PageProgramContent | PageLiveContent | PageMediaContent,
    hasCurrentLive = false,
    media?: MediaCard | MediaPremiumCard
  ) {
    super(t("button.stov_title"), "stov");
    if (content.pageType === "PROGRAM") {
      if (hasCurrentLive === true) {
        this._assetId = content.live?.id ?? "";
        this._type = content.live?.type ?? "VIDEO";
      } else {
        if (media?.id !== undefined) {
          this._assetId = media.id ?? "";
        } else {
          this._assetId = content.media?.assetId ?? "";
        }
        this._type = content.media?.type ?? "VIDEO";
      }
      this._rating = content.media?.rating ?? undefined;
    } else {
      this._assetId = content.id;
      this._type = content.type;
      this._rating = content.rating;
    }

    if (hasCurrentLive === true) {
      this._embedType = "LIVE";
    } else {
      this._embedType = content.pageType === "LIVE" ? "LIVE" : "MEDIA";
    }
  }

  async action() {
    this.initPlayerPreferences(this._assetId, this._type);
    void PlayerPage.playAsset(this._assetId, this._type, this._embedType, true, {
      rating: this._rating,
    });
  }
}

class InfoContentButtonView extends ContentButtonView {
  private _content;
  private _classType;
  private _casting?;
  private _tags?;

  constructor(content: PageProgramContent | PageLiveContent | PageMediaContent, classType: ClassType) {
    super(t("button.moreInformation_title"), "infos");
    this._content = content;
    this._classType = classType;
    if (content.pageType === "LIVE" || content.pageType === "MEDIA") {
      this._tags = content.tags;
      this._casting = content.casting;
    }
  }

  async action() {
    navigationStack.pushPage(
      new ContentDetailsView(
        this._classType,
        this._content.title,
        this._content.description,
        this._casting ?? [],
        this._tags ?? []
      )
    );
  }
}

class FavoriteContentButtonView extends ContentButtonView {
  private _content;
  private _isFavorite$;
  private static _locked = false;

  constructor(content: PageProgramContent | PageMediaContent) {
    super("", "", "favorite");

    this._isFavorite$ = new DS.Listenable<boolean>(FavoriteHelper.isFavorite(content.id));
    this._content = content;

    this._isFavorite$.didChange(
      isFavorite => {
        this._iconElement.style.background = `url(${require(isFavorite
          ? "@assets/images/buttons/addFavFull.png"
          : "@assets/images/buttons/addFav.png")}) 50% 50% no-repeat`;
        this._titleElement.update(t(isFavorite ? "button.addedFav_title" : "button.addFav_title"));
      },
      this,
      true
    );
  }

  /**
   * This lock allow to make only one action at a time.
   * @param callback
   */
  private static async _lock(callback: () => void): Promise<void> {
    if (this._locked === true) return;
    this._locked = true;
    await callback();
    this._locked = false;
  }

  async action() {
    void FavoriteContentButtonView._lock(async () => {
      this.rootElement.classList.add("actionInProgress");
      if (this._isFavorite$.value === true) {
        const response = await FavoriteHelper.removeFavorites(this._content);
        if (response === true) {
          this._isFavorite$.value = false;
        }
      } else {
        const response = await FavoriteHelper.addFavorites(this._content);
        if (response === true) {
          this._isFavorite$.value = true;
        }
      }
      this.rootElement.classList.remove("actionInProgress");
    });
  }
}

class GenericContentButtonView extends ContentButtonView {
  private _path;
  private _page;
  constructor(genericLink: IGenericLink) {
    super(genericLink.label, genericLink.icon ?? "generic", genericLink.id);

    this._path = genericLink.path;
    this._page = genericLink.page;
  }

  async action() {
    const page = this._page ? this._page() : new GenericPageFull(this._path ?? "");
    navigationStack.pushPage(page);
  }
}

class LiveContentButtonView extends ContentButtonView {
  private _content;

  constructor(content: TvLiveCard | PageLiveContent) {
    super(t("button.live_title"), "play");
    this._content = content;
  }

  async action() {
    void PlayerPage.playAsset(this._content.id, this._content.type, "LIVE", false, { rating: this._content.rating });
  }
}

class AlertContentButtonView extends ContentButtonView {
  private _content;

  constructor(content: PageLiveContent) {
    super(t("button.alert_title"), "alert", "alert");
    this._content = content;

    if (NotificationsManager.check(content.title)) {
      this._disableAlert();
    }
  }

  async action() {
    NotificationsManager.set(this._content.title, new Date(this._content.scheduledFrom));
    this._disableAlert();
  }

  private _disableAlert() {
    if (NotificationsManager.check(this._content.title)) {
      this.rootElement.classList.add("disabled");
      this._titleElement.update(t(`notification.button`));
      const logo = require("@assets/images/buttons/alertDisabled.png");
      this._iconElement.style.background = `url(${logo}) 50% 50% / cover no-repeat`;
      this._disabled = true;
    }
  }
}
