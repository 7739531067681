import "./parentalControlPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";

import { FocusTracker } from "../../components/focusTracker";
import { SettingsLongButtonView } from "../../components/views/settings/settingsLongButtonView/settingsLongButtonView";
import { Config } from "../../config";
import { navigationStack } from "../../main";
import { RootMenuPage } from "../../rootMenu/rootMenuPage";
import { TextHelper } from "../../tools/textHelper";
import { ModifyLevelPageView } from "./modifyLevel/modifyLevelPage";
import { CreatePinParentalPopupPage, ModifyPinParentalPopupPage } from "./parentalPopupPage";

export enum ParentalControlButtons {
  modifyPin = "modifyPin",
  createPin = "createPin",
  modifyLevel = "modifyLevel",
  modifyLevelDisable = "modifyLevelDisable",
}

class ParentalControlPageView extends AcceptsMouseFocusView implements DS.IPage {
  modelSource$ = new DS.ModelSource<ParentalControlButtons>([]);
  parentalControlPageSubtitle: HTMLElement;
  focusTracker?: FocusTracker | undefined;

  constructor() {
    super("ParentalControlPageView", "parentalControlPageView");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "parentalControlPageTitle",
      innerText: t("parentalControl.parentalControl_title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "parentalControlPageLogo",
      style: {
        backgroundImage: `url(${require("@assets/images/parentalControl/parentalControl_logo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    this.parentalControlPageSubtitle = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "parentalControlPageSubtitle",
    });

    APIGigyaOIDC.userInfo$.didChange(
      userInfo => {
        this.modelSource$.value =
          userInfo?.data?.pin === undefined
            ? [ParentalControlButtons.createPin, ParentalControlButtons.modifyLevelDisable]
            : [ParentalControlButtons.modifyLevel, ParentalControlButtons.modifyPin];
        // FIXIT: Page description should be done otherwise
        this.setPageDescription();
      },
      this,
      true
    );

    this.delegate = DS.createListComponent(
      {
        id: `parentalControl/list`,
        className: "parentalControlList",
        modelSource$: this.modelSource$,
        viewFactory: idButton => {
          return new SettingsLongButtonView(idButton);
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: button => {
          switch (button) {
            case ParentalControlButtons.createPin:
              navigationStack.pushPage(new CreatePinParentalPopupPage());
              return true;
            case ParentalControlButtons.modifyPin:
              navigationStack.pushPage(new ModifyPinParentalPopupPage());
              return true;
            case ParentalControlButtons.modifyLevel:
              navigationStack.pushPage(new ModifyLevelPageView());
              return true;
            default:
              return true;
          }
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this.focusTracker.onRelease);
        void list.setFocusOnIndex(0);
      }
    );
  }

  setPageDescription() {
    this.parentalControlPageSubtitle.innerHTML = "";

    const text = TextHelper.createTextElement({
      rootElement: this.parentalControlPageSubtitle,
      classname: "parentalControlPageSubtitleText",
      content: t("parentalControl.parentalControl_text1"),
    });

    if (
      ParentalControlHelper.currentParentalControl() === "all" &&
      APIGigyaOIDC.userInfo$.value?.data?.pin !== undefined
    )
      text.update(t("parentalControl.deactivated_text"));
    else {
      DS.DOMHelper.createElement({
        tagName: "img",
        parent: this.parentalControlPageSubtitle,
        className: "parentalControlPageSubtitleCSA",
      }).src =
        ParentalControlHelper.currentParentalControl() === "-12"
          ? require("@assets/images/csa/csa-12.png")
          : require("@assets/images/csa/csa-16.png");

      const text2 = TextHelper.createTextElement({
        rootElement: this.parentalControlPageSubtitle,
        classname: "parentalControlPageSubtitleText",
        content: "ans",
      });

      if (APIGigyaOIDC.userInfo$.value?.data?.pin === undefined) text2.update("ans (par défaut)");
    }
  }
}

export class ParentalControlPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("parentalControlPage", "RootMenuPage", () => {
      return new ParentalControlPageView();
    });
  }
}
