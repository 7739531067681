import "./fwdButtonPlayer.scss";

import { DS } from "~/libs";

import { IPlayer, PlayerStreamType } from "../../../../tools/player";
import { MoveButtonPlayer } from "./buttonPlayer";

export class FwdButtonPlayer extends MoveButtonPlayer {
  private static _instance: FwdButtonPlayer | undefined;

  constructor(player: IPlayer, isDisabled = false) {
    super(player, `fwdButtonPlayer ${isDisabled ? "disabled" : ""}`, t("player.button.fwd"), isDisabled);
    if (
      player.type$.value === PlayerStreamType.AUDIO ||
      player.type$.value === PlayerStreamType.AUDIO_LIVE ||
      player.type$.value === PlayerStreamType.AUDIO_SHIFT
    ) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "fwdImg img",
        style: {
          backgroundImage: `url(${require("@assets/images/player/controls/FWD-30.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
    } else {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "fwdImg img",
        style: {
          backgroundImage: `url(${require("@assets/images/player/controls/FWD.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
    }
    // eslint-disable-next-line consistent-this
    FwdButtonPlayer._instance = this;
  }

  static forward() {
    if (FwdButtonPlayer._instance) {
      FwdButtonPlayer._instance.onSelect();
      return true;
    }
    return false;
  }

  protected _onKeyUp(ev: KeyboardEvent) {
    const key = DS.mapKeyboardEvent(ev);
    switch (key) {
      case DS.Keys.forward:
        this.selectPressed$.value = false;
        break;
      case DS.Keys.select:
        if (this.isFocused$.value) this.selectPressed$.value = false;
        break;
    }
  }

  protected action(preview = false, released = false) {
    this.player.pause();
    if (!released) {
      this.player.forward(preview);
    } else {
      this.player.play();
    }
  }
}
