export const enum PlatformType {
  tizen = "Samsung TV",
  webos = "LG TV",
  hisense = "Hisense TV",
  tivo = "Tivo",
  ps4 = "PlayStation 4",
  ps5 = "PlayStation 5",
  other = "Smart TV (Generic)",
}

export interface IPlatform {
  type: PlatformType;
  version?: string;
  alternativeCSS: boolean;
  deviceName: string;
  deviceId: string;
  screenScale: () => number;
  exit: () => void;
}
