import { z } from "zod";

const LoginInterstitiel = z.object({
  title: z.string(),
  loginLabel: z.string(),
  registerLabel: z.string(),
  cancelLabel: z.string(),
  logoUrl: z.string(),
});

export const MenuEntry = z.object({
  id: z.string(),
  type: z.enum(["menu", "item"]),
  label: z.string(),
  path: z.string(),
  slug: z.string(),
  description: z.string().optional(),

  position: z.number(),
  iconKey: z.string(),
  logo: z.string(),
});
export type MenuEntry = z.infer<typeof MenuEntry>;

const LegalEntry = z
  .object({
    label: z.string(),
    iconKey: z.string(),
    externalLink: z.string(),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));
type LegalEntry = z.infer<typeof LegalEntry>;
const Legals = z
  .object({
    title: z.string(),
    items: z.array(LegalEntry),
  })
  .transform(({ ...input }) => ({ id: Math.random().toString(36).substring(2, 9), ...input }));

export const ChartbeatData = z.object({
  accountId: z.string(),
  siteId: z.string(),
});
export type ChartbeatData = z.infer<typeof ChartbeatData>;

export const GemiusData = z.object({
  id: z.object({
    page: z.string(),
    player: z.string(),
    embed: z.string(),
  }),
});
export type GemiusData = z.infer<typeof GemiusData>;

export const SettingsData = z.object({
  menu: z.object({
    primary: z.array(MenuEntry),
    footer: z.array(MenuEntry),
    legals: Legals,
  }),
  "menu-kids": z.object({
    primary: z.array(MenuEntry),
  }),
  copywriting: z.object({
    loginInterstitiel: LoginInterstitiel,
  }),
  globalMeta: z.object({
    smartAds: z.object({
      networkId: z.string(),
    }),
    gemius: GemiusData,
    chartbeat: ChartbeatData,
  }),
});
export type SettingsData = z.infer<typeof SettingsData>;

export const Settings = z.object({
  data: SettingsData,
});
export type Settings = z.infer<typeof Settings>;
