import "./bingeView.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { RTBF } from "~/utils/rtbf";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { IPlayer, playerVideo } from "../../../tools/player";
import { MediaCard, TvLiveCard } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";

enum PlayerBingeComponent {
  TILE = "TILE",
  BUTTON = "BUTTON",
}

class TileBingeView extends AcceptsMouseFocusView {
  nextButtonOverlay: HTMLDivElement;

  constructor(media: MediaCard | TvLiveCard) {
    super(media.id, "tileBingeView");
    let episodePlay, barPlay;
    if (media.resourceType === "MEDIA") {
      episodePlay = require("@assets/images/icons/nextEpisodePlay.png");
      barPlay = require("@assets/images/icons/nextEpisodeBar.png");
    } else if (media.resourceType === "LIVE") {
      episodePlay = require("@assets/images/icons/nextEpisodePlayLive.png");
      barPlay = require("@assets/images/icons/nextEpisodeBarLive.png");
    }
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "cardCoverContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileCover",
      style: {
        backgroundImage: `url(${media.illustration?.xl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
      innerText: media.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileSubtitle",
      innerText: media.subtitle,
    });
    const nextButton = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "nextButton",
    });
    this.nextButtonOverlay = DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: "nextButtonOverlay",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: `nextButtonIcon play ${media.resourceType === "LIVE" && "live"}`,
      style: {
        backgroundImage: `url(${episodePlay})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: `nextButtonIcon bar ${media.resourceType === "LIVE" && "live"}`,
      style: {
        backgroundImage: `url(${barPlay})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    const nextButtonTitle = DS.DOMHelper.createElement({
      tagName: "div",
      parent: nextButton,
      className: `nextButtonTitle ${media.resourceType === "LIVE" && "live"}`,
      innerText: t("bingeWatching.nextEpisode"),
    });
    if (media.resourceType === "LIVE") {
      nextButtonTitle.style.color = "#fff";
    }
  }
}

class ButtonBinge extends AcceptsMouseFocusView {
  constructor() {
    super("buttonBinge", "buttonBinge");
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
      innerText: t("bingeWatching.notNow"),
    });
  }
}

export class BingeView extends AcceptsMouseFocusView {
  private _focusTracker?: FocusTracker;
  private _nextContentTileView: TileBingeView | undefined;
  private _waitTime = 10;
  private _isInAutoBinge = false;
  private _isLive: boolean;

  constructor(player: IPlayer, nextAsset: RTBF.MediaCard | RTBF.TvLiveCard, endingBinge$: DS.IListenable<boolean>) {
    super("bingeView", "bingeView");

    this._isLive = player.isLive();

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "toBeContinuedText",
      innerText: t("bingeWatching.toBeContinued"),
    });
    this.delegate = DS.createListComponent(
      {
        id: "",
        className: "bingeList",
        modelSource$: new DS.ModelSource([PlayerBingeComponent.TILE, PlayerBingeComponent.BUTTON]),
        viewFactory: item => {
          switch (item) {
            case PlayerBingeComponent.TILE:
              if (this._nextContentTileView === undefined) this._nextContentTileView = new TileBingeView(nextAsset);
              return this._nextContentTileView;
            case PlayerBingeComponent.BUTTON:
              return new ButtonBinge();
          }
        },
        crossSectionWidth: 1,
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        scrollDuration: Config.scrollDuration,
        spatialFocus: true,
        mouseSupport: Config.mouseSupport && { focusRange: "visible" },
        onSelect: card => {
          switch (card) {
            case PlayerBingeComponent.TILE:
              // TODO: push the playerPage with new assetID
              void player.loadNextAsset(nextAsset, false);
              break;
            case PlayerBingeComponent.BUTTON:
              if (endingBinge$.value === true) player.disableNextBinge();
              if (player.percentRatio$.value === 100) {
                navigationStack.removePage(navigationStack.topPage);
                void player.destroy();
              } else {
                player.showControls$.value = true;
              }
              break;
          }
          return true;
        },
      },
      list => {
        if (!this._focusTracker) {
          let className = "focusRect ";
          playerVideo.isLive() ? (className += "live") : (className += "media");
          this._focusTracker = new FocusTracker(list, className, "cardContainer");
          this._focusTracker.rootElement.style.opacity = "1";
        }

        this.collectRelease(this._focusTracker.onRelease);
      }
    );

    player.binge({
      context: "playerPage",
      timerDelay: this._waitTime,
      timerCallback: () => {
        this._triggerProgressBar();
      },
    });
  }

  private _triggerProgressBar() {
    if (this._nextContentTileView === undefined || this._isInAutoBinge === true) return;
    this._isInAutoBinge = true;
    const buttonOverlay = this._nextContentTileView.nextButtonOverlay;
    buttonOverlay.classList.add(this._isLive === true ? "live" : "media");
    buttonOverlay.classList.add("selected");
    buttonOverlay.style.transition = `width ${this._waitTime}s ease-in-out`;
  }
}
