import "./stampView.scss";

import { DS } from "~/libs";

import { Stamp } from "../../../utils/rtbf/models";

export class StampView extends DS.View {
  constructor(stamp: Stamp) {
    super("", "stampView");
    this.rootElement.innerText = stamp.label;
    if (stamp.backgroundColor !== undefined) {
      this.rootElement.style.color = stamp.textColor;
      this.rootElement.style.backgroundColor = stamp.backgroundColor;
    }
  }
}
