import "./contentTagView.scss";

import { DS } from "~/libs";

import { Tag } from "../../../../utils/rtbf/models";
import { ClassType } from "../contentButtonView/contentButtonView";

export class ContentTagView extends DS.View {
  constructor(type: ClassType, tags: Tag[]) {
    super("", `tagsView ${type}`);

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: type,
      className: "tagsTitle",
      innerText: t("generic.tag_title"),
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tagsContent",
      innerText: tags.map(item => item.name).join(", "),
    });
  }
}
