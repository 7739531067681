import "./settingsLongButtonView.scss";

import { DS } from "dslib-tv";

import { ParentalControlButtons } from "../../../../pages/parentalControl/parentalControlPage";
import { AboutButtons } from "../../../../pages/settings/about/aboutPage";
import { LanguageSubtitleButtons } from "../../../../pages/settings/languageSubtitle/languageSubtitlePage";
import { DevicePreferenceHelper } from "../../../../tools/devicePreferencesManager";
import { AcceptsMouseFocusView } from "../../common/mouseSupport/acceptsMouseFocusView";

export class SettingsLongButtonView extends AcceptsMouseFocusView {
  private _id: string;
  private _optionText?: HTMLElement;

  constructor(id: string) {
    super("settingsLongButtonView", "settingsLongButtonView");
    this._id = id;
    const container = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: id,
      className: "cardContainer",
    });
    const textContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: container,
      className: "textContainer",
    });
    if (id in AboutButtons) {
      textContainer.innerText = t("about." + id);
    } else if (id in LanguageSubtitleButtons) {
      const buttonText = DS.DOMHelper.createElement({
        tagName: "div",
        parent: textContainer,
        className: "buttonText",
      });
      this._optionText = DS.DOMHelper.createElement({
        tagName: "div",
        parent: textContainer,
        className: "optionText",
      });

      buttonText.innerText = t("languageSubtitlePage." + id);

      if (this._id !== LanguageSubtitleButtons.personnalize) {
        buttonText.innerText += " : ";
        this._subtitlesLabels();
      }
    } else if (id in ParentalControlButtons) {
      textContainer.innerText = t("parentalControl." + id + ".buttonTitle");
    }
  }

  rejectsFocus() {
    if (this._id === ParentalControlButtons.modifyLevelDisable) return true;
    else return false;
  }

  onShown() {
    this._subtitlesLabels();
  }

  private _subtitlesLabels() {
    if (this._optionText !== undefined) {
      switch (this._id) {
        case LanguageSubtitleButtons.defaultLanguage:
          this._optionText.innerText = t(
            "languageSubtitleSelection." + DevicePreferenceHelper.cachedPreferences().audio
          );
          break;
        case LanguageSubtitleButtons.defaultSubtitle: {
          this._optionText.innerText = t(
            "languageSubtitleSelection." + DevicePreferenceHelper.cachedPreferences().subtitle
          );
          break;
        }
      }
    }
  }
}
