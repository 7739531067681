import { IPlayAsset, IPlayerState } from "@redbeemedia/javascript-player";
import mux from "mux-embed";

import { HandledPlayerErrors } from "~/pages/player/playerPage";
import { PlayerCommon } from "~/player/playerCommon";
import {
  IPlayerEventTime,
  RedBeePlayerEvents,
  RedBeePlayerInstanceType,
  RedBeePlayerType,
} from "~/player/redbee/definitions";
import { isTizen8 } from "~/tools/deviceHelper";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";
import {
  IPlayer,
  IPlayerLoadOptions,
  IPlayerQuality,
  IPlayerTrack,
  PlayerError,
  PlayerState,
  PlayerStreamType,
  WRAPPER_AUDIO_ID,
  WRAPPER_VIDEO_ID,
} from "~/tools/player";
import { GemiusPlayerEvent, TrackingHelper } from "~/tools/trackingHelper";
import { areAllPartnersAccepted } from "~/utils/didomi/didomi";
import { StatusError } from "~/utils/errors";
import { EmbedType } from "~/utils/rtbf/models";

import { APIGigyaOIDC } from "../../datas/api/apiGigyaOIDC";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    redBeeMedia?: { RedBeePlayer: RedBeePlayerInstanceType };
  }
}

/**
 * This is the implementation of the IPlayer interface.
 * When you add a function here, please do not forget to add it
 * in the interface.
 * This allow to expose to developpers only functions members
 * that they can trust.
 */
class Player extends PlayerCommon implements IPlayer {
  video?: RedBeePlayerType;
  currentPlayerElt: (HTMLMediaElement & { mux?: IMuxEmbedInstancePlugin }) | null | undefined = null;
  streamType?: "live" | "on-demand" | "trailer";
  playerInitTime = 0;
  private _trailerErrorRetry = 0;

  constructor() {
    super();
  }

  private _eventError(event: string, data: IPlayerState) {
    const eventName = Object.keys(RedBeePlayerEvents).find(
      key => RedBeePlayerEvents[key as keyof typeof RedBeePlayerEvents] === event
    );
    Log.player.error(`[Red Bee Player] Error ${eventName}:`, data);
  }

  private _eventSetState(data: IPlayerState) {
    switch (data.state) {
      case "loading":
        this.state$.value = PlayerState.LOADING;
        break;
      case "playing":
        if (this.state$.value !== PlayerState.PLAYING) {
          this.trackAnalytics("play", {
            boolean_value: true,
          });
        }
        this.state$.value = PlayerState.PLAYING;
        this.trackProgramEvent(GemiusPlayerEvent.play);
        this.showSpinner$.value = false;
        if (
          this.type$.value === PlayerStreamType.VIDEO_LIVE ||
          this.type$.value === PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT ||
          this.type$.value === PlayerStreamType.VIDEO_SHIFT ||
          this.type$.value === PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT
        ) {
          this.seekTime$.value = undefined;
        }
        if (this.restarting && this.type$.value !== undefined) {
          this.updateType();
          if (this.asset$.value?.isLive() === true) {
            this.restart();
          }
          this.restarting = false;
        }
        break;
      case "paused":
        this.state$.value = PlayerState.PAUSED;
        this.trackAnalytics("pause", {
          boolean_value: true,
        });
        break;
      case "buffering":
        this.state$.value = PlayerState.BUFFERING;
        this.trackProgramEvent(GemiusPlayerEvent.buffer);
        break;
      case "seeking":
        this.state$.value = PlayerState.SEEKING;
        this.trackAnalytics("seek", {
          integer_value: this.currentTime$.value,
        });
        break;
      case "ended":
        this.state$.value = PlayerState.ENDED;
        this.trackProgramEvent(GemiusPlayerEvent.complete);
        this.trackAnalytics("ended");
        break;
      case "error":
        this.state$.value = PlayerState.ERROR;
        break;
      default:
        this.state$.value = PlayerState.IDLE;
    }
  }

  private _registerEvents(video: RedBeePlayerType, destroy = false) {
    if (destroy) {
      video.removeEventListener(RedBeePlayerEvents.ALL);
    } else {
      video.addEventListener(
        RedBeePlayerEvents.ERROR,
        (
          event:
            | string
            | {
                code: number;
                errorType: string;
              },
          data: IPlayerState
        ) => {
          this._eventError(typeof event === "string" ? event : event.errorType, data);

          if (typeof event !== "string" && event.code === 3016 && this.streamType === "trailer") {
            Log.player.error(`Error 3016 on a trailer`);
            void (async () => {
              if (window.redBeeMedia && this.video && this._trailerErrorRetry < 3) {
                this._trailerErrorRetry++;
                Log.player.warn(`Trying to reload the video. Attempts : ${this._trailerErrorRetry}`);

                this.video.destroy();
                this.video = new window.redBeeMedia.RedBeePlayer({
                  player: {
                    ...this._options,
                    // MetadataURIs is mandatory to have adReplacement
                    metadataURIs: [],
                  },
                  analytics: this._analytics,
                  skin: {
                    disabled: true,
                  },
                  keyboard: {
                    disabled: true,
                  },
                });

                this._registerEvents(this.video);
                await this.video.load({ assetId: this._options.assetId });
                await this.initTracking(this.video.getVideoElement());
                void this.video.play();
              }
            })();
          }
        }
      );
      video.addEventListener(RedBeePlayerEvents.RESUME, () => {
        if (this.currentPlayerElt?.currentTime === 0 && this.streamType === "trailer") {
          Log.player.warn(`Resume event on a trailer; Manual playback start`);
          this.currentPlayerElt.currentTime = 1;
        }
      });
      video.addEventListener(RedBeePlayerEvents.TIME_UPDATE, (data: IPlayerEventTime) => {
        this.eventTimeUpdate({ ...data, audioTrack: this.audioTrack, subtitleTrack: this.subtitleTrack });
      });
      video.addEventListener(RedBeePlayerEvents.STATE_CHANGED, (data: IPlayerState) => {
        this._eventSetState(data);
      });
      video.addEventListener(RedBeePlayerEvents.PROGRAM_CHANGED, (data: unknown) => {
        this._handleProgramChanged(data);
      });
      video.addEventListener(RedBeePlayerEvents.PLAYER_SETUP_COMPLETED, () => {
        this.currentPlayerElt = video.getVideoElement();

        const assetId = this.asset$.value?.resource.assetId;
        if (assetId != null && this.streamType != null && this.streamType !== "trailer") {
          /* eslint-disable @typescript-eslint/naming-convention */
          mux.monitor(
            this.currentPlayerElt,
            TrackingHelper.muxData(
              {
                video_stream_type: this.streamType,
                video_id: assetId,
                video_duration: this.asset$.value?.resource.duration,
                video_title: assetId + " - " + this.asset$.value?.resource.title,
                video_series: this.asset$.value?.resource.embed?.program?.title ?? "",
                viewer_user_id: areAllPartnersAccepted() ? APIGigyaOIDC.userInfo$.value?.uid ?? "" : "",
                player_init_time: this.playerInitTime,
                // sub_property_id: ['drm' | 'no_drm'], We are waiting for the info from the RAM API
              },
              { version: /*this.video?.getPlayerInfo()?.playerVersion ??*/ "" }
            )
          );
        }
      });
      video.addEventListener(RedBeePlayerEvents.LOADED, (data: any) => {
        /*
         * Use case : when we click on the replay button on a live content view (after it's finished)
         * When it's live the manifest return timestamp values and when it's over it return seconds from the beginning
         * Then it tell the player to use the control + progress bar of a VOD instead of a live
         */
        // Check on a live if the duration returned by the player is inferior to the timestamp of a date in 2001 (the duration can be a number of seconds or a timestamp)
        if (this.isLive() === true && data.duration < 1000000000) {
          this._updateCurrentLiveAsVod();
        }
      });
      video.addEventListener(RedBeePlayerEvents.METADATA_EVENT, (data: any) => {
        try {
          // Ad Replacement data tests are based on auvio-web code
          if (typeof data?.event?.detail !== "object") return;
          if (data.event.detail.schemeiduri !== "urn:daiconnect:dai:2019:xml") return;
          if (data.event.detail.eventelement === undefined || data.event.detail.eventelement === null) return;
          if (typeof data?.event?.detail?.startTime !== "number" || typeof data?.event?.detail?.endTime !== "number")
            return;

          const startDate = new Date(data.event.detail.startTime * 1000);
          const endDate = new Date(data.event.detail.endTime * 1000);
          this.eventAdReplacement(endDate.getTime() - startDate.getTime());
        } catch (error: unknown) {
          Log.player.info("Can't have end date for Ad Replacement. Aborting.");
        }
      });
    }
  }

  attach(containerElement: HTMLElement) {
    this._options.wrapper = `div#${containerElement.id}`;
  }

  async destroy() {
    this._trailerErrorRetry = 0;

    if (await super.destroy()) {
      if (this.video) {
        this._registerEvents(this.video, true);
        this.video.destroy();
        return true;
      }
    }
    return false;
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    // Check options
    const catchError = options?.catchError ?? true;
    const isRadioLive = options?.isRadioLive ?? false;
    const isTrailer = options?.isTrailer ?? false;
    // Make sure that no player is currently playing
    await this.destroy();

    // For MUX
    this.streamType = isTrailer ? "trailer" : this.isLive() || isRadioLive ? "live" : "on-demand";
    this.playerInitTime = mux.utils.now();

    this.showSpinner$.value = true;

    // Get token redbee
    await this.getToken();

    // Load the asset to get the assetID
    try {
      await this.loadAsset(assetID, embedType, isTrailer, isRadioLive);
    } catch (e) {
      // If it's an unconnected error, let it propagate
      if (e instanceof StatusError && e.statusCode in HandledPlayerErrors === true) throw e;

      return this._loadError({}, true, async () => {
        await this.load(assetID, embedType, options);
      });
    }

    // Disable analytics if it's a trailer player
    this.analytics = !isTrailer;

    // Init the player
    if (typeof window.redBeeMedia === "undefined") {
      throw new PlayerError(1, "Redbee player not loaded");
    }
    this.video = new window.redBeeMedia.RedBeePlayer({
      player: {
        ...this._options,
        // MetadataURIs is mandatory to have adReplacement
        metadataURIs: [],
      },
      analytics: this._analytics,
      skin: {
        disabled: true,
      },
      keyboard: {
        disabled: true,
      },
    });

    // Events
    this._registerEvents(this.video);

    const radio = await this.getRadioData(assetID, isRadioLive);

    // Get the AssetId to use to launch the playback
    const assetId = await this.getAssetId(assetID, radio, isRadioLive, isTrailer);
    this._options.assetId = assetId;

    // Return the load status
    try {
      const loadObject: IPlayAsset = { assetId };
      if (options?.restart === true && this.asset$.value?.type === "MEDIA") {
        loadObject.startTime = 0;
      }
      await this.video.load(loadObject);
    } catch (error: unknown) {
      void this.destroy();
      if (this._backIntent) {
        this._backIntent = false;
      } else {
        this._loadError(error, catchError, async () => {
          await this.load(assetID, embedType, options);
        });
        throw new PlayerError(1, "Player - bad asset");
      }
    }

    await this.loadRadioLive(isRadioLive, radio);

    // Tracking
    await this.initTracking(this.video.getVideoElement());

    if (this._options.autoplay === true) {
      setTimeout(() => void this.video?.play(), this.streamType === "trailer" && isTizen8() ? 100 : 0);
    }

    // set audio with user's preference by defaut
    const audio = this.getPreferedAudioTrack();
    if (audio !== undefined) {
      this.audioTrack = audio;
    } else {
      Log.player.warn("Can't set up the audio from device preference helper.");
      this.video.setAudioTrack(this.video.getAudioTracks()[0]);
    }

    // set subtitle with user's preference by defaut
    const subtitle = this.getPreferedTextTrack();
    if (subtitle !== undefined) {
      this.subtitleTrack = subtitle;
    } else {
      Log.player.warn("Can't set up the subtitle from device preference helper.");
      this.subtitleTrack = this.subtitleTracks()[0];
    }
  }

  play(firstStart = false, restart = false) {
    if (firstStart === true) {
      this.restarting = restart;
    }
    if (restart) {
      // Call restart only if it's not the first start of a live, in that case it will be done later in playing player event
      if ((firstStart === true && this.isLive() === true) === false) {
        this.restart();
      }
      this.showControls$.value = true;
      this.showSpinner$.value = true;
    }
    if (this.state$.value === PlayerState.SEEKING) {
      this.currentTime$.value = this.seekTime$.value ?? this.currentTime$.value;
      this.seekTime$.value = undefined;
      this.video?.seekTo({ time: this.currentTime$.value });
      this.hideTooltip();
      setTimeout(() => (this.showSpinner$.value = true), 300);

      // Gemius don't want seek event to be send on startup so we need to send the hit in specific occasion
      this.trackProgramEvent(GemiusPlayerEvent.seek);
    }
    super.play();
    // the trailer often freezes for few milliseconds on startup on Tizen 2024
    setTimeout(() => void this.video?.play(), this.streamType === "trailer" && isTizen8() ? 100 : 0);
  }

  pause(isUserAction = false) {
    if (this.asset$.value?.isFastTV === true) return;
    this.updateType();

    super.pause(isUserAction);
    this.video?.pause();
  }

  restart() {
    if (this.asset$.value?.isLive() === true) {
      this.video?.seekToUTC(this.video?.getSeekable().start);
    } else {
      this.video?.seekTo({ time: 0 });
    }
  }

  isPlaying() {
    return this.video?.isPlaying() ?? false;
  }

  seekTo(playbackPos?: number) {
    if (typeof playbackPos === "number") {
      this.showSpinner$.value = true;
      this.video?.seekTo({ time: playbackPos });
    }
  }

  forward(preview = false) {
    this.seek(this._TIME_INCREMENT_FW, preview);
    this.showSpinner$.value = true;
    !preview && this.play();
    this.trackAnalytics("forward", {
      boolean_value: true,
    });
  }

  rewind(preview = false) {
    this.updateType();
    this.seek(this._TIME_INCREMENT_RW, preview);
    this.showSpinner$.value = true;
    !preview && this.play();
    this.trackAnalytics("rewind", {
      boolean_value: true,
    });
  }

  get audioTrack() {
    return {
      id: "0",
      active: true,
      track: this.video?.getAudioTrack() ?? {
        id: "0",
        label: "Not Found",
        language: "Not Found",
        raw: { active: true },
      },
    };
  }

  set audioTrack(track: IPlayerTrack) {
    this.video?.setAudioTrack({
      ...track.track,
      language: track.track.language ?? "",
    });
    this.trackAnalytics("change_audio", {
      boolean_value: true,
      string_value: track.id,
    });
  }

  audioTracks() {
    const selectedTrack = this.audioTrack;
    return [
      this.audioTrack,
      ...(
        (this.video?.getAudioTracks() || []).filter(function (item) {
          return item.label !== selectedTrack.track.label;
        }) ?? []
      ).map((item, index) => {
        return { id: `${index + 1}`, active: false, track: item };
      }),
    ];
  }

  get subtitleTrack() {
    return {
      id: "0",
      active: true,
      track: this.video?.getSubtitleTrack() ?? {
        id: "DOTSCREEN_SUBTITLE_EMPTY",
        label: "DOTSCREEN_SUBTITLE_EMPTY",
        language: "Not Found",
        raw: { active: true },
      },
    };
  }

  set subtitleTrack(track: IPlayerTrack) {
    if (track.track.id === "DOTSCREEN_SUBTITLE_EMPTY") {
      this.video?.setSubtitleTrack(null);
      return;
    }
    try {
      this.video?.setSubtitleTrack({
        ...track.track,
        language: track.track.language ?? "",
      });
      this.trackAnalytics("change_subtitle", {
        string_value: track.id,
        boolean_value: true,
      });
    } catch (error: unknown) {
      Log.player.error("Error when setting subtitles: ", error);
    }
  }

  subtitleTracks() {
    let subtitleEnable = false;
    const activeTrack = this.video?.getSubtitleTrack();

    const playerTrackArr = [
      ...(this.video?.getSubtitleTracks() ?? []).map((item, index) => {
        const active = activeTrack ? item.raw.id === activeTrack.raw.id : false; // Don't use raw.active because it's unreliable
        const track = {
          id: `${index + 1}`,
          active,
          track: { ...item, raw: { ...item.raw, active } },
        };
        if (active === true) subtitleEnable = active;

        return track;
      }),
    ] as IPlayerTrack[];

    const emptyTrack = {
      id: "0",
      active: subtitleEnable ? false : true,
      track: {
        id: "DOTSCREEN_SUBTITLE_EMPTY",
        label: "Aucun",
        language: "DISABLE",
        raw: { active: subtitleEnable ? false : true, roles: [] },
      },
    };
    playerTrackArr.unshift(emptyTrack);
    if (!subtitleEnable) this.subtitleTrack = emptyTrack;
    return playerTrackArr;
  }

  qualityLevels() {
    return this.video?.getQualityLevels();
  }

  set quality(quality: IPlayerQuality) {
    this.video?.setQualityLevel(quality);
    this.trackAnalytics("change_quality", {
      integer_value: quality.width,
    });
  }

  seekToLive() {
    // Seems that the following function is not working.
    // this.video?.seekToLive();
    // So we will use another way :)
    if (!this.isPlaying()) this.video?.play();
    this.video?.seekTo({ time: this.duration$.value });
    this.showSpinner$.value = true;
  }

  applySubtitleStyle() {
    const subtitlesContainer = document.querySelector(".redbee-player-subtitle-container");

    if (subtitlesContainer !== null) {
      subtitlesContainer.classList.add(DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.size);
      subtitlesContainer.classList.add(DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.style);
    }
  }

  positionSubtitles(playerControls?: HTMLElement) {
    const subtitleContainer: HTMLElement | null = document.querySelector(".redbee-player-subtitle-container");
    if (subtitleContainer !== undefined && subtitleContainer !== null && playerControls !== undefined) {
      const isVisible = playerControls.style.visibility !== "hidden";
      if (DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.position === "TOP") {
        subtitleContainer.style.top = isVisible ? "230px" : "40px";
      } else {
        subtitleContainer.style.bottom = isVisible ? "250px" : "100px";
      }
    }
  }

  async getTimelineSpriteCues(width?: number) {
    return (await this.video?.getTimelineSpriteCues(width)) ?? [];
  }

  getCurrentTime(isRadio: boolean) {
    if (this.asset$.value?.isLive() === true || isRadio === true) return this.video?.getState().utcCurrentTime ?? 0;
    return this.video?.getState().currentTime ?? 0;
  }
}

export class PlayerVideo extends Player {
  constructor() {
    super();
    this._options = {
      ...this._options,
      wrapper: `div#${WRAPPER_VIDEO_ID}`,
    };
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    await super.load(assetID, embedType, options);
    // Setup the garbage collector
    options?.collector?.collectRelease(() => {
      void this.destroy();
    });
    if (this.asset$.value?.isLive() === false) {
      this.type$.value = PlayerStreamType.VIDEO;
    } else if (this.asset$.value?.isLive() === true) {
      this.type$.value = this._livePlayerType();
    }
  }

  seekToLive() {
    this.type$.value = this._livePlayerType();
    super.seekToLive();
  }
}

export class PlayerAudio extends Player {
  constructor() {
    super();
    this._options = {
      ...this._options,
      wrapper: `div#${WRAPPER_AUDIO_ID}`,
      audioOnly: true,
      autoplay: true,
    };
  }

  async destroy() {
    void super.destroy();
    this.pause();
    return true;
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    await super.load(assetID, embedType, options);
    if (embedType === "MEDIA") {
      this.type$.value = PlayerStreamType.AUDIO;
    } else if (embedType === "LIVE") {
      this.type$.value = PlayerStreamType.AUDIO_LIVE;
    }
    this._TIME_INCREMENT_FW = Player._RADIO_INCREMENT_FW;
    this._TIME_INCREMENT_RW = -Player._RADIO_INCREMENT_RW;
  }

  seekToLive() {
    this.type$.value = PlayerStreamType.AUDIO_LIVE;
    super.seekToLive();
  }
}
