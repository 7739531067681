import { RedBeePlayer } from "@redbeemedia/javascript-player";

export interface IPlayerEventTime {
  currentTime: number;
  duration: number;
  seekable: {
    start: number;
    end: number;
  };
  utcCurrentTime: number;
  utcDuration: number;
  utcSeekable: { start: number; end: number };
}

// Coming from
// https://redbee.live/docs/Client%20SDKs/JavaScript/#playlist
export interface IAdsOptions {
  uid?: string;
  latitude?: string;
  longitude?: string;
  mute?: boolean;
  autoplay?: boolean;
  consent?: string;
  deviceMake?: string;
  deviceType?: any;
  ifa?: string;
  gdprOptin?: boolean;
  width?: number;
  height?: number;
  pageUrl?: string;
  domain?: string;

  /**
   * These custom parameters will be added to the querystring of the play request.
   * e.g. ?customParam1=value1&customParam2=value2 etc
   */
  [key: string]: any;
}

export type RedBeePlayerType = RedBeePlayer;
export type RedBeePlayerInstanceType = typeof RedBeePlayer;

export enum RedBeePlayerEvents {
  ALL = "*",
  PLAY = "player:play",
  PAUSE = "player:pause",
  STOP = "player:stopped",
  START = "player:start",
  RESUME = "player:resume",
  PLAYING = "player:playing",
  SEEKING = "player:seeking",
  SEEK_TIME_CHANGE = "player:seek_time_change",
  SEEKED = "player:seeked",
  TIME_UPDATE = "player:timeupdate",
  ENDED = "player:ended",
  VOLUME_CHANGE = "player:volumechange",
  ERROR = "player:error",
  LOADING = "player.loading",
  LOADED = "player:loaded",
  LOAD_START = "player:load_start",
  BUFFERING = "player:buffering",
  BUFFERED = "player:buffered",
  ID3 = "player:id3",
  BITRATE_CHANGED = "player:bitrate_changed",
  CDN_CHANGED = "player:cdn_changed",
  AUDIO_CHANGED = "player:audio_changed",
  SUBTITLE_CHANGED = "player:subtitle_changed",
  LICENSE_EXPIRED = "player:license_expired",
  DROPPED_FRAMES = "player:dropped_frames",
  DRM_UPDATE = "player:drm:update",
  STATE_CHANGED = "player:state_changed",
  PROGRAM_CHANGED = "player:program_changed",
  NOT_ENTITLED = "player:not_entitled",
  BLACKOUT = "player:blackout",
  EMPTY_SLOT = "player:empty_slot",
  CAST_START = "player:cast:start",
  CAST_STOP = "player:cast:stop",
  AIRPLAY_START = "player:airplay:start",
  AIRPLAY_STOP = "player:airplay:stop",
  AD_START = "player:ad:start",
  AD_COMPLETE = "player:ad:complete",
  ADBLOCK_START = "player:adblock:start",
  ADBLOCK_COMPLETE = "player:adblock:complete",
  INTRO_START = "player:intro:start",
  INTRO_END = "player:intro:end",
  CHAPTER_START = "player:chapter:start",
  CHAPTER_END = "player:chapter:end",
  MARKER = "player:marker",
  METADATA_EVENT = "player:metadata_event",
  SESSION_ACQUIRED = "player:session_acquired",
  PLAYER_SETUP_COMPLETED = "player:setup_completed",
  ENTITLEMENT_GRANTED = "player:entitlement_granted",
}
