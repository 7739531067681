import "./audioButtonPlayer.scss";

import { DS } from "~/libs";

import { navigationStack } from "../../../../main";
import { TracksPlayerPage } from "../../../../pages/player/tracksPlayerPage";
import { IPlayer } from "../../../../tools/player";
import { ButtonPlayer } from "./buttonPlayer";

export class AudioButtonPlayer extends ButtonPlayer {
  constructor(player: IPlayer) {
    super(player, "audioButtonPlayer", t("player.button.audio"));
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "audioImg img",
      style: {
        backgroundImage: `url(${require("@assets/images/player/controls/AUDIO.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }
  onSelect() {
    if (this.player.asset$.value?.isFastTV !== true) {
      this.player.pause();
    }
    navigationStack.pushPage(
      new TracksPlayerPage(this.player, this.player.isPremium(), () => {
        this.player.play();
      })
    );
    return true;
  }
}
