import "./trailerPlayer.scss";

import { DS } from "~/libs";

import { PlayerState, playerVideo } from "../../../tools/player";

export class TrailerPlayer extends DS.View {
  private static _playerID = "trailerPlayer";
  private _player = playerVideo;
  private _isPromoBoxTrailer = false;

  // need a id because several TrailerPlayer can be instanced on a single page
  constructor(widgetId: string, isPromoBox = false) {
    super(TrailerPlayer._playerID + widgetId, TrailerPlayer._playerID);
    this._isPromoBoxTrailer = isPromoBox;
  }

  async load(assetID: string) {
    this._player.autoplay = false;
    this._player.muted = true;
    this._player.analytics = false;
    this._player.attach(this.rootElement);
    try {
      await playerVideo.load(assetID, "MEDIA", {
        collector: this,
        catchError: false,
        isTrailer: true,
        isPromoBoxTrailer: this._isPromoBoxTrailer,
      });
      const quality = this._player.qualityLevels();
      if (quality && quality.length > 1) {
        this._player.quality = quality[1];
      }
    } catch (error) {
      Log.api.error("Error trailer load asset", error);
    }
  }

  isIdle() {
    return this._player.state$.value === PlayerState.IDLE;
  }

  play() {
    this._player.play();
  }

  pause() {
    this._player.pause();
  }

  isPlaying() {
    return this._player.isPlaying();
  }

  onRelease() {
    super.onRelease();
    this._player.asset$.value = undefined; // Important : created for Gemius and the tracking for trailers (see ticket AV3-7196)
  }
}
