import "./accessAuvioPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { RootMenuPage } from "../../../rootMenu/rootMenuPage";
import { createQRCode } from "../../../tools/uiHelper";

export class AccessAuvioPageView extends AcceptsMouseFocusView implements DS.IPage {
  constructor() {
    super("accessAuvioPageView", "accessAuvioPageView");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "accessAuvioPageLogo",
      style: {
        backgroundImage: `url(${require("@assets/images/accessAuvio/accessAuvio_logo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "accessAuvioPageTitle",
      innerText: t("accessAuvio.accessAuvio_title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "accessAuvioPageSubtitle",
      innerText: t("accessAuvio.accessAuvio_subtitle"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "accessAuvioPageDesc",
      innerText: t("accessAuvio.accessAuvio_desc"),
    });

    this.rootElement.appendChild(createQRCode(t("accessAuvio.accessAuvio_url"), "accessAuvioPageQRCode"));
  }
}

export class AccessAuvioPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("AccessAuvioPage", "RootMenuPage", () => {
      return new AccessAuvioPageView();
    });
  }
}
