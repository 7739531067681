export type IQueryStringParameters = { [key: string]: string | number | boolean };

/**
 * Transform an object to a query string
 */
export function objectToQueryString(parameters: IQueryStringParameters): string {
  let string = "";
  for (const [key, value] of Object.entries(parameters)) {
    string += `&${key}=${value}`;
  }

  // Remove first "&" from query string before returning it
  return string.slice(1);
}
