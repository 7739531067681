export * from "./iHttpRequest";
export * from "./iListComponent";
export * from "./iListenable";
export * from "./iModelSource";
export * from "./iNavigationStack";
export * from "./iPlatform";
export * from "./iSwitchComponent";
export * from "./iTrigger";
export * from "./iView";

export const enum DIR {
  ltr = "ltr",
  rtl = "rtl",
}

export interface ISize {
  width: number;
  height: number;
}

export const enum InputMode {
  keys = "keys",
  pointer = "pointer",
}

/**
 * extension to the {@link Size} structure
 *
 * It is used when getting the size of a DOM element, to differentiate "intrinsic" size & "external" size (made by padding and margins and borders)
 * used only by the {@link sizeOf} helper function
 */
export interface IExtraSize extends ISize {
  borderLeft: number;
  marginLeft: number;
  paddingLeft: number;
  borderRight: number;
  marginRight: number;
  paddingRight: number;
  borderTop: number;
  marginTop: number;
  paddingTop: number;
  borderBottom: number;
  marginBottom: number;
  paddingBottom: number;
  extraWidthLeft: number;
  extraWidthRight: number;
  extraHeightTop: number;
  extraHeightBottom: number;
}

export interface IPoint {
  x: number;
  y: number;
}

export interface IRect {
  origin: IPoint;
  size: ISize;
}

//const = readonly | let = read and write
export const enum Keys {
  up = "ArrowUp",
  down = "ArrowDown",
  left = "ArrowLeft",
  right = "ArrowRight",
  back = "Backspace",
  select = "Enter",
  one = "1",
  two = "2",
  three = "3",
  four = "4",
  five = "5",
  six = "6",
  seven = "7",
  eight = "8",
  nine = "9",
  zero = "0",
  red = "ColorF0Red",
  green = "ColorF1Green",
  yellow = "ColorF2Yellow",
  blue = "ColorF3Blue",
  forward = "MediaFastForward",
  rewind = "MediaRewind",
  play = "MediaPlay",
  pause = "MediaPause",
  playPause = "MediaPlayPause",
  stop = "MediaStop",
  exit = "Exit",
  next = "MediaTrackNext",
  previous = "MediaTrackPrevious",
  pageUp = "PageUp",
  pageDown = "PageDown",
  info = "Info",
}

/** IFocusable base interface. If an element can get focus & is put in the focus tree, it needs to define those functions
 */
export interface IFocusable {
  /** Is this element rejecting focus?
   * fromChildElement is provided when focus happens via mouse. Otherwise it's not specified
   * @default false
   */
  rejectsFocus?: (fromChildElement?: HTMLElement) => boolean;

  /** Is this element accepting mouse focus
   * @default false
   */
  acceptsMouseFocus?: () => boolean;

  /** called after gaining focus
   */
  onFocused?: () => void;

  /** called after focus is lost
   */
  onUnfocused?: () => void;
}

export interface IShowable {
  /** called after being shown
   */
  onShown?: () => void;
  /** called after being hidden
   */
  onHidden?: () => void;
}

export interface IReleasable {
  onRelease?: () => void;
}

export interface INavigable {
  /** called to handle key event
   * @param key - the event type
   * @returns true if nav was handled, false otherwise
   */
  onNav?: (key: Keys) => boolean;
}

export interface IMousable {
  /** gives a chance to the view to catch a mouse wheel event
   * @param deltaY - amount scrolled
   * @param point - the coordinates where the scroll occured, in screen rect
   * @param target - the element the scroll occured in
   */
  onMouseWheel?: (deltaY: number, point: IPoint, target: HTMLElement) => boolean;
  /** gives a chance to the view to catch a mouse move event
   * @param point - the coordinates of the move, in screen rect
   * @param target - the element the move occured in
   */
  onMouseMove?: (point: IPoint, target: HTMLElement) => boolean;
  /** gives a chance to the view to catch a click & interrupt its processing
   * @param point - the coordinates of the click, in screen rect
   * @param target - the element the click occured in
   */
  onMouseDown?: (point: IPoint, target: HTMLElement) => boolean;
}

export interface IDelegate extends IReleasable, IFocusable, IShowable, INavigable, IMousable {
  rootElement: HTMLElement;
}

export type Identifiable = number | string | { id: string | number };
