import "./keyView.scss";

import { DS } from "~/libs";

import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";
import { KeyboardSpecialKey } from "./keyboardView";

export class KeyView extends AcceptsMouseFocusView {
  private _rejectFocus = false;

  constructor(id: string) {
    super(id, "keyView");
    const isUppercaseLetter = id.length === 1 && "ABCDEFGHIJKLMNOPQRSTUVWXYZ".includes(id);
    let eltId = `${id}_key`;
    if (id.startsWith(KeyboardSpecialKey.NoneButton) === true) {
      eltId = `${KeyboardSpecialKey.NoneButton}_key`;
      this._rejectFocus = true;
    } else if (id.startsWith(KeyboardSpecialKey.NoneLetter) === true) {
      eltId = `${KeyboardSpecialKey.NoneLetter}_key`;
      this._rejectFocus = true;
    }
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: eltId,
      className: id.length > 1 ? "specialKey" : "key" + (isUppercaseLetter ? " uppercase" : ""),
      innerText: id.length > 1 ? undefined : id,
    });
  }

  rejectsFocus() {
    return this._rejectFocus;
  }
}
