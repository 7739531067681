import { DS } from "dslib-tv";

import { navigationStack } from "../main";
import { PopupFullPage } from "../pages/popup/popupPage";
import { connectionParam } from "../typings/webOSTypes";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    webOSDev?: {
      connection: {
        getStatus: (arg0: connectionParam) => void;
      };
    };
  }
}

export class NetworkManager {
  private _popup: PopupFullPage | undefined;
  private static _isOnline$ = new DS.Listenable(true);

  init() {
    switch (DS.platform.type) {
      case DS.PlatformType.webos:
        window.webOSDev?.connection.getStatus({
          onSuccess: res => {
            res.isInternetConnectionAvailable ? this.onOnline() : this.onOffline();
          },
          onFailure: this.onOffline,
          subscribe: true,
        });
        break;
      case DS.PlatformType.tizen:
        webapis.network.addNetworkStateChangeListener(() => {
          webapis.network.isConnectedToGateway() ? this.onOnline() : this.onOffline();
        });
        break;
      default:
        window.addEventListener("offline", this.onOffline);
        window.addEventListener("online", this.onOnline);
        break;
    }
  }

  onOffline() {
    NetworkManager._isOnline$.value = false;
    navigationStack.pushPage(
      (this._popup = new PopupFullPage({
        title: t("network.errorTitle"),
        description: t("network.errorDesc"),
      }))
    );
  }

  onOnline() {
    NetworkManager._isOnline$.value = true;
    navigationStack.removePage(this._popup);
    this._popup = undefined;
  }

  static get isOnline$(): DS.IListenable<boolean> {
    return NetworkManager._isOnline$;
  }
}

export const networkManager = new NetworkManager();
