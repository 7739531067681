import "./playerAds.scss";

import { DS } from "dslib-tv";

import { IPlayer } from "../../../tools/player";

export class PlayerAds extends DS.View {
  constructor(player: IPlayer) {
    super("PlayerAds");

    const text = DS.DOMHelper.createElement({
      parent: this.rootElement,
      tagName: "div",
      className: "adTextInfo",
    });

    player.adReplacement$.didChange(
      value => {
        if (value !== undefined) text.innerText = `Votre contenu reprendra dans ${Math.floor(value / 1000)} secondes`;
      },
      this,
      true
    );
  }
}
