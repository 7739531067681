import "./infoCastingPlayerPage.scss";

import { ContentTagView } from "~/components/views/content/contentTagView/contentTagView";
import { Config } from "~/config";
import { DS } from "~/libs";

import { ContentItem } from "../../components/contents/contentView";
import { EmptyContentView } from "../../components/contents/empty/emptyContentView";
import { PlayerTitle } from "../../components/player/title/playerTitle";
import { ClassType } from "../../components/views/content/contentButtonView/contentButtonView";
import { ContentCastingView } from "../../components/views/content/contentCastingView/contentCastingView";
import { ContentDescriptionView } from "../../components/views/content/contentDescriptionView/contentDescriptionView";
import { IPlayer } from "../../tools/player";

export class InfoCastingPlayerPage extends DS.View implements DS.IPage {
  private _playerContentList: ContentItem[] = [];
  private _player: IPlayer;

  constructor(player: IPlayer) {
    super("", "infoCastingPlayerPage");
    this._player = player;

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "gradient",
    });

    if (player.asset$.value !== undefined) {
      const content = player.asset$.value?.resource;

      this._playerContentList.push(ContentItem.title);
      this._playerContentList.push(ContentItem.description);
      if (content?.embed?.casting && content.embed.casting.length > 0) {
        this._playerContentList.push(ContentItem.casting);
      }
      if (content?.embed?.tags && content.embed.tags.length > 0) {
        this._playerContentList.push(ContentItem.tags);
      }

      this.delegate = DS.createListComponent({
        id: "playerContentList",
        className: "playerContentList",
        modelSource$: new DS.ModelSource(this._playerContentList),
        viewFactory: item => {
          switch (item) {
            case ContentItem.title:
              return new PlayerTitle(player);
            case ContentItem.description:
              return new ContentDescriptionView(content?.description ?? "", {
                rejectFocus: false,
                hideOverflow: false,
              });
            case ContentItem.casting:
              return new ContentCastingView(
                player.asset$.value?.type === "LIVE" ? ClassType.currentLive : ClassType.media,
                content?.embed?.casting ?? []
              );
            case ContentItem.tags:
              return new ContentTagView(ClassType.media, content?.embed?.tags ?? []);
            default:
              return new EmptyContentView();
          }
        },
        scrollingMode: { type: DS.ScrollingType.elasticWindow, horizontal: false },
        mouseSupport: Config.mouseSupport && {
          focusRange: "page",
          wheelScrollBy: 300,
        },
      });
    }
  }

  isPopup = () => true;

  onRelease() {
    super.onRelease();
    this._player.play();
  }
}
