import { IPlatform, PlatformType } from "../../typings";

/**
 * Some globals that aren't already defined
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Hisense_GetDeviceID?: () => string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Hisense_GetBrand?: () => string;
  }
}

export function init(): (Partial<IPlatform> & { type: PlatformType.hisense }) | undefined {
  // careful! any code here needs to work *without* polyfills!!
  // String.includes for example doesn't. So do an initial first pass on the platform type to set the polyfills
  if (navigator.userAgent.match(new RegExp("hisense", "i"))) {
    require("core-js/es");
    // now we have polyfills in place

    const exit = () => {
      window.close();
    };

    return {
      type: PlatformType.hisense,
      deviceName: `${window.Hisense_GetBrand?.() ?? "#unknown#"}_${~~(Math.random() * 99999999)}`,
      exit,
    };
  }
}
