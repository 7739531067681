declare global {
  // declaring this here this way makes sure any user of this library also knows about those type extensions
  // having those in a global.d.ts would require importing those types in the consumer, while it's already importing this file, so it feels like a duplication
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Console {
    re: Console;
  }
}

export const consoleTrace = console.trace.bind(console);
export const consoleDebug = console.debug.bind(console);
export const consoleLog = console.log.bind(console);
export const consoleInfo = console.info.bind(console);
export const consoleWarn = console.warn.bind(console);
export const consoleError = console.error.bind(console);
