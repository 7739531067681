import "./longSettingsButtonView.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";

import { SwitchButtonView } from "../switchButton/switchButton";

type SwitchData = {
  present: boolean;
  isOn: boolean;
};

export class LongSettingsButtonView extends AcceptsMouseFocusView {
  button?: SwitchButtonView;

  constructor(text: string, switchBtn: SwitchData, logoBefore?: string) {
    super("longSettingsButtonView", "longSettingsButtonView");
    const container = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    const textContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: container,
      className: "textContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: textContainer,
      className: "buttonText",
      innerText: text,
    });
    if (logoBefore !== undefined) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: container,
        className: `iconBefore ${logoBefore}`,
      });
    }

    if (switchBtn.present) {
      this.button = new SwitchButtonView(container, switchBtn.isOn);
    }
  }

  toggle(): boolean {
    const switchButton = this.rootElement.querySelector(".switchButton");
    switchButton?.classList.contains("on") ?? false
      ? switchButton?.classList.remove("on")
      : switchButton?.classList.add("on");
    return this.button?.toggleButton() ?? false;
  }
}
