/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isISODate = require("is-iso-date");
import { z } from "zod";

export const SessionTokens = z.object({
  // TODO:
  expirationDateTime: z.string().refine(isISODate, { message: "Not a valid ISO string date " }),
  sessionToken: z.string(),
});
export type SessionTokens = z.infer<typeof SessionTokens>;
