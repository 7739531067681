import { DIR, Identifiable, IExtraSize, IListComponent, IPoint, IRect, IView } from "../typings";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface HTMLElement {
    _dsSize?: IExtraSize;
    _dsTranslate?: IPoint;
    _dsListComponent?: IListComponent<Identifiable, IView>;
    _dsView?: IView;
    _dsListScrollElement?: boolean;
  }
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line @typescript-eslint/ban-types
type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

/**
 * Create HTMLElement
 * @param args
 * @returns the created HTMLElement
 */
export function createElement<K extends keyof HTMLElementTagNameMap>(
  args: {
    /** Element type/tagName for the new HTMLElement (div,span,h1,...) */
    tagName: K;
    /** HTMLElement parent in DOM for the new HTMLElement */
    parent?: Element;
    /** Element id for the new HTMLElement */
    id?: string;
    /** Element class for the new HTMLElement */
    className?: string;
    /** Element style properties for the new HTMLElement */
    style?: Partial<
      Omit<
        CSSStyleDeclaration,
        "length" | "parentRule" | "setProperty" | "removeProperty" | "item" | "getPropertyValue" | "getPropertyPriority"
      >
    >;
  } & XOR<
    {
      /** Some text for the new HTMLElement - */
      innerText?: string;
    },
    {
      /** Some HTML for the new HTMLElement - */
      innerHTML?: string;
    }
  >
): HTMLElementTagNameMap[K] {
  const element = document.createElement(args.tagName);
  if (args.id !== undefined) element.id = args.id;
  if (args.className !== undefined) element.className = args.className;
  if (args.style) {
    Object.assign(element.style, args.style);
  }
  if (args.innerHTML !== undefined) element.innerHTML = args.innerHTML;
  if (args.innerText !== undefined) element.innerText = args.innerText;
  if (args.parent !== undefined) args.parent.appendChild(element);
  return element;
}

/**
 * ondemand import when we want to log an error, to prevent circular import issues
 * @param msg - string message to log
 */
function logError(msg: string) {
  void import("../uiLog").then(({ UILog: log }) => {
    log.ui.error(msg);
  });
}

/**
 * ondemand import when we want to trace some log, to prevent circular import issues
 * @param msg - string message to log
 */
function logTrace(msg: string) {
  void import("../uiLog").then(({ UILog: log }) => {
    log.ui.trace(msg);
  });
}

const debugHolder = createElement({ tagName: "div", id: "debugHolder" });
debugHolder.style.position = "float";
debugHolder.style.opacity = "0.0";
document.body.appendChild(debugHolder);
const debugCanvas = createElement({ tagName: "canvas" });

/**
 * Used to build page.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isHtmlElement(obj: any): obj is HTMLElement {
  return obj !== undefined && obj !== null && (obj as HTMLElement).style !== undefined;
}

/**
 * adds a style element to DOM
 * @param rules - string of CSS rules to add
 */
export function addStyleSheet(rules: string): void {
  const style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = rules;
  document.head.appendChild(style);
}

/**
 * Find the first HTMLElement with spedcified className
 * @param className - HTMLElement classname that you want to find
 * @returns HTMLElement if found or undefined
 */
export function firstElementByClassName(className: string): HTMLElement | undefined {
  const element = document.getElementsByClassName(className).item(0);
  if (isHtmlElement(element)) {
    return element;
  } else {
    logError(`no HTML element found for classname ${className}`);
    return undefined;
  }
}

export function getHead(): HTMLHeadElement | undefined {
  const head = document.getElementsByTagName("head").item(0);
  if (!head) {
    logError("no head!");
  }
  return head ?? undefined;
}

/**
 * Get the body HTMLElement
 * @returns HTMLBodyElement if found or undefined
 */
export function getBody(): HTMLBodyElement | undefined {
  const body = document.getElementsByTagName("body").item(0);
  if (!body) {
    logError("no body!");
  }
  return body ?? undefined;
}

/**
 * calculates the size and location of an element in the DOM window
 * @param element - target HTMLElement
 * @param expandBy - value to pad the calculation
 * @returns
 */
export function screenRectOf(element?: HTMLElement, expandBy = 0): IRect | undefined {
  if (!element) return undefined;
  const domRect = element.getBoundingClientRect();
  if (domRect.x === 0 && domRect.y === 0 && domRect.width === 0 && domRect.height === 0) {
    // assume we're not in the DOM yet, use the _dsTranslate as a reference
    domRect.x = element._dsTranslate?.x ?? 0;
    domRect.y = element._dsTranslate?.y ?? 0;
    domRect.width =
      (element._dsSize?.width ?? 0) + (element._dsSize?.extraWidthLeft ?? 0) + (element._dsSize?.extraWidthRight ?? 0);
    domRect.height =
      (element._dsSize?.height ?? 0) +
      (element._dsSize?.extraHeightTop ?? 0) +
      (element._dsSize?.extraHeightBottom ?? 0);
  }
  return {
    origin: { x: domRect.left - expandBy, y: domRect.top - expandBy },
    size: { width: domRect.width + 2 * expandBy, height: domRect.height + 2 * expandBy },
  };
}

/**
 * calculates element's width based on the width of its target text
 * @param text - string to be added to element
 * @param refElement - reference element that text is to be added
 * @returns
 */
export function textWidthOf(text: string, refElement: HTMLElement): number {
  if (refElement.parentElement !== debugHolder) {
    const oldParent = refElement.parentElement;
    const nextSibling = refElement.nextSibling;
    debugHolder.appendChild(refElement);
    const width = textWidthOf(text, refElement);
    oldParent?.insertBefore(refElement, nextSibling);
    return width;
  } else {
    const ctx = debugCanvas.getContext("2d");
    if (!ctx) return 0;
    const styles = getComputedStyle(refElement);
    ctx.font = styles.font;
    const size = ctx.measureText(text);
    return size.width;
  }
}

/**
 * get dimensions of an element (cached after first call). Uses getComputedStyle
 * @param element - the HTMLElement to measure
 * @returns the dimensions of the element
 */
export function sizeOf(element?: HTMLElement): IExtraSize {
  if (!element)
    return {
      width: 0,
      height: 0,
      borderLeft: 0,
      marginLeft: 0,
      paddingLeft: 0,
      borderRight: 0,
      marginRight: 0,
      paddingRight: 0,
      borderTop: 0,
      marginTop: 0,
      paddingTop: 0,
      borderBottom: 0,
      marginBottom: 0,
      paddingBottom: 0,
      extraWidthLeft: 0,
      extraWidthRight: 0,
      extraHeightTop: 0,
      extraHeightBottom: 0,
    };
  if (element._dsSize === undefined) {
    if (!isInDOM(element)) {
      // const oldParent = element.parentElement;
      // const nextSibling = element.nextSibling;
      debugHolder.appendChild(element);
      element._dsSize = sizeOf(element);
      debugHolder.removeChild(element);
      // oldParent?.insertBefore(element, nextSibling);
    } else {
      const styles = getComputedStyle(element);

      let marginLeft = parseFloat(styles.marginLeft);
      if (isNaN(marginLeft)) {
        logTrace(`marginLeft NaN for ${element.id}, ${element.className}`);
        marginLeft = 0;
      }
      let marginRight = parseFloat(styles.marginRight);
      if (isNaN(marginRight)) {
        logTrace(`marginRight NaN for ${element.id}, ${element.className}`);
        marginRight = 0;
      }
      let marginTop = parseFloat(styles.marginTop);
      if (isNaN(marginTop)) {
        logTrace(`marginTop NaN for ${element.id}, ${element.className}`);
        marginTop = 0;
      }
      let marginBottom = parseFloat(styles.marginBottom);
      if (isNaN(marginBottom)) {
        logTrace(`marginBottom NaN for ${element.id}, ${element.className}`);
        marginBottom = 0;
      }

      let paddingLeft = parseFloat(styles.paddingLeft);
      if (isNaN(paddingLeft)) {
        logTrace(`paddingLeft NaN for ${element.id}, ${element.className}`);
        paddingLeft = 0;
      }
      let paddingRight = parseFloat(styles.paddingRight);
      if (isNaN(paddingRight)) {
        logTrace(`paddingRight NaN for ${element.id}, ${element.className}`);
        paddingRight = 0;
      }
      let paddingTop = parseFloat(styles.paddingTop);
      if (isNaN(paddingTop)) {
        logTrace(`paddingTop NaN for ${element.id}, ${element.className}`);
        paddingTop = 0;
      }
      let paddingBottom = parseFloat(styles.paddingBottom);
      if (isNaN(paddingBottom)) {
        logTrace(`paddingBottom NaN for ${element.id}, ${element.className}`);
        paddingBottom = 0;
      }

      let borderLeft = parseFloat(styles.borderLeft);
      if (isNaN(borderLeft)) {
        logTrace(`borderLeft NaN for ${element.id}, ${element.className}`);
        borderLeft = 0;
      }
      let borderRight = parseFloat(styles.borderRight);
      if (isNaN(borderRight)) {
        logTrace(`borderRight NaN for ${element.id}, ${element.className}`);
        borderRight = 0;
      }
      let borderTop = parseFloat(styles.borderTop);
      if (isNaN(borderTop)) {
        logTrace(`borderTop NaN for ${element.id}, ${element.className}`);
        borderTop = 0;
      }
      let borderBottom = parseFloat(styles.borderBottom);
      if (isNaN(borderBottom)) {
        logTrace(`borderBottom NaN for ${element.id}, ${element.className}`);
        borderBottom = 0;
      }
      // default width / height to something non-null
      let width = parseFloat(styles.width);
      if (isNaN(width)) {
        logTrace(`width NaN for ${element.id}, ${element.className}`);
        width = 10;
      }
      let height = parseFloat(styles.height);
      if (isNaN(height)) {
        logTrace(`height NaN for ${element.id}, ${element.className}`);
        height = 10;
      }
      element._dsSize = {
        width,
        height,
        borderLeft,
        marginLeft,
        paddingLeft,
        borderRight,
        marginRight,
        paddingRight,
        borderTop,
        marginTop,
        paddingTop,
        borderBottom,
        marginBottom,
        paddingBottom,
        extraWidthLeft: borderLeft + marginLeft + paddingLeft,
        extraWidthRight: borderRight + marginRight + paddingRight,
        extraHeightTop: borderTop + marginTop + paddingTop,
        extraHeightBottom: borderBottom + marginBottom + paddingBottom,
      };
    }
  }

  return {
    ...element._dsSize,
  };
}

/**
 * removes a target element from the DOM tree
 * @param element - target HTMLElement to be removed
 */
export function clean(element?: HTMLElement | null): void {
  // OUT!
  try {
    element?.parentElement?.removeChild(element);
  } catch (error) {
    //
  }
}

/**
 * sets the X/Y position of an HTMLElement
 * @param element - target HTMLElement
 * @param origin - target X/Y coordinates
 * @param dir - browser orientation (left-to-right or right-to-left)
 * @returns
 */
export function setOrigin(element: HTMLElement, origin?: IPoint, dir = DIR.ltr): void {
  if (!origin) return;
  element.style.position !== "absolute" && (element.style.position = "absolute");
  // support older webkit browsers, prefix with -webkit- in CSS.
  if (origin.x === 0 && origin.y === 0) {
    element.style.webkitTransform = element.style.transform = "";
  } else {
    element.style.webkitTransform = element.style.transform = `translate(${(dir === DIR.rtl ? -1 : 1) * origin.x}px, ${
      origin.y
    }px)`;
  }
  element._dsTranslate = origin;
}

/**
 * detects if a target element currently exists in the DOM tree
 * @param element - target HTMLElement
 * @returns
 */
export function isInDOM(element: HTMLElement): boolean {
  // figure if we're in the DOM or not
  let topMostParent = element.parentElement;
  while (topMostParent?.parentElement) topMostParent = topMostParent.parentElement;

  // if not in the DOM, no need to do anything because it won't work
  return topMostParent?.tagName === "HTML";
}
