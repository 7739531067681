import "./bannerWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GenericPage } from "../../../pages/generic/genericPage";
import { openKids } from "../../../pages/onBoarding/onBoardingPage";
import { Widget, WidgetBanner } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { WidgetView } from "../widgetView";

class BannerTileView extends AcceptsMouseFocusView {
  constructor(banner: WidgetBanner) {
    super(banner.id, "tile");

    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "img",
      style: {
        backgroundImage: `url(${banner.image.l})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    const titleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
    });
    const descriptionElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileDescription",
    });

    Config.locale$.didChange(
      () => {
        // when local changes, we reset our text - which is dependent on this locale
        titleElement.innerText = `${banner.title}`;
        descriptionElement.innerText = `${banner.description}`;
      },
      this,
      true
    );
  }
}

/**
 * View title to display missing widget during development
 */
export class BannerWidgetView extends WidgetView {
  focusTracker?: FocusTracker;
  list;

  constructor(widget: Widget) {
    super(widget.id, "bannerWidget");
    this._setAnalytics(widget);
    // Widget list of content
    this.delegate = this.list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget))),
        viewFactory: widgetBanner => new BannerTileView(widgetBanner),
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: (card, index) => {
          if (card.deepLink !== undefined) {
            if (card.deepLink === "/kids") {
              openKids();
            } else {
              navigationStack.pushPage(new GenericPage(card.deepLink));
            }
          }
          super._analyticsCards({
            card_order: index,
            promo_subtitle: card.description,
            promo_title: card.title,
            media_id: card.id,
          });
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this.focusTracker.onRelease);
      }
    );
  }

  /**
   * Fetch WidgetBanner if contentPath is defined
   *
   * @param {Widget} widget
   * @return {Promise<(WidgetBanner)[]>}
   */
  private async _content(widget: Widget): Promise<WidgetBanner[]> {
    try {
      return widget.contentPath !== undefined ? [await APIAuvio.widget(widget.contentPath, WidgetBanner)] : [];
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }

  protected _analyticsOnLeft() {
    const card = this.list.modelFromIndex(this.list.focusedIndex$.value);
    this._updateAnalytics({
      media_id: card?.id,
      promo_title: card?.title,
      promo_subtitle: card?.description,
      redirection_ressource: "page",
    });
    super._analyticsOnLeft();
  }

  protected _analyticsOnRight() {
    const card = this.list.modelFromIndex(this.list.focusedIndex$.value);
    this._updateAnalytics({
      media_id: card?.id,
      promo_title: card?.title,
      promo_subtitle: card?.description,
      redirection_ressource: "page",
    });
    super._analyticsOnRight();
  }

  protected _analyticsOnSelect() {
    const card = this.list.modelFromIndex(this.list.focusedIndex$.value);
    this._updateAnalytics({
      media_id: card?.id,
      promo_title: card?.title,
      promo_subtitle: card?.description,
      redirection_ressource: "page",
    });
    super._analyticsOnSelect();
  }

  protected _analyticsOnUpDown() {
    const card = this.list.modelFromIndex(this.list.focusedIndex$.value);
    this._updateAnalytics({
      media_id: card?.id,
      promo_title: card?.title,
      promo_subtitle: card?.description,
      redirection_ressource: "page",
    });
    super._analyticsOnUpDown();
  }
}
