import { callWithDelegateFallback } from "../helpers/delegate";
import * as DOMHelper from "../helpers/DOMHelper";
import { Listenable } from "../helpers/Listenable";
import {
  IDelegate,
  Identifiable,
  ISwitchComponent,
  ISwitchComponentParams,
  IView,
  IViewPersistency,
  Keys,
} from "../typings";
import { UILog } from "../uiLog";
import { BaseComponent, keyGenerator, objFilter } from "./baseComponent";

export function isSwitchComponent<M extends Identifiable, V extends IView>(
  value: unknown
): value is ISwitchComponent<M, V> {
  // Check if the value has a .go function. If so, it's an IValidator
  return value !== undefined
    ? typeof (value as ISwitchComponent<M, V>).shownView$ === "object" &&
        typeof (value as ISwitchComponent<M, V>).shownId$ === "object"
    : false;
}

export class SwitchComponent<M extends Identifiable, V extends IView>
  extends BaseComponent<M, V>
  implements ISwitchComponent<M, V>, IDelegate
{
  params: ISwitchComponentParams<M, V>;

  shownId$ = new Listenable<string | undefined>(undefined);
  shownView$ = new Listenable<V | undefined>(undefined);
  focused$ = new Listenable(false);
  shown$ = new Listenable(false);

  /**
   * @param params - SwitchComponent parameters
   */
  constructor(params: ISwitchComponentParams<M, V>) {
    super(params.id, params.className);
    this.params = params;

    // keep view in sync with index
    this.shownId$.didChange(shownId => {
      this.shownView$.value = this.viewFromId(shownId);
    }, this);

    if (Object.keys(params.model).length === 0) throw new Error("a switch needs at least 1 component!");

    params.model.forEach(item => {
      const id = keyGenerator(item);
      this.ids.push(id);
      this.modelMap[id] = item;
    });
    this.show(params.initialViewId);
  }

  /**
   * display component with specified index
   * @param id - view id to display
   */
  show(id: string) {
    if (id === this.shownId$.value) return;
    // at this point, we can remove any views that isn't to be cached
    // so filter our view map
    this.viewMap = objFilter(this.viewMap, (key, view) => {
      // keep the others as they are
      if (this.shownId$.value !== key) return true;

      // it's always hidden otherwise
      callWithDelegateFallback(view, "onHidden");
      if (view.persistency !== IViewPersistency.none) {
        // we keep
        return true;
      } else {
        // we cleanup from the DOM
        DOMHelper.clean(view?.rootElement);
        callWithDelegateFallback(view, "onRelease");
        return false;
      }
    });

    let nextView = this.viewFromId(id);
    if (!nextView) {
      UILog.ui_switch.log(`Switch ${this.rootElement.id} creating view for key ${id}`);
      const item = this.modelFromId(id);
      if (item !== undefined) {
        nextView = this.viewMap[id] = this.params.viewFactory(item);
      }
    }
    if (!nextView) UILog.ui_switch.error(`no views with id ${id} on switch ${this.rootElement.id}`);

    nextView?.rootElement && this.rootElement.appendChild(nextView.rootElement);
    DOMHelper.isInDOM(this.rootElement) && callWithDelegateFallback(nextView, "onShown");
    this.shownId$.value = id;
  }

  /**
   * onNav {@link INavigable} implementation.
   * @param key - Key pressed
   * @returns - true if the key pressed have been handled by the current shown view or one his child
   */
  public onNav(key: Keys): boolean {
    // just forward to shown view
    return callWithDelegateFallback(this.viewFromId(this.shownId$.value), "onNav", key) ?? false;
  }

  /**
   * rejectsFocus {@link IFocusable} implementation.\
   * indicates if the current shown View can be focused by calling the rejectsFocus method of the View
   * @returns true if the current shown View rejects the focus
   */
  public rejectsFocus(): boolean {
    return this.viewFromId(this.shownId$.value)?.rejectsFocus?.() ?? false;
  }

  /**
   * onUnfocused {@link IFocusable} implementation.\
   * event triggered when the component is no longer focused
   * @returns
   */
  public onUnfocused() {
    UILog.ui_switch.debug(`${this.rootElement.id} onUnfocused`);
    this.focused$.value = false;
    const currentFocusedView = this.viewFromId(this.shownId$.value);
    currentFocusedView?.rootElement.classList.remove("focused");
    callWithDelegateFallback(currentFocusedView, "onUnfocused");
  }

  /**
   * onFocused {@link IFocusable} implementation.\
   * event triggered when the component has been focused
   * @returns
   */
  public onFocused(): void {
    UILog.ui_switch.debug(`${this.rootElement.id} onFocused`);
    this.focused$.value = true;
    const currentFocusedView = this.viewFromId(this.shownId$.value);
    currentFocusedView?.rootElement.classList.add("focused");
    callWithDelegateFallback(currentFocusedView, "onFocused", false);
  }

  public onRelease(): void {
    super.onRelease();
    const oldView = this.viewFromId(this.shownId$.value);
    DOMHelper.clean(oldView?.rootElement);
    callWithDelegateFallback(oldView, "onHidden");
    callWithDelegateFallback(oldView, "onRelease");
  }

  /**
   * onShown {@link IShowable} implementation.\
   * event triggered when the SwitchComponent is shown
   * @returns
   */
  public onShown() {
    // just forward to shown view
    this.shown$.value = true;
    DOMHelper.isInDOM(this.rootElement) && callWithDelegateFallback(this.viewFromId(this.shownId$.value), "onShown");
  }

  /**
   * onHidden {@link IShowable} implementation.\
   * event triggered when the SwitchComponent is hidden
   * @returns
   */
  public onHidden() {
    // just forward to shown view
    this.shown$.value = false;
    callWithDelegateFallback(this.viewFromId(this.shownId$.value), "onHidden");
  }
}
