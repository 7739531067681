import { trycatch } from "../ui/helpers/trycatch";
import { Keys } from "../ui/typings";
import { logNavigationStack } from "./logNavigationStack";
import { outputOverlay } from "./outputs/outputOverlay";
import { ICustomMenuEntry, ILogUI } from "./types";
import { MainMenu } from "./UI/menu";

export class LogUIImpl implements ILogUI {
  overlayToggleKey = Keys.red;

  private _triggerSequences: string[][] = [[Keys.blue, Keys.yellow, Keys.green, Keys.blue, Keys.green]];

  get menuTriggerSequences() {
    return this._triggerSequences;
  }
  set menuTriggerSequences(sequences: string[][]) {
    this._triggerSequences = sequences;
    this._triggerSequenceIndexes = this.menuTriggerSequences.map(_ => 0);
  }

  private _triggerSequenceIndexes: number[] = this.menuTriggerSequences.map(_ => 0);
  private _lastBlockingKey?: string;
  private _customMenuEntries: Record<string, ICustomMenuEntry> = {};

  keyHandler = (key: string, type: "keydown" | "keyup"): boolean => {
    return (
      trycatch("log::navigationStack::keyhandler", () => {
        if (type === "keyup") {
          // on keyup, we just check if we need to prevent the handling of the event (anything showing)
          return logNavigationStack.pages$.value.length > 0 || key === this._lastBlockingKey;
        } else {
          // push on navigation stack
          if (logNavigationStack.pages$.value.length > 0) {
            logNavigationStack.keyHandler(key);
            this._lastBlockingKey = key;
            return true;
          } else {
            if (key === this.overlayToggleKey) {
              const overlayElement = outputOverlay.overlayElement;
              if (overlayElement?.parentElement) {
                overlayElement.hidden = !overlayElement.hidden;
              }
            }
            if (this.menuTriggerSequences.length === 0) return false;

            this.menuTriggerSequences.forEach((sequence, index) => {
              if (key === sequence[this._triggerSequenceIndexes[index]]) {
                this._triggerSequenceIndexes[index]++;
              } else {
                this._triggerSequenceIndexes[index] = 0;
              }

              if (this._triggerSequenceIndexes[index] >= sequence.length) {
                this._triggerSequenceIndexes[index] = 0;
                // show the UI
                logNavigationStack.pushPage(new MainMenu(this._customMenuEntries));
                this._lastBlockingKey = key;
                return true;
              }
            });
          }
        }
        return false;
      }) ?? false
    );
  };

  addCustomMenuEntry = (newEntry: ICustomMenuEntry) => {
    return (
      trycatch("addCustomMenuEntry", () => {
        if (this._customMenuEntries[newEntry.id] === undefined) {
          this._customMenuEntries[newEntry.id] = newEntry;
          return true;
        } else {
          console.error(`custom menu entry ${newEntry.id} already exists!`);
        }
        return false;
      }) ?? false
    );
  };
}

export const LogUI = new LogUIImpl();
