/* eslint-disable @typescript-eslint/no-explicit-any */

import { DS } from "../..";
import { DOMHelper } from "../../exports";
import { IView } from "../typings";
import { trycatch } from "./trycatch";

type DelegateFunctions = "onRelease" | "onNav" | "onFocused" | "onUnfocused" | "onShown" | "onHidden" | "asyncContent";
const returningFunctions = ["onNav", "asyncContent"];

/**
 * executes a target function in a try/catch "safe zone" to prevent exceptions from interupting JS thread
 * @param obj - view component that implements {@link IDelegate} interface
 * @param funcName - target delegate function
 * @param args - function arguments
 * @returns a boolean if target delegate function is a returningFunction (for expample: "onNav") else return undefined
 */
export const callWithDelegateFallback = <V extends IView>(
  obj: V | undefined,
  funcName: DelegateFunctions,
  ...args: any[]
): boolean | undefined => {
  let current: any = obj;
  const returning = returningFunctions.includes(funcName);
  while (current !== undefined) {
    const func = current[funcName];
    if (func !== undefined && typeof func === "function") {
      // bind in order to make sure it's the right "this" when we call the function
      const boundFunc = func.bind(current);
      const results = trycatch<any>(`callWithDelegateFallback ${funcName} error`, () => {
        return boundFunc(...args);
      });
      // if it's a returning function, drill down until we've exhausted delegates
      // otherwise, just return
      if (returning && results === true) return results;
    }
    current = current.delegate as any;
  }
};

/** returns the top-most parent element that doesn't reject focus starting from the target element */
export function firstNonFocusRejectedElement(target: HTMLElement): HTMLElement | null {
  // first walk the tree up to find if we should reject or not (any parent rejecting)
  let walkElement: HTMLElement | null = target;
  let nonFocusRejectedElement: HTMLElement | null = target;

  while (walkElement && walkElement !== document.body) {
    if (walkElement.parentElement?.parentElement?._dsListComponent) {
      if (walkElement.parentElement?.parentElement?._dsListComponent.rejectsFocus?.(walkElement) ?? false) {
        nonFocusRejectedElement = walkElement.parentElement?.parentElement;
      }
      walkElement = walkElement.parentElement?.parentElement ?? null;
    } else {
      if (walkElement._dsView?.rejectsFocus?.() ?? false) {
        nonFocusRejectedElement = walkElement.parentElement;
      }
      walkElement = walkElement.parentElement ?? null;
    }
  }

  // then walk the tree up from the uppermost non-rejecting element to figure who accepts focus
  return nonFocusRejectedElement;
}

/** returns the deeper HTMLElement parent of the provided target that
 * - isn't in a rejecting focus part of the tree
 * - accepts focus
 * - and therefore has an associated dsview
 */
export function firstMouseFocusableElement(target: HTMLElement): HTMLElement | null {
  // first walk the tree up to find if we should reject or not (any parent rejecting)
  const walkElement: HTMLElement | null = firstNonFocusRejectedElement(target);
  return callUpTree(walkElement, (_, view) => view?.acceptsMouseFocus?.());
}

export const callUpTree = (
  fromElement: HTMLElement | null,
  callback: (element: HTMLElement, view: DS.IView) => boolean | undefined
) => {
  if (DOMHelper.isHtmlElement(fromElement)) {
    let walkElement: HTMLElement | null = fromElement;
    // walk up the rest of the tree to figure if someone wants to handle the event
    while (walkElement && walkElement !== document.body) {
      // exit if true
      if ((walkElement._dsView && callback(fromElement, walkElement._dsView)) ?? false) return walkElement;
      walkElement = walkElement?.parentElement;
    }
  }
  return null;
};
