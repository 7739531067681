import "./filterPeriodWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { DS } from "~/libs";

import { Config } from "../../../config";
import { RTBF } from "../../../utils/rtbf";

class FilterTileView extends AcceptsMouseFocusView {
  constructor(label: string, selected = false) {
    super("", "filterTileAction");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "filterTile",
      innerText: label,
    });

    if (selected) this.rootElement.classList.add("selected");
  }
}

export class FilterPeriodWidgetView extends AcceptsMouseFocusView {
  private _list;

  constructor(table: RTBF.PeriodTable = [], selected: DS.Listenable<string>) {
    super("filterPeriodWidgetView", "filterPeriodWidgetView");

    // Widget list of content
    this.delegate = this._list = DS.createListComponent({
      id: "filterPeriodWidget",
      className: "filterPeriodList",
      modelSource$: new DS.ModelSource(table),
      viewFactory: item => {
        if (item.key === table[0]?.key) {
          return new FilterTileView(item.label, true);
        }
        return new FilterTileView(item.label);
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
      onSelect: item => {
        this._list.viewFromId(item.id)?.rootElement.classList.add("selected");
        this._list.viewFromId(selected.value)?.rootElement.classList.remove("selected");
        selected.value = item.id;
        return true;
      },
    });
  }
}
