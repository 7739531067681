import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";

/**
 * Replace {id} occurence in string with user ID
 * @param url URL containing a {id} occurence to be replaced
 * @returns string
 */
export async function updateUrlWithUserId(url: string) {
  if (APIGigyaOIDC.userInfo$.value?.uid !== undefined) return url.replace("{id}", APIGigyaOIDC.userInfo$.value.uid);
  return url;
}
