import { Identifiable, IListComponent, IOnClick, IOnHover, IView } from "../typings";

/**
 * @internal
 * Return true if value implements {@link IOnClick}\
 * used by mouseListComponent to determine the arrowScrollMethod
 * @param value - unknown
 * @returns
 */
export function isOnClick(value: unknown): value is IOnClick {
  return value !== undefined ? typeof (value as IOnClick).onClick === "object" : false;
}

/**
 * @internal
 * Return true if value implements {@link IOnHover}\
 * used by mouseListComponent to determine the arrowScrollMethod
 * @param value - unknown
 * @returns
 */
export function isOnHover(value: unknown): value is IOnHover {
  return value !== undefined ? typeof (value as IOnHover).onHover === "object" : false;
}

/**
 * @internal
 * Return true if value implements {@link IListComponent}
 * @param value - unknown
 * @returns
 */
export function isListComponent<M extends Identifiable, V extends IView>(
  value: unknown
): value is IListComponent<M, V> {
  return value !== undefined ? typeof (value as IListComponent<M, V>).refresh === "function" : false;
}
