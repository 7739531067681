import { Identifiable, ISwitchComponent, ISwitchComponentParams, IView } from "../typings";
import { SwitchComponent } from "./switchComponent";

/**
 * creates a switch component
 * @param params - SwitchComponent parameters
 * @param onReadyCallback - funciton to be called once the component is ready
 * @returns
 */
export function createSwitchComponent<M extends Identifiable, V extends IView>(
  params: ISwitchComponentParams<M, V>,
  onReadyCallback?: (switchComponent: ISwitchComponent<M, V>) => void
): ISwitchComponent<M, V> {
  const switchComponent = new SwitchComponent(params);
  void (async () => {
    onReadyCallback?.(switchComponent);
  })();

  return switchComponent;
}
