/* eslint-disable @typescript-eslint/unbound-method */
import { DS } from "../..";
import * as DOMHelper from "../helpers/DOMHelper";
import { Memoize } from "../helpers/memoize";
import { Identifiable } from "../typings";
import { IView } from "../typings/iView";
import { ListComponent } from "./listComponent";

interface IRange {
  readonly start: number;
  readonly end: number;
  readonly offsetStart: number;
  readonly offsetEnd: number;
}

export interface IViewBounds {
  along: IRange;
  across: IRange;
}

class Range implements IRange {
  start: number;
  end: number;
  scrollOffset: number;

  constructor(start: number, end: number, scrollOffset = 0) {
    this.start = start;
    this.end = end;
    this.scrollOffset = scrollOffset;
  }

  get offsetStart() {
    return this.start - this.scrollOffset;
  }
  get offsetEnd() {
    return this.end - this.scrollOffset;
  }
}

export class ViewBounds implements IViewBounds {
  along: Range;
  across: Range;

  constructor(view: IView, horizontal: boolean, scrollOffset = 0) {
    const size = DOMHelper.sizeOf(view.rootElement);
    const origin = view.rootElement._dsTranslate ?? { x: 0, y: 0 };
    if (horizontal) {
      this.along = new Range(
        origin.x,
        origin.x + size.width + size.extraWidthLeft + size.extraWidthRight,
        scrollOffset
      );
      this.across = new Range(origin.y, origin.y + size.height + size.extraHeightTop + size.extraHeightBottom);
    } else {
      this.along = new Range(
        origin.y,
        origin.y + size.height + size.extraHeightTop + size.extraHeightBottom,
        scrollOffset
      );
      this.across = new Range(origin.x, origin.x + size.width + size.extraWidthLeft + size.extraWidthRight);
    }
  }
}

class ParentScrollingRange<M extends Identifiable, V extends IView> implements IRange {
  list: ListComponent<M, V>;
  horizontal: boolean;
  scrollOffset: number;
  constructor(list: ListComponent<M, V>, horizontal: boolean, scrollOffset = 0) {
    this.list = list;
    this.horizontal = horizontal;
    this.scrollOffset = scrollOffset;
  }

  @Memoize()
  get delegateOffset() {
    // start from the list itself, go up the tree
    // either we are a direct child of the upper list (and therefore no offset)
    // or we are a delegate of the child of the upper list (and therefore we compute the offset of the list in the child element)
    let walkingElement: HTMLElement | null = this.list.rootElement ?? null;
    let translation = 0;
    let offset = 0;
    while (walkingElement !== null) {
      if (walkingElement.parentElement?._dsListScrollElement === true) {
        const sizes = DOMHelper.sizeOf(walkingElement);
        const holderBounding = walkingElement.getBoundingClientRect();
        const childListBounding = this.list.rootElement.getBoundingClientRect();

        offset =
          (this.horizontal ? sizes.borderLeft + sizes.marginLeft : sizes.borderTop + sizes.marginTop) +
          DS.platform.screenScale() *
            (this.horizontal
              ? childListBounding.left - holderBounding.left
              : childListBounding.top - holderBounding.top);
        offset = isNaN(offset) ? 0 : offset;
        translation = this.horizontal ? walkingElement._dsTranslate?.x ?? 0 : walkingElement._dsTranslate?.y ?? 0;
        break;
      }
      walkingElement = walkingElement.parentElement;
    }
    return { translation, offset };
  }

  get start() {
    return this.delegateOffset.translation;
  }

  get end() {
    // if (this.list._creatingViews) return undefined;

    return (
      this.delegateOffset.translation +
      ((this.horizontal ? this.list.params.scrollingMode.horizontal : !this.list.params.scrollingMode.horizontal)
        ? this.list._overallSize.along
        : this.list._overallSize.across) +
      this.delegateOffset.offset
    );
  }

  get offsetStart() {
    return this.start - this.scrollOffset;
  }
  get offsetEnd() {
    return this.end - this.scrollOffset;
  }
}

export class ParentScrollingViewBounds<M extends Identifiable, V extends IView> implements IViewBounds {
  along: ParentScrollingRange<M, V>;
  across: ParentScrollingRange<M, V>;

  list: ListComponent<M, V>;

  constructor(list: ListComponent<M, V>, horizontal: boolean, scrollOffset = 0) {
    this.list = list;
    this.along = new ParentScrollingRange(list, horizontal, scrollOffset);
    this.across = new ParentScrollingRange(list, !horizontal);
  }
}

class TranslatedRange implements IRange {
  range: IRange;
  translation: number;

  constructor(range: IRange, translation: number) {
    this.range = range;
    this.translation = translation;
  }

  get start() {
    return this.range.start + this.translation;
  }

  get end() {
    return this.range.end + this.translation;
  }

  get offsetStart() {
    return this.range.offsetStart + this.translation;
  }

  get offsetEnd() {
    return this.range.offsetEnd + this.translation;
  }
}

export class TranslatedViewBounds implements IViewBounds {
  viewBounds: IViewBounds;
  along: TranslatedRange;
  across: TranslatedRange;

  constructor(viewBounds: IViewBounds, translation: { along: number; across: number }) {
    this.viewBounds = viewBounds;
    this.along = new TranslatedRange(viewBounds.along, translation.along);
    this.across = new TranslatedRange(viewBounds.across, translation.across);
  }
}
