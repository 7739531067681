import "./switchButton.scss";

import { DS } from "dslib-tv";

export class SwitchButtonView extends DS.View {
  buttonState$?: DS.Listenable<boolean>;
  constructor(parent: HTMLElement, state: boolean) {
    super("", "");
    this.buttonState$ = new DS.Listenable(state);
    const container = DS.DOMHelper.createElement({
      tagName: "div",
      parent: parent,
      className: `switchButton ${state && "on"}`,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: container,
      className: "switchTrail",
    });
    const switchRoundButton = DS.DOMHelper.createElement({
      tagName: "div",
      parent: container,
      className: "switchRoundButton",
    });
    const toggledOffIcon = require("@assets/images/settings/toggleOff.png");
    const toggledOnIcon = require("@assets/images/settings/toggleOn.png");
    const switchIcon = DS.DOMHelper.createElement({
      tagName: "div",
      parent: switchRoundButton,
      className: "switchIcon",
      style: {
        backgroundImage: `url(${state ? toggledOnIcon : toggledOffIcon})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    this.buttonState$?.didChange(value => {
      switchIcon.style.background = `url("${value ? toggledOnIcon : toggledOffIcon}") 50% 50% no-repeat`;
      switchIcon.style.backgroundSize = "cover";
    }, this);
  }

  toggleButton(): boolean {
    if (this.buttonState$ !== undefined) {
      this.buttonState$.value = !this.buttonState$.value;
      return this.buttonState$.value;
    }
    return false;
  }
}
