import * as Sentry from "@sentry/browser";

import { env } from "./datas/consts";
import { DS } from "./libs";

const namespacedLog = ((window as any).Log = DS.LogFactory.create(
  ["player", "api", "analytics", "app"],
  _IS_DEV_ ? require("./logLevels.json") : require("../releaseLogLevels.json")
));
declare global {
  const Log: typeof namespacedLog;
}

DS.LogInfo.appName = _APP_NAME_;
DS.LogInfo.appVersion = _APP_VERSION_;
DS.LogInfo.appHash = _APP_HASH_;

DS.LogFactory.setOutput(!_IS_DEV_ || DS.platform.type === DS.PlatformType.tivo ? "buffer" : "console");
DS.LogFactory.setConsoleNamespace("_");
_IS_DEV_ && (DS.LogUI.menuTriggerSequences = [["Control"], [DS.Keys.blue]]);

DS.LogInfo.qrEncodedText = `https://sentry.io/organizations/auvio3/issues/?project=4504241669668864&query=is%3Aunresolved%20platformDeviceId%3A${DS.platform.deviceId}`;

let sentryInitialized = false;
function initSentry() {
  if (!sentryInitialized) {
    // from auvio-tv project: https://sentry.io/settings/auvio3/projects/auvio-tv/keys/
    const DSN = "https://adc624e3bfec4964a7fe19bc01aec200@o4504213474246656.ingest.sentry.io/4504241669668864";
    /**
     * init sentry monitoring https://sentry.io/welcome/
     */
    Sentry.init({
      dsn: DSN,
      release: _APP_VERSION_,
      environment: env(),
      autoSessionTracking: true,
      maxBreadcrumbs: 100, // we can't have more than 100. Alternative is to log more log as extra data, attaching it in beforeSend
      // don't capture console logs, fetch & xhr calls. We can whitelist/blacklist for more precision
      integrations: [new Sentry.Integrations.Breadcrumbs({ console: false, fetch: false, xhr: false })],
      beforeSend: async event => {
        if (
          event.exception === undefined ||
          (event.exception.values?.filter(exception => !(exception.value?.includes("Clpp-Error") === true))?.length ??
            0) > 0
        ) {
          const extra: { [timestamp: string]: string } = {};
          DS.LogFactory.entries.forEach(
            (entry, index) => (extra[`${entry.timestampAsString} - (${index})`] = entry.text)
          );
          return { ...event, extra };
        }
        return null;
      },
    });

    Sentry.configureScope(scope => {
      scope.setTag("sessionId", DS.LogInfo.sessionId);
      scope.setTag("platformDeviceId", DS.platform.deviceId);
    });

    console.warn("Sentry initialized");
  }
  sentryInitialized = true;
}

// enable sentry for all builds running on a known platform (browsers don't count)
if (_IS_RELEASE_ && [DS.PlatformType.other].includes(DS.platform.type) === false) {
  initSentry();

  DS.LogUI.addCustomMenuEntry({
    id: "sentry Report",
    title: `Log to Sentry`,
    desc: `${DS.platform.deviceId}`,
    onSelect: () => {
      initSentry();
      Sentry.withScope(async () => {
        const { navigationStack } = await import("./main");
        Sentry.captureMessage(
          `${DS.platform.deviceId}: MANUAL EVENT in ${navigationStack.topPage?.rootElement.id}`,
          "info"
        );
        console.warn("Sentry manual report sent");
      });
    },
  });
}
