import { IPlayerTrack } from "./player";

/**
 * Return an audio selection value from a player track (rules improved from auvio-web behavior)
 * @param {IPlayerTrack} track Track from the player
 * @return {string | undefined}
 */
export function getAudioSelectionValueFromTrack(track: IPlayerTrack): string | undefined {
  if (track.track.language === "fr") {
    return track.track.raw?.roles?.includes("alternate") === true ? "qad" : "fr";
  } else if (track.track.label.toLocaleLowerCase().includes("original") === true) {
    return "qaa";
  } else if (track.track.label.toLocaleLowerCase().includes("description") === true) {
    return "qad";
  }

  return undefined;
}

/**
 * Return a subtitle selection value from a player track (rules improved from auvio-web behavior)
 * @param {IPlayerTrack} track Track from the player
 * @return {string | undefined}
 */
export function getSubtitlesSelectionValueFromTrack(track: IPlayerTrack): string | undefined {
  if (track.track.language === "fr" && track.track.raw?.roles?.includes("caption") === true) {
    return "qhh";
  }
  return track.track.language;
}
