import mitt from "mitt";

type TDefaultHandler = (...args: any[]) => void;
export default class EmitterBaseClass<T = string> {
  emitter: any;

  constructor() {
    this.emitter = mitt();
  }

  protected emit(event: T, data?: any): void {
    if (this.emitter != null) {
      this.emitter.emit.apply(this, [event, data]);
    } else {
      console.warn("[EmitterBaseClass] trying to emit on destroyed emitter");
    }
  }

  public on<H = TDefaultHandler>(event: T, handler: H): void {
    if (this.emitter != null) {
      this.emitter.on.apply(this, [event, handler]);
    }
  }

  public off<H = TDefaultHandler>(event: T, handler?: H): void {
    if (this.emitter != null) {
      this.emitter.off.apply(this, [event, handler]);
    }
  }

  public addEventListener<H = TDefaultHandler>(event: T, handler: H): void {
    if (this.emitter != null) {
      this.on(event, handler);
    }
  }

  public removeEventListener<H>(event: T, handler?: H): void {
    if (this.emitter != null) {
      this.off(event, handler);
    }
  }

  public destroy(options?: any): void {
    if (this.emitter != null) {
      this.emitter.off("*");
    }
    this.emitter = null;
  }
}
