/**
 * this needs to be done right away at the top of our project entrypoint (usually main.ts or init.ts)
 * we condition which polyfills to import based on the platform
 *
 * We're not allowed the Log object here as it's not yet created
 * @module Platform
 */
import { CookieStorage } from "../helpers/cookieStorage";
import { IPlatform, PlatformType } from "../typings";
import { init as initHisense } from "./hisense";
import { init as initPS4 } from "./playstation/ps4";
import { init as initPS5 } from "./playstation/ps5";
import { init as initTizen } from "./tizen";
import { init as initWebos } from "./webos";

const platformMatch = initTizen() ?? initWebos() ?? initPS4() ?? initPS5() ?? initHisense();

const platformType: PlatformType = platformMatch?.type ?? PlatformType.other;

// we need those to save device name & ids persistently
export const Storage = "undefined" === typeof localStorage ? new CookieStorage() : localStorage;

const persistedDeviceId = (() => {
  const saveDeviceId = Storage.getItem("device_id");
  if (saveDeviceId !== null) return saveDeviceId;

  const uniqueId = `${new Date().getTime().toString(36)}-${Math.floor(Math.random() * 1000).toString(36)}`;

  // console.warn("device_id", uniqueId);
  Storage.setItem("device_id", uniqueId);
  return uniqueId;
})();

const persistedDeviceName = (() => {
  const saveDeviceName = Storage.getItem("device_name");
  if (saveDeviceName !== null) return saveDeviceName;

  const uniqueName = platformMatch?.deviceName ?? `${platformType}_${~~(Math.random() * 99999999)}`;

  // console.warn("device_name", uniqueName);
  Storage.setItem("device_name", uniqueName);
  return uniqueName;
})();

function defaultScreenScale() {
  return document.body.offsetWidth / document.body.getBoundingClientRect().width;
}

function defaultExit() {
  console.warn("won't quit, unknown platform");
}

export const platform: IPlatform = {
  type: platformType,
  version: platformMatch?.version,
  deviceName: persistedDeviceName,
  deviceId: persistedDeviceId,
  screenScale: platformMatch?.screenScale ?? defaultScreenScale,
  alternativeCSS: platformMatch?.alternativeCSS ?? false,
  exit: platformMatch?.exit ?? defaultExit,
};
