import "./productContentView.scss";

import { Config } from "~/config";
import { DS } from "~/libs";
import { PremiumHelper, PremiumManager } from "~/tools/premiumHelper";
import { TextHelper } from "~/tools/textHelper";
import { PageProductOfferingContent, Widget } from "~/utils/rtbf/models";

import { FilterGenreButton } from "../../../pages/filter/filterButtonView";
import { ContentView } from "../contentView";

export class ProductContentView extends ContentView {
  private _content;
  private _isFocusable = false;
  private _purchases$;
  private _list;

  constructor(content: PageProductOfferingContent, widgets: Widget[] | undefined) {
    super(content.id, "productContentView");
    this._content = content;
    void PremiumHelper.updatePurchases();
    this._purchases$ = PremiumHelper.getPurchases();

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentTitle",
      innerText: content.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentBrandImg",
      style: {
        backgroundImage: `url(${content.logo.xl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentSubtitle",
      innerText: content.subtitle,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentDesc",
      innerHTML: TextHelper.sanitizeString(content.description).replace(/(\n|\\n)/g, "<br>"),
    });
    const subElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentSubscriptionPrice",
    });
    const priceElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: subElt,
      className: "price",
      style: {
        opacity: "0",
      },
    });
    const end = DS.DOMHelper.createElement({
      tagName: "div",
      parent: subElt,
      className: "end",
      innerText: "/" + content.durationLabel,
      style: {
        opacity: "0",
      },
    });
    const subscriptionDescElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentSubscriptionDesc",
      innerText: content.salesIncentiveAltLabel,
      style: {
        opacity: "0",
      },
    });

    if (content.widgetFilter && widgets) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "filterTitle",
        innerText: content.widgetFilter.label,
      });

      this.delegate = this._list = DS.createListComponent(
        {
          id: "sectionContainer",
          className: "sectionContainer",
          modelSource$: new DS.ModelSource(["filter"]),
          viewFactory: () => {
            return new FilterGenreButton(widgets, content.widgetFilter?.fieldLabel ?? "");
          },
          scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
          mouseSupport: Config.mouseSupport,
          onSelect: item => {
            const view = this._list?.viewFromId(item);
            if (view instanceof FilterGenreButton) view.onSelect();
            return true;
          },
        },
        list => {
          void list.setFocusOnIndex(0);
        }
      );

      this._isFocusable = true;
    }

    this._purchases$.didChange(
      purchases => {
        if (purchases === undefined) return;
        if (PremiumHelper.isProductOfferingPurchased(this._content.productOffering) === true) {
          priceElt.innerText = t("generic.subscribed");
        } else {
          priceElt.innerText = PremiumManager.priceFromProductOffering(content.productOffering);
          end.style.opacity = "1";
          subscriptionDescElt.style.opacity = "1";
        }
        priceElt.style.opacity = "1";
      },
      this,
      true
    );
  }

  rejectsFocus() {
    return !this._isFocusable;
  }
}
