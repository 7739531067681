import "./rwdButtonPlayer.scss";

import { DS } from "~/libs";

import { IPlayer, PlayerStreamType } from "../../../../tools/player";
import { MoveButtonPlayer } from "./buttonPlayer";

export class RwdButtonPlayer extends MoveButtonPlayer {
  private static _instance: RwdButtonPlayer | undefined;

  constructor(player: IPlayer, isDisabled = false) {
    super(player, `rwdButtonPlayer ${isDisabled ? "disabled" : ""}`, t("player.button.rwd"), isDisabled);
    if (
      player.type$.value === PlayerStreamType.AUDIO ||
      player.type$.value === PlayerStreamType.AUDIO_LIVE ||
      player.type$.value === PlayerStreamType.AUDIO_SHIFT
    ) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "rwdImg img",
        style: {
          backgroundImage: `url(${require("@assets/images/player/controls/RWD-15.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
    } else {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "rwdImg img",
        style: {
          backgroundImage: `url(${require("@assets/images/player/controls/RWD.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
    }
    // eslint-disable-next-line consistent-this
    RwdButtonPlayer._instance = this;
  }

  static rewind() {
    if (RwdButtonPlayer._instance) {
      RwdButtonPlayer._instance.onSelect();
      return true;
    }
    return false;
  }

  protected _onKeyUp(ev: KeyboardEvent) {
    const key = DS.mapKeyboardEvent(ev);
    switch (key) {
      case DS.Keys.rewind:
        this.selectPressed$.value = false;
        break;
      case DS.Keys.select:
        if (this.isFocused$.value) this.selectPressed$.value = false;
        break;
    }
  }

  protected action(preview = false, released = false) {
    this.player.pause();
    if (!released) {
      this.player.rewind(preview);
    } else {
      this.player.play();
    }
  }
}
