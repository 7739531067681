import "./filterPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { FocusTracker } from "../../components/focusTracker";
import { Config } from "../../config";
import { navigationStack } from "../../main";
import { GenericPage } from "../generic/genericPage";
import { FilterCategoryButton, FilterGenreButton } from "./filterButtonView";

class FilterMenuItemView extends AcceptsMouseFocusView {
  selected: boolean;

  constructor(mediaTitle: string, selected = false, premium = false) {
    super(premium ? "filterPremium" : "filterCategory", "filter");

    this.selected = selected;
    const filterItem = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "filterMenuItem",
    });
    const radioButtonOffIcon = require("@assets/images/radioButton/notSelected.png");
    const radioButtonOnIcon = require("@assets/images/radioButton/selected.png");
    const radiobutton = DS.DOMHelper.createElement({ tagName: "span", parent: filterItem, className: "radioButton" });
    const radioButtonIcon = DS.DOMHelper.createElement({
      tagName: "img",
      parent: radiobutton,
      className: "radioButtonIcon",
    });
    radioButtonIcon.src = selected ? radioButtonOnIcon : radioButtonOffIcon;
    const labelElement = DS.DOMHelper.createElement({
      tagName: "span",
      parent: filterItem,
      className: "labelRadioButton",
    });
    labelElement.innerText = mediaTitle;

    if (selected) this.rootElement.classList.add("selected");
  }

  onSelect() {
    this.selected = !this.selected;
    return true;
  }
}

class FilterPageView extends DS.View implements DS.IPage {
  focusTracker: FocusTracker | undefined;
  idSelected: string | undefined;

  constructor() {
    super("FilterPage", `filterMenuView`);
  }

  onNav(key: DS.Keys) {
    switch (key) {
      case DS.Keys.left:
        navigationStack.removePage(navigationStack.topPage);
        return true;
    }
    return false;
  }

  isPopup = () => true;
}

export class FilterCategoryPageView extends FilterPageView {
  private _list;
  constructor(button: FilterCategoryButton) {
    super();

    this.idSelected = button.id;

    this.delegate = this._list = DS.createListComponent(
      {
        id: "filterCategoryWidget",
        className: "filterList",
        modelSource$: new DS.ModelSource(button.categories),
        viewFactory: item => {
          if (this.idSelected === item.id) return new FilterMenuItemView(item.label, true);
          else return new FilterMenuItemView(item.label);
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
          wheelScrollBy: 300,
        },
        onSelect: item => {
          this.idSelected = item.id;
          this._list.viewFromId(item.id)?.onSelect();
          button.id = this.idSelected;
          navigationStack.removePage(navigationStack.topPage);
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "filterAction");
        this.collectRelease(this.focusTracker.onRelease);
        void list.setFocusOnId(this.idSelected);
      }
    );
  }
}

export class FilterGenrePageView extends FilterPageView {
  private _list;
  private _widgets;
  constructor(button: FilterGenreButton) {
    super();

    this.idSelected = button.id;
    this._widgets = button.widgets;

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "filterMenuTitle",
      innerText: button.title,
    });

    this.delegate = this._list = DS.createListComponent(
      {
        id: "filterGenreWidget",
        className: "filterList",
        modelSource$: new DS.ModelSource(this._content()),
        viewFactory: widget => {
          if (this.idSelected === widget.id) return new FilterMenuItemView(widget.title, true, true);
          else return new FilterMenuItemView(widget.title, false, true);
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
          wheelScrollBy: 300,
        },
        onSelect: item => {
          // this.idSelected = item.id;
          // this._list.viewFromId(item.id)?.onSelect();
          // button.id = this.idSelected;
          navigationStack.removePage(navigationStack.topPage);
          if (item.cta?.contentPath !== undefined) navigationStack.pushPage(new GenericPage(item.cta?.contentPath));
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "filterMenuItem");
        this.collectRelease(this.focusTracker.onRelease);
        void list.setFocusOnIndex(0);
      }
    );
  }

  private _content() {
    return this._widgets.sort((a, b) => a.title.localeCompare(b.title));
  }
}
