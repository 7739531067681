import { DS } from "~/libs";
import { Storage } from "~/tools/storage";

const locales = ["en", "fr"] as const;
type Locale = typeof locales[number];

const themes = ["light", "dark"] as const;
type Theme = typeof themes[number];

const platformType = DS.platform.type;

export const Config = {
  delaySplashScreen: 2000,
  mouseSupport: false,
  locale$: new DS.Listenable((Storage.getItem("locale") ?? "fr") as Locale),
  theme$: new DS.Listenable((Storage.getItem("theme") ?? "light") as Theme),
  scrollDuration: 400,
  platformShowNumericKeyboard: true,
};

switch (platformType) {
  case DS.PlatformType.webos: // LG
    Config.mouseSupport = true;
    break;
  case DS.PlatformType.other: // browser
    Config.mouseSupport = true;
    break;
  default:
    // samsung / hisense / tivo
    Config.mouseSupport = false;
    break;
}

// listen to the locale changing, & keep the storage in sync
Config.locale$.didChange(locale => {
  Storage.setItem("locale", locale);
}, null);

// listen to the locale changing, & keep the storage in sync
function setTheme(theme: Theme) {
  if (theme === "light") document.documentElement.classList.remove("dark");
  else document.documentElement.classList.add("dark");
}

Config.theme$.didChange(theme => {
  Storage.setItem("theme", theme);
  setTheme(theme);
}, null);
setTheme(Config.theme$.value);
