import "./generalConditionPage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { RootMenuPage } from "../../../rootMenu/rootMenuPage";
import { createQRCode } from "../../../tools/uiHelper";

class GeneralConditionPageView extends AcceptsMouseFocusView implements DS.IPage {
  constructor() {
    super("generalConditionPageView", "generalConditionPageView settingsPages");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("generalCondition.generalCondition_title"),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${require("@assets/images/mentionLegale/mentionLegale_logo.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    const generalConditionPageDesc = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "generalConditionPageDesc settingsPageDesc",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: generalConditionPageDesc,
      className: "pageSubtitle",
      innerText: t("generalCondition.generalCondition_desc"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: generalConditionPageDesc,
      className: "pageText1",
      innerText: t("generic.scanQRCode_text"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: generalConditionPageDesc,
      className: "pageWebsite",
      innerText: t("generalCondition.generalCondition_website"),
    });
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: generalConditionPageDesc,
      className: "pageText1",
      innerText: t("generalCondition.generalCondition_desc2"),
    });

    this.rootElement.appendChild(createQRCode(t("generalCondition.generalCondition_website"), "settingsQRCode"));
  }
}

export class GeneralConditionPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("generalConditionPage", "RootMenuPage", () => {
      return new GeneralConditionPageView();
    });
  }
}
