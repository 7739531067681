/**
 * Convert seconds number to a string, for the player
 * @param seconds The number of seconds
 * @returns A string with a duration
 */
export function convertSecondToPlayerDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secs = Math.floor(seconds - hours * 3600 - minutes * 60);
  let returnFormated = "";

  if (hours < 10) {
    returnFormated += `0${Math.max(0, hours)}:`;
  } else {
    returnFormated += `${Math.max(0, hours)}:`;
  }

  if (minutes < 10) {
    returnFormated += `0${Math.max(0, minutes)}:`;
  } else {
    returnFormated += `${Math.max(0, minutes)}:`;
  }

  if (secs < 10) {
    returnFormated += `0${Math.max(0, secs)}`;
  } else {
    returnFormated += Math.max(0, secs);
  }

  return returnFormated;
}

export function convertSecondToMinutes(seconds: number): number {
  return Math.floor(seconds / 60);
}

/**
 * Format a specific number of seconds to hours:minute
 * @param seconds Seconds to convert
 * @returns A string formatted
 */
export function getFormatTime(seconds: number, hoursOnly = false): string {
  let formatted = "";

  const hours = Math.trunc(seconds / 3600);
  if (hours) {
    formatted = `${hours} ${t("generic.hour_short")} `;
    if (hoursOnly === true) return formatted;
  }
  const minutes = Math.trunc((seconds - hours * 3600) / 60);
  if (minutes) {
    formatted = `${formatted}${hours && minutes < 10 ? "0" : ""}${minutes} ${
      minutes <= 1 ? t("generic.min_short") : t("generic.mins_short")
    }`;
  }

  return formatted;
}

/**
 * Convert a date to a specified format, default is "mmhhh"
 * @param {date} date The date to format
 * @param {DateFormats} format The format
 * @returns A string formatted
 */
type DateFormats =
  | "mm:hh" // 12:02
  | "mmhhh" // 12h02
  | "dd/mm/yyyy" // 12/03/2023
  | "dddd dd mmmm yyyy"; // lundi 12 mars 2023
export function getFormatDate(date: Date, format: DateFormats = "mmhhh"): string {
  switch (format) {
    case "mm:hh":
      return formatTime(date.getHours()) + ":" + formatTime(date.getMinutes());
    case "mmhhh":
      return formatTime(date.getHours()) + t("generic.hour_short") + formatTime(date.getMinutes());
    case "dd/mm/yyyy":
      // Unfortunately the PS4 doesn't support well the fr-FR locale with toLocaleDateString() and swap days and months
      return `${formatTime(date.getDate())}/${formatTime(date.getMonth() + 1)}/${date.getFullYear()}`;
    case "dddd dd mmmm yyyy":
      return `${getLongName(date, "day")} ${date.getDate()} ${getLongName(date, "month")} ${date.getFullYear()}`;
  }
}

/**
 * Get the number of days remaining
 * @param date1 The first date
 * @param date2 The second date
 * @returns THe number of days remaining
 */
export function getRemainingDays(date1: Date, date2: Date): number {
  return (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);
}

/**
 * Get publish date formatted text
 * @param date The publish date
 */
export function getPublishedText(date: Date): string {
  return `${t("generic.publishedWhen")} ${getFormatDate(date, "dd/mm/yyyy")}`;
}

/**
 * Calculte the number of days since today to a date
 * @param date The next date
 * @returns The number of days
 */
export function getAvailableDaysText(date: Date): string {
  const currentDate = new Date();
  const remainingDays = getRemainingDays(date, currentDate);
  if (remainingDays >= 0) {
    if (remainingDays <= 1) return `${t("generic.available_title")} ${getFormatTime(remainingDays * 24 * 3600, true)}`;
    else if (remainingDays <= 7)
      return `${t("generic.available_title")} ${Math.trunc(remainingDays)} ${t("generic.day_short")}`;
    else if (remainingDays <= 365) return `${t("generic.availableUntil_title")} ${getFormatDate(date, "dd/mm/yyyy")}`;
  }

  return "";
}

/**
 * Get the progress percentage
 * @param startTime The date of the start
 * @param duration Duration number
 * @returns A percentage of completion
 */
export function getProgressPercentage(startTime: Date, duration: number): number {
  if (duration <= 0) return 0;
  const now = Date.now();
  const elapsed = (now - startTime.getTime()) / 1000;
  return Math.max(Math.min(Math.round((elapsed / duration) * 100), 100), 0);
}

/**
 * Return number with a leading 0 if it's lower than 10
 * @param {number} time
 * @return {string}
 */
export const formatTime = (time: number): string => {
  if (time < 10) {
    return "0" + time;
  } else {
    return time.toString();
  }
};

/**
 * Return long name from a date, default is based on current date
 * Unfortunately the PS4 doesn't support the "long" option from toLocaleDateString, so it's handled via the translation file
 *
 * @param {date} date
 * @param LongDateType{} type
 * @return {string}
 */
type LongDateType = "day" | "month";
export function getLongName(date = new Date(), type: LongDateType) {
  if (type === "day") return t(`day.${date.getDay()}`);
  else return t(`month.${date.getMonth()}`);
}

/**
 * Return month long name from a date, default is current month
 * Unfortunately the PS4 doesn't support the weekday = "long" option from toLocaleDateString, so it's handled via the translation file
 *
 * @param {date} date
 * @return {string}
 */
export function getMonthName(date = new Date()) {
  return t(`month.${date.getMonth()}`);
}

/**
 * Compute the duration between a given date and now
 * @param date a given date
 */
export const getCurrentTimeRelativeToNow = (date: Date) => {
  return new Date().getTime() - date.getTime();
};

export const hoursToMilliseconds = (hours: number) => {
  return hours * 60 * 60 * 1000;
};

export class StopWatch {
  private _start?: Date = undefined;

  start() {
    this._start = new Date();
  }
  /**
   * Get milliseconds since the last call to start
   */
  get time() {
    return this._start ? new Date().getTime() - this._start.getTime() : 0;
  }
}

/**
 * Check if two dates are on the same day
 * @param d1 First date to compare
 * @param d2 Second date to compare
 * @returns boolean
 */
export function isSameDay(d1: Date, d2: Date): boolean {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

/**
 * Add x number of days to provided date
 * @param date Date
 * @param nbDays Number of days to add to the date
 * @returns Date
 */
export function addDays(date: Date, nbDays: number): Date {
  date.setDate(date.getDate() + nbDays);
  return date;
}

/**
 * Give time in milliseconds from now to given UTC date, to be used with setTimeout
 * @param {Date} date
 * @returns {number}
 */
export function millisecondsUntilDate(date: Date): number {
  return date.getTime() - new Date().getTime();
}
