export type Listener<T> = (newValue: T, oldValue: T) => void;

export interface IReleaseCollector {
  collectRelease(release?: () => void): void;
}

export interface IListenable<T> {
  readonly value: T;
  willChange: (
    listener: Listener<T>,
    collector: IReleaseCollector | null,
    callListenerInitially?: boolean
  ) => () => void;
  didChange: (
    listener: Listener<T>,
    collector: IReleaseCollector | null,
    callListenerInitially?: boolean
  ) => () => void;
}
