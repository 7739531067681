import { IListenable, IReleasable } from ".";

export interface IModelSource<M> extends IReleasable, IListenable<M[]> {
  /**
   * The version of the data in the model. Every time the data is changed, the version is incremented
   * This is used to cancel a list refresh sequence (which is async) of the source version changes in the meantime because it's been set to another value
   * it'll only be defined once data (or absence of it) has been determined
   * ie: its initial value is undefined until the source has been resolved, in this case it'll turn to 1 (and the source can still be empty)
   */
  readonly version$: IListenable<number | undefined>;
  /**
   * function to fetch data from a source
   * @param from - starting index of what we want from the source
   * @param count - how many elements to retrieve. If undefined, consider everything will be returned
   * @returns an array of model
   */
  fetch(from: number, count?: number): Promise<M[]>;

  /**
   * should return true if all items have been fetched
   */
  isComplete$: IListenable<boolean>;
}
