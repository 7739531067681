import "./emptyContentView.scss";

import { ContentView } from "../contentView";

export class EmptyContentView extends ContentView {
  constructor() {
    super("", "emptyContentView");
  }

  rejectsFocus() {
    return true;
  }
}
