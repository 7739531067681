import { DS } from "~/libs";

export interface ITextElement {
  update(content: string): void;
  get(): HTMLElement;
}
interface ITextElementParams {
  rootElement?: HTMLElement | null;
  elementId?: string | null;
  classname?: string | null;
  collector?: DS.IReleaseCollector | null;
  isSpan?: boolean;
  onChange?: (content: string) => Promise<string>;
}

interface IListenableTextElementParams extends ITextElementParams {
  listenable$: DS.Listenable<string | undefined>;
  collector: DS.IReleaseCollector;
}

interface IContentTextElementParams extends ITextElementParams {
  content: string;
}

function convertCustomTags(str: string): string {
  return str
    .replace(/{premium}((?:(?!{premium})[\s\S])*){premium}/g, `<span class='premium'>$1</span>`)
    .replace(/\n/g, "<br/>");
}

class TextElement {
  private _element: HTMLElement;
  private _callback: (content: string) => Promise<string>;

  constructor(params: IListenableTextElementParams | IContentTextElementParams) {
    if (params.isSpan === true) {
      this._element = DS.DOMHelper.createElement({
        tagName: "span",
        parent: params.rootElement ?? undefined,
        id: params.elementId ?? undefined,
        className: params.classname ?? undefined,
      });
    } else {
      this._element = DS.DOMHelper.createElement({
        tagName: "div",
        parent: params.rootElement ?? undefined,
        id: params.elementId ?? undefined,
        className: params.classname ?? undefined,
      });
    }

    if (params.onChange) {
      this._callback = params.onChange;
    } else {
      this._callback = async content => content;
    }

    if ("listenable$" in params) {
      this._setListenableElement(params);
    } else {
      this._setContentElement(params);
    }
  }

  /**
   * @description Init the element with a listenable object
   * @param params The listenable params
   */
  private _setListenableElement(params: IListenableTextElementParams): void {
    params.listenable$.didChange(value => {
      void this.update(value ?? "");
    }, params.collector);
  }

  /**
   * @description Init the element with a content string
   * @param params The content params
   */
  private _setContentElement(params: IContentTextElementParams): void {
    void this.update(params.content);
  }

  /**
   * Update the content of the text element
   * @param content The new string
   */
  async update(content: string) {
    /**
     * This is the only place where we can use innerHTML, since we control it.
     */
    this._element.innerHTML = convertCustomTags(await this._callback(content));
  }

  get(): HTMLElement {
    return this._element;
  }
}

export const TextHelper = {
  /**
   * @description create a class for an HTMLElement text.
   * @param params The text element params. It can be a listenable localized or a string.
   * @returns A new instance of the TextElement class.
   */
  createTextElement: (params: IContentTextElementParams | IListenableTextElementParams): ITextElement => {
    return new TextElement(params);
  },
  createTextEmpty: (rootElement: HTMLElement | null, classname: string | null): ITextElement => {
    return new TextElement({
      rootElement: rootElement,
      classname: classname,
      content: "",
    });
  },

  sanitizeString: (str: string): string => {
    const textNode = document.createTextNode(str);
    return textNode.textContent ?? "";
  },
};

export function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}
