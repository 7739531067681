import "./playerErrorPage.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { Config } from "~/config";
import { GenericPage } from "~/pages/generic/genericPage";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";

import { navigationStack } from "../../main";
import { IPlayer } from "../../tools/player";
import { TextHelper } from "../../tools/textHelper";
import { AccessAuvioPageView } from "../profil/accessAuvio/accessAuvioPage";
import { FAQPage } from "../settings/faq/faqPage";
import { PlayerPage } from "./playerPage";

enum Button {
  faq = "faq",
  portabilite = "portabilite",
}

class ButtonView extends AcceptsMouseFocusView {
  constructor(type: Button) {
    super("", "errorButton");
    TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "button",
      content: t("player.error.buttons." + type),
    });
  }
}

class PlayerErrorPage extends AcceptsMouseFocusView implements DS.IPage {
  constructor(classname: string, title: string, button: Button, player?: IPlayer) {
    super("PlayerErrorPage", classname);

    TextHelper.createTextElement({ rootElement: this.rootElement, classname: "errorMessage", content: title });

    TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "assetTitle",
      content: player?.lastKnownAsset?.resource.embed?.title ?? "",
    });

    const top = TextHelper.createTextEmpty(this.rootElement, "assetTopTitle");
    if (player?.lastKnownAsset?.resource?.embed?.category !== null) {
      void top.update(player?.lastKnownAsset?.resource?.embed?.category?.label ?? "");
    } else if (player.lastKnownAsset.resource?.embed?.program !== null) {
      void top.update(t("player.meta.serie"));
    }

    const bottom = TextHelper.createTextEmpty(this.rootElement, "assetBottomTitle");
    void bottom.update(player?.lastKnownAsset?.resource?.subtitle ?? "");

    this.delegate = DS.createListComponent(
      {
        id: "PlayerErrorPageList",
        className: "playerErrorPageList",
        modelSource$: new DS.ModelSource([button]),
        viewFactory: button => {
          return new ButtonView(button);
        },
        onSelect: () => {
          return this.onSelect();
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        noTransform: true,
        mouseSupport: Config.mouseSupport,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }

  isPopup = () => true;

  onSelect() {
    return false;
  }
}

export class NoAccessPlayerErrorPage extends PlayerErrorPage {
  constructor(player: IPlayer) {
    super("noAccessPlayerErrorPage", t("player.error.noAccess.title"), Button.faq, player);

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "errorSubMessage",
      innerText: t("player.error.noAccess.subtitle"),
    });
  }

  onSelect() {
    navigationStack.pushPage(new FAQPage());
    return true;
  }

  onNav = (key: DS.Keys) => {
    if (key === DS.Keys.back) {
      navigationStack.removePage(this);

      if (navigationStack.pages$.value.length > 1) {
        if (navigationStack.topPage instanceof PlayerPage) {
          navigationStack.removePage(navigationStack.topPage);
        }
        // The player page can be loaded directly by deeplink
      } else {
        if (DevicePreferenceHelper.kidsEnable$.value) navigationStack.pushPage(new GenericPage("/kids"));
        else navigationStack.pushPage(new GenericPage("/home"));
      }
      return true;
    }
    return false;
  };
}

export class VPNPlayerErrorPage extends PlayerErrorPage {
  constructor(player: IPlayer) {
    super("vpnPlayerErrorPage", t("player.error.vpn.title"), Button.faq, player);

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "errorSubMessage",
      innerText: t("player.error.vpn.subtitle"),
    });
  }

  onSelect() {
    navigationStack.pushPage(new FAQPage());
    return true;
  }

  onNav = (key: DS.Keys) => {
    if (key === DS.Keys.back) {
      navigationStack.removePage(this);
      if (navigationStack.topPage instanceof PlayerPage === true) {
        navigationStack.removePage(navigationStack.topPage);
      }
      return true;
    }
    return false;
  };
}

export class NoPortabilityPlayerErrorPage extends PlayerErrorPage {
  constructor(player: IPlayer) {
    super("noPortabilityPlayerErrorPage", t("player.error.noPortability.title"), Button.portabilite, player);
  }

  onSelect() {
    navigationStack.pushPage(new AccessAuvioPageView());
    return true;
  }
}
