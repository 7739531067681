import "./playerProgressBar.scss";

import { DS } from "dslib-tv";

import { FocusTracker } from "~/components/focusTracker";
import { PlayerControls } from "~/components/player/controls/playerControl/playerControl";
import { FastTVLogo } from "~/components/player/fastTV/FastTVLogo";
import { PlayerTitle } from "~/components/player/title/playerTitle";
import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { Config } from "~/config";
import { Listenable } from "~/libs/exports";
import { PlayerPageComponent } from "~/pages/player/playerPage";
import { PlayerState, PlayerStreamType } from "~/tools/player";
import { Toast } from "~/tools/uiHelper";

type TestModelSource =
  | PlayerPageComponent.fastTVLogo
  | PlayerPageComponent.controls
  | PlayerPageComponent.title
  | "buglist"
  | "playerValues";

const testPageModelSource$ = new DS.ModelSource<TestModelSource>(["buglist"]);
const debouncedRefreshScreen = DS.debounce(() => {
  testPageModelSource$.value = [...testPageModelSource$.value];
  Toast("Date values updated");
}, 1000);

/**
 * This file is a quick testing hack for testing progress bars use case. (code quality is really bad and should be improved over time)
 */

export class PageTestPlayerProgressBarView extends DS.View implements DS.IPage {
  protected _playerControls?: PlayerControls;
  protected _list;

  constructor() {
    super("PlayerPage", "pageTestPlayerProgressBarView direct");

    if (window.dotscreen !== undefined && window.dotscreen.fakePlayer === undefined)
      window.dotscreen.fakePlayer = fakePlayer;

    this.delegate = this._list = DS.createListComponent(
      {
        id: "PlayerList",
        className: "",
        modelSource$: testPageModelSource$,
        viewFactory: elem => {
          switch (elem) {
            case PlayerPageComponent.title:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return new PlayerTitle(fakePlayer);
            case PlayerPageComponent.controls:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return (this._playerControls = new PlayerControls(fakePlayer));
            case PlayerPageComponent.fastTVLogo:
              return new FastTVLogo(mockFastLogo);
            case "buglist":
              return new BuglistView(this);
            case "playerValues":
              return new PlayerValuesView();
          }
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        noTransform: true,
      },
      list => {
        void list.setFocusOnId("buglist");
        list.viewsUpdateCompletedTrigger.didSignal(async () => {
          // Prevent the progress bar getting the focus
          await DS.delay(20);
          void list.setFocusOnId("buglist");
        }, this);
      }
    );
  }
}

type Bug = {
  id: string;
  title: string;
};

const bugList$ = new DS.ModelSource<Bug>([
  {
    id: "live",
    title: "Live",
  },
  {
    id: "liveAdRep",
    title: "Live ad replaced",
  },
  {
    id: "fastTV",
    title: "FastTV",
  },
  {
    id: "vod",
    title: "VOD",
  },
  {
    id: "9056",
    title: "AV3-9056 Live timelines superposées",
  },
]);

class BuglistView extends DS.View {
  focusTracker?: FocusTracker;
  parentList;
  list;

  constructor(parentList: PageTestPlayerProgressBarView) {
    super("", "bugListView");
    this.parentList = parentList;
    this.persistency = DS.IViewPersistency.static;

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
    });

    this.delegate = this.list = DS.createListComponent(
      {
        id: "",
        className: "",
        modelSource$: bugList$,
        viewFactory: bug => {
          return new BugCardView(bug);
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: card => {
          switch (card.id) {
            case "live":
              if (fakePlayer.duration$ !== undefined) fakePlayer.duration$.value = Date.now() / 1000 + 5 * 60;
              this.live();
              break;
            case "liveAdRep":
              if (fakePlayer.duration$ !== undefined) fakePlayer.duration$.value = Date.now() / 1000 + 5 * 60;
              this.liveAdRep();
              break;
            case "fastTV":
              this.fastTV();
              break;
            case "vod":
              this.vod();
              break;
            case "9056":
              this.live();
              if (fakePlayer.duration$ !== undefined) fakePlayer.duration$.value = Date.now() / 1000 + 2 * 60 * 60;

              break;
            default:
              Toast("Test non implémenté");
              break;
          }
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "tile");
        this.collectRelease(this.focusTracker.onRelease);
      }
    );
  }

  vod() {
    this.parentList.rootElement.classList.remove("direct");
    testPageModelSource$.value = [PlayerPageComponent.title, "buglist", "playerValues", PlayerPageComponent.controls];
    playerValuesModelSource$.value = ["currentTime", "seektime", "duration"];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fakePlayer = { ...playerVOD };
  }

  live() {
    this.parentList.rootElement.classList.add("direct");
    testPageModelSource$.value = [PlayerPageComponent.title, "buglist", "playerValues", PlayerPageComponent.controls];
    playerValuesModelSource$.value = ["startDate", "currentTime", "seektime", "duration", "endDate"];
    fakePlayer = { ...playerLive };
  }

  liveAdRep() {
    this.parentList.rootElement.classList.add("direct");
    testPageModelSource$.value = [PlayerPageComponent.title, "buglist", "playerValues", PlayerPageComponent.controls];
    playerValuesModelSource$.value = ["startDate", "currentTime", "seektime", "duration", "endDate"];
    fakePlayer = { ...playerLiveAdRep };
  }

  fastTV() {
    this.parentList.rootElement.classList.add("direct");
    testPageModelSource$.value = [
      PlayerPageComponent.fastTVLogo,
      PlayerPageComponent.title,
      "buglist",
      "playerValues",
      PlayerPageComponent.controls,
    ];
    playerValuesModelSource$.value = ["startDate", "currentTime", "endDate"];
    fakePlayer = { ...playerFastTV };
  }
}

class BugCardView extends AcceptsMouseFocusView {
  constructor(bug: Bug) {
    super(bug.id, "tile");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      innerText: bug.title,
    });
  }
}

type PlayerValues = "startDate" | "currentTime" | "seektime" | "duration" | "endDate";

const playerValuesModelSource$ = new DS.ModelSource<PlayerValues>(["currentTime", "duration"]);

class PlayerValuesView extends DS.View {
  constructor() {
    super("", "playerValuesView");

    this.delegate = DS.createListComponent({
      id: "",
      className: "holdMyValue",
      modelSource$: playerValuesModelSource$,
      viewFactory: item => {
        switch (item) {
          case "currentTime":
            return new ValueUpdater({
              label: item,
              listenable$: fakePlayer.currentTime$,
            });
          case "seektime":
            return new ValueUpdater({
              label: item,
              listenable$: fakePlayer.seekTime$,
            });
          case "duration":
            return new ValueUpdater({
              label: item,
              listenable$: fakePlayer.duration$,
            });
          case "startDate":
            return new ValueUpdater({
              label: item,
              date: fakePlayer.asset$.value?.resource.live?.start,
            });
          case "endDate":
            return new ValueUpdater({
              label: item,
              date: fakePlayer.asset$.value?.resource.live?.end,
            });
        }
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
    });
  }
}

class ValueUpdater extends AcceptsMouseFocusView {
  listenable$;
  date: Date | undefined;
  label: PlayerValues;
  _formatedValue;
  _rawValue;

  constructor(
    options:
      | {
          label: PlayerValues;
          listenable$: DS.Listenable<any>;
          type?: "date" | "number" | "timestamp";
        }
      | {
          label: PlayerValues;
          date: Date | undefined;
        }
  ) {
    super("", "valueUpdater");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      innerText: options.label,
    });
    this._formatedValue = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
    });
    this._rawValue = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
    });

    this.label = options.label;
    if ("listenable$" in options) {
      this.listenable$ = options.listenable$;
      this.listenable$?.didChange(
        (value: any) => {
          if (typeof value === "number") {
            this._rawValue.innerText = value?.toString();
            if (value > 10000000) {
              const date = new Date(value * 1000);
              this._formatedValue.innerText = date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s";
            }
          } else {
            this._rawValue.innerText = "";
            this._formatedValue.innerText = "";
          }
        },
        this,
        true
      );
    } else if ("date" in options && options.date !== undefined) {
      this.date = options.date;
      this.dateContent(this.date);
    }
  }

  dateContent(date: Date) {
    this._rawValue.innerText = date?.getTime().toString();
    this._formatedValue.innerText = date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s";
  }

  seekTime(direction: "rewind" | "forward") {
    const value = fakePlayer.seekTime$.value ?? fakePlayer.currentTime$.value;
    fakePlayer.seekTime$.value = value + 15 * (direction === "rewind" ? -1 : 1);
  }

  onNav = (key: DS.Keys) => {
    switch (key) {
      case DS.Keys.left:
        if (this.date !== undefined) {
          this.date = new Date(this.date.getTime() - 60 * 1000);
          const asset = fakePlayer.asset$.value;
          if (this.label === "startDate") asset.resource.live.start = this.date;
          if (this.label === "endDate") asset.resource.live.end = this.date;
          fakePlayer.asset$.value = asset;
          this.dateContent(this.date);
          debouncedRefreshScreen();
        } else if (this.label === "seektime") {
          this.seekTime("rewind");
        } else if (this.listenable$ !== undefined) {
          this.listenable$.value = this.listenable$.value - 10;
        }
        break;
      case DS.Keys.right:
        if (this.date !== undefined) {
          this.date = new Date(this.date.getTime() + 60 * 1000);
          const asset = fakePlayer.asset$.value;
          if (this.label === "startDate") asset.resource.live.start = this.date;
          if (this.label === "endDate") asset.resource.live.end = this.date;
          fakePlayer.asset$.value = asset;
          this.dateContent(this.date);
          debouncedRefreshScreen();
        } else if (this.label === "seektime") {
          this.seekTime("forward");
        } else if (this.listenable$ !== undefined) {
          this.listenable$.value = this.listenable$.value + 10;
        }
        break;
      case DS.Keys.select:
        if (this.label === "seektime" && fakePlayer.seekTime$.value !== undefined) {
          fakePlayer.currentTime$.value = fakePlayer.seekTime$.value;
          fakePlayer.seekTime$.value = undefined;
          return true;
        }
        break;
      case DS.Keys.back:
        if (this.label === "seektime" && fakePlayer.seekTime$.value !== undefined) {
          fakePlayer.seekTime$.value = undefined;
          return true;
        }
        break;
    }

    return false;
  };
}

const mockFastLogo = {
  url: "https://ds.static.uat.rtbf.be/image/epg/channel/logo/custom/8/4/d/84d2174d3bcddb2a3a3fe0df8d54b23f.png",
  width: 173,
  height: 86,
  position: {
    x: 1723,
    y: 32,
  },
};

const playerLive = {
  type$: new Listenable(PlayerStreamType.VIDEO_LIVE),
  state$: new Listenable(PlayerState.PLAYING),
  asset$: new Listenable({
    resource: {
      title: "Debug live",
      live: {
        start: new Date(Date.now() - 1 * 64 * 60 * 1000),
        end: new Date(Date.now() + 1 * 12 * 60 * 1000),
      },
    },
  }),
  duration$: new DS.Listenable(Date.now() / 1000 + 5 * 60),
  currentTime$: new DS.Listenable(Date.now() / 1000),
  seekTime$: new DS.Listenable<number | undefined>(undefined),
};

const playerLiveAdRep = {
  type$: new Listenable(PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT),
  state$: new Listenable(PlayerState.PLAYING),
  asset$: new Listenable({
    resource: {
      title: "Debug live ad replaced",
      live: {
        start: new Date(Date.now() - 1 * 64 * 60 * 1000),
        end: new Date(Date.now() + 1 * 12 * 60 * 1000),
      },
    },
  }),
  duration$: new DS.Listenable(Date.now() / 1000 + 5 * 60),
  currentTime$: new DS.Listenable(Date.now() / 1000),
  seekTime$: new DS.Listenable<number | undefined>(undefined),
};

const playerFastTV = {
  type$: new Listenable(PlayerStreamType.VIDEO_FAST_TV),
  state$: new Listenable(PlayerState.PLAYING),
  asset$: new Listenable({
    resource: {
      title: "Debug FastTV",
      live: {
        start: new Date(Date.now() - 1 * 64 * 60 * 1000),
        end: new Date(Date.now() + 1 * 12 * 60 * 1000),
      },
    },
  }),
  duration$: new DS.Listenable(Date.now() / 1000),
  currentTime$: new DS.Listenable(Date.now() / 1000),
  seekTime$: new DS.Listenable<number | undefined>(undefined),
};

const playerVOD = {
  type$: new Listenable(PlayerStreamType.VIDEO),
  state$: new Listenable(PlayerState.PLAYING),
  asset$: new Listenable({
    resource: {
      title: "Debug VOD",
    },
    isPremium: () => false,
  }),
  duration$: new DS.Listenable(6000),
  currentTime$: new DS.Listenable(300),
  seekTime$: new DS.Listenable<number | undefined>(undefined),
};

let fakePlayer = { ...playerLive };
