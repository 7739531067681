import "./mosaicContentView.scss";

import { Config } from "~/config";
import { APIAuvio } from "~/datas/api/apiAuvio";

import { DS } from "../../../libs";
import { FilterCategoryButton } from "../../../pages/filter/filterButtonView";
import { PageMosaicContent, Widget } from "../../../utils/rtbf/models";
import { ContentTitleView } from "../../views/content/contentTitleView/contentTitleView";
import { FilterPeriodWidgetView } from "../../widgets/filter/filterPeriodWidgetView";
import { ContentView } from "../contentView";

enum MosaicContentComponent {
  title = "title",
  categoryFilter = "categoryFilter",
  periodFilter = "periodFilter",
}
export class MosaicContentView extends ContentView {
  private _list;
  private _modelSource$ = new DS.ModelSource<MosaicContentComponent>([]);
  private _selectedCategory$: DS.Listenable<string> = new DS.Listenable("");
  private _selectedPeriod$: DS.Listenable<string> = new DS.Listenable("");
  private _widget: Widget | undefined;
  private _contentPathWidgetMosaic: string;
  private _displayFilteredWidget: (results: Widget) => void;

  constructor(
    content: PageMosaicContent,
    widgetMosaic: Widget | undefined,
    displayFilteredWidget: (results: Widget) => void
  ) {
    super(content.id, "mosaicContentView");
    this.persistency = DS.IViewPersistency.static;
    this._displayFilteredWidget = displayFilteredWidget;

    this._widget = widgetMosaic;
    this._contentPathWidgetMosaic = widgetMosaic?.contentPath ?? "";

    this._modelSource$.value = this._modelSource$.value.concat([MosaicContentComponent.title]);
    if (content.filters !== undefined) {
      if (content.filters.categories !== undefined) {
        this._selectedCategory$ = new DS.Listenable(content.filters.categories[0].id);
        this._modelSource$.value = this._modelSource$.value.concat([MosaicContentComponent.categoryFilter]);
      }
      if (content.filters.periods !== undefined) {
        this._selectedPeriod$ = new DS.Listenable(content.filters.periods[0].key);
        this._modelSource$.value = this._modelSource$.value.concat([MosaicContentComponent.periodFilter]);
      }
    }

    this.delegate = this._list = DS.createListComponent({
      id: "mosaicContentList",
      className: `mosaicContentList ${
        this._modelSource$.value.includes(MosaicContentComponent.periodFilter) ? "periodFilter" : ""
      }`,
      modelSource$: this._modelSource$,
      viewFactory: item => {
        switch (item) {
          case MosaicContentComponent.title:
            return new ContentTitleView(content.title);
          case MosaicContentComponent.categoryFilter:
            return new FilterCategoryButton(content.filters?.categories, this._selectedCategory$);
          case MosaicContentComponent.periodFilter:
            return new FilterPeriodWidgetView(content.filters?.periods, this._selectedPeriod$);
        }
      },
      mouseSupport: Config.mouseSupport && {
        wheelScrollBy: 0,
        focusRange: "visible",
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
      onSelect: item => {
        const view = this._list.viewFromId(item);
        if (view instanceof FilterCategoryButton) view.onSelect();
        return true;
      },
    });

    this._selectedCategory$.didChange(async () => {
      void this._displayFiltered();
    }, this);
    this._selectedPeriod$.didChange(async () => {
      void this._displayFiltered();
    }, this);
  }

  private async _displayFiltered() {
    if (this._widget === undefined) return;
    const widget = this._widget;
    widget.contentPath = await this._getUrlFilteredMosaic(
      this._contentPathWidgetMosaic,
      this._selectedCategory$.value,
      this._selectedPeriod$.value
    );
    this._displayFilteredWidget(widget);
  }

  private _getUrlFilteredMosaic(contentPath: string, categoryid: string, period: string) {
    const categoryQuery = `&categoryId=${categoryid}`;
    const periodQuery = `&period=${period}`;
    return `${APIAuvio.addUserAgentToUrl(contentPath)}${categoryQuery}${periodQuery}`;
  }

  rejectsFocus() {
    if (
      this._modelSource$.value.includes(MosaicContentComponent.categoryFilter) ||
      this._modelSource$.value.includes(MosaicContentComponent.periodFilter)
    )
      return false;
    else return true;
  }
}
