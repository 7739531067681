import "./csaRatingView.scss";

import { DS } from "~/libs";

export class CsaRatingView extends DS.View {
  constructor(csaRating: string, parent: HTMLElement) {
    super("", "csaRatingView");
    let csaRatingLogo = "";
    try {
      csaRatingLogo = require("@assets/images/csa/csa" + csaRating + ".png");
    } catch (e: unknown) {
      Log.app.error("Can't find CSA !");
    }
    DS.DOMHelper.createElement({
      tagName: "img",
      parent: parent,
      className: "csaRating",
    }).src = csaRatingLogo;
  }
}
