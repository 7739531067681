import { View } from "../../ui/components/view";
import * as DOMHelper from "../../ui/helpers/DOMHelper";
import { Listenable } from "../../ui/helpers/Listenable";
import { IPage, Keys } from "../../ui/typings";
import { logNavigationStack } from "../logNavigationStack";

DOMHelper.addStyleSheet(`
#CodePopup {
    z-index: 1010;
    position: absolute;
    width: 600px;
    height: 260px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 5px;
    font-family: sans-serif;
    border: solid #222;
    background-color: rgba(51,51,51, 0.9);
    box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.3);
    color: rgb(45,45,45);
}

#CodePopup #title {
    font-size: 24px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgb(252, 252, 252);
}

#CodePopup #digitsHolder {
    position: absolute;
    left: 50%;
    height: 194px;
    width: 530px;
    background-color: rgba(0,0,0,0.4);
    transform: translate(-50%,0%);
    border-radius: 10px;
}

#CodePopup #digitsHolder .digit {
    width: 118px;
    height: 180px;
    background-color: white;
    border: 4px solid #222;
    float: left;
    border-radius: 10px;
    margin: 3px;
    font-size: 156px;
    text-align: center;
}

#CodePopup #digitsHolder .digit.selected {
    border-color: darkred;
}
`);

const DIGITS = 4;
export class CodePopup extends View implements IPage {
  digitElements: HTMLElement[] = [];
  code$ = new Listenable<string>("");
  callback: (code?: string) => void;

  constructor(title: string, callback: (code?: string) => void) {
    super("CodePopup", "");
    this.callback = callback;

    DOMHelper.createElement({ tagName: "div", parent: this.rootElement, id: "title", innerText: title });
    const digitsHolder = DOMHelper.createElement({ tagName: "div", parent: this.rootElement, id: "digitsHolder" });
    for (let index = 0; index < DIGITS; index++) {
      this.digitElements[index] = DOMHelper.createElement({
        tagName: "span",
        parent: digitsHolder,
        className: "digit",
      });
    }
    this.digitElements[0].classList.add("selected");

    // UI update loop
    this.code$.didChange(code => {
      for (let index = 0; index < DIGITS; index++) {
        this.digitElements[index].innerText = code[index] ?? "";
        this.digitElements[index].classList.remove("selected");
      }
      this.digitElements[code.length < DIGITS ? code.length : DIGITS - 1].classList.add("selected");
    }, this);
  }

  isPopup = () => true;

  onNav = (key: Keys) => {
    if ("0123456789".includes(key)) {
      this.code$.value += key;
      if (this.code$.value.length === DIGITS) {
        this.callback(this.code$.value);
        logNavigationStack?.removePage(this);
      }
      return true;
    }
    if (key === Keys.back && this.code$.value.length > 0) {
      this.code$.value = this.code$.value.substr(0, this.code$.value.length - 1);
      return true;
    }
    return false;
  };
}
