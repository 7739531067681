import "./numericKeyboardPopupPage.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "../../components/views/common/mouseSupport/acceptsMouseFocusView";
import { NumericKeyboardKey, NumericKeyboardView } from "../../components/views/keyboard/numericKeyboardView";

export class NumericKeyboardPopupPage extends AcceptsMouseFocusView implements DS.IPage {
  private _onKeyBack: () => boolean;
  constructor(onKeyBack: () => boolean, onSelectKey: (key: NumericKeyboardKey) => boolean) {
    super("NumericKeyboardPopupPage", "numericKeyboardPopupPage");
    this._onKeyBack = onKeyBack;
    this.delegate = new NumericKeyboardView(onSelectKey);
  }

  isPopup() {
    return true;
  }

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.back:
        return this._onKeyBack();
    }
    return false;
  }
}
