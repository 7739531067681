import "./onBoardingPage.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { Config } from "~/config";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";

import { navigationStack } from "../../main";
import { RootMenuView } from "../../rootMenu/rootMenuView";
import { DevicePreferenceHelper } from "../../tools/devicePreferencesManager";
import { IActionButton } from "../../tools/interfaces";
import { SettingsHelper } from "../../tools/settingsHelper";
import { GenericPage } from "../generic/genericPage";
import { VerifyPinPopupPage } from "../parentalControl/parentalPopupPage";
import { PopupButton, PopupSendPinPage } from "../popup/popupPage";

enum OnBoardingContentSection {
  top = "top",
  bottom = "bottom",
}

enum OnBoardingContentType {
  consentCheckBox = "contentCheckBox",
  validateChoice = "validateChoice",
  pinReset = "pinReset",
  accessSpace = "accessSpace",
}

enum OnBoardingContent {
  pin = "pin",
  access = "access",
}

/**
 * Open kids space according to the device preference and account settings
 */
export const openKids = () => {
  if (DevicePreferenceHelper.kidsEnable$.value === true) {
    // we are already in the kids space, no need to check secured and onboarding
    navigationStack.pushPage(new GenericPage("/kids"));
    return;
  }
  if (
    !APIGigyaOIDC.isConnected() ||
    (DevicePreferenceHelper.hasOnBoardingBeenShowned() && DevicePreferenceHelper.hasSecuredOnBoardingBeenShowned())
  ) {
    OnboardingPageView.goToKidsAction();
    //DevicePreferenceHelper.switchKids(true);
  } else {
    if (!OnboardingPageView.layoutControlBlockOn) {
      if (DevicePreferenceHelper.isUserKidsSecured() && !DevicePreferenceHelper.hasSecuredOnBoardingBeenShowned()) {
        OnboardingPageView.showOnBoardingSecuredPage();
      } else {
        OnboardingPageView.showOnBoardingNotSecuredPage();
      }
    } else {
      OnboardingPageView.layoutControlBlockOn = false;
    }
  }
};

export class OnboardingPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _list: DS.IListComponent<OnBoardingContentSection, OnBoardingTopSectionView | OnBoardingBottomSectionView>;
  static securityEnable$ = new DS.Listenable<boolean>(true);
  static layoutControlBlockOn = false;
  constructor(
    contentType: OnBoardingContent,
    params: {
      title: string;
      descriptionP1: string;
      descriptionP2: string;
      descriptionP3?: string;
      button1?: IActionButton;
      button2?: IActionButton;
    }
  ) {
    super("onBoardingPageView", "onBoardingPageView");

    //image
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "onBoardingImg",
    });

    // title
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "onBoardingTitle",
      innerText: params.title,
    });

    // description
    const descContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "onBoardingDescContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: descContainer,
      className: "onBoardingDesc1",
      innerText: params.descriptionP1,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: descContainer,
      className: "onBoardingDesc2",
      innerText: params.descriptionP2,
    });
    params.descriptionP3 !== undefined &&
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: descContainer,
        className: "onBoardingDesc3",
        innerText: params.descriptionP3,
      });

    const buttons: IActionButton[] = [];
    params.button1 && buttons.push(params.button1);
    params.button2 && buttons.push(params.button2);

    this.delegate = this._list = DS.createListComponent(
      {
        id: "onBoardingContainer/list",
        className: "onBoardingContainerList",
        modelSource$: new DS.ModelSource([OnBoardingContentSection.top, OnBoardingContentSection.bottom]),
        viewFactory: section => {
          if (section === OnBoardingContentSection.top) {
            return contentType === OnBoardingContent.pin
              ? new OnBoardingTopSectionView(OnBoardingContentType.pinReset)
              : new OnBoardingTopSectionView(OnBoardingContentType.consentCheckBox);
          } else {
            return new OnBoardingBottomSectionView(buttons);
          }
        },
        onSelect: button => {
          if (button === OnBoardingContentSection.top) {
            const topView = this._list.viewFromIndex(0);

            if (topView instanceof OnBoardingTopSectionView) {
              void topView.onSelect();
            }
          }
          return true;
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        mouseSupport: Config.mouseSupport,
      },
      list => {
        void list.setFocusOnIndex(1);
      }
    );
  }

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.back:
        RootMenuView.instance.refreshHome();
        return true;
    }
    return false;
  }

  isPopup = () => true;

  static goToKidsAction() {
    const kidsPath = SettingsHelper.instance.data?.["menu-kids"].primary[0].path;
    if (kidsPath !== undefined) {
      navigationStack.destroyStack();
      navigationStack.pushPage(new GenericPage(kidsPath));
      DevicePreferenceHelper.switchKids(true);
    }
  }

  static showOnBoardingNotSecuredPage = () => {
    navigationStack.pushPage(
      new OnboardingPageView(OnBoardingContent.access, {
        title: t("onBoarding.title"),
        descriptionP1: t("onBoarding.description_page1_p1"),
        descriptionP2: t("onBoarding.description_page1_p2"),
        descriptionP3: t("onBoarding.description_page1_p3"),
        button1: {
          label: t("onBoarding.not_now"),
          action: () => {
            this.goToKidsAction();
          },
        },
        button2: {
          label: t("onBoarding.validate_choice"),
          action: () => {
            if (this.securityEnable$.value) {
              if (APIGigyaOIDC.userInfo$.value?.data?.pin !== undefined) {
                navigationStack.pushPage(new VerifyPinPopupPage());
              } else {
                this._showMailPinPopup();
              }
            } else {
              this.layoutControlBlockOn = true;
              DevicePreferenceHelper.enableKidsSecurity({
                onBoardingShown: true,
                isSecured: false,
                securedPageShown: true,
              });
              this.goToKidsAction();
            }
          },
        },
      })
    );
  };

  static showOnBoardingSecuredPage = () => {
    navigationStack.pushPage(
      new OnboardingPageView(OnBoardingContent.pin, {
        title: t("onBoarding.title"),
        descriptionP1: t("onBoarding.description_page2_p1"),
        descriptionP2: t("onBoarding.description_page2_p2"),
        button1: {
          label: t("onBoarding.access_kids"),
          action: () => {
            DevicePreferenceHelper.enableKidsSecurity({
              onBoardingShown: true,
              isSecured: true,
              securedPageShown: true,
            });
            this.goToKidsAction();
          },
        },
      })
    );
  };

  private static _showMailPinPopup() {
    navigationStack.pushPage(
      new PopupSendPinPage({
        title: t("sendPin.title"),
        description1: t("sendPin.desc1"),
        description2: t("sendPin.desc2"),
        button1: {
          label: t("sendPin.receivePin"),
          action: () => void this.mailPinCode(),
        },
      })
    );
  }

  static async mailPinCode() {
    const result = await APIAuvio.resetPinCode();
    if (result) {
      DevicePreferenceHelper.enableKidsSecurity({ onBoardingShown: true, isSecured: true, securedPageShown: true });
      this.goToKidsAction();
    }
  }

  static OnboardingNavigate() {
    if (DevicePreferenceHelper.isUserKidsSecured() && !DevicePreferenceHelper.hasSecuredOnBoardingBeenShowned()) {
      OnboardingPageView.showOnBoardingSecuredPage();
    } else if (DevicePreferenceHelper.isUserKidsSecured() && DevicePreferenceHelper.hasOnBoardingBeenShowned()) {
      OnboardingPageView.goToKidsAction();
    } else {
      OnboardingPageView.showOnBoardingNotSecuredPage();
    }
  }
}

class OnBoardingTopSectionView extends AcceptsMouseFocusView {
  private _checked$ = OnboardingPageView.securityEnable$;
  private _selected?: HTMLDivElement;
  private _content?: OnBoardingContentType;
  constructor(content: OnBoardingContentType) {
    super(content, "onBoardingTopSectionView");
    this._content = content;
    if (content === OnBoardingContentType.consentCheckBox) {
      const checkbox = DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "checkbox",
      });
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "checkboxLabel",
        innerText: t("onBoarding.secure_kids"),
      });
      this._selected = DS.DOMHelper.createElement({
        tagName: "div",
        parent: checkbox,
        className: "selectedIcon",
        style: {
          backgroundImage: `url(${require("@assets/images/icons/selected.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
      !this._checked$.value && this.unselect();
    } else if (content === OnBoardingContentType.pinReset) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "forgottenPinLabel",
        innerText: t("onBoarding.forgotten_pin"),
      });
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "forgottenPinLink",
        innerText: t("onBoarding.receive_pin"),
      });
    }
  }

  async onSelect() {
    if (this._content === OnBoardingContentType.consentCheckBox) {
      this._checked$.value ? this.unselect() : this.select();
      this._checked$.value = !this._checked$.value;
    } else {
      const result = await APIAuvio.resetPinCode();
      if (result) {
        OnboardingPageView.goToKidsAction();
      }
    }
  }

  select() {
    if (this._selected !== undefined) this._selected.style.visibility = "visible";
  }

  unselect() {
    if (this._selected !== undefined) this._selected.style.visibility = "hidden";
  }
}

class OnBoardingBottomSectionView extends AcceptsMouseFocusView {
  constructor(buttons?: IActionButton[]) {
    super("onBoardingBottomSectionView", "onBoardingBottomSectionView");
    if (buttons !== undefined)
      this.delegate = DS.createListComponent(
        {
          id: "onBoarding-button-container",
          className: "onBoarding-button-container",
          modelSource$: new DS.ModelSource(buttons.map((button, index) => ({ ...button, id: index }))),
          viewFactory: button => {
            return new PopupButton(button);
          },
          onSelect: button => {
            navigationStack.removePage(this);
            button.action?.();
            return true;
          },
          scrollingMode: { type: DS.ScrollingType.none, horizontal: true },
          mouseSupport: Config.mouseSupport,
        },
        list => {
          if (list.visibleRange$.value.last !== undefined) void list.setFocusOnIndex(list.visibleRange$.value.last);
        }
      );
  }
}
