import { Listenable } from "../../ui/helpers/Listenable";
import { ILogStats, StatsPane } from "../types";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StatsModule = require("stats.js") as any;

// make stats work at least for DOM counts in case browser is really old
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).performance = (window as any).performance ?? {
  now: function () {
    return 0;
  },
};

window.requestAnimationFrame = window.requestAnimationFrame ?? function () {};

export class LogStatsImpl implements ILogStats {
  _mode$ = new Listenable<StatsPane>("Off");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _stats: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _domPanel: any;
  _intervalDOMUpdate?: number;

  constructor() {
    this._mode$.didChange(
      mode => {
        window.clearInterval(this._intervalDOMUpdate);
        switch (mode) {
          case "Off":
            this.showStats(false);
            break;

          case "DOM Count":
            {
              this.showStats(true);
              const that = this;
              this._intervalDOMUpdate = window.setInterval(function func() {
                // Only update DOM node graph when we have time to spare to call
                // numDomNodes(), which is a fairly expensive function.
                that._domPanel?.update(document.getElementsByTagName("*").length, 1500);
              }, 1000);

              const statsDom = this._stats.dom as HTMLElement;
              this._stats.showPanel(statsDom.childElementCount - 1);
            }
            break;

          case "FPS":
            this.showStats(true);
            this._stats.showPanel(0);
            break;

          case "Memory":
            this.showStats(true);
            this._stats.showPanel(2);
            break;
        }
      },
      null,
      true
    );
  }

  get mode$() {
    return this._mode$;
  }

  setMode(mode: StatsPane) {
    this._mode$.value = mode;
  }

  showStats = (show: boolean) => {
    if (show) {
      if (this._stats === undefined) {
        this._stats = new StatsModule();
        this._domPanel = new StatsModule.Panel("", "#ff0", "#002");
        this._stats.addPanel(this._domPanel);
        document.body.appendChild(this._stats.dom);

        // regular FPS/memory update loop
        const that = this;
        requestAnimationFrame(function loop() {
          if (that._stats !== undefined) {
            that._stats.update();
            requestAnimationFrame(loop);
          }
        });
      }
    } else {
      if (this._stats !== undefined) {
        try {
          (this._stats.dom as HTMLElement).parentElement?.removeChild(this._stats.dom);
        } catch (error) {
          //
        }
        this._stats = undefined;
        this._domPanel = undefined;
      }
    }
  };
}

export const LogStats = new LogStatsImpl();
