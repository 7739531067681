import { IListenable, IPage } from ".";

export interface INavigationStackParams {
  /** the DOM element this navigation stack will use as its root.
   * All pages pushed to the stack will be a child of this root Element
   */
  rootElement: HTMLElement;
  /** min internal between keypad events
   * @default 0 (no throttle)
   */
  keyThrottle?: number;
  /** min internal between mouse wheel events
   * @default 0 (no throttle)
   */
  wheelThrottle?: number;
  /** this is called when the {@link Keys.back} is pressed, and we're on the "top page"
   * on LG or Samsungs, this is supposed to show a popup that asks a user if he wants to quit the app.
   * It's up to the app to implement this popup & the logic to quit the app depending on the platforms
   */
  onExit?: () => void;
}

/**
 * The interface of a navigation stack to be used by the app
 */
export interface INavigationStack {
  /** the page at the top of the stack
   */
  readonly topPage: IPage | undefined;
  readonly pages$: IListenable<IPage[]>;
  pushPage(page: IPage): void;
  removePage(page?: IPage): void;
  destroyStack(): void;
  keyHandler(key: string): void;
}
