import "./mouseSupportArrow.scss";

import { DS } from "dslib-tv";

import { Config } from "~/config";

import { AcceptsMouseFocusView } from "./acceptsMouseFocusView";

const _defaultMouseSupportArrowHoriParams =
  Config.mouseSupport &&
  ({
    focusRange: "visible" as const,
    arrows: {
      scrollMethod: {
        onHover: {
          delay: 400,
          scrollBy: 100,
          interval: 100,
        },
      },
      factory: (position: "prev" | "next") => {
        return new MouseSupportArrowView("hori", position);
      },
    },
  } as const);

export const mouseSupportArrowHoriParams = () => _defaultMouseSupportArrowHoriParams;

class MouseSupportArrowView extends AcceptsMouseFocusView {
  constructor(direction: "hori" | "vert", position: "prev" | "next") {
    super("", "mouseSupportArrow" + " " + direction + " " + position);
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "arrowImg",
    });
  }
}
