import "./widgetView.scss";

import { DS } from "~/libs";

import { TrackingHelper } from "../../tools/trackingHelper";
import {
  CategoryCard,
  ChannelCard,
  CtaCard,
  EmptyCard,
  FavoriteCard,
  GuestCard,
  MediaCard,
  MediaPremiumCard,
  PlayHistoryCard,
  ProductCard,
  ProgramCard,
  PromoBoxCard,
  QuickLinkCard,
  RadioLiveCard,
  TabListCard,
  TrackingData,
  TvLiveCard,
  Widget,
  WidgetBanner,
  WidgetMediaTrailer,
} from "../../utils/rtbf/models";
import { AcceptsMouseFocusView } from "../views/common/mouseSupport/acceptsMouseFocusView";

/**
 * All widget views should extends from this view.
 * This allow the GenericPage to know each items of
 * every widgets.
 */
export abstract class WidgetView extends AcceptsMouseFocusView {
  modelSource!: DS.IModelSource<
    | ProgramCard
    | RadioLiveCard
    | ProductCard
    | MediaCard
    | MediaPremiumCard
    | TvLiveCard
    | CategoryCard
    | ChannelCard
    | WidgetBanner
    | WidgetMediaTrailer
    | PromoBoxCard
    | TabListCard
    | QuickLinkCard
    | Widget
    | Widget[]
    | FavoriteCard
    | PlayHistoryCard
    | CtaCard
    | GuestCard
    | EmptyCard
    | MediaPremiumCard
  >;
  id: string;
  private _gtag: Partial<TrackingData>;

  constructor(id: string, classname: string) {
    super(id, "widget " + classname);
    this.id = id;
    this._gtag = {
      widget_id: id,
      widget_title: "",
      widget_type: undefined,
      widget_order: 0,
    };
  }

  protected _addSubtitleIfAvailable(widget: Widget) {
    if (widget.subtitle !== null && widget.subtitle !== "") {
      this.rootElement.classList.add("subtitle");
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "widgetSubtitle",
        innerText: widget.subtitle,
      });
    }
  }

  /**
   * Set analytics info for GTAG
   * @param widget The widget
   */
  protected _setAnalytics(widget: Widget) {
    this._gtag = {
      widget_id: widget.id,
      widget_title: widget.title,
      widget_order: widget.index,
      widget_type: widget.type,
    };
  }

  protected _updateAnalytics(gtag: Partial<TrackingData>) {
    this._gtag = {
      ...this._gtag,
      ...gtag,
    };
  }

  protected _analyticsOnLeft() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "previous",
      },
    });
  }

  protected _analyticsOnRight() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "next",
      },
    });
  }

  protected _analyticsOnSelect() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "click",
        area: "frame",
      },
    });
  }

  protected _analyticsOnUpDown() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "scroll",
      },
    });
  }

  protected _analyticsCards(gtag: Partial<TrackingData>) {
    TrackingHelper.track({
      event_name: TrackingHelper.pageType === "EXPLORER" ? "search_select" : "card_interaction",
      gtag: {
        ...this._gtag,
        action: "click",
        area: "frame",
        ...gtag,
      },
    });
  }

  protected _analyticsTrailer() {
    TrackingHelper.track({
      event_name: "widget_trailer_action",
      gtag: {
        ...this._gtag,
        action: "call_to_action",
        area: "button",
      },
    });
  }

  onShown() {
    TrackingHelper.track({
      event_name: "widget_shown",
      gtag: {
        ...this._gtag,
        action: "view",
      },
    });
  }

  onNav(key: DS.Keys) {
    switch (key) {
      case DS.Keys.down:
      case DS.Keys.up:
        this._analyticsOnUpDown();
        break;
      case DS.Keys.select:
        this._analyticsOnSelect();
        break;
      case DS.Keys.left:
        this._analyticsOnLeft();
        break;
      case DS.Keys.right:
        this._analyticsOnRight();
        break;
    }
    return false;
  }
}
