/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from "zod";

import {
  CategoryCard,
  ChannelCard,
  Image,
  Logo,
  MediaCard,
  MediaType,
  ProductCard,
  ProgramCard,
  Rating,
  TvLiveCard,
} from ".";
import { AIP, Casting, Chartbeat, Gemius, Response, SEO, SmartAds, SocialNetworks, Tag } from "./base";
const isISODate = require("is-iso-date");

export const EmbedType = z.enum(["LIVE", "MEDIA"]);
export type EmbedType = z.infer<typeof EmbedType>;

export const EmbedMeta = z.object({
  aip: AIP.nullish().transform(arg => arg ?? undefined),
  seo: SEO.nullish().transform(arg => arg ?? undefined),
  smartAds: SmartAds.nullish().transform(arg => arg ?? undefined),
  gemius: Gemius.nullish().transform(arg => arg ?? undefined),
  chartbeat: Chartbeat.nullish().transform(arg => arg ?? undefined),
  socialNetworks: SocialNetworks.optional(),
  shareUrl: z.string().optional(),
  adsWizz: z.unknown(),
});
export type EmbedMeta = z.infer<typeof EmbedMeta>;

export const Geoloc = z.object({
  id: z.number(),
  key: z.string(),
  label: z.string(),
});
export type Geoloc = z.infer<typeof Geoloc>;

export const EmbedMedia = z.object({
  id: z.string(),
  type: MediaType,
  playerType: z.literal("MEDIA"),
  title: z.string(),
  subtitle: z.string(),
  description: z.string(),
  shortDescription: z.string(),
  publishedFrom: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).optional(),
  publishedTo: z.string().refine(isISODate, { message: "Not a valid ISO string date " }).optional(),
  duration: z.number(),
  rating: Rating.nullish().transform(arg => arg ?? undefined),
  isPremium: z.boolean(),
  hasAudioDescriptions: z.boolean(),
  hasMultilingualVersions: z.boolean(),
  hasSubtitles: z.boolean(),
  background: Image.nullish(),
  logo: Logo.nullish().transform(arg => (arg ? arg : undefined)),
  channel: ChannelCard.nullish(),
  category: CategoryCard.nullish(),
  program: ProgramCard.nullable(),
  tags: z.array(Tag).nullable(),
  casting: z.array(Casting).nullable(),
  products: z.array(ProductCard),
  previous: MediaCard.nullish().transform(arg => arg ?? undefined),
  next: MediaCard.nullish().transform(arg => arg ?? undefined),
  geoloc: Geoloc,
  assetId: z.string(),
  streamId: z.string().nullish(),
});
export type EmbedMedia = z.infer<typeof EmbedMedia>;

export const EmbedLive = z.object({
  id: z.string(),
  type: MediaType,
  playerType: z.literal("LIVE"),
  title: z.string(),
  subtitle: z.string(),
  description: z.string(),
  shortDescription: z.string(),
  scheduledFrom: z.string().refine(isISODate, { message: "Not a valid ISO string date " }),
  scheduledTo: z.string().refine(isISODate, { message: "Not a valid ISO string date " }),
  duration: z.number(),
  rating: Rating.nullish().transform(arg => arg ?? undefined),
  hasAudioDescriptions: z.boolean(),
  hasMultilingualVersions: z.boolean(),
  hasSubtitles: z.boolean(),
  background: Image.nullable(),
  logo: Logo.nullish().transform(arg => (arg ? arg : undefined)),
  channel: ChannelCard.nullish(),
  category: CategoryCard.nullable(),
  program: ProgramCard.nullable(),
  tags: z.array(Tag).nullable(),
  casting: z.array(Casting).nullable(),
  next: TvLiveCard.nullish().transform(arg => arg ?? undefined),
  geoloc: Geoloc,
  assetId: z.string().nullish(),
  streamId: z.string().nullish(),
  isAdReplacement: z.boolean().nullish(),
});
export type EmbedLive = z.infer<typeof EmbedLive>;

export const EmbedRadio = z.object({
  id: z.string(),
  title: z.string(),
  duration: z.number(),
  subtitle: z.string(),
  scheduledFrom: z
    .string()
    .transform(date => (isISODate(date) === true ? date : undefined))
    .optional(),
  scheduledTo: z
    .string()
    .transform(date => (isISODate(date) === true ? date : undefined))
    .optional(),
  channel: ChannelCard.nullish(),
});
export type EmbedRadio = z.infer<typeof EmbedRadio>;

export const EmbedResponse = Response.extend({
  meta: EmbedMeta,
  data: z.union([EmbedMedia, EmbedLive, EmbedRadio]),
});
