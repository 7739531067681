import "./didomiPopupPage.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { navigationStack } from "~/main";
import { TrackingHelper } from "~/tools/trackingHelper";
import { Didomi } from "~/typings/didomi";
import { areAllPartnersAccepted } from "~/utils/didomi/didomi";

const VISIBLE_NOTICE_CLASS_NAME = "visible";
const DIDOMI_DOM_ELEMENT_SELECTOR = "#didomi-host";

export class DidomiPopupPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _didomiInstance: Didomi;
  /**
   * This boolean is used to avoid firing multiple time API.signin method in the setInterval
   * callback.
   **/
  private _isSigningIn = false;

  constructor(didomi: Didomi, options: { context: "settings" } | { context: "startup"; onClose: () => void }) {
    super("DidomiPopup", "didomiPopup");

    this._didomiInstance = didomi;
    Log.app.log("USER STATUS: ", didomi.getCurrentUserStatus());

    if (options.context === "startup") {
      /*
       * NOTE: I don't know why I need to hide the preferences to show the notice
       * The second line is useless in my experience, but I'd rather keep it since I don't understand what's going on
       **/
      this._didomiInstance.preferences.hide();
      this._didomiInstance.notice.show();
    } else if (options.context === "settings") {
      this._didomiInstance.preferences.show("purposes");
    }

    /**
     * Check if Didomi has been closed by the user and close the popup if so.
     *
     * We need to use a timer since there is no other way to detect that user quit
     * Didomi page by pressing "back" or "accept" / "deny" the consent.
     */
    const isClosedInterval = window.setInterval(() => {
      if (this._isSigningIn === true) return;
      const shouldConsentBeCollected = didomi?.shouldConsentBeCollected?.() ?? false;

      if (
        options.context === "startup" &&
        this._didomiInstance.notice.isVisible() === false &&
        this._didomiInstance.preferences.isVisible() === false
      ) {
        this._onCloseDidomi(shouldConsentBeCollected, options.onClose);
      } else if (options.context === "settings" && this._didomiInstance.preferences.isVisible() === false) {
        this._onCloseDidomi(shouldConsentBeCollected);
      }
    }, 500);

    this.collectRelease(() => {
      window.clearInterval(isClosedInterval);
    });
  }

  private _onCloseDidomi(shouldConsentBeCollected: boolean, onClose?: () => void) {
    this._isSigningIn = true;
    navigationStack.removePage(this);

    onClose?.();

    if (shouldConsentBeCollected === false) {
      if (!areAllPartnersAccepted()) {
        TrackingHelper.reLoadChartbeat();
      } else {
        TrackingHelper.reLoadChartbeat(false);
      }
    }
  }

  isPopup = () => {
    return true;
  };

  onNav = (key: DS.Keys) => {
    return true;
  };

  onShown() {
    const didomiElement = document.querySelector(DIDOMI_DOM_ELEMENT_SELECTOR);

    if (didomiElement !== null) {
      /**
       * This allow Didomi Dom Element to be visible and set back its z-index
       */
      didomiElement.classList.add(VISIBLE_NOTICE_CLASS_NAME);
    }
  }

  onHidden() {
    const didomiElement = document.querySelector(DIDOMI_DOM_ELEMENT_SELECTOR);

    if (didomiElement !== null) {
      /**
       * This allow Didomi Dom Element to be hidden
       */
      didomiElement.classList.remove(VISIBLE_NOTICE_CLASS_NAME);
    }
  }
}
