import { Counter } from "./helpers/counter";
import { UILog } from "./uiLog";

const requestStats = new Counter("Request");

type StubMode = "record" | "replay";
const stubMode: StubMode | undefined = undefined;

export interface IOptions {
  method?: "get" | "post" | "put" | "delete" | "PATCH";
  queryParams?: Record<string, string | number | boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  ignoreCache?: boolean;
  headers?: { [key: string]: string };
  // 0 (or negative) to wait forever
  timeout?: number;
}

interface IResult {
  readonly url: string;
  readonly status: number;

  readonly statusText: string;
  readonly text: string;
  readonly xml: Document | null;
  readonly json: JSON;
  readonly withCredentials: boolean;
  getResponseHeader: (key: string) => string | null;
}

type StubData = {
  status: number;
  statusText: string;
  text: string;
  xml: Document | null;
  withCredentials: boolean;
  responseHeaders: string;
};
class StubResult implements IResult {
  requestOptions?: IOptions;
  _url: string;
  _data: StubData;
  private _json?: JSON;

  constructor(requestOptions: IOptions | undefined, url: string) {
    this._url = url;
    this.requestOptions = requestOptions;
    const jsonText = localStorage.getItem(url.replace(/https?/, ""));
    this._data = JSON.parse(jsonText ?? "{}") as StubData;
  }

  get url() {
    return this._url;
  }
  get status() {
    return this._data.status;
  }
  get statusText() {
    return this._data.statusText;
  }
  get text() {
    return this._data.text;
  }
  get xml() {
    return null;
  }
  get json() {
    try {
      return this._json ?? (this._json = this.text ? JSON.parse(this.text) : {});
    } catch (error) {
      UILog.net.error("JSON: parsing failed, URL:", this.url);
      UILog.net.error("Request options", JSON.stringify(this.requestOptions));
      UILog.net.error(
        "XHR",
        JSON.stringify({
          status: this.status,
          statusText: this.statusText,
          responseText: this.text,
          responseHeaders: this._data.responseHeaders,
          withCredentials: this.withCredentials,
        })
      );
      throw error;
    }
  }
  get withCredentials() {
    return this._data.withCredentials;
  }
  getResponseHeader(key: string) {
    return "not implemented";
  }
}
export class Result implements IResult {
  requestOptions?: IOptions;
  private _xhr: XMLHttpRequest;
  private _json?: JSON;

  constructor(requestOptions: IOptions | undefined, xhr: XMLHttpRequest) {
    this.requestOptions = requestOptions;
    this._xhr = xhr;

    if (stubMode === "record") {
      localStorage.setItem(
        xhr.responseURL.replace(/https?/, ""),
        JSON.stringify({
          status: this.status,
          statusText: this.statusText,
          text: this.text,
          xml: this.xml,
          withCredentials: this.withCredentials,
          responseHeaders: xhr.getAllResponseHeaders(),
        })
      );
    }
  }

  get url() {
    return this._xhr.responseURL;
  }
  get status() {
    return this._xhr.status;
  }
  get statusText() {
    return this._xhr.statusText;
  }
  get text() {
    return this._xhr.responseText;
  }
  get xml() {
    return this._xhr.responseXML;
  }
  get json() {
    try {
      return this._json ?? (this._json = this.text ? JSON.parse(this.text) : {});
    } catch (error) {
      UILog.net.error("JSON: parsing failed, URL:", this.url);
      UILog.net.error("Request options", JSON.stringify(this.requestOptions));
      UILog.net.error(
        "XHR",
        JSON.stringify({
          status: this.status,
          statusText: this.statusText,
          responseText: this.text,
          responseHeaders: this._xhr.getAllResponseHeaders(),
          withCredentials: this.withCredentials,
        })
      );
      throw error;
    }
  }
  get withCredentials() {
    return this._xhr.withCredentials;
  }
  getResponseHeader(key: string) {
    return this._xhr.getResponseHeader(key);
  }
}

export class Error extends Result implements Error {
  name: string;
  message: string;
  timedOut: boolean;

  constructor(
    name: string,
    message: string | undefined,
    timedOut: boolean,
    url: string,
    requestOptions: IOptions | undefined,
    xhr: XMLHttpRequest
  ) {
    super(requestOptions, xhr);
    this.timedOut = timedOut;
    this.name = name;
    this.message = message ?? xhr.statusText ?? "unknown";
  }
}

export let proxyUrl = "";
export function setProxyUrl(url: string) {
  proxyUrl = url;
}

export const DEFAULT_REQUEST_OPTIONS = {
  method: "get",
  queryParams: {},
  body: null,
  ignoreCache: false,
  headers: {
    Accept: "application/json, text/javascript, text/plain",
  },
  // default max duration for a request
  timeout: 10000,
};

/**
 * parses and maps query string params into a string compatible with HTTP request standards
 * @param params - list of values to be added as query string params
 * @returns
 */
function queryParams(params: Record<string, string | number | boolean> = {}) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
}

/**
 * Builds a url with query parameters
 * @param url - request url w/o query strings
 * @param params - list of values to be added as query string params
 * @returns
 */
function withQuery(url: string, params: Record<string, string | number | boolean> = {}) {
  const queryString = queryParams(params);
  return queryString ? url + (url.indexOf("?") === -1 ? "?" : "&") + queryString : url;
}

/**
 * creates and executes external HTTP request via XMLHttpRequest
 * @param url - request url
 * @param options - request options
 * @returns - Promise of a Result or ResultError
 */
export function make(url: string, options?: IOptions) {
  const method = options?.method ?? DEFAULT_REQUEST_OPTIONS.method;
  const body = options?.body ?? DEFAULT_REQUEST_OPTIONS.body;
  const queryParams = options?.queryParams ?? DEFAULT_REQUEST_OPTIONS.queryParams;
  const ignoreCache = options?.ignoreCache ?? DEFAULT_REQUEST_OPTIONS.ignoreCache;
  const headers = options?.headers ?? DEFAULT_REQUEST_OPTIONS.headers;
  const timeout = options?.timeout ?? DEFAULT_REQUEST_OPTIONS.timeout;

  // if check if proxied
  if (proxyUrl) {
    const targetOrigin = /^https?:\/\/([^/]+)/i.exec(url);
    if (targetOrigin && targetOrigin[0].toLowerCase() !== url && targetOrigin[1] !== proxyUrl) {
      url = `${proxyUrl}/${url}`;
    }
  }

  requestStats.increase(url);

  return new Promise<IResult>((resolve, reject) => {
    if (stubMode === "replay") {
      setTimeout(() => {
        resolve(new StubResult(options, url));
      }, 1000);
    }
    const xhr = new XMLHttpRequest();
    try {
      xhr.open(method, withQuery(url, queryParams));
    } catch (error: any) {
      reject(new Error(error?.name, error?.message, false, url, options, xhr));
    }

    Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]));

    if (ignoreCache) {
      xhr.setRequestHeader("Cache-Control", "no-cache");
    }

    xhr.timeout = timeout;

    xhr.onload = () => {
      UILog.net.debug("XHR onload", url);
      requestStats.decrease(url);
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(new Result(options, xhr));
      } else {
        reject(new Error("XHR server Error", undefined, false, url, options, xhr));
      }
    };

    xhr.onerror = () => {
      UILog.net.error("XHR onerror", url);
      requestStats.decrease(url);
      reject(new Error("XHR onError", undefined, false, url, options, xhr));
    };

    xhr.ontimeout = () => {
      UILog.net.error("XHR ontimeout", url);
      requestStats.decrease(url);
      reject(new Error("XHR onTimeout", undefined, true, url, options, xhr));
    };

    UILog.net.debug("XHR will send", url);
    if ((method === "post" || method === "put" || method === "PATCH") && body !== undefined) {
      xhr.send(body);
    } else {
      xhr.send();
    }
  });
}
