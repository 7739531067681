/**
 * The ListComponent related structures.
 *
 * A ListComponent is created by calling {@link createListComponent} which returns an interface {@link IListComponent}
 *
 * A ListComponent is handling a list of elements. The elements to handle are input as a model via a kind of {@link IModelSource}.
 *
 * @module ListComponent
 */

import { ILogger } from "../../log/types";
import { DIR$ } from "../const";
import { centerOf, distanceBetweenPointAndRectSQR } from "../helpers";
import { callWithDelegateFallback } from "../helpers/delegate";
import * as DOMHelper from "../helpers/DOMHelper";
import { Listenable } from "../helpers/Listenable";
import { Trigger } from "../helpers/trigger";
import { trycatch } from "../helpers/trycatch";
import {
  DIR,
  Identifiable,
  IExtraSize,
  IListComponent,
  IListComponentParams,
  IModelSource,
  IPoint,
  IRefreshParams,
  IView,
  IViewPersistency,
  Keys,
  ScrollingMode,
  ScrollingType,
} from "../typings";
import { UILog } from "../uiLog";
import { BaseComponent, keyGenerator, objFilter } from "./baseComponent";
import { isListComponent } from "./isOfType";
import { isSwitchComponent } from "./switchComponent";
import { IViewBounds, ParentScrollingViewBounds, TranslatedViewBounds, ViewBounds } from "./viewBounds";

const loadingPlaceholderPrefix = "loading-placeholder";
interface IParamsMandatory<M extends Identifiable, V extends IView> {
  modelSource$: IModelSource<M>;
  modelSourcePageLength: number;
  onModelSourceChangedRefreshParams: IRefreshParams;
  id: string;
  viewFactory: (item: M, index: number) => V;
  loadingPlaceholderFactory?: (index: number) => IView;
  onSelect: (model: M, index: number) => boolean;
  defaultFocusId?: string;
  crossSectionWidth: number;
  maxItems: number;
  scrollingMode: ScrollingMode;
  spatialFocus: boolean;
  scrollDuration: number;
  viewOffset: (item: M, index: number) => number | undefined;
  dir?: DIR;
  updateChildrenClassOnFocus: boolean;
  noTransform: boolean;
}

const getNonScrollingChildList = (view?: IView) => {
  const list = (isListComponent(view) ? view : view && isListComponent(view.delegate) ? view.delegate : undefined) as
    | ListComponent<any, IView>
    | undefined;

  if (list !== undefined && list.params.scrollingMode.type === ScrollingType.none) {
    return list;
  }
};

class ListRange {
  start: number;
  end: number;
  get length() {
    return this.end - this.start;
  }
  constructor(start: number, end: number) {
    this.start = start;
    this.end = end;
  }

  translate(translation: number) {
    return new ListRange(this.start + translation, this.end + translation);
  }
}
interface IListBounds {
  visible: ListRange;
  page: ListRange;
}

function uiListLogFactory<M extends Identifiable, V extends IView>(list: ListComponent<M, V>): ILogger {
  return {
    trace: (...msg: unknown[]) =>
      UILog.ui_list.trace(`${(list.rootElement.id + ":" + list._internalId).padEnd(15, " ")})`, ...msg),
    debug: (...msg: unknown[]) =>
      UILog.ui_list.debug(`${(list.rootElement.id + ":" + list._internalId).padEnd(15, " ")}`, ...msg),
    info: (...msg: unknown[]) =>
      UILog.ui_list.info(`${(list.rootElement.id + ":" + list._internalId).padEnd(15, " ")}`, ...msg),
    log: (...msg: unknown[]) =>
      UILog.ui_list.log(`${(list.rootElement.id + ":" + list._internalId).padEnd(15, " ")}`, ...msg),
    warn: (...msg: unknown[]) =>
      UILog.ui_list.warn(`${(list.rootElement.id + ":" + list._internalId).padEnd(15, " ")}`, ...msg),
    error: (...msg: unknown[]) =>
      UILog.ui_list.error(`${(list.rootElement.id + ":" + list._internalId).padEnd(15, " ")}`, ...msg),
  };
}

let internalIdCount = 0;
export class ListComponent<M extends Identifiable, V extends IView>
  extends BaseComponent<M, V>
  implements IListComponent<M, V>
{
  params: IParamsMandatory<M, V>;
  scrollElement: HTMLDivElement;

  _listLogger: ILogger = uiListLogFactory(this);

  scrollPosition$ = new Listenable<number | undefined>(undefined);
  viewsUpdateCompletedTrigger = new Trigger<boolean>();
  scrollAnimationCompletedTrigger = new Trigger();

  focusedIndex$ = new Listenable<number | undefined>(undefined);
  focusedId$ = new Listenable<string | undefined>(undefined);
  focusedView$ = new Listenable<V | undefined>(undefined);
  focused$ = new Listenable(false);
  shown$ = new Listenable(false);
  focusedFromMouse$ = new Listenable(false);

  pageRange$ = new Listenable<{ first: number | undefined; last: number | undefined }>({
    first: undefined,
    last: undefined,
  });
  visibleRange$ = new Listenable<{ first: number | undefined; last: number | undefined }>({
    first: undefined,
    last: undefined,
  });

  // private state
  protected _visibleSize: IExtraSize;
  protected _lastCreatedIndex?: number;
  protected _lastTranslation = { along: 0, across: 0 };
  protected _creatingViews = false;
  protected _maxAnchoredScrollPosition?: number;

  // not protected - need to be accessed by viewBounds
  _overallSize = { along: 0, across: 0 };

  // cache for _listBounds - busted on refresh
  _listBoundsCache: { [key: number]: IListBounds } = {};

  /** if a scrolling to an index was requested but we weren't visible, store it there to set it back when shown */
  protected _pendingRefreshParams?: IRefreshParams;

  _scrollCompletedUnregister?: () => void;

  /** if some child views said they are async */
  _hasAsyncChildren = false;

  /** true if a fetch from the modelsource is going on */
  _fetching = false;
  /** our list of potential waiters on maybefetch, waiting for previous fetches to complete */
  _pendingMaybeFetches: {
    requested: { from: number; count: number };
    resolve: (value: Promise<boolean>) => void;
    reject: () => void;
  }[] = [];
  _internalId = internalIdCount++;

  _placeholders: IView[] = [];

  _maybeFethCount = 0;

  /**
   * the constructor
   * @param params - the parameters defining the behavior of the list
   * @returns index component
   */
  constructor(params: IListComponentParams<M, V>) {
    super(params.id, params.className);
    this.params = {
      ...params,
      // optional values
      modelSourcePageLength: params.modelSourcePageLength ?? 16,
      onModelSourceChangedRefreshParams: params.onModelSourceChangedRefreshParams ?? {
        keepSelection: false,
        animate: true,
        clearCached: false,
      },
      onSelect: params.onSelect ?? (() => false),
      crossSectionWidth: params.crossSectionWidth ?? 1,
      maxItems: params.maxItems ?? Number.MAX_SAFE_INTEGER,
      spatialFocus: params.spatialFocus ?? false,
      scrollDuration: params.scrollDuration ?? 400,
      viewOffset: params.viewOffset ?? (() => 0),

      updateChildrenClassOnFocus: params.updateChildrenClassOnFocus ?? true,
      noTransform: params.noTransform ?? false,
    };

    // make sure we measure our rootElement - that gives us the visible bounds of the list
    this._visibleSize = DOMHelper.sizeOf(this.rootElement);
    this._overallSize.across = params.scrollingMode.horizontal ? this._visibleSize.height : this._visibleSize.width;
    this._listLogger.warn(`_visibleSize ${JSON.stringify(this._visibleSize)}`);

    // listen to modelSource - but not fetch (kinda hack)
    this.params.modelSource$.didChange(async () => {
      this._listLogger.log(`modelSource.didChange , refresh`);
      await this.refresh(this.params.onModelSourceChangedRefreshParams);
      this._listLogger.debug(`modelSource.didChange , refresh ended`);
    }, this);

    // listen to RTL updates
    if (!this.params.dir) {
      DIR$.didChange(() => {
        // update the scrollElement to reflect the change now - remove/reset the transition to not have it animated
        void this.updateDOMScrollPosition(this.scrollElement, this.scrollElement._dsTranslate || { x: 0, y: 0 }, 0);
      }, this);
    }
    // keep all listenables in sync
    this.focusedIndex$.didChange(focusedIndex => {
      this.focusedId$.value = focusedIndex !== undefined ? this.ids[focusedIndex] : undefined;

      // take care of the callbacks here, after focusedId has changed (for spatialFocus to work)
      const focusedView = this.viewFromIndex(focusedIndex);
      if (this.focused$.value) {
        callWithDelegateFallback(focusedView, "onFocused");
        this.params.updateChildrenClassOnFocus && focusedView?.rootElement.classList.add("focused");
      }

      const oldFocusedView = this.focusedView$.value;
      callWithDelegateFallback(oldFocusedView, "onUnfocused");
      this.params.updateChildrenClassOnFocus && oldFocusedView?.rootElement.classList.remove("focused");
      this.focusedView$.value = focusedView;
    }, this);

    this.focusedId$.didChange(focusedId => {
      const index = focusedId !== undefined ? this.ids.indexOf(focusedId) : -1;
      this.focusedIndex$.value = index === -1 ? undefined : index;
    }, this);

    this.focused$.didChange(focused => {
      if (focused) {
        callWithDelegateFallback(this.focusedView$.value, "onFocused");
        this.params.updateChildrenClassOnFocus && this.focusedView$.value?.rootElement.classList.add("focused");
      } else {
        callWithDelegateFallback(this.focusedView$.value, "onUnfocused");
        this.params.updateChildrenClassOnFocus && this.focusedView$.value?.rootElement.classList.remove("focused");
      }
    }, this);

    this.rootElement._dsListComponent = this;
    this.scrollElement = DOMHelper.createElement({ tagName: "div", parent: this.rootElement });
    // initially, not showing. Will show when children are ready
    // TODO: fix in new callflow (draw a callflow!)
    // this.scrollElement.hidden = this.params.loadingPlaceholderFactory === undefined;
    this.scrollElement._dsListScrollElement = true;
    if (this.params.dir) this.scrollElement.dir = this.params.dir;

    void this.refresh({ keepSelection: false, animate: false });
  }

  public onRelease(): void {
    super.onRelease();
    // need to clean the _dsListComponent to prevent a circular reference which prevents garbage collection
    delete this.rootElement._dsListComponent;
    delete this.rootElement._dsListScrollElement;

    this.ids = [];
    // remove views from the DOM
    Object.values(this.viewMap).forEach(view => {
      // only clean/hide a view if it's still "ours". Cacheable views (a persistent rootMenu) could be moved across multiple lists
      if (view?.rootElement.parentElement === this.scrollElement) {
        this._hideView(view);
      }
      // when releasing views, also release cached ones - but leave static ones alone
      view.persistency !== IViewPersistency.static && callWithDelegateFallback(view, "onRelease");
    });
    this.viewMap = {};
    this.modelMap = {};
    this.boundsMap = {};

    // just in case
    this._hideLoadingPlaceholders();
  }

  /** if returning true, the parent of the view in the view tree should hold off showing itself */
  asyncContent = () => true;

  /**
   * Drives the whole chain: read model, create views, measure, until all visible views are created, + 1
   * @returns boolean true if views can still be created, false if no more views will be created
   */
  async _createViews(args: {
    bounds?: {
      new: IListBounds;
      old: IListBounds;
    };
    upToIndex?: number;
  }): Promise<boolean> {
    this._listLogger.log(`_createViews ${JSON.stringify(args)}`);

    // need either param
    if (args.bounds === undefined && args.upToIndex === undefined) {
      this._listLogger.error("either a scroll position or a last index needs to be provided to createNewViews");
      return false;
    }

    // don't create views if we're not in the DOM
    // if (this.ids.length !== 0 && !DOMHelper.isInDOM(this.rootElement)) {
    //   return false;
    // }

    if (this._creatingViews) return true;
    this._creatingViews = true;

    /**
     * the main loop is purely based on DOM measurements
     * it will go over ids, views, offsets, reading more if necessary
     * break conditions:
     * - enough views to fill the whole visible area, + 1
     * - no more ids to read
     */
    let index = this._lastCreatedIndex ?? 0;
    let prevBounds = this.boundsFromIndex(index);
    let modelComplete = false; //this._isComplete();
    let maxAlong = 0;
    let moreViewsToCreate = false;

    // how far should I create views? in pixels. If no scrollPosition specified, doesn't bound
    const visibleRangeEnd = args.bounds?.new?.visible.end ?? Number.MAX_SAFE_INTEGER;
    const indexRangeEnd = args.upToIndex ?? Number.MAX_SAFE_INTEGER;

    // as long as a child list is creating content, don't create our own
    while ((prevBounds === undefined || prevBounds.along.end < visibleRangeEnd) && index <= indexRangeEnd) {
      if (index >= this.ids.length) {
        if (!modelComplete) {
          // need to fetch some (more) data
          modelComplete = !(await this._maybeFetch(index + this.params.modelSourcePageLength));
        } else {
          // model was complete in the last loop cycle, so it's over
          break;
        }
      }

      // if (this.rootElement.id.startsWith("Grid")) debugger;

      // no if I have enough ids, I can iterate
      const id = this.ids[index];
      const model = this.modelFromIndex(index);

      if (model === undefined) {
        this._listLogger.error(`no model for index ${index}`);
        break;
      }

      // don't yet have a view, create, measure, check if it's enough
      let view = this.viewFromIndex(index);
      if (!view) {
        // CREATION
        this._listLogger.trace(`creating view for index ${index}`);
        view = trycatch(`Exception thrown in viewFactory:`, () => {
          return this.params.viewFactory(model, index);
        });

        if (!view) {
          this._listLogger.error(`no view created for index ${index}`);
          break;
        }

        // remove placeholders on first view created
        if (this._lastCreatedIndex === undefined) this._hideLoadingPlaceholders();

        // we don't position anything if no transform is set - just append
        if (!this.params.noTransform) {
          const origin = this.params.scrollingMode.horizontal
            ? { x: this._lastTranslation.along, y: this._lastTranslation.across }
            : { x: this._lastTranslation.across, y: this._lastTranslation.along };
          this._listLogger.log(`setOrigin ${view.rootElement.id}`, origin);
          DOMHelper.setOrigin(view.rootElement, origin, this.params.dir ?? DIR$.value);

          // make sure the view comes back here (if it's cacheable & got dragged into another list)
          if (view.rootElement.parentElement !== this.scrollElement) {
            this.scrollElement.appendChild(view.rootElement);
            callWithDelegateFallback(view, "onShown");
          }
        }

        this.viewMap[id] = view;
        this._lastCreatedIndex = index;
      }

      if (this.focused$.value && this.focusedId$.value !== undefined && id === this.focusedId$.value) {
        // very specific use case - if we had the focus, been refreshed, are supposed to keep the focus - repush the focus on the new view right away
        // to prevent blinking
        this.params.updateChildrenClassOnFocus && view?.rootElement.classList.add("focused");
        callWithDelegateFallback(view, "onFocused");
      }

      // PLACEMENT
      this._hasAsyncChildren = this._hasAsyncChildren || (callWithDelegateFallback(view, "asyncContent") ?? false);

      const nonScrollingChildList = getNonScrollingChildList(view);
      // recursive part: if our last created view is a non scrolling list, let it create all its views and don't bother even doing the loop here
      // so we stop before generating a bound for this view (we don't know its extents yet)
      if (
        nonScrollingChildList &&
        nonScrollingChildList.params.scrollingMode.horizontal === this.params.scrollingMode.horizontal &&
        args.bounds
      ) {
        const { translation } = nonScrollingChildList._referenceList();
        if (
          (await nonScrollingChildList._createViews({
            bounds: {
              new: {
                visible: args.bounds.new.visible.translate(-translation),
                page: args.bounds.new.page.translate(-translation),
              },
              old: {
                visible: args.bounds.old.visible.translate(-translation),
                page: args.bounds.old.page.translate(-translation),
              },
            },
          })) === true
        ) {
          this._creatingViews = false;
          moreViewsToCreate = true;
        }
      }

      // Bounds handling if subview creation didn't stop the execution

      let currentBounds: IViewBounds | undefined = this.boundsMap[id];
      if (currentBounds === undefined) {
        const viewOffset = model !== undefined ? this.params.viewOffset?.(model, index) : 0;
        if (nonScrollingChildList) {
          currentBounds = this.boundsMap[id] = new ParentScrollingViewBounds(
            nonScrollingChildList,
            this.params.scrollingMode.horizontal,
            viewOffset
          );
        } else {
          currentBounds = this.boundsMap[id] = new ViewBounds(
            nonScrollingChildList ?? view,
            this.params.scrollingMode.horizontal,
            viewOffset
          );
        }
      }
      // when the last created view is the last element in the modelsource, consider we stop scrolling to prevent showing empty space
      if (
        this.params.scrollingMode.type === ScrollingType.anchored &&
        index === this.params.modelSource$.value.length - 1 &&
        this.params.modelSource$.isComplete$.value &&
        this._maxAnchoredScrollPosition === undefined &&
        prevBounds
      ) {
        // when we created the last element, we know its bounds, we can then figure the last anchored index to ensure the last element in the page is fully visible
        this._maxAnchoredScrollPosition = Math.max(0, currentBounds.along.end - this._listBounds().page.length);

        // super edgy case: scroll back to bounds in case we "overshoot" (blindly went too far using setFocusOnIndex)
        if (
          this.scrollPosition$.value !== undefined &&
          this._maxAnchoredScrollPosition !== undefined &&
          this.scrollPosition$.value > this._maxAnchoredScrollPosition
        ) {
          await this.setScrollPosition(this._maxAnchoredScrollPosition);
          // we'll reenter so stop processing here
          this._creatingViews = false;
          return true;
        }
      }

      // exit hatch if we created a non-scrolling sublist which still has views to create
      // we wanted the bounds to be created, but we don't want to proceed in the loop any further
      if (moreViewsToCreate) break;

      // now decide how the offsets will change
      // if the list is N items wide, then it needs to increase the translate if globalIndex % N ===  N-1 (last one of the "row")
      const column = index % this.params.crossSectionWidth;

      // if we don't have defined bounds (child in parent scroll mode?) don't update anything
      // maintain max size along the list
      maxAlong = Math.max(maxAlong, currentBounds.along.end);

      // only count visible items for overall along
      this._overallSize.along = Math.max(this._overallSize.along, currentBounds.along.end);

      if (column === this.params.crossSectionWidth - 1) {
        // last column, reset across offset, move along with maxAlong
        this._lastTranslation.across = 0;
        this._lastTranslation.along = maxAlong;
        prevBounds = currentBounds;
        maxAlong = 0;
      } else {
        // move across, maintain maxAlong
        this._lastTranslation.across = currentBounds.across.end;
      }
      index++;
      // view = this.viewFromIndex(index);
      // nonScrollingChildList = getNonScrollingChildList(view);
      // if (nonScrollingChildList && (await nonScrollingChildList.createNewViews(args)) === true) {
      //   return true;
      // }
    }

    // apply focus if not set yet
    if (this.ids.length && (this.focusedId$.value === undefined || !this.ids.includes(this.focusedId$.value))) {
      // once we have content, we set the initial focus
      const firstFocusableId = this.params.defaultFocusId ?? this._getFirstFocusableId(0, 1);
      // we set what the focus will be when the focus path goes through here - but we don't actually set the focus unless we already have it
      firstFocusableId !== undefined && (await this.setFocusOnId(firstFocusableId, { focus: this.focused$.value }));
    }

    // TODO: fix in new callflow (draw a callflow!)
    // if (!this._hasAsyncChildren && this.ids.length) this.treeContentReady();

    this._updateDOMSize();

    this._creatingViews = false;

    moreViewsToCreate =
      moreViewsToCreate ||
      !(
        this.params.modelSource$.isComplete$.value &&
        this._lastCreatedIndex === this.params.modelSource$.value.length - 1
      );

    // if we have the same count of views than the complete model, we're done
    return moreViewsToCreate;
  }

  // TODO: fix in new callflow (draw a callflow!)
  treeContentReady() {
    if (this.scrollElement.hidden) {
      this.scrollElement.hidden = false;
      // and walk up the parent list to call the relayout above
      let walkElement = this.rootElement.parentElement;
      while (walkElement && walkElement.parentElement !== document.body) {
        if (walkElement._dsListComponent !== undefined) {
          (walkElement._dsListComponent as ListComponent<M, V>).treeContentReady();
          break;
        }
        walkElement = walkElement?.parentElement || null;
      }
    }
    // when everything is showing, notify all waiters it's done
  }

  // after a scroll, need to update
  _updateDOMSize(): void {
    if (this.params.scrollingMode.type === ScrollingType.none) {
      this._listLogger.debug(`_updateDOMSize`);

      this.rootElement.style.width = `${
        this.params.scrollingMode.horizontal ? this._overallSize.along : this._overallSize.across
      }px`;
      this.rootElement.style.height = `${
        !this.params.scrollingMode.horizontal ? this._overallSize.along : this._overallSize.across
      }px`;

      // clear up the cached size
      delete this.rootElement._dsSize;
    }
  }

  /**
   * refreshes a list based on the current data model
   * @param params - refresh parameters, define for example if the list should keep the selection
   */
  public async refresh(params?: IRefreshParams): Promise<void> {
    this._listLogger.debug(`refresh`, params);

    if (!DOMHelper.isInDOM(this.rootElement)) {
      this._pendingRefreshParams = params;
      return;
    }

    const oldScrollPosition = this.scrollPosition$.value;
    const oldFocusedId = this.focusedId$.value;
    const keepSelection = params?.keepSelection ?? false;
    const animate = params?.animate ?? true;
    const clearCached = params?.clearCached ?? false;

    //cleanup
    this.ids = [];
    this._lastCreatedIndex = undefined;
    this._lastTranslation = { along: 0, across: 0 };
    this.visibleRange$.value = { first: undefined, last: undefined };
    // undef scroll index to be sure to refresh
    this.scrollPosition$.value = undefined;
    this._maxAnchoredScrollPosition = undefined;
    this._overallSize.along = 0;
    this._listBoundsCache = {};

    this.focusedIndex$.value = undefined;
    this.boundsMap = {};
    this.modelMap = {};

    // at this point, we can remove any views that isn't to be cached
    this.viewMap = objFilter(this.viewMap, (_, view) => {
      if (view.persistency !== IViewPersistency.none && !clearCached) {
        // we keep - but they lose focus and aren't released
        return true;
      } else {
        // we cleanup from the DOM
        DOMHelper.clean(view?.rootElement);
        callWithDelegateFallback(view, "onHidden");
        callWithDelegateFallback(view, "onRelease");
        return false;
      }
    });

    // refetch all from scratch
    if (keepSelection && oldFocusedId !== undefined) {
      await this.setFocusOnId(oldFocusedId, { focus: this.focused$.value, animate });
    } else {
      if (!keepSelection) this.focusedId$.value = undefined;
      await this.setScrollPosition(keepSelection ? oldScrollPosition ?? 0 : 0, animate);
    }

    // wait for views to be created
    await this.viewsUpdateCompletedTrigger.onSignal;

    // remove all cached views that aren't in ids anymore
    this.viewMap = objFilter(this.viewMap, (id, view) => {
      if (view.persistency !== IViewPersistency.none && !clearCached && !this.ids.includes(id)) {
        // we cleanup from the DOM
        DOMHelper.clean(view?.rootElement);
        callWithDelegateFallback(view, "onHidden");
        // we keep - but they lose focus and aren't released
        return false;
      }
      return true;
    });
  }

  /**
   * shows placeholders when initially loading model data
   */
  _showLoadingPlaceholders(): void {
    if (!this.params.loadingPlaceholderFactory || this._placeholders.length > 0) return;

    /**
     * loop is purely based on DOM measurements
     * break conditions:
     * - enough views to fill the whole visible area, + 1
     */
    let index = 0;
    let lastElementScrollOffset = 0;
    let maxAlong = 0;
    let offsetAcross = 0;

    // how far should I create views? in pixels
    const visibleBoundsEnd = this._listBounds(0).visible.end;

    do {
      this._listLogger.trace(`creating placeholder view for index ${index}`);
      trycatch(`Exception thrown in arrowFactory:`, () => {
        if (!this.params.loadingPlaceholderFactory) return;
        const view = this.params.loadingPlaceholderFactory(index);
        this._placeholders.push(view);
        this.scrollElement.appendChild(view.rootElement);

        const viewSize = DOMHelper.sizeOf(view.rootElement);
        const widthAlong = this.params.scrollingMode.horizontal
          ? viewSize.width + viewSize.extraWidthLeft + viewSize.extraWidthRight
          : viewSize.height + viewSize.extraHeightTop + viewSize.extraHeightBottom;
        const widthAcross = this.params.scrollingMode.horizontal
          ? viewSize.height + viewSize.extraHeightTop + viewSize.extraHeightBottom
          : viewSize.width + viewSize.extraWidthLeft + viewSize.extraWidthRight;

        const origin = this.params.scrollingMode.horizontal
          ? { x: lastElementScrollOffset, y: offsetAcross }
          : { x: offsetAcross, y: lastElementScrollOffset };

        DOMHelper.setOrigin(view.rootElement, origin, this.params.dir ?? DIR$.value);

        // now decide how the offsets will change
        // if the list is N items wide, then it needs to increase the scrollOffset if globalIndex % N ===  N-1 (last one of the "row")
        const column = index % this.params.crossSectionWidth;
        // maintain max size along the list
        maxAlong = Math.max(maxAlong, widthAlong);

        if (column === this.params.crossSectionWidth - 1) {
          // last column, reset across offset, move along with maxAlong
          offsetAcross = 0;
          lastElementScrollOffset += maxAlong;
          maxAlong = 0;
        } else {
          // move across, maintain maxAlong
          offsetAcross += widthAcross;
        }
        index++;
      });
    } while (lastElementScrollOffset < visibleBoundsEnd);
  }

  /**
   * Hides all available placeholder image elements
   */
  _hideLoadingPlaceholders(): void {
    if (this.params.loadingPlaceholderFactory) {
      this._placeholders.forEach(view => {
        this._hideView(view);
      });
      this._placeholders = [];
    }
  }

  /**
   * internal pagination of list items. indicates if more data was received to be rendered
   * @param data - data to be added to list
   */
  _receivedMore(data: M[]): void {
    // if no new elements, no need to do more. This breaks a potential loop with modelReceived
    if (data.length === 0) return;
    this._listLogger.trace(`receivedMore (${data.length} items)`);

    // we receive more data, so we append
    data.forEach(item => {
      const id = keyGenerator(item);
      if (id.startsWith(loadingPlaceholderPrefix)) {
        this._listLogger.error(
          `id (${id}) starts with ${loadingPlaceholderPrefix} - please don't use that prefix! It's reserved for placeholders`
        );
        return false;
      }
      if (this.ids.includes(id)) {
        this._listLogger.error(`duplicated id (${id}) in data set for list `);
        return false;
      }
      this.modelMap[id] = item;
      this.ids.push(id);
    });
  }

  /**
   * fetch more data from the source if it's necessary
   * @param toIndex - the index of the last element we'd want to see in the new batch of models
   * @returns true if returned all requested data, false if not (it would mean the source is complete)
   */
  async _maybeFetch(toIndex: number): Promise<boolean> {
    // fetch if more data is needed - based on current scrollIndex & visible items
    // to be sure in case we have promises, use an local async call

    // limit the number of retrieved elements if such a limit is defined
    toIndex = Math.min(this.params.maxItems, toIndex);

    const fromIndex = this.ids.length;
    const count = toIndex - fromIndex;

    this._listLogger.debug(`maybeFetch  (${fromIndex}, ${count})`);

    // TODO: debug code to not ever return data
    // if (this.params.loadingPlaceholderFactory) {
    //   await new Promise<boolean>(resolve => {});
    // }

    // no need to try fetching if we already have the same amount of elements (non zero) when the source is complete
    if (
      count > 0 &&
      (!this.params.modelSource$.isComplete$.value ||
        (this.params.modelSource$.value.length && this.params.modelSource$.value.length > this.ids.length))
    ) {
      if (this._fetching) {
        // we need to wait our turn
        this._listLogger.debug(
          `maybeFetch  (${fromIndex}, ${count}), put on hold over ${this._pendingMaybeFetches.length}`
        );
        this._maybeFethCount++;
        if (this._maybeFethCount > 50) {
          // debugger;
          throw new Error(`maybeFetch  aborted - too many recursive calls`);
        }
        return new Promise<boolean>((resolve, reject) =>
          this._pendingMaybeFetches.push({ requested: { from: fromIndex, count }, resolve, reject })
        );
      }

      // check if our source changed after fetching it
      const sourceVersion = this.params.modelSource$?.version$.value;
      this._fetching = true;
      this._listLogger.info(`maybeFetch  (${fromIndex}, ${count}), fetching`);

      let data: M[] | undefined;
      try {
        data = await this.params.modelSource$?.fetch(this.ids.length, count);
        // abort if source has changed
        if (sourceVersion !== undefined && sourceVersion !== this.params.modelSource$.version$.value) {
          this._listLogger.error(`maybeFetch  (${fromIndex}, ${count}) aborted - source version changed`);
          // throw `maybeFetch  aborted - source version changed`;
          // we're not fetching anymore
          this._fetching = false;
          // don't process results, answer "we got back nothing"
          data = [];
        } else {
          this._listLogger.debug(
            `maybeFetch  (${fromIndex}, ${count}) received ${data.length} more elements, processing over ${this.ids.length} existing ids`
          );
          this._receivedMore(data);
          this._listLogger.debug(
            `maybeFetch  (${fromIndex}, ${count}) more elements added. ${this.ids.length} existing ids`
          );
          // we're not fetching anymore
          this._fetching = false;
        }
        // go through the queued requests - in a detached/parallel fashion as the initial call doesn't need to wait for the subsequent ones to complete
        // we should only go over a copy of the pending fetches, in order to avoid endless loops because of reentry
        const pendingMaybeFetches = [...this._pendingMaybeFetches];
        this._pendingMaybeFetches = [];
        pendingMaybeFetches.forEach(pendingMaybeFetch => {
          this._listLogger.debug(
            `maybeFetch  (${fromIndex}, ${count}) resuming (${pendingMaybeFetch?.requested.from}, ${pendingMaybeFetch?.requested.count}), ${this._pendingMaybeFetches.length} left pending`
          );
          pendingMaybeFetch.resolve(
            this._maybeFetch(pendingMaybeFetch.requested.from + pendingMaybeFetch.requested.count)
          );
        });
        return data?.length === count;
      } catch (error) {
        this._listLogger.error(`maybefetch : (${fromIndex}, ${count}) exception: `, error);
        // we're not fetching anymore
        this._fetching = false;
        // source  version changed, or general failure, in this case consider all attempts invalid
        this._pendingMaybeFetches.forEach(pending => pending.reject());
        this._pendingMaybeFetches = [];
      }
    }
    this._listLogger.debug(`maybeFetch  (${fromIndex}, ${count}) done`);
    // nothing to return, so nothing to do
    return false;
  }

  /**
   * defines the scroll position of the list (in pixels)
   * async - once completed, it's ensured all views (and subviews) have been created & page/visible indexes are updated
   *
   * it triggers fetching data from the model until enough views have been created to fill the visible area with views
   * warning: it's not bound to a max scrolling position (because we can't know in advance how big the list could be)
   * So if scrolling arbitrarily too far one can end up seeing nothing (because model source has been exhausted)
   *
   * @param scrollPosition - scrolling position, in pixels. starts at 0
   */
  public async setScrollPosition(scrollPosition: number, animate = true) {
    this._listLogger.log(`setScrollPosition ${scrollPosition}`);
    // handle the case where we are relaying out because of RTL - no animation in this case
    // const shouldUpdateScrollPosition = this.ids.length === 0 && isRootElementInDOM;

    scrollPosition = Math.max(0, scrollPosition);
    scrollPosition = Math.min(this._maxAnchoredScrollPosition ?? Number.MAX_SAFE_INTEGER, scrollPosition);

    // no need to update & compute anything if it hasn't changed
    if (scrollPosition === this.scrollPosition$.value) return;

    const oldScrollPosition = this.scrollPosition$.value ?? scrollPosition;

    // update our internal reference to the final scrollPosition
    this.scrollPosition$.value = scrollPosition;

    const bounds = {
      new: this._listBounds(scrollPosition),
      old: this._listBounds(oldScrollPosition),
    };
    this._listLogger.log(`setScrollPosition - createViews`);
    // create our own views, depending on the new scrollPosition
    // this operation is recursive - if a sublist is encountered as a child of a list, it's "drilling down" creating views in the visible range of the topmost list
    await this._createViews({
      bounds,
    });

    if (this.scrollPosition$.value === scrollPosition) {
      // once views have been created, and if scroll position hasn't been changed because we hit the anchored max boundary, then update
      // if scrollPosition has changed, it's been done by createView so don't do anything
      // again, this is recursive on visible elements
      this._listLogger.log(`setScrollPosition - updateIndexes`);
      this._updateIndexes(this.params.scrollingMode.horizontal, bounds.new, bounds.old);

      // ensure visible based on indexes
      // should this also be recursive?
      this._ensureVisibleItemsAreInScrollElement();

      // signal the whole chain of action related to views is complete
      this.viewsUpdateCompletedTrigger.signal(true);

      // fire & forget async code to handle DOM scrolling (and wait for animation to complete to )
      void (async () => {
        // reference
        const scrollDuration = animate && this.ids.length !== 0 ? this.params.scrollDuration : 0;

        // check if it's the last scroll operation
        if (
          this.scrollPosition$.value !== undefined &&
          (await this.updateDOMScrollPosition(
            this.scrollElement,
            {
              x: this.params.scrollingMode.horizontal ? -this.scrollPosition$.value : 0,
              y: this.params.scrollingMode.horizontal ? 0 : -this.scrollPosition$.value,
            },
            scrollDuration
          )) &&
          !this.params.noTransform
        ) {
          // signal scroll is complete
          this.scrollAnimationCompletedTrigger.signal();
          this._listLogger.debug(`finished scrolling to ${this.scrollPosition$.value} in ${scrollDuration}ms`);
        }
      })();
    }
  }

  _ensureVisibleItemsAreInScrollElement() {
    this.forEveryView("visible", index => {
      const view = this.viewFromIndex(index);
      if (view && view.rootElement.parentElement !== this.scrollElement) {
        this.scrollElement.appendChild(view.rootElement);
        callWithDelegateFallback(view, "onShown");
      }
    });
  }

  _hideView(view?: IView) {
    DOMHelper.clean(view?.rootElement);
    callWithDelegateFallback(view, "onHidden");
  }

  /** on each element outside of the visible range, remove from the DOM */
  _hideInvisibleItems() {
    if (this.visibleRange$.value.first !== undefined && this.visibleRange$.value.last !== undefined) {
      // before first visible
      for (let index = 0; index < this.visibleRange$.value.first; index++) {
        this._hideView(this.viewFromIndex(index));
      }

      // after first visible
      for (let index = this.visibleRange$.value.last + 1; index < this.ids.length; index++) {
        this._hideView(this.viewFromIndex(index));
      }

      this._listLogger.log(
        `hid everything outside ranges ${this.visibleRange$.value.first}-${this.visibleRange$.value.last}`
      );
    }
  }

  /**
   * get the first focusable component
   * @param index - starting index
   * @param refocusDirection - setting the focus tries to set the focus to the first element that accepts it starting at index. By default does nothing.
   * @returns true if focus was accepted, false otherwise
   */
  _getFirstFocusableId(fromIndex: number, refocusDirection: -1 | 1): string | undefined {
    let targetIndex = fromIndex;
    let nextFocusedView = this.viewFromIndex(targetIndex);

    while (nextFocusedView?.rejectsFocus?.() ?? false) {
      // try to find one prev or next
      targetIndex = targetIndex + refocusDirection;
      if (targetIndex < 0 || targetIndex > this.ids.length - 1) return undefined;
      nextFocusedView = this.viewFromIndex(targetIndex);
    }

    return this.ids[targetIndex];
  }

  _updateFocusPath(): void {
    // now that our own focusIndex is set, update our parents
    let walkElement: HTMLElement | null = this.rootElement;
    // a list as its own root, in which there's the scroller div, in which all the rootElements of the items
    while (walkElement !== undefined && walkElement.parentElement && walkElement.parentElement.parentElement) {
      if (walkElement.parentElement.parentElement._dsListComponent !== undefined) {
        // in case it's in a scroller
        (walkElement.parentElement.parentElement._dsListComponent as ListComponent<M, V> | undefined)?.focusFromSubView(
          walkElement,
          "visible",
          false
        );
      } else if (walkElement.parentElement._dsListComponent !== undefined) {
        // in case it's static
        (walkElement.parentElement._dsListComponent as ListComponent<M, V> | undefined)?.focusFromSubView(
          walkElement,
          "visible",
          false
        );
      }

      walkElement = walkElement?.parentElement ?? null;
    }
  }
  /**
   * focus a component by index. It doesn't check if the component accepts focus or not
   * @param index - component index to focus
   */
  async setFocusOnIndex(
    index?: number,
    options?: {
      focus?: boolean;
      animate?: boolean;
      scroll?: boolean;
      fromMouse?: boolean;
    }
  ): Promise<void> {
    this._listLogger.log(`setFocusOnIndex(${index}), focus: ${options?.focus ?? true}`);

    if (index === undefined) {
      // this will clear index & id
      this.focusedIndex$.value = undefined;
      return;
    }

    this.focusedFromMouse$.value = options?.fromMouse ?? false;

    // in the case we don't show this index, then fetch more & create views
    // if we're here from setting by id, it's been done already
    let view = this.viewFromIndex(index);
    if (index !== undefined && !view) {
      // always ask a full row (so last index is the last of the grid/column)
      await this._createViews({
        upToIndex: (Math.trunc(index / this.params.crossSectionWidth) + 1) * this.params.crossSectionWidth - 1,
      });
      view = this.viewFromIndex(index);
    }

    if (!getNonScrollingChildList(view))
      await this._setScrollPositionFromBounds(index, this.focusedIndex$.value, options);

    // }
    const newFocusedId = index !== undefined ? this._getFirstFocusableId(index, 1) : undefined;
    if (newFocusedId !== this.focusedId$.value) {
      this.focusedId$.value = newFocusedId;
    }
    if (this.focusedId$.value !== undefined) this.focusedIndex$.value = this.ids.indexOf(this.focusedId$.value);
    // update focused last to prevent too many loops?
    if (options?.focus ?? true) {
      this.focused$.value = true;
      this._updateFocusPath();
    }
  }

  /**
   * focus a component. It doesn't check if the component accepts focus or not
   * @param id - component to focus
   */
  async setFocusOnId(
    id?: string,
    options?: {
      focus?: boolean;
      animate?: boolean;
      scroll?: boolean;
    }
  ): Promise<void> {
    this._listLogger.log(`setFocusOnId(${id}), focus: ${options?.focus ?? true}`);

    if (id === undefined) {
      // this will clear index & id
      this.focusedIndex$.value = undefined;
      return;
    }
    let index = this.indexFromId(id);

    if (index !== undefined) {
      await this.setFocusOnIndex(index, options);
    } else {
      this._listLogger.debug(`setFocusOnId , before maybeFetch`);
      while (index === undefined && (await this._maybeFetch(this.ids.length + this.params.modelSourcePageLength))) {
        index = this.indexFromId(id);
      }
      index = this.indexFromId(id);
      this._listLogger.debug(`setFocusOnId , after maybeFetch`);
      index !== undefined && (await this.setFocusOnIndex(index, options));
    }
  }

  /**
   * returns next focusable index across the list in the specified direction
   * @returns undefined if nothing in that direction is focusable - or the next focusable index
   */
  _getNextFocusableIndexAcross = (offset: 1 | -1) => {
    let targetIndex = this.focusedIndex$.value ?? 0;
    const min =
      this.params.crossSectionWidth * Math.floor((this.focusedIndex$.value ?? 0) / this.params.crossSectionWidth);
    const max = Math.max(0, Math.min(min + this.params.crossSectionWidth, this.ids.length) - 1);
    do {
      targetIndex = targetIndex + offset;
      if (Math.min(Math.max(targetIndex, min), max) !== targetIndex) {
        this._listLogger.info(`tried to focus (across) an index (${targetIndex}) out of bound [${min} to ${max}]`);
        // blocked
        return undefined;
      }
    } while (this.viewFromIndex(targetIndex)?.rejectsFocus?.() ?? false);
    return targetIndex;
  };

  /**
   * returns next focusable index along the list in the specified direction
   * @returns undefined if nothing in that direction is focusable - or the next focusable index
   */
  _getNextFocusableIndexAlong = (direction: 1 | -1) => {
    // stepping along in a multiple column / width setup is a bit complicated.
    // First, figure out the current "line/column" the focus is in
    const previousFocusedIndex = this.focusedIndex$.value ?? 0;
    const previousFocusLine = Math.floor(previousFocusedIndex / this.params.crossSectionWidth);
    // which 'column' in the line was the focus in
    const previousFocusColumn = previousFocusedIndex - previousFocusLine * this.params.crossSectionWidth;
    // if source is incomplete,
    const maxFocusLine = this.params.modelSource$.isComplete$.value
      ? Math.floor(Math.max(this.params.modelSource$.value.length - 1, 0) / this.params.crossSectionWidth)
      : Number.POSITIVE_INFINITY;
    // then check if we can move a line
    let targetLine = previousFocusLine;
    do {
      targetLine = targetLine + direction;
      if (Math.min(Math.max(targetLine, 0), maxFocusLine) !== targetLine) {
        this._listLogger.info(`tried to focus (along) an index (${targetLine}) out of bound [0 to ${maxFocusLine}]`);
        // blocked
        return undefined;
      }
    } while (
      this.viewFromIndex(
        Math.min(
          Math.max(targetLine * this.params.crossSectionWidth + previousFocusColumn, 0),
          this.params.modelSource$.value.length - 1
        )
      )?.rejectsFocus?.() ??
      false
    );
    return Math.min(
      Math.max(targetLine * this.params.crossSectionWidth + previousFocusColumn, 0),
      this.params.modelSource$.value.length - 1
    );
  };

  /**
   * Finds 'view' index closest to a given point (x, y) in the specified range ("visible" or "page")
   * used by mouseListComponent
   * used by listComponent with spatialFocus
   * @param point - x/y number coordinates
   * @param boundType - "visible" | "page"
   * @returns
   */
  _viewIndexNearPoint(point: IPoint, boundType: "visible" | "page"): number | undefined {
    let nearestIndex = undefined;

    let closestDistance = Infinity;
    this.forEveryView(boundType, index => {
      const view = this.viewFromIndex(index);
      if (view) {
        const element = view.rootElement;
        const screenRect = DOMHelper.screenRectOf(element);
        this._listLogger.trace(`viewIndexNearPoint considering ${element?.id} - ${JSON.stringify(screenRect)}`);
        // if point is included,
        const distance = distanceBetweenPointAndRectSQR(point, screenRect);
        if (distance < closestDistance) {
          // figure out if any of the parents are rejecting focus (it's not a drill down op, it's a bubble up)
          let rejectsFocus = false;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let walkingElement: HTMLElement | null = view?.rootElement ?? null;
          while (walkingElement && !rejectsFocus) {
            rejectsFocus = rejectsFocus || (walkingElement._dsView?.rejectsFocus?.() ?? false);
            walkingElement = walkingElement.parentElement;
          }
          if (!rejectsFocus) {
            closestDistance = distance;
            nearestIndex = index;
          }
        }
      }
    });
    return nearestIndex;
  }

  /**
   * sets focus to the item closest to a given point in the pageRange
   * @param point - IPoint x/y number coordinates
   * @param scroll - @default true indicates if list should animate scroll to item
   */
  focusNearPoint(point: IPoint, scroll = true): void {
    this._listLogger.trace(`focusNearPoint ${JSON.stringify(point)}`);
    const targetFocusIndex = this._viewIndexNearPoint(point, "page");
    // if (targetFocusIndex !== undefined) {
    // this.setFocusedIndex(targetFocusIndex, true);
    void this.setFocusOnIndex(targetFocusIndex, { focus: true, scroll });
    // } else {
    //   // initial focus, try to find one
    //   const firstFocusableId = this.params.initialFocusId || this.getFirstFocusableId(1);
    //   firstFocusableId !== undefined && (this.focusedId = firstFocusableId); // this.setFocusedIndex(firstFocusableIndex, true);
    // }
  }

  focusFromSubView(viewRootElement: HTMLElement, boundType: "visible" | "page", fromMouse: boolean): void {
    if (this.ids.length === 0) return;
    this.forEveryView(boundType, index => {
      if (
        (this.viewFromIndex(index)?.rootElement.contains(viewRootElement) ?? false) &&
        (this.focusedIndex$.value !== index || !this.focused$.value)
      ) {
        void this.setFocusOnIndex(index, { focus: true, scroll: false, animate: false, fromMouse });
        return true; // we break
      }
    });
  }

  /**
   * indicates if list is in right-to-left mode
   * @returns
   */
  isRtl(): boolean {
    return (this.params.dir ?? DIR$.value) === DIR.rtl;
  }

  /**
   * onNav {@link INavigable} implementation.
   * @param key - Key pressed
   * @returns - true if the key pressed have been handled by the list or one of his child
   */
  public onNav(key: Keys) {
    // pass to focused first if any
    this._listLogger.log(`onNav ${key} in list  - focus is ${this.focusedIndex$.value}`);
    const view = this.viewFromIndex(this.focusedIndex$.value);

    const handled: boolean = callWithDelegateFallback(view, "onNav", key) ?? false;

    if (!handled) {
      this._listLogger.log(`onNav  will handle event`);
      switch (key) {
        case Keys.up:
        case Keys.down: {
          const direction = key === Keys.up ? -1 : 1;
          const nextIndex = this.params.scrollingMode.horizontal
            ? this._getNextFocusableIndexAcross(direction)
            : this._getNextFocusableIndexAlong(direction);
          if (nextIndex !== undefined) {
            void this.setFocusOnIndex(nextIndex);
            return true;
          } else return false;
        }

        case Keys.left:
        case Keys.right: {
          const direction = this.isRtl() ? (key === Keys.left ? 1 : -1) : key === Keys.left ? -1 : 1;
          const nextIndex = !this.params.scrollingMode.horizontal
            ? this._getNextFocusableIndexAcross(direction)
            : this._getNextFocusableIndexAlong(direction);
          if (nextIndex !== undefined) {
            void this.setFocusOnIndex(nextIndex);
            return true;
          } else return false;
        }

        case Keys.select: {
          const model = this.modelFromIndex(this.focusedIndex$.value);
          return (
            (model !== undefined &&
              this.focusedIndex$.value !== undefined &&
              this.params.onSelect?.(model, this.focusedIndex$.value)) ||
            false
          );
        }

        default:
          return false;
      }
    } else return true;
  }

  /**
   * rejectsFocus {@link IFocusable} implementation.\
   * indicates if the current focused View can be focused by calling the rejectsFocus method of the View
   * @returns true if the current focused View rejects the focus
   */
  public rejectsFocus(): boolean {
    return this.viewFromIndex(this.focusedIndex$.value)?.rejectsFocus?.() ?? false;
  }

  /**
   * onUnfocused {@link IFocusable} implementation.\
   * event triggered when a list component is no longer focused
   * @returns
   */
  public onUnfocused(): void {
    this.focused$.value = false;
    const view = this.viewFromIndex(this.focusedIndex$.value);
    this._listLogger.debug(`onUnfocused view:${view?.rootElement.id}`);
    this.params.updateChildrenClassOnFocus && view?.rootElement.classList.remove("focused");
    callWithDelegateFallback(view, "onUnfocused");
  }

  /**
   * onFocused {@link IFocusable} implementation.\
   * event triggered when a list component has been focused
   * @returns
   */
  public onFocused(): void {
    this._listLogger.debug(`onfocused hasFocus:${this.focused$.value}`);
    if (!this.focused$.value) {
      if (this.focusedIndex$.value !== undefined) {
        if (this.params.spatialFocus) {
          this._listLogger.trace(`onFocused spatial`);

          const focusedElement = this._focusedLeafView()?.rootElement;
          const focusedViewRect = DOMHelper.screenRectOf(focusedElement);
          const focusedViewCenter = centerOf(focusedViewRect);
          this._listLogger.trace(`spatial focus from ${focusedElement?.id} - ${JSON.stringify(focusedViewCenter)}`);
          focusedViewCenter && this.focusNearPoint(focusedViewCenter, true);
        } else {
          this._listLogger.trace(`onFocused focusedIndex:${this.focusedIndex$.value}`);

          const view = this.viewFromIndex(this.focusedIndex$.value);
          this.params.updateChildrenClassOnFocus && view?.rootElement.classList.add("focused");
          callWithDelegateFallback(view, "onFocused");
        }
      }
      this.focused$.value = true;
    } else {
      this._listLogger.trace(`onFocused already has focus`);
    }
  }

  _updateIndexes = (horizontal: boolean, newBounds: IListBounds, oldBounds: IListBounds) => {
    // prevent treating indexes if the scrolling occured on the other axis
    if (horizontal !== this.params.scrollingMode.horizontal) return;
    this._listLogger.log(
      `updateIndexes ${horizontal ? "horizontal" : "vertical"} ${JSON.stringify(newBounds)} from ${JSON.stringify(
        oldBounds
      )}`
    );
    let firstVisibleIndex = (() => {
      if (newBounds.visible.start <= 0) return this.ids.length ? 0 : undefined;
      if (newBounds.visible.start === oldBounds.visible.start) return this.visibleRange$.value.first;
      let index = 0;
      if (newBounds.visible.start > oldBounds.visible.start) {
        index = this.visibleRange$.value.first ?? 0;
        while (this._lastCreatedIndex !== undefined && index <= this._lastCreatedIndex) {
          const viewOffsetEnd = this.boundsFromIndex(index)?.along.end;
          if (viewOffsetEnd === undefined) return index - this.params.crossSectionWidth;
          if (viewOffsetEnd > newBounds.visible.start) return index;
          index += this.params.crossSectionWidth;
        }
      } else {
        // when going backwards, start from the previous last
        if (this.visibleRange$.value.last === undefined) return undefined;
        index = this.visibleRange$.value.last;
        while (index >= 0) {
          const viewOffsetStart = this.boundsFromIndex(index)?.along.start;
          if (viewOffsetStart === undefined) return index + this.params.crossSectionWidth;
          if (viewOffsetStart <= newBounds.visible.start) return index;
          index -= this.params.crossSectionWidth;
        }
      }

      return index;
    })();
    firstVisibleIndex =
      firstVisibleIndex !== undefined
        ? Math.min(
            Math.max(0, Math.trunc(firstVisibleIndex / this.params.crossSectionWidth)) * this.params.crossSectionWidth,
            this._lastCreatedIndex ?? 0
          )
        : undefined;

    // from the first visible, compute the last
    let lastVisibleIndex = (() => {
      if (firstVisibleIndex === undefined) return undefined;
      if (newBounds.visible.start === oldBounds.visible.start)
        return this.visibleRange$.value.last ?? this._lastCreatedIndex;

      let index = firstVisibleIndex;
      while (this._lastCreatedIndex !== undefined && index <= this._lastCreatedIndex) {
        const viewOffsetEnd = this.boundsFromIndex(index)?.along.end;
        if (viewOffsetEnd === undefined) return index - this.params.crossSectionWidth;
        if (viewOffsetEnd >= newBounds.visible.end) return index;
        index += this.params.crossSectionWidth;
      }

      return index;
    })();
    lastVisibleIndex =
      lastVisibleIndex !== undefined
        ? Math.min(
            Math.trunc(lastVisibleIndex / this.params.crossSectionWidth) * this.params.crossSectionWidth +
              this.params.crossSectionWidth -
              1,
            this._lastCreatedIndex ?? 0
          )
        : undefined;

    this.visibleRange$.value = {
      first: firstVisibleIndex,
      last: lastVisibleIndex,
    };

    // reactive based on scrollPosition, need to happen after views are created
    // update the first / last page element references
    let firstPageIndex = (() => {
      if (newBounds.page.start <= 0) return this.ids.length ? 0 : undefined;
      if (newBounds.page.start === oldBounds.page.start) return this.pageRange$.value.first;
      let index = 0;
      if (newBounds.visible.start > oldBounds.visible.start) {
        index = this.visibleRange$.value.first ?? 0;
        while (this._lastCreatedIndex !== undefined && index <= this._lastCreatedIndex) {
          const bounds = this.boundsFromIndex(index);
          const viewOffsetStart = bounds?.along.start;
          if (viewOffsetStart === undefined) return index - this.params.crossSectionWidth;
          if (viewOffsetStart >= newBounds.page.start || bounds instanceof ParentScrollingViewBounds) return index;
          index += this.params.crossSectionWidth;
        }
      } else {
        // when going backwards, start from the previous last - and the whole view needs to be visible
        if (this.visibleRange$.value.last === undefined) return undefined;
        index = this.visibleRange$.value.last;
        while (index >= 0) {
          const bounds = this.boundsFromIndex(index);
          const viewOffsetStart = bounds?.along.start;
          if (viewOffsetStart === undefined) return index + this.params.crossSectionWidth;
          if (viewOffsetStart === newBounds.page.start) return index;
          if (viewOffsetStart < newBounds.page.start) {
            if (bounds instanceof ParentScrollingViewBounds) return index;
            else return index + this.params.crossSectionWidth;
          }
          index -= this.params.crossSectionWidth;
        }
      }

      return index;
    })();
    firstPageIndex =
      firstPageIndex !== undefined
        ? Math.min(
            Math.max(0, Math.trunc(firstPageIndex / this.params.crossSectionWidth)) * this.params.crossSectionWidth,
            this._lastCreatedIndex ?? 0
          )
        : undefined;

    // from the first visible, compute the last
    let lastPageIndex = (() => {
      if (firstPageIndex === undefined) return undefined;
      if (newBounds.page.start === oldBounds.page.start && this.pageRange$.value.last !== undefined)
        return this.pageRange$.value.last;

      let index = firstPageIndex + this.params.crossSectionWidth;
      while (this._lastCreatedIndex !== undefined && index <= this._lastCreatedIndex) {
        const bounds = this.boundsFromIndex(index);
        const viewOffsetEnd = bounds?.along.end;
        if (viewOffsetEnd === undefined) return index - this.params.crossSectionWidth;
        if (viewOffsetEnd > newBounds.page.end) {
          if (bounds instanceof ParentScrollingViewBounds) return index;
          else return index - this.params.crossSectionWidth;
        }
        index += this.params.crossSectionWidth;
      }

      return index;
    })();
    lastPageIndex =
      lastPageIndex !== undefined
        ? Math.min(
            Math.trunc(lastPageIndex / this.params.crossSectionWidth) * this.params.crossSectionWidth +
              this.params.crossSectionWidth -
              1,
            this._lastCreatedIndex ?? 0
          )
        : undefined;

    this.pageRange$.value = {
      first: firstPageIndex,
      last: lastPageIndex,
    };
    // }

    // make sure we also update potential child list indexes
    this.forEveryView("visible", index => {
      const nonScrollingChildList = getNonScrollingChildList(this.viewFromIndex(index));
      // recursive part: if our last created view is a non scrolling list, let it create all its views and don't bother even doing the loop here
      // so we stop before generating a bound for this view (we don't know its extents yet)
      if (nonScrollingChildList) {
        const { translation } = nonScrollingChildList._referenceList();

        nonScrollingChildList._updateIndexes(
          horizontal,
          {
            visible: newBounds.visible.translate(-translation),
            page: newBounds.page.translate(-translation),
          },
          {
            visible: oldBounds.visible.translate(-translation),
            page: oldBounds.page.translate(-translation),
          }
        );
      }
    });
    this._listLogger.warn(
      "fv,fp,lp,lv,lc",
      this.visibleRange$.value.first,
      this.pageRange$.value.first,
      this.pageRange$.value.last,
      this.visibleRange$.value.last,
      this._lastCreatedIndex
    );
  };

  // TODO: confusion between onShown (as in, visible) and "inTree". Think about it?
  public onShown(): void {
    this._listLogger.warn(`onShown`);
    if (!this.shown$.value) {
      this.shown$.value = true;

      // if initial loading, show loading placeholders if defined
      if (this.params.modelSource$.version$.value === undefined) {
        this._showLoadingPlaceholders();
      }

      if (this._pendingRefreshParams !== undefined) {
        void this.refresh(this._pendingRefreshParams);
        this._pendingRefreshParams = undefined;
      }

      this._scrollCompletedUnregister?.();
      const { referenceList } = this._referenceList();
      this._scrollCompletedUnregister = referenceList.scrollAnimationCompletedTrigger.didSignal(() => {
        // after animation is over, hide views now not visible anymore
        referenceList._hideInvisibleItems();
      }, this);

      // go over visible elements to notify them - they haven't changed
      this.forEveryView("visible", index => callWithDelegateFallback(this.viewFromIndex(index), "onShown"));

      this._ensureVisibleItemsAreInScrollElement();
    }
  }

  public onHidden(): void {
    this._listLogger.warn(`onHidden`);
    if (this.shown$.value) {
      this.shown$.value = false;

      this._scrollCompletedUnregister?.();

      // go over visible elements to notify them
      this.forEveryView("visible", index => callWithDelegateFallback(this.viewFromIndex(index), "onHidden"));
    }
  }

  /** helper iterator over all visible tiles
   * @param callback - the function to call. If it returns true, the loop breaks
   */
  forEveryView = (type: "visible" | "page", callback: (index: number) => boolean | void) => {
    for (
      let index = (type === "visible" ? this.visibleRange$.value.first : this.pageRange$.value.first) ?? 0;
      index <=
      Math.min(
        this.ids.length - 1,
        (type === "visible" ? this.visibleRange$.value.last : this.pageRange$.value.last) ?? 0
      );
      index++
    ) {
      if (callback(index) === true) break;
    }
  };

  _focusedChildOf(view: IView | undefined): IView | undefined {
    if (!view) return undefined;

    if (isListComponent(view)) {
      return this._focusedChildOf(view.focusedView$.value) || view;
    } else if (isListComponent(view.delegate)) {
      return this._focusedChildOf(view.delegate.focusedView$.value) || view.delegate;
    } else if (isSwitchComponent(view)) {
      return this._focusedChildOf(view.shownView$.value) || view;
    } else if (isSwitchComponent(view.delegate)) {
      return this._focusedChildOf(view.delegate.shownView$.value) || view.delegate;
    } else return view;
  }

  _focusedLeafView(): IView | undefined {
    // first walk all the way up to the top list
    let topMostView: IView | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let walkingElement: HTMLElement | null = this.rootElement.parentElement ?? null;
    while (walkingElement) {
      topMostView = walkingElement._dsView ?? topMostView;
      walkingElement = walkingElement.parentElement;
    }

    // walk down the pages following focus path. I wonder how costly that is
    return this._focusedChildOf(topMostView);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _referenceList(): { referenceList: ListComponent<any, any>; translation: number } {
    if (this.params.scrollingMode.type === ScrollingType.none) {
      let walkingElement: HTMLElement | null = this.rootElement ?? null;
      while (walkingElement !== null) {
        if (walkingElement.parentElement?._dsListScrollElement === true) {
          const holderBounding = walkingElement.getBoundingClientRect();
          const childListBounding = this.rootElement.getBoundingClientRect();
          return {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            referenceList: walkingElement.parentElement.parentElement?._dsListComponent as ListComponent<any, any>,
            translation: Math.ceil(
              this.params.scrollingMode.horizontal
                ? (walkingElement._dsTranslate?.x ?? 0) + (childListBounding.left - holderBounding.left)
                : (walkingElement._dsTranslate?.y ?? 0) + childListBounding.top - holderBounding.top
            ),
          };
        }
        walkingElement = walkingElement.parentElement;
      }
    }
    return { referenceList: this, translation: 0 };
  }

  _listBounds(scrollPositionOverride?: number) {
    let cachedResult = scrollPositionOverride !== undefined ? this._listBoundsCache[scrollPositionOverride] : undefined;
    if (cachedResult !== undefined) return cachedResult;

    // always take the highest integer - DOM measurement is sometimes a bit short
    const pageLength = Math.ceil(
      this.params.scrollingMode.horizontal ? this._visibleSize.width : this._visibleSize.height
    );

    const visibleBefore = Math.ceil(
      this.params.scrollingMode.horizontal ? this._visibleSize.extraWidthLeft : this._visibleSize.extraHeightTop
    );
    const visibleAfter = Math.ceil(
      this.params.scrollingMode.horizontal ? this._visibleSize.extraWidthRight : this._visibleSize.extraHeightBottom
    );

    const scrollPosition = scrollPositionOverride ?? Math.trunc(this.scrollPosition$.value ?? 0);
    const pageStart = scrollPosition;
    const pageEnd = scrollPosition + pageLength;
    const visibleStart = pageStart - visibleBefore;
    const visibleEnd = pageEnd + visibleAfter;

    cachedResult = {
      visible: new ListRange(visibleStart, visibleEnd),
      page: new ListRange(pageStart, pageEnd),
    } as IListBounds;
    if (scrollPositionOverride !== undefined)
      this._listBoundsCache[scrollPositionOverride ?? Number.MAX_SAFE_INTEGER] = cachedResult;
    return cachedResult;
  }

  _setScrollPositionFromBounds = async (
    newFocusedIndex: number | undefined,
    oldFocusedIndex: number | undefined,
    options?: {
      focus?: boolean;
      animate?: boolean;
      scroll?: boolean;
      fromMouse?: boolean;
    }
  ) => {
    // don't bother computing if we aren't scrolling
    if (!(options?.scroll ?? true)) return;

    const { referenceList, translation } = this._referenceList();

    const newFocusedBounds = (() => {
      const bounds = this.boundsFromIndex(newFocusedIndex);
      return bounds && translation !== 0 ? new TranslatedViewBounds(bounds, { along: translation, across: 0 }) : bounds;
    })();

    const oldFocusedBounds = (() => {
      const bounds = this.boundsFromIndex(oldFocusedIndex);
      return bounds && translation !== 0 ? new TranslatedViewBounds(bounds, { along: translation, across: 0 }) : bounds;
    })();

    // if (this.rootElement.id.startsWith("Generic")) debugger;
    const targetScrollPosition = (() => {
      if (newFocusedBounds === undefined) return undefined;
      if (newFocusedBounds !== undefined) {
        // special case: we're scrolling farther than everything we've created ? return latest visible end bound?
        if (newFocusedBounds === undefined)
          return referenceList.boundsFromIndex(referenceList._lastCreatedIndex)?.along.end;

        const listBounds = referenceList._listBounds();
        // need to define if the scroll position should change
        if (oldFocusedBounds === undefined) {
          // special case? initial value?
          return undefined;
        } else {
          switch (referenceList.params.scrollingMode.type) {
            case ScrollingType.none:
              return listBounds.visible.start;

            // page: if newly focused element bounds are outside of the visible area, scroll to a new page
            case ScrollingType.page: {
              if (newFocusedIndex === undefined) return listBounds.visible.start;
              // in page mode, make sure focused element is fully visible in a page
              if (newFocusedBounds.along.end > listBounds.page.end) return newFocusedBounds.along.start;
              if (newFocusedBounds.along.start < listBounds.page.start)
                return newFocusedBounds.along.end - listBounds.page.length;
              // otherwise, we're in bounds, nothing to do
              return referenceList.scrollPosition$.value ?? 0;
            }

            case ScrollingType.slidingWindow: {
              // using visibility, figure boundaries
              if (newFocusedBounds.along.offsetStart < listBounds.page.start) {
                return newFocusedBounds.along.offsetStart;
              } else if (newFocusedBounds.along.end > listBounds.page.end) {
                // we push right, we align right
                return newFocusedBounds.along.end - listBounds.page.length;
              }
              // otherwise, we're in bounds, nothing to do
              return referenceList.scrollPosition$.value ?? 0;
            }

            case ScrollingType.elasticWindow: {
              return Math.max(0, newFocusedBounds.along.offsetEnd - listBounds.page.length);
            }

            case ScrollingType.anchored: {
              const anchorOffset = referenceList.params.scrollingMode.anchorOffset ?? 0;
              return newFocusedBounds !== undefined
                ? Math.max(
                    0,
                    Math.min(
                      newFocusedBounds.along.offsetStart - anchorOffset,
                      referenceList._maxAnchoredScrollPosition ?? Number.MAX_SAFE_INTEGER
                    )
                  )
                : 0;
            }
          }
        }
      }
      return 0;
    })();

    // first initial the scroll, to get the new elements in the DOM
    if (targetScrollPosition !== undefined) {
      await referenceList.setScrollPosition(targetScrollPosition, options?.animate ?? true);
    }
  };

  /** our current scroll operation ID. It's increased on every scroll. After a scroll completes, it's checked for match. If a scroll occured while a scroll was already going on, it's ignored */
  _scrollOpIdGenerator = 0;
  /**
   *
   * @param element
   * @param origin
   * @param scrollDuration
   * @returns true if wasn't called again while waiting for the animation to finish
   */
  updateDOMScrollPosition(element: HTMLElement, origin: IPoint, scrollDuration: number): Promise<boolean> {
    const scrollOpId = ++this._scrollOpIdGenerator;
    return new Promise(resolve => {
      if (scrollDuration) {
        element.style.transition === "" && (element.style.transition = `transform ${scrollDuration}ms ease`);
      } else {
        element.style.transition !== "" && (element.style.transition = "");
      }
      DOMHelper.setOrigin(element, origin, DIR$.value);
      element.style.position !== "" && (element.style.position = "");
      if (scrollDuration) {
        const listener = () => {
          resolve(scrollOpId === this._scrollOpIdGenerator);
          element.removeEventListener("transitionend", listener);
        };
        element.addEventListener("transitionend", listener);
      } else {
        resolve(true);
      }
    });
  }
}
