const debug = false;
// eslint-disable-next-line @typescript-eslint/ban-types
/**
 * simplifies the try/catch syntax for cleaner inline code
 * @param message - exception message to be dislayed
 * @param tryfunc - function to attempt
 * @param catchfunc - catch function in event of an exception
 * @returns
 */
 export function trycatch<T>(message: string, tryfunc: () => T, catchfunc?: () => void) {
  if (debug) {
    return tryfunc?.();
  } else {
    try {
      return tryfunc?.();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      void import("../uiLog").then(({ UILog: log }) => {
        log.ui.error(`Exception in ${message}`, error?.stack ?? error);
      });
      catchfunc?.();
    }
  }
}
