import { DS } from "~/libs";

/**
 * Force the WebMAF browser to load a fallback font for special caracters before it is needed
 */
export function loadPlaystationFallbackFont() {
  if (![DS.PlatformType.ps4, DS.PlatformType.ps5].includes(DS.platform.type)) return;

  const versionAppElt = document.querySelector("#versionApp");
  if (versionAppElt === null) return;

  DS.DOMHelper.createElement({
    tagName: "span",
    parent: versionAppElt,
    innerText: " ",
    style: {
      color: "transparent",
    },
  });
}
