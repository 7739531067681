import { z } from "zod";

export const MetadataChannel = z.object({
  rawdata: z.string(),
  // userplayout: z.boolean(),
  channel: z.string(),
  // "session": "",
  // "position": "cafimnu73dms72srcah0",
  // "marker": "",
  id: z.string(),
  // "mount": "",
  channelkey: z.string(),
  timestamp: z.string(),
  artist: z.string().optional(),
  song: z.string().optional(),
  station: z.string(),
  // "start": "07.06.2022 12:41:35",
  duration: z.number(),
  start_timestamp: z.number(),
  type: z.string(),
  // "etype": 0,
  // "isrc": "",
  // "cover": "http://waps3.rtbf.be/images/radio/SONS2_01/712ECAAC-7ED5-4026-9779-518EFE12E8D9_0.jpg",
  // "images": {
  //     "large": {
  //         "url": "http:\/\/waps3.rtbf.be\/images\/radio\/SONS2_01\/712ECAAC-7ED5-4026-9779-518EFE12E8D9_0.jpg"
  //     },
  //     "medium": {
  //         "url": "http:\/\/waps3.rtbf.be\/images\/radio\/SONS2_01\/712ECAAC-7ED5-4026-9779-518EFE12E8D9_0.jpg"
  //     },
  //     "small": {
  //         "url": "http:\/\/waps3.rtbf.be\/images\/radio\/SONS2_01\/712ECAAC-7ED5-4026-9779-518EFE12E8D9_0.jpg"
  //     }
  // },
  extdata: z
    .object({
      HeureDebut: z.string().transform(arg => (arg === "" ? undefined : arg)),
      HeureFin: z.string().transform(arg => (arg === "" ? undefined : arg)),
      TitreEmission: z.string().transform(arg => (arg === "" ? undefined : arg)),
      Presentateur: z.string().transform(arg => (arg === "" ? undefined : arg)),
      VisuelChaine: z.string().transform(arg => (arg === "" ? undefined : arg)),
      VisuelEmission: z.string().transform(arg => (arg === "" ? undefined : arg)),
      NomChaine: z.string().transform(arg => (arg === "" ? undefined : arg)),
      SloganChaine: z.string().transform(arg => (arg === "" ? undefined : arg)),
    })
    .nullish(),
});
export type MetadataChannel = z.infer<typeof MetadataChannel>;
