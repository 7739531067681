import { UILog } from "../uiLog";

/**
 * Used to read JSON file
 * @param json - JSON to read
 * @param key - JSON key to read
 * @return the value if value found or undefined
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function readFromJSON<T>(json: any, key: string, errorWhenMissing = false): T | undefined {
  if (json === null || json === undefined) {
    UILog.net.debug(`trying to read ${key} from undefined`);
    return undefined;
  }
  if (!(typeof json === "object")) {
    UILog.net.debug(`trying to read ${key} from a non object`);
    return undefined;
  }

  const value = json[key];
  if (value === undefined) {
    // lowering log level because that actually happens a *lot*
    if (errorWhenMissing) {
      UILog.net.error(`${key} is missing from ${JSON.stringify(json, null, 2)}`);
    } else {
      UILog.net.debug(`${key} is missing from ${JSON.stringify(json, null, 2)}`);
    }
    return undefined;
  }
  return value;
}

/**
 * Used to read a string value from a JSON file
 * @param json - JSON to read
 * @param key -  JSON key to read
 * @returns string value | target key value in string format
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function readStringFromJSON(json: any, key: string): string {
  if (json === null || json === undefined) {
    UILog.net.debug(`trying to read ${key} from undefined`);
    return `#no-${key}#`;
  }
  if (!(typeof json === "object")) {
    UILog.net.debug(`trying to read ${key} from a non object`);
    return `#no-${key}#`;
  }

  const value = json[key];
  if (value === undefined) {
    UILog.net.error(`${key} is missing from ${JSON.stringify(json, null, 2)}`);
    return `#no-${key}#`;
  }
  return value;
}
