import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { GigyaAccountConsentList, isGigyaAccountConsent, SiteConsentDetails } from "~/utils/gigya/models";

import { getFormatDate } from "./time";

export type ContractsFormated = Array<{
  key: string;
  isActive: boolean;
  contractName?: string;
  activationDate?: string;
  url: string;
}>;

class LogConsentManager {
  private static _instance: LogConsentManager;
  private _lastConsentVerificationDate?: Date;
  private _consentDetailsList: SiteConsentDetails = {};
  private _localStorageID = "logConsentVerificationDate";
  private _timeoutDelay: number = 24 * 60 * 60 * 1000; // 24h in milliseconds

  constructor() {
    const localStorageData = DS.Storage.getItem(this._localStorageID);
    if (localStorageData !== null && localStorageData !== "") {
      this._lastConsentVerificationDate = new Date(localStorageData);
    }
  }

  static get instance() {
    return (LogConsentManager._instance = LogConsentManager._instance ?? new LogConsentManager());
  }

  async getConsentDetails() {
    this._consentDetailsList = await APIGigyaOIDC.siteConsentsRequest();
  }

  public get consentDetails() {
    return this._consentDetailsList;
  }

  public get timeoutDelay(): number {
    if (this._lastConsentVerificationDate !== undefined) {
      const delta = new Date().getTime() - this._lastConsentVerificationDate.getTime();
      if (delta < this._timeoutDelay) return this._timeoutDelay - delta;
    }
    return this._timeoutDelay;
  }

  /**
   * Save last date where consents where modified or reset that date to undefined
   */
  updateLastConsentVerificationDate(resetDate = false) {
    if (resetDate === false) {
      this._lastConsentVerificationDate = new Date();
      DS.Storage.setItem(
        this._localStorageID,
        this._lastConsentVerificationDate !== undefined ? this._lastConsentVerificationDate.toUTCString() : ""
      );
    } else {
      this._lastConsentVerificationDate = undefined;
      DS.Storage.removeItem(this._localStorageID);
    }
  }

  formatContractDate(date: Date) {
    return `${getFormatDate(date, "dddd dd mmmm yyyy")} à ${getFormatDate(date, "mm:hh")}`;
  }

  /**
   * Format the consents of the website for easier usage in the app
   */
  getConsentPreferencesDetails = (): ContractsFormated => {
    const contractsDetailsList = [];
    for (const contractsKey in this._consentDetailsList) {
      contractsDetailsList.push({
        key: contractsKey,
        isActive: this._consentDetailsList[contractsKey].isActive,
        contractName: this._consentDetailsList[contractsKey].customData?.filter(data => {
          return data.key === "consentName";
        })?.[0]?.value,
        activationDate: this._consentDetailsList[contractsKey].customData?.filter(data => {
          return data.key === "activationDate";
        })?.[0]?.value,
        url: this._consentDetailsList[contractsKey].legalStatements.fr.documentUrl,
      });
    }
    return contractsDetailsList;
  };

  /**
   * Check if mandatory consents are accepted by the user
   */
  checkActiveConsentIsUpTodate = (userConsent: GigyaAccountConsentList | undefined): boolean => {
    const activeConsentList = Object.entries(this._consentDetailsList).filter(([_, consentValue]) => {
      return consentValue.isActive === true;
    });
    if (!activeConsentList.length) {
      return true; // no reference consent exist
    }

    if (userConsent === undefined) {
      return false; //all consent need to be accepted
    } else {
      //some contracts already sign
      const consentNotSigned = activeConsentList.filter(([consentKey, consentValue]) => {
        if (Object.keys(userConsent).includes(consentKey)) {
          //need to validate date
          const userFrDate = userConsent[consentKey].locales?.fr.docDate;
          const refFrDate = consentValue.legalStatements.fr.minDocDate;
          const userConsentDate = userFrDate !== undefined && new Date(userFrDate);
          const refConsentDate = refFrDate !== undefined && new Date(refFrDate);
          if (
            userConsentDate !== false &&
            refConsentDate !== false &&
            userConsentDate.getTime() < refConsentDate.getTime()
          ) {
            return true; //at least one consent is too old
          } else if (userConsentDate === undefined || refConsentDate === undefined) {
            return true; //consent have no data defined
          }
        } else {
          return true; // at least one consent mandatory need to be accepted
        }
        return false;
      });
      if (consentNotSigned.length > 0) return false;
      return true;
    }
  };

  /**
   * Check if optional consents aren't accepter by the user
   */
  checkPreConsentIsUpTodate = (userConsent: GigyaAccountConsentList | undefined): boolean => {
    const preConsentList = Object.entries(this._consentDetailsList).filter(([_, consentValue]) => {
      return consentValue.isActive === false;
    });
    if (!preConsentList.length) {
      return true; // no reference consent exist
    }
    const preConsentNotSigned = preConsentList.filter(([consentKey, consentValue]) => {
      if (!userConsent) {
        return true;
      } else {
        if (Object.keys(userConsent).includes(consentKey)) {
          //need to validate date
          const userFrDate = userConsent[consentKey].locales?.fr.docDate;
          const refFrDate = consentValue.legalStatements.fr.minDocDate;
          const userConsentDate = userFrDate !== undefined && new Date(userFrDate);
          const refConsentDate = refFrDate !== undefined && new Date(refFrDate);
          if (
            userConsentDate !== false &&
            refConsentDate !== false &&
            userConsentDate.getTime() < refConsentDate.getTime()
          ) {
            return true; //at least one consent is too old
          } else if (userConsentDate === undefined || refConsentDate === undefined) {
            return true; //consent have no data defined
          }
        } else {
          return true; // at least one consent  need to be accepted
        }
      }
      return false;
    });
    if (preConsentNotSigned.length > 0) return false;

    return true;
  };

  /**
   * Check if site consents, mandatory or optional, are accepted by the user
   */
  areConsentsUpdated = (forceCheck = false): false | "mandatory" | "optional" => {
    if (
      forceCheck === true ||
      this._lastConsentVerificationDate === undefined ||
      Date.now() > this._lastConsentVerificationDate.getTime() + this._timeoutDelay
    ) {
      const preferences = APIGigyaOIDC.userInfo$.value?.preferences;
      if (preferences !== undefined && preferences !== null) {
        const consentUpToDate = this.checkActiveConsentIsUpTodate(preferences);
        const preConsentUpToDate = this.checkPreConsentIsUpTodate(preferences);

        if (!consentUpToDate) {
          return "mandatory";
        } else if (!preConsentUpToDate) {
          return "optional";
        }
      }
    }
    return false;
  };

  /**
   * Recursive function to facilitate comparison of consents from userinfo and getSiteConsentDetails responses
   * Gigya consider . as an object separator when saving (not when querying site consents) and the client use . as a separator
   *
   * Exemple response :
   * -  {preferences: { terms: { siteTerms: consentDetail } } } (getAccountInfo endpoint)
   * -  {siteConsentDetails: { terms.siteTerms: consentDetail } } (getSiteConsentDetail endpoint)
   */
  keyCollapser = (obj: unknown, currentKey: string, refObject: Record<string, unknown>) => {
    if (typeof obj === "object" && obj) {
      Object.entries(obj).forEach(([key, value]) => {
        if (isGigyaAccountConsent(value)) {
          const newKey = currentKey + key;
          Object.assign(refObject, { [newKey]: value });

          Object.entries(value).forEach(([key, value]) => {
            if (isGigyaAccountConsent(value)) {
              try {
                this.keyCollapser(value, key + ".", refObject);
              } catch (e) {
                Object.assign(refObject, { [newKey + "." + key]: value });
              }
            }
          });
        } else {
          this.keyCollapser(value, key + ".", refObject);
        }
      });
    } else {
      throw new Error("bad account preferences structure");
    }
    return refObject;
  };
}

export const LogConsentHelper = LogConsentManager.instance;
