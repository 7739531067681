/**
 * Navigation related structures.
 *
 * A NavigationStack is created by calling {@link createNavigationStack} which returns an interface {@link INavigationStack}
 *
 * The concept of a stack is to push pages on top of a stack.
 *
 * Any page pushed on top of the stack will hide the page previously showing if any - except if the Page has declared that it's a popup via
 * the override of the isPopup(): true function
 *
 * By default, a page will be removed from the stack when the {@link Keys.back} key is pressed - If it is the last page on the stack the onExit callback will be called (if defined)
 *
 * @module Navigation
 */
import { inputMode$ } from "../const";
import { callUpTree, callWithDelegateFallback, firstMouseFocusableElement } from "../helpers/delegate";
import * as DOMHelper from "../helpers/DOMHelper";
import { Listenable } from "../helpers/Listenable";
import { IThrottleHandler, throttle } from "../helpers/timingHelper";
import { trycatch } from "../helpers/trycatch";
import { INavigationStack, INavigationStackParams, InputMode, IPage, IViewPersistency, Keys } from "../typings";
import { UILog } from "../uiLog";

/** @internal */
let lastMouseTarget: HTMLElement | null;

export class NavigationStack implements INavigationStack {
  rootElement: HTMLElement;
  pages$ = new Listenable<IPage[]>([]);
  onExit?: () => void;
  throttledOnNav: IThrottleHandler<(key: string) => void>;
  lastKey: string | undefined;

  constructor(params: INavigationStackParams) {
    this.rootElement = params.rootElement;
    this.onExit = params.onExit;

    // debug mouseMove as mouseDown
    // document.onmousedown = this.mousemoveHandler;

    document.onmousemove = this.mousemoveHandler;
    document.onmousedown = this.mousedownHandler;

    document.onwheel =
      params.wheelThrottle !== undefined
        ? throttle(this.mousewheelHandler, params.wheelThrottle, "immediate").func
        : this.mousewheelHandler;

    this.throttledOnNav = throttle(this.onNav, params.keyThrottle ?? 0, "immediate");
  }

  get topPage(): IPage | undefined {
    // will return undefined if length = 0
    return this.pages$.value[this.pages$.value.length - 1];
  }

  // add one on the stack. This is only happening when actually stacking screens (popup)
  // focus handling isn't handled here - assume page does it in its creator
  pushPage = (page: IPage): void => {
    // if a page is already in our stack, don't do a thing
    if (this.pages$.value.includes(page)) return;
    // remove old UI
    if (!(page.isPopup?.() ?? false)) {
      // clean up all previous popups - until we land on a non-popup page
      let index = this.pages$.value.length - 1;
      while (index >= 0) {
        DOMHelper.clean(this.pages$.value[index]?.rootElement);
        callWithDelegateFallback(this.pages$.value[index], "onHidden");
        if (!(this.pages$.value[index]?.isPopup?.() ?? false)) break;
        index--;
      }
    }
    // show new UI
    this.rootElement.appendChild(page.rootElement);
    callWithDelegateFallback(page, "onShown");

    // push it on the stack
    this.pages$.value = [...this.pages$.value, page];
    UILog.ui_nav.log(
      "nav stack after push",
      this.pages$.value.map(page => page.rootElement.id)
    );
  };

  // called by something which knows the page, take it out of the stack, but leave the responsability
  // of destroying it to the caller
  removePage = (page?: IPage, destroyingStack = false): void => {
    if (!page) return;
    // if a page isn't in our stack, don't do a thing
    if (!this.pages$.value.includes(page)) return;
    // remove old UI
    DOMHelper.clean(page.rootElement);
    callWithDelegateFallback(page, "onHidden");
    this.pages$.value = this.pages$.value.filter(stackPage => page !== stackPage);
    // show new UI
    if (this.topPage && !destroyingStack && !(page.isPopup?.() ?? false)) {
      // append back up all previous pages - until we land on a non-popup page
      let index = this.pages$.value.length - 1;
      while (index >= 0) {
        // don't appendChild here - we are going to top to bottom in our page stack, therefore the first to be appended is the topmost one
        // and next ones need to be inserted before the top one
        this.rootElement.insertBefore(
          this.pages$.value[index].rootElement,
          this.pages$.value[index + 1]?.rootElement ?? null
        );
        callWithDelegateFallback(this.pages$.value[index], "onShown");
        if (!(this.pages$.value[index]?.isPopup?.() ?? false)) break;
        index--;
      }
    }
    page.persistency !== IViewPersistency.static && callWithDelegateFallback(page, "onRelease");
    UILog.ui_nav.log(
      "nav stack after removePage",
      this.pages$.value.map(page => page.rootElement.id)
    );
  };

  destroyStack = (): void => {
    while (this.pages$.value.length > 0) {
      this.removePage(this.topPage, true);
    }
  };

  keyHandler = (key: string): void => {
    trycatch("navigationStack::keyhandler", () => {
      /* OLD CODE TODO: why we made this test? 
      const oldInputMode = inputMode$.value;
      inputMode$.value = InputMode.keys;
      // only handle the onNav event if we didn't revert from pointer to keys
      if (oldInputMode === InputMode.keys) {
        if (key !== this.lastKey) {
          this.lastKey = key;
          this.throttledOnNav.abort();
          this.onNav(key);
        } else {
          this.throttledOnNav.func(key);
        }
      }*/
      inputMode$.value = InputMode.keys;
      if (key !== this.lastKey) {
        this.lastKey = key;
        this.throttledOnNav.abort();
        this.onNav(key);
      } else {
        this.throttledOnNav.func(key);
      }
    });
  };

  onNav = (key: string) => {
    const page = this.topPage;
    UILog.debug.debug();
    UILog.debug.debug();
    UILog.debug.debug();
    if (!(callWithDelegateFallback(page, "onNav", key) === true) && key === Keys.back) {
      if (this.pages$.value.length > 1) {
        this.removePage(page);
      } else {
        this.onExit?.();
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  throttledMousemoveHandler = throttle(
    (ev: MouseEvent) => {
      if (ev.target && DOMHelper.isHtmlElement(ev.target)) {
        // first walk up the tree to find if we reject or not
        const firstFocusableElement = firstMouseFocusableElement(ev.target);

        callUpTree(firstFocusableElement, (element, view) =>
          view.onMouseMove?.({ x: ev.clientX, y: ev.clientY }, element)
        );
      }
    },
    250,
    "immediate"
  );

  mousemoveHandler = (ev: MouseEvent): void => {
    inputMode$.value = InputMode.pointer;

    if (DOMHelper.isHtmlElement(ev.target)) {
      // if target has changed, abort current throttle to force the new target to process the event
      if (ev.target !== lastMouseTarget) this.throttledMousemoveHandler.abort();
      this.throttledMousemoveHandler.func(ev);
      lastMouseTarget = ev.target;
    }
  };

  mousedownHandler = (ev: MouseEvent): void => {
    inputMode$.value = InputMode.pointer;

    if (DOMHelper.isHtmlElement(ev.target) && ev.button === 0) {
      // get  first Element that isn't part of a rejected tree branch
      callUpTree(firstMouseFocusableElement(ev.target), (element, view) =>
        view.onMouseDown?.({ x: ev.clientX, y: ev.clientY }, element)
      );
    }
  };

  mousewheelHandler = (ev: WheelEvent): void => {
    UILog.ui_nav.debug("wheelHandler", inputMode$.value);
    // first wheel is just to turn back to pointer mode, not to wheel down
    inputMode$.value = InputMode.pointer;
    if (DOMHelper.isHtmlElement(ev.target)) {
      // mouse wheel is independent & always active
      callUpTree(ev.target, (element, view) =>
        view.onMouseWheel?.(ev.deltaY, { x: ev.clientX, y: ev.clientY }, element)
      );
    }
  };
}
