import "./parentalButtonView.scss";

import { DS } from "~/libs";

import { ITextElement, TextHelper } from "../../../tools/textHelper";
import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";

export class ParentalButtonView extends AcceptsMouseFocusView {
  label: string;
  button: ITextElement;

  constructor(disabled = true, label?: string) {
    super("ParentalButtonView", "parentalButtonView");
    this.label = "ParentalButton";
    this.button = TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "parentalButtonContainer",
      content: t(label ?? "parentalControl.confirmButtonText"),
    });
    if (disabled) this.button.get().classList.add("disabled");
  }

  async action() {
    return;
  }

  rejectsFocus() {
    if (this.button.get().classList.contains("disabled")) return true;
    return false;
  }

  enable() {
    this.button.get().classList.remove("disabled");
  }

  disable() {
    this.button.get().classList.add("disabled");
  }
}
export class SendPinButtonView extends AcceptsMouseFocusView {
  constructor() {
    super("SendPinButtonView", "sendPinButtonView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "parentalButtonContainer",
      innerText: t("parentalControl.sendPinButtonText"),
    });
  }
}
