import "./languageSelectionPage.scss";
import "~/pages/settings/settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";
import { RootMenuPage } from "~/rootMenu/rootMenuPage";

import { FocusTracker } from "../../../components/focusTracker";
import { SettingsSelectionButtonView } from "../../../components/views/settings/settingsSelectionButtonView/settingsSelectionButtonView";
import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { DevicePreferenceHelper } from "../../../tools/devicePreferencesManager";
import { Toast } from "../../../tools/uiHelper";
import { LanguageSubtitleButtons } from "../languageSubtitle/languageSubtitlePage";

export enum LanguageSelectionButtons {
  DISABLE = "DISABLE",
  FR = "FR",
  EN = "EN",
  QAA = "QAA", // version original (audio)
  QAD = "QAD", // audio description
  QHH = "QHH", // sous-titres pour mal voyants
}

export class LanguageSelectionPage extends RootMenuPage implements DS.IPage {
  constructor(type: LanguageSubtitleButtons) {
    super("LanguageSubtitletitlePage", "RootMenuPage", () => {
      return new LanguageSelectionPageView(type);
    });
  }
}

class LanguageSelectionPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _focusTracker?: FocusTracker;
  private _list: DS.IListComponent<LanguageSelectionButtons, SettingsSelectionButtonView>;

  constructor(type: LanguageSubtitleButtons) {
    super("languageSubtitleSettingsPageView", "languageSubtitleSettingsPageView settingsPages");
    const pageLogo = require("@assets/images/languageSubtitle/languageSubtitle_logo.png");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("languageSubtitlePage." + type),
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageIcon",
      style: {
        backgroundImage: `url(${pageLogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    const modelSourcebuttonList: LanguageSelectionButtons[] = [];

    if (type === LanguageSubtitleButtons.defaultLanguage) {
      // LANG AUDIO
      modelSourcebuttonList.push(
        LanguageSelectionButtons.FR,
        LanguageSelectionButtons.QAA,
        LanguageSelectionButtons.QAD,
        LanguageSelectionButtons.EN
      );
    } else {
      // SUBTITLES
      modelSourcebuttonList.push(
        LanguageSelectionButtons.DISABLE,
        LanguageSelectionButtons.FR,
        LanguageSelectionButtons.QHH,
        LanguageSelectionButtons.EN
      );
    }

    this.delegate = this._list = DS.createListComponent(
      {
        id: `languageSubtitle/list`,
        className: "languageSelectionList",
        modelSource$: new DS.ModelSource(modelSourcebuttonList),
        viewFactory: button => {
          return new SettingsSelectionButtonView(
            button,
            type === LanguageSubtitleButtons.defaultLanguage ? "audio" : "subtitle"
          );
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: btn => {
          const button = this._list.viewFromId(btn);
          if (button === undefined) return false;
          const devicePreferences = DevicePreferenceHelper.cachedPreferences();
          devicePreferences[button.type] = button.id;
          DevicePreferenceHelper.save(devicePreferences);
          Toast(t("toaster.change" + button.type) + t("languageSubtitleSelection." + button.id));

          navigationStack.removePage(navigationStack.topPage);

          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
        void list.setFocusOnIndex(0);
      }
    );
  }
}
