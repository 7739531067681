import { INavigationStack, INavigationStackParams } from "../typings";
import { NavigationStack } from "./navigationStack";

/**
 * Creates a navigation stack that will be "rooted" to the provided rootElement
 * @param params navigation stack params
 **/
export function createNavigationStack(params: INavigationStackParams): INavigationStack {
  return new NavigationStack(params);
}
