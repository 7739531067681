import "./tracksPlayerPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";
import { getAudioSelectionValueFromTrack, getSubtitlesSelectionValueFromTrack } from "~/tools/playerTracksHelper";

import { Config } from "../../config";
import { Listenable } from "../../libs/exports";
import { navigationStack } from "../../main";
import { DevicePreferenceHelper } from "../../tools/devicePreferencesManager";
import { IPlayer, IPlayerTrack } from "../../tools/player";
import { TextHelper } from "../../tools/textHelper";

enum TracksComponent {
  AUDIO = "audio",
  SUBTITLE = "subtitle",
  OK = "ok",
}

class ValidateView extends AcceptsMouseFocusView {
  constructor() {
    super("", "validateView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "text",
      innerText: "Valider",
    });
  }
}

class TrackView extends AcceptsMouseFocusView {
  constructor(track: IPlayerTrack) {
    super("", "track");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "innerSelection",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "outerSelection",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "label",
      innerText: track.track.label,
    });
    this.isSelected(track.active);
  }

  isSelected(value: boolean) {
    value ? this.rootElement.classList.add("selected") : this.rootElement.classList.remove("selected");
  }
}

class TrackListView extends AcceptsMouseFocusView {
  private _list;
  private _selected$: DS.Listenable<IPlayerTrack | undefined>;
  constructor(
    classname: string,
    title: string,
    modelSource$: DS.IModelSource<IPlayerTrack>,
    selected$: DS.Listenable<IPlayerTrack | undefined>,
    isPremium: boolean,
    listCallback?: (list: DS.IListComponent<IPlayerTrack, TrackView>) => void
  ) {
    super("", "trackListContainer " + classname);
    this._selected$ = selected$;
    TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "trackListTitle",
      content: title,
    });
    this.delegate = this._list = DS.createListComponent(
      {
        id: classname,
        className: `trackList ${isPremium === true && "isPremium"}`,
        modelSource$: modelSource$,
        viewFactory: track => {
          if (track.active) this._selected$.value = track;
          return new TrackView(track);
        },
        scrollingMode: { type: DS.ScrollingType.elasticWindow, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        noTransform: true,
        onSelect: track => {
          this._selected$.value = track;
          return true;
        },
      },
      list => {
        this._selected$.didChange((current, old) => {
          this._list.viewFromId(old?.id)?.isSelected(false);
          this._list.viewFromId(current?.id)?.isSelected(true);
        }, this);
        listCallback?.(list);
      }
    );
  }
}

export class TracksPlayerPage extends AcceptsMouseFocusView implements DS.IPage {
  private _player;
  private _onReleaseCallback: () => void;
  private _audioSelected$ = new Listenable<IPlayerTrack | undefined>(undefined);
  private _subtitleSelected$ = new Listenable<IPlayerTrack | undefined>(undefined);
  constructor(player: IPlayer, isPremium: boolean, onReleaseCallback: () => void) {
    super("TracksPlayerPage", isPremium === true ? "isPremium" : "");
    this._player = player;
    this._onReleaseCallback = onReleaseCallback;
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "",
      className: "gradient",
    });
    this.delegate = DS.createListComponent({
      id: "",
      className: "tracksList",
      modelSource$: new DS.ModelSource([TracksComponent.AUDIO, TracksComponent.SUBTITLE, TracksComponent.OK]),
      viewFactory: elem => {
        switch (elem) {
          case TracksComponent.AUDIO:
            return new TrackListView(
              "audioView",
              "Audio",
              new DS.ModelSource(player.audioTracks()),
              this._audioSelected$,
              isPremium,
              list => {
                void list.setFocusOnIndex(0);
              }
            );
          case TracksComponent.SUBTITLE:
            return new TrackListView(
              "subtitleView",
              "Sous - titres",
              new DS.ModelSource(player.subtitleTracks()),
              this._subtitleSelected$,
              isPremium
            );
          case TracksComponent.OK:
            return new ValidateView();
        }
      },
      crossSectionWidth: 2,
      scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
      noTransform: true,
      onSelect: elem => {
        switch (elem) {
          case TracksComponent.OK:
            if (this._audioSelected$.value) {
              player.audioTrack = this._audioSelected$.value;
              const language = getAudioSelectionValueFromTrack(this._audioSelected$.value);
              if (language !== undefined) {
                DevicePreferenceHelper.setPlayerAudioPreference(language);
              }
            }
            if (this._subtitleSelected$.value) {
              player.subtitleTrack = this._subtitleSelected$.value;
              const language = getSubtitlesSelectionValueFromTrack(this._subtitleSelected$.value);
              if (language !== undefined) {
                DevicePreferenceHelper.setPlayerSubtitlePreference(language);
              }
            }
            console.error("audio", player.audioTrack);
            console.error("subtitle", player.subtitleTrack);
            navigationStack.removePage(navigationStack.topPage);
            // We need to delay the application of the subtitle styles for it to work
            return true;
        }
        return false;
      },
    });
  }
  isPopup = () => true;

  onRelease() {
    super.onRelease();
    this._onReleaseCallback();
  }
}
