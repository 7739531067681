export function parseJwt(token: string) {
  try {
    let tokenCleaned = token.split(".")[1];
    if (token.includes("-") || token.includes("_")) {
      tokenCleaned = tokenCleaned.replace(/-/g, "+").replace(/_/g, "/");
    }
    return JSON.parse(window.atob(tokenCleaned));
  } catch (e) {
    return null;
  }
}
