import { z } from "zod";

import { Response } from "./base";
import { Widget } from "./widget";

const SearchResult = z.array(Widget);

export const SearchResponse = Response.extend({
  data: SearchResult,
});
