import "./contentCounterView.scss";

import { DS } from "~/libs";
import { formatTime } from "~/tools/time";

export class ContentCounterView extends DS.View {
  private _checkTimer = -1;

  constructor(countdownDate: Date, onCountdownEnded: (() => void) | undefined) {
    super("", "counterView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentCounter",
      innerText: t("generic.remaining_title"),
    });
    const counterTextElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentCounterText",
    });
    this._checkTimer = window.setInterval(() => {
      const now = new Date().getTime();
      const remainingTime = Math.max(countdownDate.getTime() - now, 0);

      if (remainingTime > 24 * 60 * 60 * 1000) return;
      else this.rootElement.style.visibility = "visible";

      const hour = formatTime(Math.floor(remainingTime / 1000 / 60 / 60));
      const minute = formatTime(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)));
      const second = formatTime(Math.floor((remainingTime % (1000 * 60)) / 1000));

      counterTextElement.innerText = `${hour} : ${minute} : ${second}`;
      if (remainingTime === 0) {
        window.clearInterval(this._checkTimer);
        onCountdownEnded?.();
      }
    }, 1000);
  }

  rejectsFocus() {
    return true;
  }

  onRelease() {
    super.onRelease();
    window.clearInterval(this._checkTimer);
  }
}
