import "./previousButtonPlayer.scss";

import { DS } from "~/libs";

import { IPlayer, IPlayerAsset } from "../../../../tools/player";
import { EmbedMedia, EmbedType, MediaCard } from "../../../../utils/rtbf/export";
import { ButtonPlayer } from "./buttonPlayer";

export class PreviousButtonPlayer extends ButtonPlayer {
  mediaCard?: MediaCard;
  embedType?: EmbedType;
  disabled = false;

  constructor(player: IPlayer) {
    super(player, "previousButtonPlayer", t("player.button.previous"));

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "previousImg img",
      style: {
        backgroundImage: `url(${require("@assets/images/player/controls/PREV.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    if ((player.asset$.value?.resource.embed as EmbedMedia)?.previous === undefined) {
      this.disabled = true;
    } else {
      this.mediaCard = (player.asset$.value?.resource.embed as EmbedMedia).previous;
      this.embedType = (player.asset$.value as IPlayerAsset).type;
    }

    this.disabled && this.rootElement.classList.add("disabled");
    this.rootElement.hidden = this.disabled;
  }

  onSelect(): boolean {
    if (this.mediaCard && this.mediaCard.id && this.embedType) {
      void this.player.load(this.mediaCard.id, this.embedType);
      this.player.play(true);
      this.player.showControls$.value = true;
    }

    return true;
  }

  rejectsFocus = () => this.disabled;
}
