import { IPlatform, PlatformType } from "../../typings";

/**
 * Some globals that aren't already defined
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    webOS?: {
      libVersion: string;
      platform: {
        tv: boolean;
      };
      platformBack: () => void;
    };
  }
}

export function init(): (Partial<IPlatform> & { type: PlatformType.webos }) | undefined {
  // careful! any code here needs to work *without* polyfills!!
  // String.includes for example doesn't. So do an initial first pass on the platform type to set the polyfills
  if (navigator.userAgent.match(new RegExp("web(?:o|0)s", "i"))) {
    require("ts-polyfill");
    // now we have polyfills in place
    require("./webOSTV.js");

    const version = (() => {
      // parse version. assume highest by default as we don't know about future naming schemes but we know about past ones
      const chromeVersion = parseInt(navigator.userAgent.match(new RegExp("Chrome/([^.]*)"))?.[1] ?? "0");
      const safariVersion = parseInt(navigator.userAgent.match(new RegExp("Safari/([^.]*)"))?.[1] ?? "0");
      switch (chromeVersion) {
        case 53:
          return "4.x";
          break;

        case 38:
          return "3.x";
          break;

        default:
          if (chromeVersion === 0) {
            // then it must be 1 or 2?
            switch (safariVersion) {
              case 537:
                return "1.x";
                break;

              case 538:
                return "2.x";
                break;
            }
          }
          break;
      }
      return "5.x ";
    })();

    const alternativeCSS = parseInt(version?.[0] ?? "0") <= 3;

    const exit = () => {
      window.close();
    };

    return {
      type: PlatformType.webos,
      version,
      alternativeCSS,
      exit,
    };
  }
}
