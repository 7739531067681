import { trycatch } from "../../ui/helpers/trycatch";
import { ILogCaller, ILogEntry, LogLevel } from "../types";

// import { consoleError } from "../log";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let util: {
  inspect: (obj: any) => string;
};
try {
  util = require("util");
} catch (error) {
  // consoleError("util not found!");
}

let getCaller: (index: number) => ILogCaller | undefined;
const isLocal = false;
// const isLocal = /localhost|[0-9]{2,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}|::1|\.local|local|^$/gi.test(
//   window.location.hostname
// )
//   ? true
//   : false;

if (isLocal) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCaller = (require("./es5reConnector") as any).consolere.getCaller;
}

const logLevelToString = {
  [LogLevel.error]: "E",
  [LogLevel.warn]: "W ",
  [LogLevel.info]: "I",
  [LogLevel.debug]: "d",
  [LogLevel.trace]: "t",
};

const first = Date.now();
function timestampAsString(timestamp: number) {
  const hundredth = Math.floor((timestamp % 1000) / 10),
    seconds = Math.floor((timestamp / 1000) % 60),
    minutes = Math.floor((timestamp / (1000 * 60)) % 60),
    hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24);

  return (
    (hours < 10 ? `0${hours}` : `${hours}`) +
    ":" +
    (minutes < 10 ? `0${minutes}` : `${minutes}`) +
    ":" +
    (seconds < 10 ? `0${seconds}` : `${seconds}`) +
    "." +
    (hundredth < 10 ? `0${hundredth}` : `${hundredth}`)
  );
}

class OutputBuffer {
  maxEntries = 1000;
  buffer: ILogEntry[] = [];

  trace = (namespace: string, ...data: unknown[]) => {
    this.logWithLevel(LogLevel.trace, namespace, ...data);
  };
  debug = (namespace: string, ...data: unknown[]) => {
    this.logWithLevel(LogLevel.debug, namespace, ...data);
  };
  log = (namespace: string, ...data: unknown[]) => {
    this.logWithLevel(LogLevel.info, namespace, ...data);
  };
  info = (namespace: string, ...data: unknown[]) => {
    this.logWithLevel(LogLevel.info, namespace, ...data);
  };
  warn = (namespace: string, ...data: unknown[]) => {
    this.logWithLevel(LogLevel.warn, namespace, ...data);
  };
  error = (namespace: string, ...data: unknown[]) => {
    this.logWithLevel(LogLevel.error, namespace, ...data);
  };

  logWithLevel(level: LogLevel, namespace: string, ...data: unknown[]): boolean {
    return (
      trycatch("logWithLevel", () => {
        // not interested in some specific reduxLogger messages
        if (data.length && data[0] === "—— log end ——") return false;
        if (level !== LogLevel.off) {
          // always log errors
          const output = data
            .map(obj => {
              switch (typeof obj) {
                case "object":
                case "function":
                  return (util?.inspect(obj) ?? obj!.toString()).substring(0, 2048);

                case "undefined":
                  return "undefined";

                default:
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return (obj as any).toString();
              }
            })
            .join(" ");
          // only do some cleanup when reaching +10%
          if (this.buffer.length > Math.floor(this.maxEntries * 1.1)) {
            this.buffer.splice(0, Math.floor(this.maxEntries * 0.1) + 1);
          }

          // elapsed();
          const timestamp = Date.now() - first;
          this.buffer.push({
            level,
            caller: isLocal ? getCaller(7) : undefined,
            levelAsString: logLevelToString[level],
            namespace,
            timestamp,
            timestampAsString: timestampAsString(timestamp),
            text: output,
          });
          return true;
        }
        return false;
      }) ?? false
    );
  }
}

export const outputBuffer = new OutputBuffer();
