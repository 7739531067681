export class StatusError extends Error {
  readonly statusCode: number;
  constructor(statusCode: number, message?: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, StatusError.prototype);

    this.statusCode = statusCode;
  }
}
