export class Mutex<T> {
  private _mutex = Promise.resolve();

  lock(): PromiseLike<() => void> {
    let begin: (unlock: () => void) => void = () => {};

    this._mutex = this._mutex.then(() => {
      return new Promise(begin);
    });

    return new Promise(resolve => {
      begin = resolve;
    });
  }

  async dispatch(fn: (() => T) | (() => PromiseLike<T>)): Promise<T> {
    const unlock = await this.lock();
    try {
      return await Promise.resolve(fn());
    } finally {
      unlock();
    }
  }
}
