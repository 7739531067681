import { DS } from "~/libs";

import { navigationStack } from "../main";
import { ErrorPage } from "../pages/popup/popupPage";
import { RootMenuPage } from "../rootMenu/rootMenuPage";
import { RootMenuView } from "../rootMenu/rootMenuView";

export class RootPageHelper {
  private static _instance?: RootMenuPage | DS.IPage;
  static get instance() {
    return (RootPageHelper._instance =
      RootPageHelper._instance ??
      (navigationStack.topPage?.rootElement.className === "RootMenuPage" ? navigationStack.topPage : undefined));
  }
  static isTopPage() {
    return RootPageHelper.instance !== undefined;
  }
  static reset() {
    RootPageHelper._instance = undefined;
  }
}

/**
 * Help creating a popup ErrorPage.
 * @param goHomeAction define what to do when pressing goHome button.
 * @param title the error title.
 * @param desc  the error description.
 */
export const showErrorGoHomePopup = (goHomeAction?: () => void, title?: string, desc?: string) => {
  navigationStack.pushPage(
    new ErrorPage({
      title: title ?? t("error.title"),
      description: desc ?? t("error.desc"),
      button1: {
        label: t("error.button.home"),
        action:
          goHomeAction ??
          (async () => {
            await RootMenuView.refreshHome();
          }),
      },
    })
  );
};

/**
 * Help creating a popup ErrorPage.
 * @param backAction define what to do when pressing back button.
 * @param retryAction define what to do when pressing retry button.
 * @param title the error title.
 * @param desc  the error description.
 */
export const showErrorRetryPopup = (
  retryAction: () => void,
  backAction?: () => void,
  title?: string,
  desc?: string,
  retryLabel?: string
) => {
  navigationStack.pushPage(
    new ErrorPage({
      title: title ?? t("error.title"),
      description: desc ?? t("error.desc"),
      button1: {
        label: retryLabel ?? t("error.button.retry"),
        action: retryAction,
      },
      button2: {
        label: t("error.button.goBack"),
        action:
          backAction ??
          (async () => {
            if (RootPageHelper.isTopPage()) {
              await RootMenuView.refreshHome();
            } else {
              navigationStack.removePage(navigationStack.topPage);
            }
            void RootPageHelper.reset();
          }),
      },
    })
  );
};
