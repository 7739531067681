import "./restartButtonPlayer.scss";

import { DS } from "~/libs";

import { IPlayer } from "../../../../tools/player";
import { ButtonPlayer } from "./buttonPlayer";

export class RestartButtonPlayer extends ButtonPlayer {
  constructor(player: IPlayer) {
    super(player, "restartButtonPlayer", t("player.button.restart"));
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "restartImg img",
      style: {
        backgroundImage: `url(${require("@assets/images/player/controls/RESTART.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }

  onSelect(): boolean {
    this.player.play(false, true);
    return true;
  }
}
