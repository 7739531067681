import { Listenable } from "../helpers/Listenable";
import * as HTTPRequest from "../httpRequest";
import { IModelSource } from "../typings";
import { UILog } from "../uiLog";

export class ModelSource<M> extends Listenable<M[]> implements IModelSource<M> {
  sourceAsPromise?: Promise<M[]>;
  // the data set is always complete in this case, so always true
  isComplete$ = new Listenable(false);
  version$ = new Listenable<number | undefined>(undefined);

  /**
   * Create a model source from a array or a Promise array.
   * This class extends Listenable. This class is commonly used for the createListComponent modelSource$ parameter
   * @param source - initial data
   */
  constructor(source: Promise<M[]> | M[]) {
    // initialize either to the listenable value, or the source itself if not a promise
    super([]);
    if (source instanceof Promise) {
      // we keep it to await it on the first fetch
      this.sourceAsPromise = source;
    } else {
      // all the data is there already, set it
      this.value = source;
      this.version$.value = 1;
      this.isComplete$.value = true;
    }
  }

  // need to completely override the setter - TS isn't able to use super.value = newValue;
  // https://github.com/microsoft/TypeScript/issues/338
  set value(newValue: M[]) {
    if (this._currentValue !== newValue) {
      // ModelSource implementation
      this.version$.value = (this.version$.value ?? 0) + 1;

      // Listenable implementation
      const oldValue = this._currentValue;
      this._willChangeListeners.forEach(listener => listener(newValue, oldValue));
      this._currentValue = newValue;
      this._didChangeListeners.forEach(listener => listener(newValue, oldValue));
    }
  }
  // also need to completely override the getter as they both need to exist at the same "level"
  get value() {
    return this._currentValue;
  }

  async fetch(from: number, count?: number): Promise<M[]> {
    // if we had a promise as the input, we await for it, then use it
    if (this.sourceAsPromise) {
      this.value = await this.sourceAsPromise;
      // we won't await for it later.
      this.sourceAsPromise = undefined;
      // we now are complete
      this.isComplete$.value = true;
    }
    return this.value.slice(from, count !== undefined ? from + count : undefined);
  }
}

/**
 * Parameters for fetchModelSource
 * constraints on the api: it needs to return an array of objects, which will be modeled as M by the modelFactory
 */
export interface IFetchModelSourceParams<M> {
  /** a function taking a page number in, returning an object with the url to fetch and the options of the request */
  httpRequestMakeParamsGenerator: (page: number) => { url: string; options?: HTTPRequest.IOptions };
  /** from a json object, generate our model */
  modelFactory: (json: unknown) => [M[], boolean];
}

export class FetchModelSource<M> extends ModelSource<M> {
  // initial params
  params: IFetchModelSourceParams<M>;

  pageLastFetched?: number;
  pageFetching?: number;
  /** our list of potential waiters on fetch, waiting for previous fetches to complete
   * if fetch of page 0 is going on and fetch 1 is asked, it's "queued" in the sense it's added
   * to that object, waiting on page 0.
   * Once fetch 0 completes, we go over the list, and resolve the waiting ones, which in turn will fetch, and potentially unblock other calls, etc
   */
  private _waitingForPreviousContent: {
    requested: { from: number; count?: number };
    resolve: (value: M[] | PromiseLike<M[]>) => void;
  }[] = [];

  /**
   * Create a fetchable model source
   *
   * This class extends ModelSource. This class is commonly used for paginate endPoints
   *
   * Constraints on the api: it needs to return an array of objects, which will be modeled as M by the modelFactory
   * @param params - {@link IFetchModelSourceParams}
   */
  constructor(params: IFetchModelSourceParams<M>) {
    super([]);
    // we're starting without data
    this.version$.value = undefined;
    this.params = params;
    this.isComplete$.value = false;
  }

  async fetch(from: number, count?: number): Promise<M[]> {
    if (this.pageFetching !== undefined) {
      // we need to wait our turn
      UILog.net.debug(`(${from}, ${count}), on hold, page ${this.pageFetching} fetching`);
      return new Promise<M[]>(resolve => this._waitingForPreviousContent.push({ requested: { from, count }, resolve }));
    }

    UILog.net.debug(`(${from}, ${count}), starting loop, has ${this.value.length} elements`);

    while (this.value.length < from + (count ?? Number.MAX_SAFE_INTEGER) && !this.isComplete$.value) {
      try {
        // need to fetch more. We're giving a generic counter param as the input - we don't know how the API handles pagination
        // we'll keep asking incremental pages until we get back enough data for the fetch - or it's complete
        // first requested page is -1
        this.pageFetching = (this.pageLastFetched ?? -1) + 1;

        const httpRequestParams = this.params.httpRequestMakeParamsGenerator(this.pageFetching);
        UILog.net.info(`(${from}, ${count}), fetching`, httpRequestParams);
        const response = await HTTPRequest.make(httpRequestParams.url, httpRequestParams.options);
        const [additionalData, complete] = this.params.modelFactory(response.json);
        // NOT TRIGGERING THE LISTENABLE ON PURPOSE
        // the fetchModelSource would always mutate its content. It's not what we want here
        // I feel it's kind of a hack
        this.value.push(...additionalData);
        // we've retrieved something so now we have a version - and it won't change
        this.version$.value = this.version$.value ?? 1;
        this.isComplete$.value = complete;

        // data has been retrieved
        this.pageLastFetched = this.pageFetching;
        this.pageFetching = undefined;
        // }
      } catch (error: any) {
        UILog.net.error("modelSource fetch error", error?.stack ?? error);
        console.warn("timeout?", error instanceof HTTPRequest.Error);
        if (error?.timedOut !== false) {
          // retry?
        } else {
          // just stop on error
          this.isComplete$.value = true;
        }
        // we couldn't fetch last page, so we stay where we were
        this.pageFetching = undefined;
      }
    }

    UILog.net.info(`(${from}, ${count}), ended loop, now has ${this.value.length} elements`);

    const pendingMaybeFetches = [...this._waitingForPreviousContent];
    this._waitingForPreviousContent = [];
    pendingMaybeFetches.forEach(pendingMaybeFetch => {
      UILog.net.debug(
        `from: ${pendingMaybeFetch?.requested.from}, count: ${pendingMaybeFetch?.requested.count}, resumed`
      );
      pendingMaybeFetch.resolve(this.fetch(pendingMaybeFetch?.requested.from, pendingMaybeFetch?.requested.count));
    });

    UILog.net.debug(`(${from}, ${count}), returning data`);
    // now we have enough data! return what was asked for
    return this.value.slice(from, count !== undefined ? from + count : undefined);
  }
}
