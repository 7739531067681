import { DS } from "dslib-tv";
import { QrCode, QREcc } from "dslib-tv/log/UI/QRCodeLib";

import { Toaster, ToasterTile } from "../components/toaster/toaster";
import { ClassType } from "../components/views/content/contentButtonView/contentButtonView";
import { IPlayer, PlayerState } from "./player";

export function getClassType(startDate: Date, endDate: Date): ClassType {
  const now = new Date();
  if (startDate <= now && endDate > now) return ClassType.currentLive;
  else if (startDate >= now) return ClassType.futureLive;
  else return ClassType.passedLive;
}

export function Toast(text: string) {
  if (document.getElementById("toaster") === null) {
    const toaster = new Toaster();
    document.body.appendChild(toaster.rootElement);
  }
  const toasterTile = new ToasterTile(text);
  document.getElementById("toaster")?.appendChild(toasterTile.rootElement);
}

export class AudioAnimation extends DS.View {
  private _bars: HTMLElement[] = [];

  constructor(player: IPlayer, type: "player" | "sticky" = "player") {
    super("", "audioAnimation");

    // Create the bars
    const container = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: `audioAnimatedBarContainer`,
    });
    for (let index = 0; index < 5; index++) {
      this._bars.push(
        DS.DOMHelper.createElement({
          tagName: "div",
          parent: container,
          className: `audioAnimatedBar`,
        })
      );
    }

    // Select all the bars and animate them
    for (let i = 0; i < this._bars.length; i++) {
      this._bars.forEach(item => {
        // Random move
        item.style.animationDuration = `${Math.random() * (0.7 - 0.2) + 0.2}s`;
      });
    }

    // Play and pause animation
    player.state$.didChange(
      state => {
        switch (state) {
          case PlayerState.PLAYING:
            if (type === "player") this._playAnimation();
            if (type === "sticky") container.style.visibility = "visible";
            break;
          case PlayerState.ENDED:
          case PlayerState.PAUSED:
          case PlayerState.SEEKING:
            if (type === "player") this._pauseAnimation();
            if (type === "sticky") container.style.visibility = "hidden";
            break;
        }
      },
      this,
      true
    );
  }

  private _pauseAnimation() {
    this.rootElement.classList.add("pause");
    // this._bars.forEach(bar => bar.classList.add("pause"));
  }

  private _playAnimation() {
    // this._bars.forEach(bar => bar.classList.remove("pause"));
    this.rootElement.classList.remove("pause");
  }
}

/**
 * Return a QR code from a given URL
 * @param {string} url URL to generate the QR code
 * @param {string} className Classname to be associated with the styling
 */
export function createQRCode(url: string, className: string) {
  const qrCodeElement = DS.DOMHelper.createElement({
    tagName: "div",
    className: className,
  });
  const canvas = DS.DOMHelper.createElement({
    tagName: "canvas",
  });
  const qrCode = QrCode.encodeText(url, QREcc.HIGH);
  qrCode.drawCanvas(25, 0, canvas);
  qrCodeElement.style.backgroundImage = `url(${canvas.toDataURL()})`;

  return qrCodeElement;
}
