import "./contentMetaView.scss";

import { DS } from "~/libs";

import { CsaRatingView } from "../../csaView/csaRatingView";
import { ContentDurationView } from "../contentDurationView/contentDurationView";
import { ContentIconMetaView } from "../contentIconMetaView/contentIconMetaView";

export class ContentMetaView extends DS.View {
  constructor(params: {
    csaRating: string | null;
    hasMultilingual: boolean;
    hasSubtitle: boolean;
    hasAudio: boolean;
    duration: number;
    date?: string;
    textDuration: string;
    publishedTo?: { text: string; unavailableSoon: boolean };
  }) {
    super("", "contentMetaView");

    const metaViewElt = DS.DOMHelper.createElement({
      tagName: "span",
      parent: this.rootElement,
      className: "contentMeta",
    });

    if (params.csaRating !== null || params.hasMultilingual || params.hasSubtitle || params.hasAudio) {
      const iconsContainer = DS.DOMHelper.createElement({
        tagName: "div",
        parent: metaViewElt,
        className: "iconsContainer",
      });

      if (params.csaRating !== null) {
        const csaRatingSpan = DS.DOMHelper.createElement({
          tagName: "span",
          parent: iconsContainer,
          className: "csaRatingSpan",
        });
        new CsaRatingView(params.csaRating, csaRatingSpan);
      }

      if (params.hasMultilingual || params.hasSubtitle || params.hasAudio) {
        new ContentIconMetaView(iconsContainer, params.hasMultilingual, params.hasSubtitle, params.hasAudio);
      }
    }

    this.rootElement.appendChild(
      new ContentDurationView(params.duration, params.textDuration, params.date, params.publishedTo).rootElement
    );
  }

  rejectsFocus() {
    return true;
  }
}
