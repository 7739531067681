import { IListenable, IReleaseCollector, Listener } from "../typings";

export class Listenable<T> implements IListenable<T> {
  protected _currentValue: T;
  protected _willChangeListeners: Listener<T>[] = [];
  protected _didChangeListeners: Listener<T>[] = [];

  constructor(initialValue: T) {
    this._currentValue = initialValue;
  }
  get value(): T {
    return this._currentValue;
  }
  set value(newValue: T) {
    if (this._currentValue !== newValue) {
      const oldValue = this._currentValue;
      this._willChangeListeners.forEach(listener => listener(newValue, oldValue));
      this._currentValue = newValue;
      this._didChangeListeners.forEach(listener => listener(newValue, oldValue));
    }
  }
  /**
   * Register to value changes
   * @param listener - the listener callback function
   * @param collector - the collector which will be used to call the listener unregister. If null, the listener will not be removed, so careful!
   * @param callListenerInitially - (optional) if set to true, the listener callback provided will be called right away with the current value
   * @returns the unregister function
   */
  willChange = (
    listener: Listener<T>,
    collector: IReleaseCollector | null,
    callListenerInitially = false
  ): (() => void) => {
    if (!this._willChangeListeners.includes(listener)) {
      this._willChangeListeners.push(listener);
      if (callListenerInitially) listener(this._currentValue, this._currentValue);
    }
    const unregister = () => {
      this._willChangeListeners = this._willChangeListeners.filter(
        registeredListener => registeredListener !== listener
      );
    };
    collector?.collectRelease(unregister);
    return unregister;
  };
  /**
   * Register to value changes
   * @param listener - the listener callback function
   * @param collector - the collector which will be used to call the listener unregister. If null, the listener will not be removed, so careful!
   * @param callListenerInitially - (optional) if set to true, the listener callback provided will be called right away with the current value
   * @returns the unregister function
   */
  didChange = (
    listener: Listener<T>,
    collector: IReleaseCollector | null,
    callListenerInitially = false
  ): (() => void) => {
    if (!this._didChangeListeners.includes(listener)) {
      if (this._didChangeListeners.length > 100) {
        void import("../uiLog").then(({ UILog: log }) => {
          log.perf.warn(
            `${this._didChangeListeners.length} listeners added to Listenable: \n"${listener
              .toString()
              .replace(/\n?\s+/g, " ")
              .substring(0, 128)}"`
          );
        });
      }
      this._didChangeListeners.push(listener);
      if (callListenerInitially) listener(this._currentValue, this._currentValue);
    }
    const releaseListener = () => {
      this._didChangeListeners = this._didChangeListeners.filter(registeredListener => registeredListener !== listener);
    };
    collector?.collectRelease(releaseListener);
    return releaseListener;
  };
}
