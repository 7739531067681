import "./contentTitleView.scss";

import { DS } from "~/libs";

export class ContentTitleView extends DS.View {
  constructor(title: string) {
    super("", "contentTitleView");
    const contentTitleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentTitle",
    });
    contentTitleElement.innerText = title;
  }

  rejectsFocus() {
    return true;
  }
}
