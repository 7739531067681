import "./numericKeyboardView.scss";

import { DS } from "dslib-tv";

import { Config } from "../../../config";
import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";
import { KeyView } from "./keyView";

export type NumericKeyboardKey =
  | DS.Keys.zero
  | DS.Keys.one
  | DS.Keys.two
  | DS.Keys.three
  | DS.Keys.four
  | DS.Keys.five
  | DS.Keys.six
  | DS.Keys.seven
  | DS.Keys.eight
  | DS.Keys.nine
  | "Delete";

const MODELSOURCE = new DS.ModelSource<{ id: string; key: NumericKeyboardKey }>([
  { id: "0", key: DS.Keys.zero },
  { id: "1", key: DS.Keys.one },
  { id: "2", key: DS.Keys.two },
  { id: "3", key: DS.Keys.three },
  { id: "4", key: DS.Keys.four },
  { id: "5", key: DS.Keys.five },
  { id: "6", key: DS.Keys.six },
  { id: "7", key: DS.Keys.seven },
  { id: "8", key: DS.Keys.eight },
  { id: "9", key: DS.Keys.nine },
  { id: "Delete", key: "Delete" },
]);

export class NumericKeyboardView extends AcceptsMouseFocusView {
  private _onSelectKey: (key: NumericKeyboardKey) => boolean;
  constructor(onSelectKey: (key: NumericKeyboardKey) => boolean) {
    super("NumericKeyboardView", "numericKeyboardView");
    this._onSelectKey = onSelectKey;
    this.delegate = DS.createListComponent(
      {
        id: "NumericKeyboardViewList",
        className: "numericKeyboardViewList",
        modelSource$: MODELSOURCE,
        viewFactory: model => new KeyView(model.id),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        mouseSupport: Config.mouseSupport,
        onSelect: model => this._onSelectKey(model.key),
      },
      list => {
        void list.setFocusOnIndex(5);
      }
    );
  }

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.zero:
      case DS.Keys.one:
      case DS.Keys.two:
      case DS.Keys.three:
      case DS.Keys.four:
      case DS.Keys.five:
      case DS.Keys.six:
      case DS.Keys.seven:
      case DS.Keys.eight:
      case DS.Keys.nine:
        return this._onSelectKey(key);
    }
    return false;
  }
}
