import "./programWidgetView.scss";
import "~/pages/parentalControl/parentalPopupPage.scss";

import { ProgramTileView } from "~/components/tiles/program/programTileView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GenericPage } from "../../../pages/generic/genericPage";
import { CtaCard, ProgramCard, Widget, WidgetProgramList } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { MoreContentTile } from "../../tiles/moreContent/moreContent";
import { WidgetView } from "../widgetView";

/**
 * Category widget, built by the GenericPage.
 * @param widget The category widget.
 */
export class ProgramWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;

  constructor(widget: Widget) {
    super(widget.id, "programWidget");
    this._setAnalytics(widget);
    // Widget title
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });
    this._addSubtitleIfAvailable(widget);

    // Widget list of content
    this.delegate = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource<ProgramCard | CtaCard>(this._content(widget))),
        viewFactory: card => {
          const media = ProgramCard.safeParse(card);
          if (media.success) {
            return new ProgramTileView(media.data, "listSize");
          }
          return new MoreContentTile(CtaCard.parse(card));
        },
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          if (card.resourceType === "PROGRAM") {
            super._analyticsCards({
              card_order: index,
              media_id: card.id,
              media_program: card.title,
              media_type: "vod",
            });
          }
          navigationStack.pushPage(new GenericPage(card.path));
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
      }
    );
  }

  /**
   * Fetch program list and return it, with a CTA if available, if contentPath is defined
   * Otherwise it returns the content stored in the widget object in the constructor (used for search)
   *
   * @param {Widget} widget
   * @return {Promise<(CtaCard | ProgramCard)[]>} List of items
   */
  private async _content(widget: Widget): Promise<(CtaCard | ProgramCard)[]> {
    try {
      const content: (CtaCard | ProgramCard)[] = (
        widget.contentPath !== undefined
          ? await APIAuvio.widget(widget.contentPath, WidgetProgramList)
          : WidgetProgramList.parse(widget)
      ).content;
      if (widget.cta !== undefined && widget.cta !== null) {
        content.push(widget.cta);
      }
      return content;
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }
}
