import "./checkboxView.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";

export class CheckboxView extends AcceptsMouseFocusView {
  private _checked$ = new DS.Listenable<boolean>(true);
  private _selected: HTMLDivElement;

  constructor(classname = "") {
    super(`CheckboxView`, `checkboxView ${classname}`);
    const checkbox = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "checkbox",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "checkboxLabel",
      innerText: t("onBoarding.secure_kids"),
    });
    this._selected = DS.DOMHelper.createElement({
      tagName: "div",
      parent: checkbox,
      className: "selectedIcon",
      style: {
        backgroundImage: `url(${require("@assets/images/icons/selected.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    this._checked$.didChange(value => {
      this._selected.style.visibility = value === true ? "visible" : "hidden";
    }, this);
  }

  toggle() {
    this._checked$.value = !this._checked$.value;
  }

  get value(): boolean {
    return this._checked$.value;
  }
}
