import { LogFactory } from "../log/logFactory";
import { platform } from "./platform";

// specific use inside this library
export const UILog = LogFactory.create(["keys", "ui", "ui_nav", "ui_list", "ui_switch", "net", "perf", "debug"]);

UILog.ui.warn("Useragent:        " + navigator.userAgent);
UILog.ui.warn("Platform type:    " + platform.type);
UILog.ui.warn("Platform version: " + platform.version);
UILog.ui.warn("device Name:      " + platform.deviceName);
UILog.ui.warn("device Id:        " + platform.deviceId);
