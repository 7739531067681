import { createNavigationStack } from "../ui/components/createNavigationStack";
import * as DOMHelper from "../ui/helpers/DOMHelper";

export const logNavigationStack = createNavigationStack({
  rootElement: DOMHelper.createElement({ tagName: "div", parent: window.document.body, id: "DSLogContainer" }),
  onExit: () => {
    // we're being called onExit on the last page - we don't want to leave it up
    logNavigationStack.destroyStack();
  },
});
