import { Listenable } from "../../ui/helpers/Listenable";
import { LogInfo } from "../logInfo";
import { LogLevel } from "../types";
import { outputBuffer } from "./outputBuffer";

class OutputConsoleRe {
  lastTimestamp?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consolere?: any;
  channel?: string;
  attached$ = new Listenable(false);

  trace = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.trace, namespace, ...data);
    outputBuffer.buffer.length && (this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp);
    console.re.trace(`[${namespace.toUpperCase()}]`, ...data);
  };
  debug = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.debug, namespace, ...data);
    outputBuffer.buffer.length && (this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp);
    console.re.debug(`[${namespace.toUpperCase()}]`, ...data);
  };
  log = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.info, namespace, ...data);
    outputBuffer.buffer.length && (this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp);
    console.re.log(`[${namespace.toUpperCase()}]`, ...data);
  };
  info = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.info, namespace, ...data);
    outputBuffer.buffer.length && (this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp);
    console.re.info(`[${namespace.toUpperCase()}]`, ...data);
  };
  warn = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.warn, namespace, ...data);
    outputBuffer.buffer.length && (this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp);
    console.re.warn(`[${namespace.toUpperCase()}]`, ...data);
  };
  error = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.error, namespace, ...data);
    outputBuffer.buffer.length && (this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp);
    console.re.error(`[${namespace.toUpperCase()}]`, ...data);
  };

  attach = (code: string) => {
    this.channel = `${LogInfo.appName.replace("/", "-")}-${code}`;
    const { consolere } = require("./es5reConnector");
    this.consolere = consolere.connect({
      channel: this.channel,
    });
    this.consolere?.mark(`--- session ${LogInfo.sessionId} ---`);

    outputBuffer.buffer.forEach(entry => {
      if (this.lastTimestamp === undefined || entry.timestamp > this.lastTimestamp) {
        switch (entry.level) {
          case LogLevel.trace:
            console.re.trace(`[${entry.namespace.toUpperCase()}]`, entry.text);
            break;
          case LogLevel.debug:
            console.re.debug(`[${entry.namespace.toUpperCase()}]`, entry.text);

            break;
          case LogLevel.info:
            console.re.info(`[${entry.namespace.toUpperCase()}]`, entry.text);

            break;
          case LogLevel.warn:
            console.re.warn(`[${entry.namespace.toUpperCase()}]`, entry.text);

            break;
          case LogLevel.error:
            console.re.error(`[${entry.namespace.toUpperCase()}]`, entry.text);
            break;
        }
      }
    });
    this.consolere?.mark(`--- end of buffered logs ---`);
    this.attached$.value = true;
  };

  detach = (): void => {
    this.consolere?.disconnectIO();
    this.attached$.value = false;
  };
}
export const outputConsoleRe = new OutputConsoleRe();
