import "./liveContentView.scss";

import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { capitalize } from "~/tools/textHelper";

import { Config } from "../../../config";
import { DS } from "../../../libs";
import { GenericPage } from "../../../pages/generic/genericPage";
import { PlayerPage } from "../../../pages/player/playerPage";
import { addDays, getFormatDate, getRemainingDays, isSameDay } from "../../../tools/time";
import { getClassType } from "../../../tools/uiHelper";
import { PageLiveContent } from "../../../utils/rtbf/models";
import {
  ClassType,
  ContentButton,
  ContentButtonsContainerView,
  ContentButtonWidgetType,
} from "../../views/content/contentButtonView/contentButtonView";
import { ContentCategoryChannelView } from "../../views/content/contentCategoryChannelView/contentCategoryChannelView";
import { ContentCounterView } from "../../views/content/contentCounterView/contentCounterView";
import { ContentDescriptionView } from "../../views/content/contentDescriptionView/contentDescriptionView";
import { ContentMetaView } from "../../views/content/contentMetaView/contentMetaView";
import { ContentSubtitleView } from "../../views/content/contentSubtitleView/contentSubtitleView";
import { ContentTimelineView } from "../../views/content/contentTimelineView/contentTimelineView";
import { ContentTitleGraphicView } from "../../views/content/contentTitleView/contentTitleGraphicView";
import { ContentTitleView } from "../../views/content/contentTitleView/contentTitleView";
import { ContentItem, ContentView } from "../contentView";

type LiveContentItem =
  | ContentItem.title
  | ContentItem.titleGraphic
  | ContentItem.subtitle
  | ContentItem.meta
  | ContentItem.categoryChannel
  | ContentItem.description
  | ContentItem.contentButtonsContainer
  | ContentItem.counter
  | ContentItem.timeline;

export class LiveContentView extends ContentView {
  private _classType: ClassType = ClassType.passedLive;
  private _buttons: ContentButton[] = [];
  private _content: PageLiveContent;
  private _startDate: Date;
  private _endDate: Date;
  private _modelSource$ = new DS.ModelSource<LiveContentItem>([]);
  private _textDuration = "";

  constructor(content: PageLiveContent) {
    super(content.id, "liveContentView");
    this._content = content;
    this._startDate = new Date(content.scheduledFrom);
    this._endDate = new Date(content.scheduledTo);

    this.delegate = DS.createListComponent({
      id: "liveContentList",
      className: "liveContentList",
      modelSource$: this._modelSource$,
      viewFactory: item => {
        switch (item) {
          case ContentItem.title:
            return new ContentTitleView(content.title);
          case ContentItem.titleGraphic:
            return new ContentTitleGraphicView(content.logo?.dark.png);
          case ContentItem.subtitle:
            return new ContentSubtitleView(content.subtitle);
          case ContentItem.meta:
            return new ContentMetaView({
              csaRating: content.rating ?? null,
              hasMultilingual: content.hasMultilingualVersions,
              hasSubtitle: content.hasSubtitles,
              hasAudio: content.hasAudioDescriptions,
              duration: content.duration,
              textDuration: this._textDuration,
            });
          case ContentItem.categoryChannel:
            return new ContentCategoryChannelView(this._classType, content.channel?.label, content.category?.label);
          case ContentItem.description:
            return new ContentDescriptionView(content.description);
          case ContentItem.contentButtonsContainer:
            return new ContentButtonsContainerView(this._buttons, content, this._classType);
          case ContentItem.counter:
            return new ContentCounterView(new Date(content.scheduledFrom), () => {
              if (APIGigyaOIDC.isConnected()) {
                void PlayerPage.playAsset(content.id, content.type, "LIVE", false, { rating: content.rating });
              }
            });
          case ContentItem.timeline:
            return new ContentTimelineView({
              from: content.scheduledFrom,
              to: content.scheduledTo,
              duration: content.duration,
              type: "LIVE",
            });
        }
      },
      scrollingMode: { type: DS.ScrollingType.elasticWindow, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
    });
  }

  update() {
    const now = new Date();

    this._classType = getClassType(this._startDate, this._endDate);
    const modelSource: LiveContentItem[] = [
      ContentItem.categoryChannel,
      this._content.logo !== null ? ContentItem.titleGraphic : ContentItem.title,
      ContentItem.subtitle,
      ContentItem.meta,
    ];
    if (this._classType === ClassType.currentLive) {
      modelSource.push(ContentItem.timeline);
    }
    modelSource.push(ContentItem.contentButtonsContainer, ContentItem.description);

    this._buttons = [];

    // Live is current
    if (this._classType === ClassType.currentLive) {
      // Timeline is displayed

      this._buttons.push({
        id: "LiveContentButton",
        type: ContentButtonWidgetType.liveContentButton,
        content: this._content,
      });
      if (this._content.isAdReplacement !== true && this._content.channel?.fastTv !== true) {
        this._buttons.push({
          id: "StovContentButton",
          type: ContentButtonWidgetType.stovContentButton,
          content: this._content,
        });
      }

      // if this is a serie with episodes, program is provisionned
      if (this._content.program !== null) {
        const path = this._content.program.path;
        this._buttons.push({
          id: `GenericContentButton${this._content.id}`,
          type: ContentButtonWidgetType.genericContentButton,
          genericLink: {
            id: `episodeButton${this._content.id}`,
            label: t("button.allEpisodes_title"),
            page: () => {
              return new GenericPage(path);
            },
          },
        });
      }

      // More details to display castings, tags and large description
      this._buttons.push({
        id: "InfoContentButton",
        type: ContentButtonWidgetType.infoContentButton,
        content: this._content,
      });
    } else if (this._classType === ClassType.futureLive) {
      // Live is in the future
      this.rootElement.classList.add("futureLive");

      this._buttons.push({
        id: "AlertContentButton",
        type: ContentButtonWidgetType.alertContentButton,
        content: this._content,
      });

      // if this is a serie with episodes, program is provisionned
      if (this._content.program !== null) {
        const path = this._content.program.path;
        this._buttons.push({
          id: `GenericContentButton${this._content.id}`,
          type: ContentButtonWidgetType.genericContentButton,
          genericLink: {
            id: `episodeButton${this._content.id}`,
            label: t("button.allEpisodes_title"),
            page: () => {
              return new GenericPage(path);
            },
          },
        });
      }

      // More details to display castings, tags and large description
      this._buttons.push({
        id: "InfoContentButton",
        type: ContentButtonWidgetType.infoContentButton,
        content: this._content,
      });

      if (isSameDay(this._startDate, now) === true) {
        this._textDuration = t("generic.today") + " " + t("generic.time_at") + " " + getFormatDate(this._startDate);
      } else if (isSameDay(this._startDate, addDays(now, 1)) === true) {
        this._textDuration = t("generic.tomorrow") + " " + t("generic.time_at") + " " + getFormatDate(this._startDate);
      }

      // Countdown timer
      if (getRemainingDays(this._startDate, now) <= 1) {
        modelSource.unshift(ContentItem.counter);
      } else {
        this._textDuration = `${capitalize(getFormatDate(this._startDate, "dddd dd mmmm yyyy"))} ${t(
          "generic.time_at"
        )} ${getFormatDate(this._startDate, "mmhhh")}`;
      }
    } else if (this._classType === ClassType.passedLive) {
      this._textDuration = t("generic.passed_live_title");
      this.rootElement.classList.add("passedLive");

      if (this._content.isAdReplacement !== true && this._content.channel?.fastTv !== true) {
        this._buttons.push({
          id: "StovContentButton",
          type: ContentButtonWidgetType.stovContentButton,
          content: this._content,
        });
      }

      // if this is a serie with episodes, program is provisionned
      if (this._content.program !== null) {
        const path = this._content.program.path;
        this._buttons.push({
          id: `GenericContentButton${this._content.id}`,
          type: ContentButtonWidgetType.genericContentButton,
          genericLink: {
            id: `episodeButton${this._content.id}`,
            label: t("button.allEpisodes_title"),
            page: () => {
              return new GenericPage(path);
            },
          },
        });
      }

      // Favorites
      this._buttons.push({
        id: "InfoContentButton",
        type: ContentButtonWidgetType.infoContentButton,
        content: this._content,
      });
    }
    this._modelSource$.value = modelSource;
  }

  /**
   * Here we use the onShown provided by the DS.View
   * So we can update the list each time we go back on it.
   * We do not forget to trigger a little delay, so this
   * function will activate right after the list creation.
   */
  async onShown() {
    await DS.delay(1);
    this.update();
  }
}
