import * as DOMHelper from "../helpers/DOMHelper";
import { trycatch } from "../helpers/trycatch";
import { IDelegate, IReleaseCollector, IView, IViewPersistency } from "../typings";
export class View implements IView, IReleaseCollector {
  readonly rootElement: HTMLDivElement;
  // defaults to no caching
  persistency = IViewPersistency.none;
  private _delegate?: IDelegate;
  private _toBeReleased: (() => void)[] = [];

  /**
   * Generic component used to create lists, pages, basic components, etc. used in an app's lifecycle.
   * @param id - string to be used as HTML tag ID
   * @param className - string to be used as HTML tag Class
   */
  constructor(id: string, className?: string) {
    this.rootElement = DOMHelper.createElement({ tagName: "div", id, className });
    // make it possible to bubble up from views to views via the DOM
    this.rootElement._dsView = this;

    // this is needed for some reason - otherwise it breaks with a non-defined this in some cases
    this.onRelease = this.onRelease.bind(this);
    this.collectRelease = this.collectRelease.bind(this);
  }

  get delegate(): IDelegate | undefined {
    return this._delegate;
  }

  set delegate(newDelegate: IDelegate | undefined) {
    if (this.delegate)
      throw new Error(`the view ${this.rootElement.id} already has a delegate!!! (${this.delegate.rootElement.id})`);
    this._delegate = newDelegate;
    // setting a delegate means setting as a child in the DOM
    if (newDelegate) {
      this.rootElement.appendChild(newDelegate.rootElement);
    }
  }

  collectRelease(release?: () => void) {
    release && this._toBeReleased.push(release);
  }

  /**
   * removes all elements and listeners from DOM tree
   */
  onRelease() {
    // need to clean the _dsView to prevent a circular reference which prevents garbage collection
    delete this.rootElement._dsView;

    trycatch("View.onRelease", () => {
      this._toBeReleased.forEach(release => release());
    });
  }
}
