import { UILog } from "../uiLog";

export class Counter {
  name: string;
  currentCount = 0;
  maxCount = 0;

  /**
   * creates a counter class that can be used to track iterations of a given value
   * @param name - string indicating the name of the counter, displayed in debug log
   */
  constructor(name: string) {
    this.name = name;
  }

  /**
   * increment the counter by  1
   * @param ref - string displayed in debug log
   */
  increase(ref?: string): void {
    this.currentCount++;
    UILog.perf.debug(`${this.name}: +++: ${this.currentCount}${ref !== undefined ? ` (${ref})` : ""}`);
    if (this.currentCount > this.maxCount) {
      this.maxCount = this.currentCount;
      UILog.perf.warn(`${this.name}: max increased: ${this.maxCount}`);
    }
  }

  /**
   * decrease the counter by  1
   * @param ref - string displayed in debug log
   */
  decrease(ref?: string): void {
    this.currentCount--;
    UILog.perf.debug(`${this.name}: --- : ${this.currentCount}${ref !== undefined ? ` (${ref})` : ""}`);
  }
}
