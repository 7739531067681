import * as DOMHelper from "../../ui/helpers/DOMHelper";
import { Listenable } from "../../ui/helpers/Listenable";
import { LogInfo } from "../logInfo";
import { outputOverlay } from "../outputs/outputOverlay";
import { QrCode, QREcc } from "./QRCodeLib";

DOMHelper.addStyleSheet(`
#QR {
  opacity: 0.5;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 3.5%;
  right: 2%;
  z-index: 1001;
  pointer-events: none;
}`);

export class LogQRImpl {
  private _canvas: HTMLCanvasElement;
  visible$ = new Listenable<string>(localStorage.getItem("ShowSessionQR") ?? "false");

  constructor(encodedText: string) {
    this._canvas = DOMHelper.createElement({
      tagName: "canvas",
      parent: outputOverlay.overlayElement,
      id: "QR",
    });
    try {
      if (typeof jest === "undefined") {
        const qr: QrCode = QrCode.encodeText(encodedText, QREcc.LOW); // Make the QR Code symbol
        qr.drawCanvas(10, 1, this._canvas); // Draw it on screen
      }
    } catch (error) {
      console.error("QR creation failed!", error);
    }
    // default to session ID
    this.visible$.didChange(this.updateVisibility, null, true);
  }

  updateVisibility = () => {
    localStorage.setItem("ShowSessionQR", this.visible$.value);
    if (this.visible$.value === "true") {
      document.body.appendChild(this._canvas);
    } else {
      this._canvas?.parentElement?.removeChild(this._canvas);
    }
  };
}

export const LogQR = new LogQRImpl(LogInfo.qrEncodedText);
