import { IReleaseCollector, ITrigger, TriggerListener } from "../typings";

export class Trigger<T = void> implements ITrigger<T> {
  private _listeners: TriggerListener<T>[] = [];
  private _callbacks: { resolve: (value: T | PromiseLike<T>) => void; reject: () => void }[] = [];

  signal(t: T) {
    this._listeners.forEach(listener => listener(t));
    this._callbacks.forEach(callback => callback.resolve(t));
    this._callbacks = [];
  }

  abort() {
    this._callbacks.forEach(callback => callback.reject());
    this._callbacks = [];
  }

  get onSignal() {
    return new Promise<T>((resolve, reject) => {
      this._callbacks.push({ resolve, reject });
    });
  }

  /**
   * Register to signals
   * @param listener - the listener callback function
   * @returns the unregister function
   */
  didSignal = (listener: TriggerListener<T>, collector: IReleaseCollector | null): (() => void) => {
    if (!this._listeners.includes(listener)) {
      this._listeners.push(listener);
    }
    const triggerRelease = () => {
      this._listeners = this._listeners.filter(registeredListener => registeredListener !== listener);
    };
    collector?.collectRelease(triggerRelease);
    return triggerRelease;
  };
}
