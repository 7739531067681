import "./settingsPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import {
  ClassType,
  ContentButton,
  ContentButtonsContainerView,
  ContentButtonWidgetType,
} from "~/components/views/content/contentButtonView/contentButtonView";
import { env } from "~/datas";
import { DS } from "~/libs";
import { LanguageSubtitlePage } from "~/pages/settings/languageSubtitle/languageSubtitlePage";
import { Toast } from "~/tools/uiHelper";

import { Config } from "../../config";
import { navigationStack } from "../../main";
import { RootMenuPage } from "../../rootMenu/rootMenuPage";
import { DidomiPopupPageView } from "../consent/didomi/didomiPopupPage";
import { PageTestView } from "../test/pageTest";
import { AboutPage } from "./about/aboutPage";
import { FAQPage } from "./faq/faqPage";
import { PlaybackSettingsPage } from "./playbackSettingsPage/playbackSettingsPage";

class SettingsPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _buttons: ContentButton[] = [
    {
      id: "LanguageGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "languageButton",
        label: t("settings.button.languageButton"),
        icon: "language",
        page: () => {
          return new LanguageSubtitlePage();
        },
      },
    },
    {
      id: "VideoSettingsGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "videoSettingButton",
        label: t("button.videoSetting_title"),
        icon: "videoSetting",
        page: () => {
          return new PlaybackSettingsPage();
        },
      },
    },
    {
      id: "HelpGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "helpButton",
        label: t("settings.button.helpButton"),
        icon: "help",
        page: () => {
          return new FAQPage();
        },
      },
    },
    {
      id: "ConsentGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "consentButton",
        label: t("settings.button.consentButton"),
        icon: "consent",
        page: () => {
          if (window.Didomi !== undefined) {
            return new DidomiPopupPageView(window.Didomi, { context: "settings" });
          } else {
            Toast(t("settings.didomiErrorToast"));
            throw new Error("Couldn't open Didomi page, SDK not loaded");
          }
        },
      },
    },
    {
      id: "AProposGenericContentButton",
      type: ContentButtonWidgetType.genericContentButton,
      genericLink: {
        id: "aProposButton",
        label: t("settings.button.aproposButton"),
        icon: "apropos",
        page: () => {
          return new AboutPage();
        },
      },
    },
  ];

  constructor() {
    super("settingsPageView", "settingsPageView settingsPages");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "settingsPageTitle",
      innerText: t("settings.settings_title"),
    });

    DS.DOMHelper.createElement({
      tagName: "span",
      parent: this.rootElement,
      className: "settingsPageAppVer",
      innerText: t("settings.settings_version") + " " + `${_APP_VERSION_}-${_APP_HASH_}-${_IS_DEV_ ? "dev" : "prod"}`,
    });

    this.delegate = DS.createListComponent({
      id: "settingsWidget",
      className: "settingsWidget",
      modelSource$: new DS.ModelSource<"Buttons">(["Buttons"]),
      viewFactory: () => new ContentButtonsContainerView(this._buttons, undefined, ClassType.setting),
      scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
    });
  }

  onNav(key: DS.Keys): boolean {
    if ([DS.Keys.zero, "ColorF2Yellow"].indexOf(key) !== -1 && env() === "UAT") {
      navigationStack.pushPage(new PageTestView());
    }
    return false;
  }
}

export class SettingsPage extends RootMenuPage implements DS.IPage {
  constructor() {
    super("SettingsPage", "RootMenuPage", () => {
      return new SettingsPageView();
    });
  }
}
