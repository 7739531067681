import "./playerControl.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";

import { Config } from "../../../../config";
import { DS } from "../../../../libs";
import { IPlayer, PlayerStreamType } from "../../../../tools/player";
import {
  PlayerAdReplacementProgressBar,
  PlayerAudioLiveProgressBar,
  PlayerAudioShiftProgressBar,
  PlayerVideoLiveProgressBar,
  PlayerVODProgressBar,
} from "../../progress/playerProgress";
import { PlayerButtons } from "../playerButtons";

enum PlayerControlsComponent {
  progressBar = "progressBar",
  progressBarVideoLive = "progressBarVideoLive",
  prograssBarAdReplacement = "prograssBarAdReplacement",
  progressBarAudioLive = "progressBarAudioLive",
  progressBarAudioShift = "progressBarAudioShift",
  buttons = "buttons",
}

export class PlayerControls extends AcceptsMouseFocusView {
  private _modelSource$ = new DS.ModelSource<PlayerControlsComponent>([]);
  constructor(player: IPlayer) {
    super("PlayerControls");

    player.type$.didChange(
      value => {
        if (value) {
          let progressBar = PlayerControlsComponent.progressBar;
          switch (value) {
            case PlayerStreamType.AUDIO_LIVE:
              progressBar = PlayerControlsComponent.progressBarAudioLive;
              break;
            case PlayerStreamType.VIDEO_SHIFT:
            case PlayerStreamType.VIDEO_LIVE:
            case PlayerStreamType.VIDEO_FAST_TV:
            case PlayerStreamType.VIDEO_FAST_TV_PAUSED:
              progressBar = PlayerControlsComponent.progressBarVideoLive;
              break;
            case PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT:
            case PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT:
              progressBar = PlayerControlsComponent.prograssBarAdReplacement;
              break;
            case PlayerStreamType.AUDIO_SHIFT:
              progressBar = PlayerControlsComponent.progressBarAudioShift;
              break;
          }
          this._modelSource$.value = [progressBar, PlayerControlsComponent.buttons];
        }
      },
      this,
      true
    );

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "gradient",
    });
    this.delegate = DS.createListComponent(
      {
        id: "",
        className: "playerControls",
        modelSource$: this._modelSource$,
        viewFactory: button => {
          switch (button) {
            case PlayerControlsComponent.progressBarVideoLive:
              return new PlayerVideoLiveProgressBar(player);
            case PlayerControlsComponent.progressBarAudioLive:
              return new PlayerAudioLiveProgressBar(player);
            case PlayerControlsComponent.progressBarAudioShift:
              return new PlayerAudioShiftProgressBar(player);
            case PlayerControlsComponent.progressBar:
              return new PlayerVODProgressBar(player);
            case PlayerControlsComponent.prograssBarAdReplacement:
              return new PlayerAdReplacementProgressBar(player);
            case PlayerControlsComponent.buttons:
              return new PlayerButtons(player);
          }
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        noTransform: true,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }

  onHidden() {
    this.rootElement.style.visibility = "hidden";
  }

  onShown() {
    this.rootElement.style.visibility = "visible";
  }
}
