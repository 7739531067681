import { APIRedbee } from "~/datas";
import { RedBeePlayerEvents } from "~/player/redbee/definitions";
import { ApiChannelAsset } from "~/utils/redbee/models/epg";

/**
 * Emit an event containing current assetId of a fast TV
 * This is needed for PlayStation players, this feature is included in RedBee js player
 */
export class FastTVProgramService {
  private _channelAssetId: string;
  private _eventTargetElement: HTMLElement;
  private _storedPrograms: ApiChannelAsset[] = [];
  private _watching?: ApiChannelAsset;
  private _refreshTimeoutId?: number;

  /**
   * Instanciate the service that will regularly check the programs of a Fast TV
   * @param {string} channelId The id used to request programs list
   * @param {HTMLElement} eventTargetElement The element used to emit PROGRAM_CHANGED events
   */
  constructor(channelId: string, eventTargetElement: HTMLElement) {
    this._channelAssetId = channelId;
    this._eventTargetElement = eventTargetElement;
    void this.ping();
  }

  private async _updatePrograms(): Promise<void> {
    const programs = await this._getPrograms();

    if (programs.length === 0) {
      return;
    }
    programs.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
    this._storedPrograms = programs;
  }

  private async _getPrograms(): Promise<ApiChannelAsset[]> {
    try {
      const { assets } = await APIRedbee.getFastTVPrograms(this._channelAssetId, 100);

      const storedStartTimes = this._storedPrograms.map(p => p.startTime);
      const nextPrograms = assets.filter(p => !storedStartTimes.includes(p.startTime));
      return [...this._storedPrograms, ...nextPrograms];
    } catch (e) {
      console.warn("[ProgramService] Failed to get programs", e);
      return [];
    }
  }

  private _getCurrentProgram(): ApiChannelAsset | undefined {
    const now = new Date().getTime();
    return this._storedPrograms.find(
      program => new Date(program.startTime).getTime() < now && new Date(program.endTime).getTime() > now
    );
  }

  private _getUpcomingProgram(): ApiChannelAsset | undefined {
    const now = new Date().getTime();
    return this._storedPrograms.find(program => new Date(program.startTime).getTime() > now);
  }

  /**
   * Emit a program change from local data, update data it if needed
   */
  async ping(): Promise<void> {
    let currentProgram = this._getCurrentProgram();
    let upcomingProgram = this._getUpcomingProgram();

    if (this._watching?.startTime != null && this._watching.startTime === currentProgram?.startTime) {
      return;
    }

    if (!currentProgram || !upcomingProgram) {
      await this._updatePrograms();
      currentProgram = this._getCurrentProgram();
      upcomingProgram = this._getUpcomingProgram();
    }

    // If there's still no programs, disable the ProgramService for 30s
    // Report no current program, i.e. only channel will be reported from the player
    if (!currentProgram) {
      this._planRefresh(30);
    } else {
      await this._handleProgramChanged(currentProgram, upcomingProgram);
    }
  }

  private async _handleProgramChanged(
    currentProgram: ApiChannelAsset,
    upcomingProgram?: ApiChannelAsset
  ): Promise<void> {
    this._watching = currentProgram;

    this._emit(RedBeePlayerEvents.PROGRAM_CHANGED, {
      program: currentProgram,
      upnext: upcomingProgram,
    });
    this._planRefresh();
  }

  /**
   * Plan a refresh in :
   * 1) x seconds
   * 2) at the end of the current program (or 30s if calculated delay doesn't make sense)
   */
  private _planRefresh(seconds?: number) {
    let delay = 0;

    if (seconds !== undefined) {
      delay = seconds * 1000;
    } else if (this._watching !== undefined) {
      delay = new Date(this._watching.endTime).getTime() - new Date().getTime();
      // If delay is negative, plan a refresh in 30s
      if (delay < 0) delay = 30 * 1000;
    }

    clearTimeout(this._refreshTimeoutId);
    this._refreshTimeoutId = window.setTimeout(() => {
      void this.ping();
    }, delay);
  }

  private _emit(event: RedBeePlayerEvents, data: unknown): void {
    this._eventTargetElement.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  destroy() {
    window.clearTimeout(this._refreshTimeoutId);
  }
}
