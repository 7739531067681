import "./moreContent.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { CtaCard } from "../../../utils/rtbf/models";

export class MoreContentTile extends AcceptsMouseFocusView {
  constructor(cta: CtaCard, additionnalStyle?: "program") {
    super("", "MoreContentTile tile");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: `cardContainer moreContent ${additionnalStyle ?? ""}`,
      innerText: cta.label,
    });
  }
}
