import { Listenable } from "./helpers/Listenable";
import { DIR, InputMode } from "./typings";

export const DIR$ = new Listenable<DIR>(DIR.ltr);
// listen to RTL updates
DIR$.didChange((dir: DIR) => {
  document.dir = dir;
}, null);

export const inputMode$ = new Listenable(InputMode.keys);
