import "./infoButtonPlayer.scss";

import { DS } from "~/libs";

import { navigationStack } from "../../../../main";
import { InfoCastingPlayerPage } from "../../../../pages/player/infoCastingPlayerPage";
import { IPlayer } from "../../../../tools/player";
import { ButtonPlayer } from "./buttonPlayer";

export class InfoButtonPlayer extends ButtonPlayer {
  constructor(player: IPlayer) {
    super(player, "infoButtonPlayer", t("player.button.info"));
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "infoImg img",
      style: {
        backgroundImage: `url(${require("@assets/images/player/controls/INFO.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }
  onSelect() {
    if (this.player.asset$.value?.isFastTV !== true) {
      this.player.pause();
    }
    navigationStack.pushPage(new InfoCastingPlayerPage(this.player));
    return true;
  }
}
