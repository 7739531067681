import "./FavorisSpecificView.scss";

import { DS } from "~/libs";

import { EmptyCard, GuestCard } from "../../../utils/rtbf/models";
import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";

export class FavorisSpecificView extends AcceptsMouseFocusView {
  constructor(card: GuestCard | EmptyCard) {
    super("FavorisSpecificView", "FavorisSpecificView");

    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "favorisSpecific",
      className: "cardContainer",
    });
    if (card.background !== null)
      cardContainer.style.background = ` linear-gradient(90deg, #000 30%, rgba(255, 255, 255, 0) 70%) ,url("${card.background?.xl}") 100% 100% / cover no-repeat`;
    if (card.icon !== null)
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: cardContainer,
        className: "logoContainer",
        style: {
          backgroundImage: `url(${card.icon.xs})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "text",
      innerText: card.description,
    });
    if (card.cta !== null)
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: cardContainer,
        id: "cta",
        className: "text",
        innerText: card.cta.label,
      });
  }
}
