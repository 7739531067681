import "./goToLiveButtonPlayer.scss";

import { DS } from "~/libs";

import { IPlayer } from "../../../../tools/player";
import { ButtonPlayer } from "./buttonPlayer";

export class GoToLiveButtonPlayer extends ButtonPlayer {
  constructor(player: IPlayer) {
    super(player, "goToLiveButtonPlayer", t("player.button.goToLive"));
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "infoImg img",
      style: {
        backgroundImage: `url(${require("@assets/images/player/controls/GOTOLIVE.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }

  onSelect(): boolean {
    this.player.seekToLive();
    return true;
  }
}
