import { DIR, Identifiable, IListenable, IModelSource, ITrigger, IView } from ".";

/*
single list, scrollPosition didChange
async -> createNewViews (which read modelSource)
        -> signal view creation is complete
          -> on signal, update page/visible range

async -> animates (independent from view creation)
        -> signal animation is complete
          -> on signal, hide elements out of DOM

In the case of parent / sub
parent scrollPosition didChange
async -> createNewViews (parent)
        -> sub gets added to parent, sub.onShown: sub registers to parent scrollPosition & parent viewCreation signal
        async -> createNewViews in sub
                -> signal view creation is complete (no one cares, sub listens to parent signal)
        -> signal view creation is complete (parent)
          -> on signal, updates page/visible range (parent)
          -> on signal, updates page/visible range (sub)

async -> async animates
  -> signal animation is complete
    -> on signal, hide elements out of DOM (parent)
    -> on signal, hide elements out of DOM (sub)
*/

/**
 * Defines the type of scrolling mode for the {@link IListComponent} (with the keys, the mouse has its own mechanism)
 */
export const enum ScrollingType {
  /**
   * Scroll page by page, depending on the list container DOM size
   */
  page = "page",
  /**
   * Scroll by moving a page around, depending on the list container DOM size
   */
  slidingWindow = "slidingWindow",
  /**
   * Scroll by moving a page around but maintaining the focus on the last element of the page unless there's not enough elements at the left of the page
   */
  elasticWindow = "elasticWindow",
  /**
   * Disables scrolling. If placed into a parent list, the parent will scroll instead
   * If not in a parent list, no scrolling happens
   * In both cases, the CSS size of the rootElement of the list is updated to match the dimension of the list content
   */
  none = "none",
  /**
   * Scroll the list so that the focused line stays in the same place relative to its parent (anchored in its parent)
   *   */
  anchored = "anchored",
}

/**
 * Defines how the list focus affects the scrolling
 */
export type ScrollingMode =
  | {
      type: ScrollingType.page;
      /** is the list horizontal? reacts on up/down. Otherwise on left/right
       */
      horizontal: boolean;
    }
  | {
      type: ScrollingType.anchored;
      /** is the list horizontal? reacts on up/down. Otherwise on left/right
       */
      horizontal: boolean;
      /**
       * where the line should be anchored in its parent
       */
      anchorOffset?: number;
    }
  | {
      type: ScrollingType.elasticWindow;
      /** is the list horizontal? reacts on up/down. Otherwise on left/right
       */
      horizontal: boolean;
    }
  | {
      type: ScrollingType.slidingWindow;
      /** is the list horizontal? reacts on up/down. Otherwise on left/right
       */
      horizontal: boolean;
    }
  | {
      type: ScrollingType.none;
      /** is the list horizontal? reacts on up/down. Otherwise on left/right
       */
      horizontal: boolean;
    };

export interface IOnClick {
  onClick: {
    /** how many elements should be scrolled on an arrow mouse click?
     * @default page_size
     */
    scrollBy: number;
  };
}

export interface IOnHover {
  onHover: {
    /** time delay before the first "hover scroll". A good default value is 500 ms
     */
    delay: number;
    /** how long between subsequent "hover scroll". A good default value is 200ms (5 times per second)
     */
    interval: number;
    /** how many elements should be scrolled when hovering over the arrow?. With the previous default values, scrolling 1 by 1 feels natural
     */
    scrollBy: number;
  };
}

/**
 * interface returned by arrowFactory in mouseListComponent
 * extends IView and force acceptsMouseFocus to be true;
 * */
export interface IArrowView extends IView {
  /**  */
  acceptsMouseFocus: () => true;
}

/**
 * @param keepSelection - (defaults to false) if true, tries to set the selection (and therefore the scroll index) to where it was before reset. WARNING: if the focused ID is not in the new model array, the whole source is fetched/read.
 * @param animate - (defaults to true) if true, any scrolling related to resetting the content will be animated
 * @param clearCached - (defaults to false) if true, all cached views are purged & recreated */
export interface IRefreshParams {
  /** @param keepSelection - (defaults to false) if true, tries to set the selection (and therefore the scroll index) to where it was before reset. WARNING: if the focused ID is not in the new model array, the whole source is fetched/read.*/
  keepSelection?: boolean;
  /** @param animate - (defaults to true) if true, any scrolling related to resetting the content will be animated */
  animate?: boolean;
  /** @param clearCached - (defaults to false) if true, all cached views are purged & recreated */
  clearCached?: boolean;
}

/** Parameters to create a ListComponent Object
 * @typeParam M - the type of model. It's infered by the modelSource or the viewFactory
 * @typeParam V - the type of returned view. V needs to extend {@link IView}. It's infered from the return type of the provided viewFactory function
 */
export interface IListComponentParams<M extends Identifiable, V extends IView> {
  /** object handling requests to get data back
   */
  modelSource$: IModelSource<M>;

  /**
   * amount of elements that are asked to the source by the list when the list is "starving" and needs more data
   * @default 16
   */
  modelSourcePageLength?: number;

  /** how the list should refresh itself if the modelSource changes
   */
  onModelSourceChangedRefreshParams?: IRefreshParams;

  /** the id to give to the DOM element
   */
  id: string;

  /** a style object to be applied to the root element
   * this style will be used to measure the element.
   */
  className: string;

  /** function which, provided a model, should return an list view element
   * @param item - an item from the modelSource array
   * @param index - the index of the passed item in the modelSource array
   * @returns a view representing the model
   */
  viewFactory: (item: M, index: number) => V;

  /** function to provide a placeholder view when data is loading
   * if not provided, no loading placeholders!
   */
  loadingPlaceholderFactory?: (index: number) => IView;

  /** callback function called when an element is selected.
   * @param item - an item from the model
   * @param index - the index of the passed item in the modelSource array
   * @returns true if the action was handled, false to let the parent elements have the chance to handle the selection
   * @default undefined
   */
  onSelect?: (model: M, index: number) => boolean;

  /** which element should has focus on start. Defaults to none which in turn means first element if any elements are created
   * @default undefined
   */
  defaultFocusId?: string;

  /** How many elements per row(line). Defaults to 1 (one row if horizontal, or on column if vertical)
   * @default 1
   */
  crossSectionWidth?: number;

  /** optional limit on the number of elements (not lines, so factor in the crossSectionWidth if needed) read from the source
   * @default undefined (all elements read from the source)
   */
  maxItems?: number;

  /** how should focus movement scroll the list. Mandatory {@link ScrollingMode}
   */
  scrollingMode: ScrollingMode;

  /** should focus tried to be set based on where (spatially) the previous focus was? Otherwise focus reverts to its last value
   * @default false
   */
  spatialFocus?: boolean;

  /** how long a scroll duration should be - in ms
   * @default 400
   */
  scrollDuration?: number;

  /** an additional offset specific for each element. If not specified, no element-specific offset
   * @param item - an item from the model
   * @param index - the index of the passed item in the modelSource array
   * @returns the offset (in pixel) specific to the view corresponding to the model; this provided offset is added to the computed translation from the previous elements.
   *
   * for example: a list has 3 elements; the first 2 elements are 100px high; therefore the third element has an translation of 2 * 100px;
   * if viewOffset for that item is 100, the view will end up at 300px translation rather than 200px.
   * @default undefined
   */
  viewOffset?: (item: M, index: number) => number | undefined;

  /** force a specific RTL or LTR layout
   * @default undefined (adapts to global layout)
   */
  dir?: DIR;

  /** should we add/remove a focused class on children when focus moves?
   * @default true
   */
  updateChildrenClassOnFocus?: boolean;

  /** if set to true, the list won't try to position children via a transform, won't scroll either
   * @default false
   */
  noTransform?: boolean;

  /** defines if / how the list will handle mouse events
   * @default false
   */
  mouseSupport?:
    | {
        arrows?: {
          /** the mandatory view factory for the arrow overlays of the list
           */
          factory: (position: "prev" | "next") => IArrowView;

          /** the scroll method for both arrows.
           * @default onClick, move by page
           */
          scrollMethod?: IOnClick | IOnHover;

          /** show left arrow if showing the first element?
           * @default false
           */
          showOnBoundaries?: boolean;
        };

        /** how many pixels should be scrolled on a wheel event?
         * only used in vertical list
         * if set to undefined, onMouseWheel of the list will return false, so the parent view will receive the event
         * if set to a number, onMouseWheel will return true (stopping the propagation on the event)
         * @default undefined
         */
        wheelScrollBy?: number;

        /** which range of element can accept mouse focus. If page, then partially obscured items won't be focusable on mouse. If visible, all of them are candidate to focus
         * @default page
         */
        focusRange?: "page" | "visible";
      }
    | boolean;
}

/**
 * The user interface of the {@link ListComponent} object, created by {@link createListComponent}
 * @typeParam M - the type of model. It's infered by the modelSource or the viewFactory in the params
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export interface IListComponent<M extends Identifiable, V extends IView> extends IView {
  /** the DOM element managed by this view
   */
  readonly rootElement: HTMLDivElement;
  /** the index of the first element showing on the left/top of the list */
  readonly scrollPosition$: IListenable<number | undefined>;

  readonly viewsUpdateCompletedTrigger: ITrigger<boolean>;
  readonly scrollAnimationCompletedTrigger: ITrigger;

  /** the index of the element that has the focus at the moment. It can be undefined in the case there aren't any elements in the list (empty model array) */
  readonly focusedIndex$: IListenable<number | undefined>;
  /** the id of the element that has the focus at the moment. It can be undefined in the case there aren't any elements in the list (empty model array) */
  readonly focusedId$: IListenable<string | undefined>;
  /** the View of the element that has the focus at the moment. It can be undefined in the case there aren't any elements in the list (empty model array) */
  readonly focusedView$: IListenable<V | undefined>;
  /** is the list focused (part of the focused tree) or not. */
  readonly focused$: IListenable<boolean>;
  /** has the focus changed from a mouse event or key event */
  readonly focusedFromMouse$: IListenable<boolean>;
  /** is the list shown or not. If the parent of the list is hidden, the list is hidden. */
  readonly shown$: IListenable<boolean>;
  /** indexes of first & last element in page */
  readonly pageRange$: IListenable<{ first: number | undefined; last: number | undefined }>;
  /** indexes of first & last element visible */
  readonly visibleRange$: IListenable<{ first: number | undefined; last: number | undefined }>;

  /**
   * async - clears all data in the list (views and model), refetches everything from the source, recreates views
   */
  refresh(params?: IRefreshParams): Promise<void>;
  /**
   * focuses an index
   * @param index - index of model in model source
   * @param options - Various options for focus
   */
  setFocusOnIndex(
    index: number,
    options?: {
      /** if true, actually sets the focus, rewriting the whole focus tree
       * if false, sets the "focus to be" in navigation lands in this list
       * @default true
       */
      focus?: boolean;
      /** if true, any scrolling related to the focus change will be animated.
       * @default true
       */
      animate?: boolean;
      /** if true, a focus change will trigger a scrolling to keep the focused element in view
       * @default true
       */
      scroll?: boolean;
    }
  ): Promise<void>;
  /**
   * focuses an index
   * @param id - id of the model in model source (id is generated from the model with the keyGenerator)
   * @param options - Various options for focus
   */
  setFocusOnId(
    id?: string,
    options?: {
      /** if true, actually sets the focus, rewriting the whole focus tree
       * if false, sets the "focus to be" in navigation lands in this list
       * @default true
       */
      focus?: boolean;
      /** if true, any scrolling related to the focus change will be animated.
       * @default true
       */
      animate?: boolean;
      /** if true, a focus change will trigger a scrolling to keep the focused element in view
       * @default true
       */
      scroll?: boolean;
    }
  ): Promise<void>;
  /**
   * retrieves the actual model from an id
   * @param id - string generated from the model
   */
  modelFromId(id?: string): M | undefined;
  /**
   * retrieves a view from an id
   * @param id - string generated from the model
   */
  viewFromId(id?: string): V | undefined;
  /**
   * retrieves a view from an index
   * @param index - number
   */
  viewFromIndex(index?: number): V | undefined;
  /**
   * retrieves the actual model from an index
   * @param index - number
   */
  modelFromIndex(index?: number): M | undefined;
}
