import { IDelegate, Identifiable, IListComponent, IListComponentParams, IView } from "../typings";
import { ListComponent } from "./listComponent";
import { MouseListComponent } from "./mouseListComponent";

/**
 * creates a listComponent
 * @typeParam M - the type of model. It's infered by the modelSource or the viewFactory in the params
 * @typeParam V - the type of returned view. V needs to extend {@link IView}. It's infered from the return type of the provided viewFactory function in the params
 * @param params - listComponent's creation parameters
 * @param onReadyCallback - callback function called when the list has been populated
 */
export function createListComponent<M extends Identifiable, V extends IView>(
  params: IListComponentParams<M, V>,
  onReadyCallback?: (listComponent: IListComponent<M, V>) => void
): IListComponent<M, V> & IDelegate {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const listComponent = params.mouseSupport ? new MouseListComponent(params) : new ListComponent(params);
  void (async () => {
    await listComponent.viewsUpdateCompletedTrigger.onSignal;
    // and we tell the consumer the list is ready
    onReadyCallback?.(listComponent);
  })();

  return listComponent;
}
