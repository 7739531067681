import { z } from "zod";

import eu from "./eu.json";

export const Portability = eu;

export const ApiLocationResponse = z.object({
  locationKnown: z.boolean(),
  countryCode: z.string(),
});
export type ApiLocationResponse = z.infer<typeof ApiLocationResponse>;
