import EmitterBaseClass from "~/analytics/EmitterBaseClass";

const throttle = require("lodash.throttle");

interface IPayload {
  EventType: string;
  SequenceNumber?: number;

  [field: string]: any;
}

interface IEventPool {
  [sessionId: string]: IPayload[];
}

export class EventPool extends EmitterBaseClass {
  public static SEND: "eventpool:send";
  private readonly _sessionId: string;
  private _sequenceNumber = 0;
  private _eventPool: IEventPool = {};
  private _dispatcher: any;

  constructor(sessionId: string) {
    super();
    this._sessionId = sessionId;
    this.createPool(sessionId);

    this.eventPoolDispatcher = this.eventPoolDispatcher.bind(this);

    this.updateInterval(3000); // we use this as default, if we don't get a interval from the backend

    document.addEventListener("visibilitychange", this.eventPoolDispatcher, {
      capture: true,
    });
    document.addEventListener("pagehide", this.eventPoolDispatcher, {
      capture: true,
    });
  }

  createPool(sessionId: string) {
    this._eventPool[sessionId] = [];
  }

  updateInterval(interval: number) {
    // Handle seconds coming in, modify to milliseconds
    const redefinedInterval = interval.toString().length < 4 ? Number(interval * 1000) : interval;
    this._dispatcher = throttle(this.eventPoolDispatcher, redefinedInterval, {
      leading: false,
      trailing: true,
    });
  }

  add(sessionId: string, payload: IPayload, forceDispatch = false) {
    this._sequenceNumber++;
    payload.SequenceNumber = this._sequenceNumber;
    Log.analytics.debug("Analytics added to pool", sessionId, payload);
    if (this._eventPool[sessionId] == null) {
      this.createPool(sessionId);
    }
    this._eventPool[sessionId].push(payload);

    if (forceDispatch) {
      this.eventPoolDispatcher();
    } else {
      this._dispatcher();
    }
  }

  eventPoolDispatcher() {
    if (
      !navigator.onLine ||
      !Array.isArray(this._eventPool[this._sessionId]) ||
      this._eventPool[this._sessionId].length === 0
    ) {
      return;
    }
    const eventPool = this._eventPool[this._sessionId].sort((a, b) => a.TimeStamp - b.TimeStamp);
    this.createPool(this._sessionId);
    if (eventPool != null) {
      Log.analytics.debug("Analytics dispatched from pool", eventPool);
      this.emit(EventPool.SEND, eventPool);
    }
  }

  public destroy() {
    document.removeEventListener("visibilitychange", this.eventPoolDispatcher);
    document.removeEventListener("pagehide", this.eventPoolDispatcher);
    this._dispatcher.flush();
  }
}
