import { DS } from "~/libs";
import { QUANTUMCAST } from "~/utils/quantumcast";

/**
 * the implementation class for the API object which is then created & exported at the end of the file
 * no constructor on it - better use the lazy get pattern (see currentUser example)
 */
class _APIQuantumCast {
  /*
   * Retrieve radio metadata and return a listenable with data updated by a websocket while playing
   *
   * To trigger the garbage collector and close the websocket, the metadata$ has to be set to undefined in the app
   * The undefined value will never be returned by the parsed API message
   */
  metadataRadioLive = async (channelKey: string) => {
    const metadata$ = new DS.Listenable<QUANTUMCAST.MetadataChannel | undefined>(undefined);
    const socket = new WebSocket(`wss://api.streamabc.net/metadata/channel/${channelKey}`);
    socket.onmessage = (msg: MessageEvent) => {
      metadata$.value = QUANTUMCAST.MetadataChannel.parse(JSON.parse(msg.data));
    };
    const unregister = metadata$.didChange(value => {
      if (value === undefined) {
        socket.close();
        unregister();
      }
    }, null);
    return metadata$;
  };
}

export const APIQuantumCast = new _APIQuantumCast();
