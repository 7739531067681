import { z } from "zod";

import { DS } from "~/libs";
import { Error as CustomError, IOptions, Result } from "~/libs/ui/httpRequest";
import { getDeviceUUID } from "~/tools/deviceHelper";
import { RootPageHelper, showErrorGoHomePopup, showErrorRetryPopup } from "~/tools/errorPageHelper";
import { objectToQueryString } from "~/tools/snippets/objectToQueryString";
import { updateUrlWithUserId } from "~/tools/snippets/updateUrlWithUserId";
import { Toast } from "~/tools/uiHelper";
import { ApiResponse } from "~/utils/api";
import { StatusError } from "~/utils/errors";
import { GIGYA } from "~/utils/gigya";
import { parseJwt } from "~/utils/parseJWT";
import { RTBF } from "~/utils/rtbf";
import {
  deleteFavoriteResponse,
  EmbedType,
  PaginatedResponse,
  PlayHistoryCard,
  Tokens,
  Widget,
  WidgetType,
} from "~/utils/rtbf/models";

import { Config } from "../consts";
import { PaginatedModelSource } from "../paginatedModelSource";
import { APIGigyaOIDC } from "./apiGigyaOIDC";
import { APIRedbee } from "./apiRedbee";

const merge = require("lodash.merge");

class _APIAuvio {
  private _localStorageTokens = "auvioTokens";
  private _tokens: Tokens | undefined = undefined;
  private _baseUrl = `${Config().RTBF.apiServerUrl}`;
  private _u2cBaseUrl = `${Config().RTBFU2C.apiServerUrl}`;
  private _version = `${Config().RTBF.apiVersion}`;
  private _userAgent = `${Config().RTBF.userAgent}`;
  private _ramBaseUrl = `${Config().RTBF.ramServerUrl}`;

  constructor() {
    this._tokens = this._loadFromLocalStorage();
  }

  private async _apiWrapper(url: string, options: IOptions = {}, isApiU2C?: true, retry?: true): Promise<ApiResponse> {
    const shouldRenewTokens = this._shouldRenewTokens(this._tokens);

    let res;
    let response;
    if (shouldRenewTokens === false) {
      res = await DS.HttpRequest.make(this.addUserAgentToUrl(url), await this._requestOptions(options, isApiU2C));
      response = ApiResponse.passthrough().parse(res);
    }

    if (res?.status === 401 || shouldRenewTokens === true) {
      this.saveTokens(
        await this.renewTokens(
          getDeviceUUID(),
          this._tokens ? this._tokens["refresh_token"] : "",
          await APIGigyaOIDC.idToken()
        )
      );
      if (retry !== true) {
        return await this._apiWrapper(url, options, isApiU2C, true);
      }
    }

    if (response !== undefined && res?.status !== undefined && res?.status >= 200 && res?.status < 300) {
      return response;
    }

    Log.api.error("Auvio API error", res, JSON.stringify(res));
    throw new Error("Auvio API couldn't be made");
  }

  //callflows: developer-service.uat.rtbf.be/documentation/contribute/stacks/auth/OAUTH.html#Authentication
  private _shouldRenewTokens(tokens?: RTBF.Tokens) {
    if (!tokens) return true;
    const expires_at: number = 1000 * (parseJwt(tokens.access_token)?.exp ?? 0);
    return Date.now() > expires_at - 5 * 60 * 1000;
  }

  /**
   * Create and return headers with RedBee bearer authentification
   */
  private async _requestOptions(options: IOptions = {}, isApiU2C?: true): Promise<IOptions> {
    const headers: IOptions["headers"] = { Authorization: `Bearer ${await this.accessToken()}` };
    if (isApiU2C === true) headers["X-RTBF-Redbee"] = `Bearer ${await APIRedbee.sessionToken()}`;
    return merge({ headers }, options);
  }

  private _loadFromLocalStorage(): Tokens | undefined {
    const localStorage = DS.Storage.getItem(this._localStorageTokens);
    if (localStorage === null || localStorage === "") return undefined;

    const parsed = Tokens.safeParse(JSON.parse(localStorage));
    return parsed.success === true ? parsed.data : undefined;
  }

  async renewTokens(
    deviceId: string,
    refreshToken?: RTBF.Tokens["refresh_token"],
    gigyaIdToken?: string
  ): Promise<RTBF.Tokens> {
    if (refreshToken === null || refreshToken === undefined) {
      // no token - use a client credential grant
      return await this.getClientCredential(deviceId, gigyaIdToken);
    } else {
      try {
        // if we have an access token that is about to expire or has expired - try to refresh
        return await this._refreshTokens(deviceId, refreshToken);
      } catch (error) {
        if (error instanceof StatusError && error.statusCode === 401) {
          return await this.getClientCredential(deviceId, gigyaIdToken);
        } else throw error;
      }
    }
  }

  private async _refreshTokens(deviceId: string, refreshToken: string) {
    const url = `${Config().RTBF.authServerUrl}/oauth/v1.1/token`;
    const header: IOptions = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
      body: `grant_type=refresh_token&client_id=${Config().RTBF.clientId}&client_secret=${
        Config().RTBF.clientSecret
      }&platform=${Config().RTBF.platform}&device_id=${deviceId}&refresh_token=${refreshToken}&scope=visitor`,
    };
    let json;
    try {
      const result = await DS.HttpRequest.make(url, header);
      Log.api.info("Auvio API - refreshToken", JSON.stringify(header), JSON.stringify(result));
      json = "json" in result ? result.json : result;
    } catch (e) {
      if (e instanceof CustomError) {
        json = e.json;
      }
    }

    if (RTBF.isError(json)) {
      const error = RTBF.Error.parse(json);
      throw new StatusError(error.status, error.title ?? "UNKNOWN");
    } else {
      return RTBF.Tokens.parse(json);
    }
  }

  getClientCredential = async (deviceId: string, gigyaIdToken?: string) => {
    const url = `${Config().RTBF.authServerUrl}/oauth/v1.1/token`;
    const bodyParameters: any = {
      grant_type: gigyaIdToken !== undefined ? "gigya_openid" : "client_credentials",
      client_id: Config().RTBF.clientId,
      client_secret: Config().RTBF.clientSecret,
      platform: Config().RTBF.platform,
      device_id: deviceId,
      scope: "visitor",
    };
    if (gigyaIdToken !== undefined) bodyParameters.token = gigyaIdToken;
    const header: IOptions = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
      body: objectToQueryString(bodyParameters),
    };
    let json;
    try {
      const result = await DS.HttpRequest.make(url, header);
      Log.api.info("Auvio API - getClientCredential", JSON.stringify(header), JSON.stringify(result));
      json = "json" in result ? result.json : result;
    } catch (e) {
      if (e instanceof CustomError) {
        json = e.json;
      }
    }

    if (RTBF.isError(json)) {
      const error = RTBF.Error.parse(json);
      throw new StatusError(error.status, error.title ?? "UNKNOWN");
    } else {
      return RTBF.Tokens.parse(json);
    }
  };

  /*
   * Return Gigya id_token, and renew it if necessary
   * @throws An error if the token renewal failed;
   */
  async accessToken(): Promise<string | undefined> {
    if (this._tokens?.access_token === undefined) {
      const tokens = await this.getClientCredential(getDeviceUUID(), await APIGigyaOIDC.idToken());
      this.saveTokens(tokens);
    } else if (this._shouldRenewTokens(this._tokens)) {
      const tokens = await this.renewTokens(getDeviceUUID(), this._tokens.refresh_token, await APIGigyaOIDC.idToken());
      if (tokens !== undefined && "access_token" in tokens) {
        this.saveTokens(tokens);
      } else {
        throw new Error("Failed to renew Auvio RAM tokens");
      }
    }
    return this._tokens?.access_token;
  }

  saveTokens(tokens: Tokens | undefined) {
    if (tokens === undefined) {
      this.clear();
    } else {
      this._tokens = tokens;
      DS.Storage.setItem(this._localStorageTokens, JSON.stringify(tokens));
    }
  }

  clear() {
    this._tokens = undefined;
    DS.Storage.removeItem(this._localStorageTokens);
  }

  addUserAgentToUrl = (path: string) => {
    if (path.includes(this._userAgent)) return path;
    else {
      return path.includes("?") ? `${path}&${this._userAgent}` : `${path}?${this._userAgent}`;
    }
  };

  /** Fetch settings when application has started */
  settings = async () => {
    try {
      const url = this.addUserAgentToUrl(`${this._baseUrl}/auvio/${this._version}/settings`);
      const { json } = await DS.HttpRequest.make(url);
      Log.api.info("Settings API - url, raw JSON response", url, json);
      return RTBF.Settings.parse(json);
    } catch (error) {
      Log.api.error("settings api", error);
      throw new Error("no settings");
    }
  };

  /** Fetch page path and deeplink */
  page = async (path: string): Promise<RTBF.Page> => {
    if (path === "mock/emission/plus-belle-la-vie/bonus") {
      const mock = require("./../../utils/rtbf/mocks/pages/widgetMosaicMediaPage.json");
      return RTBF.Page.parse(mock);
    } else if (path === "mock/emission/plus-belle-la-vie/lives") {
      const mock = require("./../../utils/rtbf/mocks/pages/widgetMosaicLivePage.json");
      return RTBF.Page.parse(mock);
    } else if (path === "mock/emission/plus-belle-la-vie/similaires") {
      const mock = require("./../../utils/rtbf/mocks/pages/widgetMosaicProgramPage.json");
      return RTBF.Page.parse(mock);
    } else if (path === "mock/kids") {
      const mock = require("./../../utils/rtbf/mocks/pages/widgetHeroListPage.json");
      return RTBF.Page.parse(mock);
    }

    try {
      const { json } = await this._apiWrapper(`${this._baseUrl}/auvio/${this._version}/pages${path}`);
      void RootPageHelper.reset();
      return RTBF.Page.parse(json);
    } catch (error: unknown) {
      Log.api.error("Page API error");
      const status = error instanceof Result && error.status;
      if (status === 404) {
        showErrorRetryPopup(async () => {
          await this.page(path);
        });
      } else {
        showErrorGoHomePopup();
      }

      throw new Error("no page");
    }
  };

  /**
   * Generic function
   * Fetch widget using a parser that will return a well typed Widget
   * The parser will infer the widget typed returns
   * @param contentPath
   * @param parser  widget zodParser
   * @returns Promise<W> W is the type return by the parser
   */
  widget = async <W extends { type: WidgetType }>(
    contentPath: string,
    parser: z.ZodType<W, z.ZodTypeDef, unknown>
  ): Promise<W> => {
    try {
      const { json } = await this._apiWrapper(`${contentPath}`);
      const response = RTBF.WidgetResponse.parse(json);
      if (response.status !== 200) {
        throw new Error("no data");
      } else {
        try {
          return parser.parse(response.data);
        } catch (parseError) {
          Log.api.error(response.data.type + "parsing error", contentPath, parseError);
          throw new Error("parsing failed");
        }
      }
    } catch (error) {
      Log.api.error("widget api", contentPath, error);
      throw new Error("no widget");
    }
  };

  /**
   * Fetch paginated mosaic widgets
   * Be careful if you have to touch to the url variable, the pagination is tricky and breaks easily (you can test it on /archives page)
   *
   * @param contentPath
   * @param responseParser zod response parser
   * @param widgetParser parser widget zodParser
   * @returns ModelSource<TvLiveCard | MediaCard | ProgramCard>
   */
  widgetPaginated = <M, W extends { data: (M | null)[] } | { data: { content: (M | null)[] } }>(
    contentPath: string,
    responseParser: z.ZodType<PaginatedResponse, z.ZodTypeDef, unknown>,
    widgetParser: z.ZodType<W, z.ZodTypeDef, unknown>
  ): PaginatedModelSource<M> => {
    let url: string | undefined = contentPath;
    return new PaginatedModelSource<M>(async () => {
      if (contentPath === "" || url === undefined) return [[], undefined];
      url = await updateUrlWithUserId(url);
      try {
        const { json } = await this._apiWrapper(url, {}, true);
        const response = responseParser.parse(json);

        if (response.status === 200) {
          url = response.links?.next;
          const data = widgetParser.parse(response).data;
          const dataWihtoutNull: M[] = [];
          if ("content" in data) {
            data.content.forEach(d => d && dataWihtoutNull.push(d));
          } else {
            data.forEach(d => d && dataWihtoutNull.push(d));
          }
          return [dataWihtoutNull, url];
        }

        Log.api.error("fetchWidgetMosaic: couldn't parse content returned by url", url);
        return [[], undefined];
      } catch (error: unknown) {
        Log.api.error("Error on fetchWidgetMosaic: ", error);
        return [[], undefined];
      }
    });
  };

  widgetFavorites = async (contentPath: string) => {
    const url = `${await updateUrlWithUserId(contentPath)}`;
    const { json } = await this._apiWrapper(url);

    try {
      const response = RTBF.WidgetFavoritesResponse.parse(json);

      if (response.status !== 200) throw new Error("no data");
      else
        try {
          return RTBF.WidgetFavorites.parse(response).data;
        } catch (error) {
          Log.api.error("WidgetFavorites parsing error", contentPath, error);
        }
    } catch (error) {
      Log.api.error("widget favorite api", contentPath, error);
      throw new Error("no widget");
    }

    return [];
  };

  widgetHistory = async (contentPath: string) => {
    const url = `${await updateUrlWithUserId(contentPath)}`;
    const { json } = await this._apiWrapper(url);

    try {
      const response = RTBF.WidgetHistoryResponse.parse(json);
      if (response.status !== 200) throw new Error("no data");
      else
        try {
          return RTBF.WidgetHistory.parse(response).data;
        } catch (error) {
          Log.api.error("WidgetHistory parsing error", contentPath, error);
        }
    } catch (error) {
      Log.api.error("widget history api", contentPath, error);
      throw new Error("no widget");
    }

    return [];
  };

  async embedMedia(id: string): Promise<{ data: RTBF.EmbedMedia; meta: RTBF.EmbedMeta } | undefined> {
    try {
      const url = `${this._baseUrl}/auvio/${this._version}/embed/media/${id}`;
      const { json } = await this._apiWrapper(url);
      const res = RTBF.EmbedResponse.parse(json);
      if (res.status !== 200) throw new Error("Embed Media - bad status code !");
      return { data: RTBF.EmbedMedia.parse(res.data), meta: RTBF.EmbedMeta.parse(res.meta) };
    } catch (error: unknown) {
      Log.api.error("API errror on embed media: ", error);
    }
  }

  embedLive = async (id: string): Promise<{ data: RTBF.EmbedLive; meta: RTBF.EmbedMeta } | undefined> => {
    try {
      const url = `${this._baseUrl}/auvio/${this._version}/embed/live/${id}`;
      const { json } = await this._apiWrapper(url);
      const res = RTBF.EmbedResponse.parse(json);
      if (res.status !== 200) throw new Error("Embed Live - bad status code !");
      return { data: RTBF.EmbedLive.parse(res.data), meta: RTBF.EmbedMeta.parse(res.meta) };
    } catch (error: unknown) {
      Log.api.error("API errror on embed live: ", error);
    }
  };

  embedRadio = async (id: string): Promise<{ data: RTBF.EmbedRadio; meta: RTBF.EmbedMeta } | undefined> => {
    try {
      const url = `${this._baseUrl}/auvio/${this._version}/embed/radio/${id}`;
      const { json } = await this._apiWrapper(url);
      const res = RTBF.EmbedResponse.parse(json);
      if (res.status !== 200) throw new Error("Embed Live - bad status code !");
      return { data: RTBF.EmbedRadio.parse(res.data), meta: RTBF.EmbedMeta.parse(res.meta) };
    } catch (error: unknown) {
      Log.api.error("API errror on embed radio: ", error);
    }
  };

  widgetRecommandation = async (id: string, type: EmbedType) => {
    try {
      const url = `${this._baseUrl}/auvio/${this._version}/medias/${id}/recommended?resourceType=${type}`;
      const { json } = await this._apiWrapper(url);
      const response = RTBF.WidgetMosaicPaginatedResponse.parse(json);
      if (response.status !== 200) throw new Error("widget recommandation - bad status code !");
      else {
        return RTBF.WidgetRecommandation.parse(response);
      }
    } catch (error: unknown) {
      Log.api.error("API errror on widget recommandation: ", error);
    }
  };

  search = async (text: string, searchUrl: string | null): Promise<Widget[]> => {
    try {
      let url;
      if (searchUrl !== null) {
        url = searchUrl + text;
      } else {
        url = `${this._baseUrl}/auvio/${this._version}/search?query=${text}`;
      }
      const { json } = await this._apiWrapper(url);
      const response = RTBF.SearchResponse.parse(json);
      if (response.status !== 200) throw new Error("Error on search - bad status code !");
      else return response.data;
    } catch (error) {
      Log.api.error("API error on seach: ", error);
      return [];
    }
  };

  async sendStopEvent() {
    try {
      const body = {
        userId: APIGigyaOIDC.userInfo$.value?.uid,
        "user-Agent": this._userAgent.split("=")[1],
        eventType: "stop",
        redbeeToken: await APIRedbee.sessionToken(),
      };

      await DS.HttpRequest.make(`${this._ramBaseUrl}/playerEvent`, {
        method: "post",
        body: JSON.stringify(body),
      });
    } catch (error: unknown) {
      Log.api.error("API errror on sendStopEvent: ", error);
    }
  }

  /**     _    ____ ___   _   _ ____   ____
   *     / \  |  _ \_ _| | | | |___ \ / ___|
   *    / _ \ | |_) | |  | | | | __) | |
   *   / ___ \|  __/| |  | |_| |/ __/| |___
   *  /_/   \_\_|  |___|  \___/|_____|\____|
   */

  getUserPlayHistory = async () => {
    if (APIGigyaOIDC.userInfo$.value?.uid === undefined) return [];
    const url = `${Config().RTBFU2C.apiServerUrl}/${this._version}/users/${
      APIGigyaOIDC.userInfo$.value?.uid
    }/play-history-ids`;

    try {
      const { json } = await this._apiWrapper(url, {}, true);
      if (typeof json === "object" && json != null && !Object.keys(json).length && json.constructor === Object)
        // Don't display the toaster if we received an empty response
        return [];
      const response = RTBF.WidgetHistoryIdsResponse.parse(json);
      if (response.status === 200) return response.data;
      return [];
    } catch (error) {
      Log.api.error("Play History API - error : ", error);
      Toast(t(`toaster.load_user_play_history_error_text`));
    }
  };

  widgetU2C = async (contentPath: string) => {
    const { json } = await this._apiWrapper(await updateUrlWithUserId(contentPath), {}, true);

    try {
      const response = RTBF.WidgetU2CResponse.parse(json);
      if (response.status !== 200) throw new Error("no data");
      else
        try {
          return RTBF.WidgetU2C.parse(response).data.content;
        } catch (error) {
          Log.api.error("WidgetU2C parsing error", contentPath, error);
        }
    } catch (error) {
      Log.api.error("widget u2c api", contentPath, error);
      throw new Error("no widget");
    }

    return [];
  };

  getUserFavorites = async () => {
    try {
      const { json } = await this._apiWrapper(
        await updateUrlWithUserId(`${this._u2cBaseUrl}/${this._version}/users/{id}/favorite-resource-ids`),
        {},
        true
      );
      const response = RTBF.GetUserFavoritesResponse.parse(json);

      if (response.status === 200) {
        return [...response.data.medias, ...response.data.programs];
      } else {
        return [];
      }
    } catch (error) {
      Log.api.error("API error on user favorites: ", error);
      Toast(t(`toaster.load_user_favoris_error_text`));
    }
  };

  addFavorite = async (
    resourceType: "MEDIA" | "PROGRAM",
    resourceId: string,
    programResourceId?: string
  ): Promise<boolean> => {
    try {
      const response = deleteFavoriteResponse.parse(
        await this._apiWrapper(
          await updateUrlWithUserId(`${this._u2cBaseUrl}/${this._version}/users/{id}/favorites`),
          await this._requestOptions(
            {
              method: "post",
              queryParams: {
                resourceId,
                resourceType,
              },
            },
            true
          )
        )
      );
      if (response.status === 204) {
        if (programResourceId !== undefined) {
          await this.addFavorite("PROGRAM", programResourceId);
        }
        return true;
      }
    } catch (error) {
      Log.api.error("API error on adding favorite", error, {
        resourceType,
        resourceId,
        programResourceId,
      });
    }
    return false;
  };

  removeFavorite = async (resourceType: "MEDIA" | "PROGRAM", resourceId: string): Promise<boolean> => {
    try {
      const response = deleteFavoriteResponse.parse(
        await this._apiWrapper(
          await updateUrlWithUserId(`${this._u2cBaseUrl}/${this._version}/users/{id}/favorites`),
          await this._requestOptions(
            {
              method: "delete",
              queryParams: {
                resourceId,
                resourceType,
              },
            },
            true
          )
        )
      );
      if (response.status === 204) {
        return true;
      }
    } catch (error) {
      Log.api.error("API error on delete favorite", error, {
        resourceType,
        resourceId,
      });
    }
    return false;
  };

  getProgramMediasHistory = async (programId: string): Promise<PlayHistoryCard[]> => {
    if (APIGigyaOIDC.userInfo$.value?.uid === undefined) return [];
    const programMediasHistory: PlayHistoryCard[] = [];

    try {
      const { json } = await this._apiWrapper(
        `${this._u2cBaseUrl}/${this._version}/users/${APIGigyaOIDC.userInfo$.value?.uid}/play-history?programId=${programId}`,
        {},
        true
      );
      const response = RTBF.WidgetHistoryResponse.parse(json);
      if (response.status === 200) {
        programMediasHistory.push(...response.data.filter(playHistorycard => playHistorycard.media !== null));
      }
    } catch (error) {
      Log.api.error("Play History API - error : ", error);
      if (programMediasHistory === undefined) {
        Toast(t(`toaster.load_user_play_history_error_text`));
      }

      return [];
    }
    return programMediasHistory;
  };

  createPinCode = async (pin: string) => {
    try {
      await this._apiWrapper(
        `${this._u2cBaseUrl}/${this._version}/users/${APIGigyaOIDC.userInfo$.value?.uid}/parental-control`,
        await this._requestOptions(
          {
            method: "post",
            body: JSON.stringify({
              pin,
            }),
          },
          true
        )
      );
      Toast(t("toaster.savePin_success_text"));
    } catch (error: unknown) {
      Toast(t("toaster.savePin_error_text"));
    }
  };

  updateParental = async (
    pin: string | undefined,
    parentalControl: GIGYA.ParentalControlLevel,
    parentalControlDisabled?: Date,
    disableParentalCode?: boolean
  ) => {
    const body: { [key: string]: string } = {
      parentalControl,
    };
    if (pin !== undefined) body.pin = pin;
    if (parentalControlDisabled !== undefined) body.parentalControlDisabled = parentalControlDisabled.toISOString();

    try {
      await this._apiWrapper(
        `${this._u2cBaseUrl}/${this._version}/users/${APIGigyaOIDC.userInfo$.value?.uid}/parental-control`,
        await this._requestOptions(
          {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          },
          true
        )
      );
      if (disableParentalCode !== undefined && disableParentalCode) {
        Toast(t("toaster.disableParentControl_success_text"));
      } else {
        Toast(t("toaster.modifyLevel_success_text"));
      }
    } catch (error: unknown) {
      Toast(t("toaster.modifyLevel_error_text"));
    }
  };

  resetPinCode = async (): Promise<boolean> => {
    try {
      await this._apiWrapper(
        `${this._u2cBaseUrl}/${this._version}/users/${APIGigyaOIDC.userInfo$.value?.uid}/parental-control`,
        await this._requestOptions(
          {
            method: "delete",
          },
          true
        )
      );

      await APIGigyaOIDC.updateUserInfo();

      Toast(t("toaster.pinPage_sendMail"));
    } catch (error: unknown) {
      Toast(t("toaster.savePin_error_text"));
      Log.api.error("reset parental code - Error authentication");
      return false;
    }
    return true;
  };
}

export const APIAuvio = new _APIAuvio();
