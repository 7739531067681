import "./logConsentPage.scss";

import { CallFlow } from "~/callflow";
import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { Config } from "~/config";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { ModelSource } from "~/libs/exports";
import { exitApp, navigationStack } from "~/main";
import { PlayerPage } from "~/pages/player/playerPage";
import { ContractsFormated, LogConsentHelper } from "~/tools/logConsentHelper";
import { createQRCode } from "~/tools/uiHelper";

enum ButtonType {
  later = "later",
  refuse = "refuse",
  refresh = "refresh",
}

export class LogConsentPageView extends AcceptsMouseFocusView {
  private _list: DS.IListComponent<ButtonType, Button>;
  private _modelSource$: DS.ModelSource<ButtonType>;
  private static _nextCheckTimeoutID: number | undefined;
  private _errorElt: HTMLElement;

  constructor(isMandatory = true) {
    super("", "logConsentPageView logConsentPage");
    LogConsentPageView.programNextCheck();

    const contracts = LogConsentHelper.getConsentPreferencesDetails();

    this._modelSource$ = new ModelSource(
      isMandatory === true ? [ButtonType.refuse, ButtonType.refresh] : [ButtonType.later, ButtonType.refresh]
    );

    const innerElement = DS.DOMHelper.createElement({
      tagName: "div",
      className: "inner",
      parent: this.rootElement,
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: innerElement,
      className: "auvioLogo",
    });

    const title = DS.DOMHelper.createElement({
      tagName: "p",
      parent: innerElement,
      className: "title",
    });

    const description = DS.DOMHelper.createElement({
      tagName: "p",
      parent: innerElement,
      className: "description",
    });
    if (contracts.length === 1) {
      title.innerText = t("logConsent.update.titleSingleContract");
      title.appendChild(
        DS.DOMHelper.createElement({
          tagName: "span",
          className: "yellow",
          innerText: contracts[0].contractName,
        })
      );
      const contractDate =
        contracts[0].activationDate !== undefined
          ? LogConsentHelper.formatContractDate(new Date(contracts[0].activationDate))
          : "";
      if (isMandatory === true) {
        description.innerText = `${t("logConsent.update.description.singleContract")} ${contracts[0].contractName} ${t(
          "logConsent.update.description.effectiveNow"
        )}.`;
      } else {
        description.innerText = `${t("logConsent.update.description.singleContract")} ${contracts[0].contractName} ${t(
          "logConsent.update.description.willBeEffective"
        )} ${contractDate}.`;
      }
    } else {
      title.innerText = t("logConsent.update.titleMultipleContracts");

      description.innerText = `${t("logConsent.update.description.multipleContracts")}`;
      description.appendChild(this._contractList(contracts));
    }

    const rowElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: innerElement,
      className: "row",
    });

    contracts.length > 0 && rowElement.appendChild(createQRCode("https://www.rtbf.be/auvio/connexion", "qrCode"));

    const columnElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: rowElement,
      className: "column",
    });
    DS.DOMHelper.createElement({
      tagName: "p",
      parent: columnElement,
      innerText: t("logConsent.update.qrCode.part1"),
    });
    const part2Elt = DS.DOMHelper.createElement({
      tagName: "p",
      parent: columnElement,
    });
    part2Elt.appendChild(
      DS.DOMHelper.createElement({
        tagName: "span",
        className: "underline",
        innerText: t("logConsent.update.qrCode.part2"),
      })
    );
    part2Elt.appendChild(
      DS.DOMHelper.createElement({
        tagName: "span",
        innerText: ".",
      })
    );

    DS.DOMHelper.createElement({
      tagName: "p",
      className: "spacing",
      parent: columnElement,
      innerText: t("logConsent.update.qrCode.part3"),
    });

    this._errorElt = DS.DOMHelper.createElement({
      tagName: "p",
      className: "errorMessage",
      parent: innerElement,
    });

    this.delegate = this._list = DS.createListComponent(
      {
        id: "",
        className: `logConsentButtonsContainer ${isMandatory && "mandatory"}`,
        modelSource$: this._modelSource$,
        viewFactory: item => {
          return new Button(item, t(`logConsent.buttons.${item}`));
        },
        onSelect: item => {
          switch (item) {
            case ButtonType.later:
              LogConsentHelper.updateLastConsentVerificationDate();
              navigationStack.removePage(this);
              return true;
            case ButtonType.refresh:
              void this._refreshConsents();
              return true;
            case ButtonType.refuse:
              void CallFlow.logout();
              return true;
          }
        },
        scrollingMode: {
          type: DS.ScrollingType.anchored,
          horizontal: false,
        },
        mouseSupport: Config.mouseSupport,
        crossSectionWidth: 2,
        noTransform: true,
      },
      list => {
        void list.setFocusOnIndex(1);
      }
    );
  }

  private _contractList(contracts: ContractsFormated) {
    const list = DS.DOMHelper.createElement({
      tagName: "ul",
    });
    contracts.forEach(el => {
      DS.DOMHelper.createElement({
        tagName: "li",
        innerText: el.contractName,
        parent: list,
      });
    });
    return list;
  }

  /**
   * API call to refresh consents
   */
  private async _refreshConsents() {
    await APIGigyaOIDC.updateUserInfo();
    const consentsNeedsUpdate = LogConsentHelper.areConsentsUpdated(true);
    if (consentsNeedsUpdate === false) {
      navigationStack.removePage(this);
    } else {
      this._errorElt.innerText = t("logConsent.error");
    }
  }

  /**
   * Update site consents and show log consent popup if necessary
   */
  static async refreshConsentsAndEventuallyShowPopup(): Promise<void> {
    void (await LogConsentHelper.getConsentDetails());
    const consentUpdated = LogConsentHelper.areConsentsUpdated();
    if (consentUpdated !== false) {
      navigationStack.pushPage(new LogConsentPageView(consentUpdated === "mandatory"));
    }
    LogConsentPageView.programNextCheck();
  }

  onNav(key: DS.Keys) {
    switch (key) {
      // Prevent to close this popup via the back button
      case DS.Keys.back:
        exitApp();
        return true;
    }
    return false;
  }

  /**
   * Define a timeout to check consents in 24h
   * Once the delay is passed, it wait for a page change when no player page is open
   */
  static programNextCheck(): void {
    if (this._nextCheckTimeoutID !== undefined) window.clearTimeout(this._nextCheckTimeoutID);
    this._nextCheckTimeoutID = window.setTimeout(async () => {
      const pageChangeReleaseCollector = navigationStack.pages$.didChange(async _ => {
        const hasPlayerPage = navigationStack.pages$.value.find(page => page instanceof PlayerPage);
        if (hasPlayerPage !== undefined) return;
        pageChangeReleaseCollector();

        await LogConsentHelper.getConsentDetails();
        const consentUpdated = LogConsentHelper.areConsentsUpdated();
        if (consentUpdated !== false) {
          navigationStack.pushPage(new LogConsentPageView(consentUpdated === "mandatory"));
        }
      }, null);
    }, LogConsentHelper.timeoutDelay);
  }
}

class Button extends AcceptsMouseFocusView {
  constructor(buttonType: ButtonType, text: string) {
    super(buttonType + "Button", "buttonContainer");
    this.rootElement.innerText = text;
  }
}
