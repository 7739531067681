import { z } from "zod";

/* Commented to show it's available, but not used in the application anymore
const ApiPurchase = z.object({
  customerId: z.string(),
  businessUnitId: z.string(),
  startedAt: z.string(),
  transactionId: z.string(),
  assetId: z.string().optional(),
  from: z.string(),
  until: z.string(),
  purchaseId: z.string(),
  renewAt: z.string().optional(),
  productOfferingId: z.string(),
  status: z.string(),
  activated: z.boolean(),
});
export type ApiPurchase = z.infer<typeof ApiPurchase>;
*/

export const ApiPurchase = z.object({
  consumedProductOfferingDiscounts: z.array(z.string()),
  // purchases: z.array(ApiPurchase).nullish(),
});
export type ApiPurchase = z.infer<typeof ApiPurchase>;

export const ApiAccountPurchase = z.object({
  productOfferingId: z.string().optional(),
  productIds: z.array(z.string()),
  from: z.string().optional(),
  until: z.string().optional(),
  renewAt: z.string().optional(),
  created: z.string().optional(),
  status: z.string().optional(),
  // Transactions infos are available in response, but aren't used yet in the web app
  voucherCode: z.string().optional(),
  assetId: z.string().optional(),
});
export type ApiAccountPurchase = z.infer<typeof ApiAccountPurchase>;

export const ApiAccountPurchasesResponse = z.array(ApiAccountPurchase);
export type ApiAccountPurchasesResponse = z.infer<typeof ApiAccountPurchasesResponse>;
