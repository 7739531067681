import "./contentTimelineView.scss";

import { DS } from "~/libs";

import { PlayHistoryHelper } from "../../../../tools/playHistoryHelper";
import { getFormatDate, getFormatTime, getProgressPercentage } from "../../../../tools/time";
import { ProgressBarView } from "../../common/progressBar/progressBarView";

type TimelineParams =
  | {
      duration: number;
      mediaId: string;
      type: "MEDIA";
    }
  | {
      duration: number;
      from: string;
      to: string;
      type: "LIVE";
    };

export class ContentTimelineView extends DS.View {
  constructor(params: TimelineParams) {
    super("", "contentTimelineView");

    const startTimeElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "startTime",
    });
    const progressBarView = new ProgressBarView(params.type === "LIVE" ? "live" : "media", params.duration);
    this.rootElement.appendChild(progressBarView.rootElement);
    const endtimeElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "endTime",
    });

    if (params.type === "LIVE") {
      // compute datas to display
      const from = new Date(params.from);
      const to = new Date(params.to);

      // display datas
      startTimeElement.innerText = getFormatDate(from);
      endtimeElement.innerText = getFormatDate(to);
      progressBarView.setPercentageProgression(getProgressPercentage(from, params.duration));
    } else if (params.type === "MEDIA" && params.duration > 0) {
      this.rootElement.removeChild(startTimeElement);
      const currentOffsetData$ = PlayHistoryHelper.getCurrentOffset(params.mediaId);
      currentOffsetData$.didChange(
        newOffset => {
          // show the progress bar if media played
          if (newOffset > 0) {
            this.rootElement.style.visibility = "visible";
          } else {
            this.rootElement.style.visibility = "hidden";
          }

          // update progress bar
          const newRemainingTime = params.duration - newOffset;
          const newFormattedTime = getFormatTime(newRemainingTime);
          progressBarView.updateCurrenOffset(newOffset);
          if (newRemainingTime > 0) {
            endtimeElement.innerText = `${newFormattedTime === "" ? "" : "-" + newFormattedTime}`;
          }
        },
        this,
        true
      );
    }
  }

  rejectsFocus() {
    return true;
  }
}
