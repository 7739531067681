import "./widgetRecommandation.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";

import { Config } from "../../../config";
import { DS } from "../../../libs";
import { playerAudio, playerVideo } from "../../../tools/player";
import { TrackingHelper } from "../../../tools/trackingHelper";
import { SnowplowPlayerEventName } from "../../../typings/snowplow";
import { MediaCard, TvLiveCard, WidgetRecommandation } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { WidgetView } from "../../widgets/widgetView";

class WidgetRecommandationTileView extends AcceptsMouseFocusView {
  constructor(card: MediaCard | TvLiveCard) {
    super("", "tileRecommandation");

    const imgContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileCover",
    });
    imgContainer.style.background = `linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, #181818 77.72%) no-repeat, url("${card.illustration?.s}") 50% 50% / cover no-repeat`;
    const titleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileTitle",
    });
    const subtitleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileSubtitle",
    });

    Config.locale$.didChange(
      () => {
        // when local changes, we reset our text - which is dependent on this locale
        titleElement.innerText = `${card.title}`;
        subtitleElement.innerText = `${card.subtitle}`;
      },
      this,
      true
    );
  }
}

export class WidgetRecommandationView extends WidgetView {
  focusTracker: FocusTracker | undefined;

  constructor(widget: WidgetRecommandation) {
    super(widget.id, "recosWidget");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetRecommandationTitle",
      innerText: t("recommendation.widget_title"),
    });

    this.delegate = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: new DS.ModelSource(widget.data),
        viewFactory: item => new WidgetRecommandationTileView(item),
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: card => {
          void this._loadNextAsset(card);
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "tileCover");
        this.collectRelease(this.focusTracker.onRelease);
      }
    );
  }

  private async _loadNextAsset(media: MediaCard | TvLiveCard) {
    if (media !== undefined) {
      void TrackingHelper.snowplowEvent(SnowplowPlayerEventName.recommendation_click, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        recommended_media_id: media.id,
      });

      if (media.type === "AUDIO") {
        playerAudio.trackAnalytics("reco_next");
        void playerVideo.destroy();
        await playerAudio.load(media.id, media.resourceType, { collector: this });
        playerAudio.play(true);
        playerAudio.showControls$.value = true;
      } else {
        playerVideo.trackAnalytics("reco_next");
        void playerAudio.destroy();
        await playerVideo.load(media.id, media.resourceType, { collector: this });
        playerVideo.play(true);
        playerVideo.showControls$.value = true;
      }
    }
  }
}

export class ButtonReplayView extends AcceptsMouseFocusView {
  constructor() {
    super("buttonRecommandation", "buttonRecommandation");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "recommandationReplay",
      style: {
        backgroundImage: `url(${require("@assets/images/buttons/recommandationReplay.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }
}
