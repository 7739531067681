"use strict";

import "./popupPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { Config } from "~/config";
import { DS } from "~/libs";

import { navigationStack } from "../../main";
import { IActionButton } from "../../tools/interfaces";

export class PopupButton extends AcceptsMouseFocusView {
  constructor(button: IActionButton) {
    super("popup-button-view", "popup-button-view");
    DS.DOMHelper.createElement({
      tagName: "div",
      className: "popup-button",
    });
    const buttonZoom = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "buttonZoom",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: buttonZoom,
      className: "popup-button-text",
      innerText: button.label || "buttonLabel",
    });
  }
}

export class PopupPage extends AcceptsMouseFocusView implements DS.IPage {
  constructor(
    params: {
      title: string;
      description: string;
      button1?: IActionButton;
      button2?: IActionButton;
      button3?: IActionButton;
    },
    lightMode?: boolean
  ) {
    super("PopupPage");
    DS.DOMHelper.createElement({
      tagName: "div",
      id: lightMode === true ? "popup-overlay-light-mode" : "popup-overlay",
    });
    const { title, description, button1, button2, button3 } = params;

    const popup = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "popup-container",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: popup,
      id: "popup-title",
      innerText: title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: popup,
      id: "popup-msg",
      innerText: description,
    });

    const buttons = [];
    button1 && buttons.push(button1);
    button2 && buttons.push(button2);
    button3 && buttons.push(button3);

    this.delegate = DS.createListComponent(
      {
        id: "popup-button-container",
        className: "popup-button-container",
        modelSource$: new DS.ModelSource(buttons.map((button, index) => ({ ...button, id: index }))),
        viewFactory: button => {
          return new PopupButton(button);
        },
        onSelect: button => {
          navigationStack.removePage(this);
          button.action?.();
          return true;
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: true },
        mouseSupport: Config.mouseSupport,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }
  isPopup = () => true;
}
export class PopupFullPage extends AcceptsMouseFocusView implements DS.IPage {
  constructor(
    params: {
      title: string;
      description: string;
      button1?: IActionButton;
      button2?: IActionButton;
      button3?: IActionButton;
    },
    lightMode?: boolean
  ) {
    super("PopupPageFull");
    DS.DOMHelper.createElement({
      tagName: "div",
      id: lightMode === true ? "popupFull-overlay-light-mode" : "popupFull-overlay",
    });
    const { title, description, button1, button2, button3 } = params;

    const popup = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "popupFull-container",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: popup,
      id: "popupFull-title",
      innerText: title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: popup,
      id: "popupFull-msg",
      innerText: description,
    });
    const brunoLogo = require("@assets/images/incentive/default.png");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: popup,
      className: "logo",
      style: {
        backgroundImage: `url(${brunoLogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    const buttons = [];
    button1 && buttons.push(button1);
    button2 && buttons.push(button2);
    button3 && buttons.push(button3);

    this.delegate = DS.createListComponent(
      {
        id: "popupFull-button-container",
        className: "popupFull-button-container",
        modelSource$: new DS.ModelSource(buttons.map((button, index) => ({ ...button, id: index }))),
        viewFactory: button => {
          return new PopupButton(button);
        },
        onSelect: button => {
          navigationStack.removePage(this);
          button.action?.();
          return true;
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: true },
        mouseSupport: Config.mouseSupport,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }
  isPopup = () => true;

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.back:
        return true;
    }
    return false;
  }
}

export class ErrorPage extends PopupFullPage {
  constructor(params: {
    title: string;
    description: string;
    button1?: IActionButton;
    button2?: IActionButton;
    button3?: IActionButton;
  }) {
    super(params);
  }
}

export class PopupSendPinPage extends AcceptsMouseFocusView implements DS.IPage {
  constructor(
    params: {
      title: string;
      description1: string;
      description2?: string;
      button1?: IActionButton;
      button2?: IActionButton;
      button3?: IActionButton;
    },
    lightMode?: boolean
  ) {
    super("PopupSendPin");
    DS.DOMHelper.createElement({
      tagName: "div",
      id: lightMode === true ? "popupSendPin-overlay-light-mode" : "popupSendPin-overlay",
    });
    const { title, description1, description2, button1, button2, button3 } = params;

    const popup = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "popupSendPin-container",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: popup,
      id: "popupSendPin-title",
      innerText: title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: popup,
      id: "popupSendPin-msg-1",
      className: "popupSendPin-msg",
      innerText: description1,
    });
    description2 !== undefined &&
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: popup,
        id: "popupSendPin-msg-2",
        className: "popupSendPin-msg",
        innerText: description2,
      });

    const buttons = [];
    button1 && buttons.push(button1);
    button2 && buttons.push(button2);
    button3 && buttons.push(button3);

    this.delegate = DS.createListComponent(
      {
        id: "popupSendPin-button-container",
        className: "popupSendPin-button-container",
        modelSource$: new DS.ModelSource(buttons.map((button, index) => ({ ...button, id: index }))),
        viewFactory: button => {
          return new PopupButton(button);
        },
        onSelect: button => {
          navigationStack.removePage(this);
          button.action?.();
          return true;
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: true },
        mouseSupport: Config.mouseSupport,
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }
  isPopup = () => true;

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.back:
        navigationStack.removePage(navigationStack.topPage);
        return true;
    }
    return false;
  }
}
