import { Listenable } from "../../ui/helpers/Listenable";
import { LogInfo } from "../logInfo";
import { consoleLog } from "../systemConsole";
import { LogLevel } from "../types";
import { outputBuffer } from "./outputBuffer";

class OutputRemoteJS {
  lastTimestamp?: number;
  script?: HTMLScriptElement;
  channel?: string;

  attached$ = new Listenable(false);

  trace = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.trace, namespace, ...data);
    this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp;
    console.trace(`[${namespace.toUpperCase()}]`, ...data);
  };
  debug = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.debug, namespace, ...data);
    this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp;
    console.debug(`[${namespace.toUpperCase()}]`, ...data);
  };
  log = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.info, namespace, ...data);
    this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp;
    console.log(`[${namespace.toUpperCase()}]`, ...data);
  };
  info = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.info, namespace, ...data);
    this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp;
    console.info(`[${namespace.toUpperCase()}]`, ...data);
  };
  warn = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.warn, namespace, ...data);
    this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp;
    console.warn(`[${namespace.toUpperCase()}]`, ...data);
  };
  error = (namespace: string, ...data: unknown[]) => {
    outputBuffer.logWithLevel(LogLevel.error, namespace, ...data);
    this.lastTimestamp = outputBuffer.buffer[outputBuffer.buffer.length - 1].timestamp;
    console.error(`[${namespace.toUpperCase()}]`, ...data);
  };

  attach = async (code: string): Promise<void> => {
    return new Promise(resolve => {
      this.channel = `${LogInfo.appName.replace("/", "-")}-${code}`;
      if (!this.script) {
        this.script = document.createElement("script");
        this.script.id = "remoteJSscript";
        this.script.src = "https://remotejs.com/agent/agent.js";
        this.script.setAttribute("data-consolejs-channel", this.channel);

        document.head.appendChild(this.script);

        const that = this;
        // need to wait for the console functions to change
        const timer = window.setInterval(function () {
          if (console.log !== consoleLog) {
            clearInterval(timer);
            console.warn(`App opened communication with remoteJS on https://remotejs.com/viewer/${that.channel}`);

            outputBuffer.buffer.forEach(entry => {
              if (that.lastTimestamp === undefined || entry.timestamp > that.lastTimestamp) {
                switch (entry.level) {
                  case LogLevel.trace:
                    console.trace(`[${entry.namespace.toUpperCase()}]`, entry.text);
                    break;
                  case LogLevel.debug:
                    console.debug(`[${entry.namespace.toUpperCase()}]`, entry.text);

                    break;
                  case LogLevel.info:
                    console.info(`[${entry.namespace.toUpperCase()}]`, entry.text);

                    break;
                  case LogLevel.warn:
                    console.warn(`[${entry.namespace.toUpperCase()}]`, entry.text);

                    break;
                  case LogLevel.error:
                    console.error(`[${entry.namespace.toUpperCase()}]`, entry.text);
                    break;
                }
              }
            });
            that.attached$.value = true;
            resolve();
          }
        }, 500);
      } else {
        this.script.setAttribute("data-consolejs-channel", this.channel);
        resolve();
      }
    });
  };

  detach = (): void => {
    // remove dom script
    this.script?.parentElement?.removeChild(this.script);
    this.script = undefined;
    this.attached$.value = false;
  };
}

export const outputRemoteJS = new OutputRemoteJS();
