import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { EyePinViewList } from "~/components/views/eyePin/eyePinView";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { navigationStack } from "~/main";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";
import { Toast } from "~/tools/uiHelper";

import { CheckboxView } from "../../components/views/checkbox/checkboxView";
import { ParentalButtonView, SendPinButtonView } from "../../components/views/parental/parentalButtonView";
import { PinView } from "../../components/views/pin/pinView";
import { Config } from "../../config";
import { GIGYA } from "../../utils/gigya";
import { ParentalControlLevel } from "../../utils/gigya/models";
import { OnboardingPageView } from "../onBoarding/onBoardingPage";
import { ParentalControlPage } from "./parentalControlPage";

enum ParentalPopupWidgetType {
  simplePin = "simplePin",
  checkPin = "checkPin",
  simplePinDesactivate = "simplePinDesactivate",
  confirmPin = "confirmPin",
  confirmNewPin = "confirmNewPin",
  actualPin = "actualPin",
  newPin = "newPin",
  verifyPinDescription = "verifyPinDescription",
  confirmCreatePinButton = "confirmCreatePinButton",
  confirmModifyPinButton = "confirmModifyPinButton",
  validateLevelPinButton = "validateLevelPinButton",
  sendPinButton = "sendPinButton",
  resetPinButton = "resetPinButton",
  validateOnboardingButton = "validateOnboardingButton",
  desactivateParentalButton = "desactivateParentalButton",
  configureParentalButton = "configureParentalButton",
  kidsCheckbox = "kidsCheckbox",
  exitKidsPinDescription = "exitKidsPinDescription",
  exitKidsButton = "exitKidsButton",
}

type ParentalPopupPageView =
  | VerifyPinDescriptionView
  | PinView
  | EyePinViewList
  | ParentalButtonView
  | SendPinButtonView
  | CheckboxView
  | VerifyPinDescriptionView;

class VerifyPinDescriptionView extends AcceptsMouseFocusView implements DS.IPage {
  constructor(params: { desc1: string; desc2?: string }) {
    super("", "verifyPinDescription");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "verifyPinMsg verifyPinMsg-1",
      innerText: params.desc1,
    });
    params.desc2 !== undefined &&
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "verifyPinMsg verifyPinMsg-2",
        innerText: params.desc2,
      });
  }

  rejectsFocus() {
    return true;
  }
}

class ParentalPopupPage extends AcceptsMouseFocusView implements DS.IPage {
  protected _list: DS.IListComponent<ParentalPopupWidgetType, ParentalPopupPageView>;

  protected _pin: string | undefined;
  protected _confirmPin: string | undefined;
  protected _newPin: string | undefined;
  protected _confirmNewPin: string | undefined;
  protected _newParentalControl: ParentalControlLevel | undefined;
  protected _checkboxView: CheckboxView | undefined;

  constructor(
    modelSource: ParentalPopupWidgetType[],
    className: string,
    options?: {
      newParentalControl?: GIGYA.ParentalControlLevel;
      callback?: () => void;
    }
  ) {
    super("PINPageView", "pinPageView");

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "pinPageTitle",
      innerText: t(`parentalControl.${className}.pageTitle`),
    });

    this.delegate = this._list = DS.createListComponent(
      {
        id: `${className}Widget`,
        className: `${className}WidgetList`,
        modelSource$: new DS.ModelSource(modelSource),
        viewFactory: item => {
          switch (item) {
            case ParentalPopupWidgetType.verifyPinDescription:
              return new VerifyPinDescriptionView({
                desc1: t(`parentalControl.${className}.desc1`),
                desc2: t(`parentalControl.${className}.desc2`),
              });
            case ParentalPopupWidgetType.simplePin:
              return new EyePinViewList(pin => {
                this._pin = pin;
                this._focusNextItem();
              }, "parentalControl.pinContainer.codePIN");
            case ParentalPopupWidgetType.checkPin:
              return new EyePinViewList(pin => {
                if (this._pin === pin) return;
                const buttonType = ((): ParentalPopupWidgetType => {
                  if (this instanceof VerifyPinPopupPage) return ParentalPopupWidgetType.validateOnboardingButton;
                  return ParentalPopupWidgetType.validateLevelPinButton;
                })();
                this._pin = pin;
                if (pin === APIGigyaOIDC.userInfo$.value?.data?.pin) {
                  this._enableView(buttonType);
                  void this._list.setFocusOnId(buttonType);
                } else {
                  this._disableView(buttonType);
                  this._resetPin(item);
                  if (pin !== undefined) Toast(t("parentalControl.wrongCode"));
                }
              }, "parentalControl.pinContainer.codePIN");
            case ParentalPopupWidgetType.simplePinDesactivate:
              return new EyePinViewList(pin => {
                this._pin = pin;
                this._focusNextItem();
              }, "parentalControl.pinContainer.yourCodePIN");
            case ParentalPopupWidgetType.actualPin:
              return new EyePinViewList(pin => {
                // Check to avoid an infinite loop inside _check
                if (this._pin !== pin) {
                  this._pin = pin;
                  this._check();
                }
              }, "parentalControl.pinContainer.actualPIN");
            case ParentalPopupWidgetType.newPin:
              return new EyePinViewList(
                pin => {
                  this._newPin = pin;
                  this._enableView(ParentalPopupWidgetType.confirmNewPin);
                  this._focusNextItem();
                },
                "parentalControl.pinContainer.newPIN",
                true
              );
            case ParentalPopupWidgetType.confirmNewPin:
              return new EyePinViewList(
                pin => {
                  // Check to avoid an infinite loop inside _check
                  if (this._confirmNewPin !== pin) {
                    this._confirmNewPin = pin;
                    this._check();
                  }
                },
                "parentalControl.pinContainer.confirmNewPIN",
                true
              );
            case ParentalPopupWidgetType.confirmPin:
              return new EyePinViewList(pin => {
                this._confirmPin = pin;
                // Check to avoid an infinite loop inside _check
                if (this._confirmNewPin !== pin) {
                  this._check();
                }
              }, "parentalControl.pinContainer.confirmPIN");
            case ParentalPopupWidgetType.confirmCreatePinButton:
              return new ParentalButtonView(true);
            case ParentalPopupWidgetType.confirmModifyPinButton:
              return new ParentalButtonView(true);
            case ParentalPopupWidgetType.validateLevelPinButton:
              this._newParentalControl = options?.newParentalControl;
              return new ParentalButtonView(true);
            case ParentalPopupWidgetType.sendPinButton:
              return new SendPinButtonView();
            case ParentalPopupWidgetType.resetPinButton:
              return new SendPinButtonView();
            case ParentalPopupWidgetType.validateOnboardingButton:
              return new ParentalButtonView(true, "parentalControl.confirmButtonText");
            case ParentalPopupWidgetType.desactivateParentalButton:
              return new ParentalButtonView(false);
            case ParentalPopupWidgetType.configureParentalButton:
              return new ParentalButtonView(false);
            case ParentalPopupWidgetType.kidsCheckbox:
              return (this._checkboxView = new CheckboxView());
            case ParentalPopupWidgetType.exitKidsPinDescription:
              return new VerifyPinDescriptionView({
                desc1: t(`parentalControl.${className}.pageDescription`),
              });
            case ParentalPopupWidgetType.exitKidsButton:
              return new ParentalButtonView(false);
          }
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && { focusRange: "visible" },
        onSelect: item => {
          switch (item) {
            case ParentalPopupWidgetType.sendPinButton:
              void this._sendPin();
              return true;
            case ParentalPopupWidgetType.resetPinButton:
              void this._sendPin(true);
              return true;
            case ParentalPopupWidgetType.kidsCheckbox:
              this._checkboxView?.toggle();
              return true;
          }
          void this.onSelect(item);
          return false;
        },
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }

  async onSelect(item: ParentalPopupWidgetType): Promise<boolean> {
    return false;
  }

  private async _sendPin(openKids = false) {
    const result = await APIAuvio.resetPinCode();
    if (result) {
      navigationStack.removePage(this);
      DevicePreferenceHelper.enableKidsSecurity({
        onBoardingShown: true,
        isSecured: true,
        securedPageShown: true,
      });
      if (openKids === true) {
        OnboardingPageView.goToKidsAction();
      }
    }
  }

  protected _check() {
    return;
  }

  isPopup = () => true;

  protected _focusNextItem() {
    this._list?.setFocusOnIndex((this._list.focusedIndex$.value ?? 0) + 1);
  }

  protected _resetPin(type: ParentalPopupWidgetType) {
    const pinView = this._list.viewFromId(type);
    pinView instanceof EyePinViewList && pinView.resetPinCode();
  }

  protected _enableView(type: ParentalPopupWidgetType) {
    const view = this._list.viewFromId(type);
    view instanceof EyePinViewList && view.enable();
    view instanceof ParentalButtonView && view.enable();
  }

  protected _disableView(type: ParentalPopupWidgetType) {
    const view = this._list.viewFromId(type);
    view instanceof EyePinViewList && view.disable();
    view instanceof ParentalButtonView && view.disable();
  }

  /**
   * Enable or disable kids security based on the checkbox  value
   */
  protected _secureKidsSpace(onBoardingShown: boolean, securedPageShown: boolean) {
    const checkboxView = this._list.viewFromId(ParentalPopupWidgetType.kidsCheckbox);
    if (checkboxView instanceof CheckboxView && checkboxView.value === true) {
      DevicePreferenceHelper.enableKidsSecurity({
        onBoardingShown,
        isSecured: true,
        securedPageShown,
      });
    } else {
      DevicePreferenceHelper.disableKidsSecurity();
    }
  }
}

export class CreatePinParentalPopupPage extends ParentalPopupPage {
  constructor() {
    super(
      [
        ParentalPopupWidgetType.simplePin,
        ParentalPopupWidgetType.confirmPin,
        ParentalPopupWidgetType.kidsCheckbox,
        ParentalPopupWidgetType.confirmCreatePinButton,
      ],
      "createPin"
    );
  }

  /*
   * This check is here to prevent this use case :
   * - The user fill the new code PIN and the confirm PIN fields
   * - Come back to the new PIN field and erase it before clicking on validate
   * - This check make sure it doesn't validate in this case
   */
  protected _check() {
    if (this._pin !== this._confirmPin || this._pin === undefined || this._pin?.length !== 4) {
      Toast(t("toaster.pin_diff_error_text"));
      this._confirmPin = "";
      this._resetPin(ParentalPopupWidgetType.confirmPin);
      this._disableView(ParentalPopupWidgetType.confirmCreatePinButton);
      return false;
    } else {
      this._enableView(ParentalPopupWidgetType.confirmCreatePinButton);
      void this._list.setFocusOnId(ParentalPopupWidgetType.confirmCreatePinButton);
      return true;
    }
  }

  async onSelect(item: ParentalPopupWidgetType) {
    if (item === ParentalPopupWidgetType.confirmCreatePinButton) {
      if (this._check() === false || this._pin === undefined) {
        return false;
      }
      this._secureKidsSpace(true, false);
      await ParentalControlHelper.setPin(this._pin);
      navigationStack.removePage(this);
      return true;
    }
    return false;
  }
}

export class ModifyPinParentalPopupPage extends ParentalPopupPage {
  constructor() {
    super(
      [
        ParentalPopupWidgetType.actualPin,
        ParentalPopupWidgetType.newPin,
        ParentalPopupWidgetType.confirmNewPin,
        ParentalPopupWidgetType.kidsCheckbox,
        ParentalPopupWidgetType.confirmModifyPinButton,
        ParentalPopupWidgetType.sendPinButton,
      ],
      "modifyPin"
    );

    this._list.viewsUpdateCompletedTrigger.didSignal(_ => {
      this._disableView(ParentalPopupWidgetType.newPin);
    }, this);
  }

  protected _check(): boolean {
    if (this._pin === APIGigyaOIDC.userInfo$.value?.data?.pin) {
      this._enableView(ParentalPopupWidgetType.newPin);
      this._focusNextItem();
    } else {
      this._resetPin(ParentalPopupWidgetType.actualPin);
      this._disableView(ParentalPopupWidgetType.newPin);
      this._disableView(ParentalPopupWidgetType.confirmNewPin);
      this._disableView(ParentalPopupWidgetType.confirmModifyPinButton);
      return false;
    }

    if (this._confirmNewPin === undefined) {
      this._disableView(ParentalPopupWidgetType.confirmModifyPinButton);
      return false;
    }

    if (this._newPin === this._confirmNewPin && this._newPin !== undefined) {
      this._enableView(ParentalPopupWidgetType.confirmModifyPinButton);
      void this._list.setFocusOnId(ParentalPopupWidgetType.confirmModifyPinButton);
      return true;
    } else {
      Toast(t("toaster.pin_diff_error_text"));
      this._confirmNewPin = undefined;
      this._resetPin(ParentalPopupWidgetType.confirmNewPin);
      void this._list.setFocusOnId(ParentalPopupWidgetType.confirmNewPin);
      return false;
    }
  }

  async onSelect(item: ParentalPopupWidgetType) {
    switch (item) {
      case ParentalPopupWidgetType.confirmModifyPinButton:
        if (this._check() === false || this._newPin === undefined) {
          this._resetPin(ParentalPopupWidgetType.newPin);
          this._resetPin(ParentalPopupWidgetType.confirmNewPin);
          void this._list.setFocusOnId(ParentalPopupWidgetType.newPin);
          Toast(t("toaster.pin_diff_error_text"));
          return true;
        }
        this._secureKidsSpace(true, true);
        await ParentalControlHelper.setPin(this._newPin);
        navigationStack.removePage(this);

        return true;
    }
    return false;
  }
}

export class ValidateLevelParentalPopupPage extends ParentalPopupPage {
  constructor(newParentalControl: GIGYA.ParentalControlLevel) {
    super(
      [
        ParentalPopupWidgetType.checkPin,
        ParentalPopupWidgetType.validateLevelPinButton,
        ParentalPopupWidgetType.sendPinButton,
      ],
      "enterPin",
      { newParentalControl }
    );
  }

  async onSelect(item: ParentalPopupWidgetType) {
    switch (item) {
      case ParentalPopupWidgetType.validateLevelPinButton:
        if (this._pin !== APIGigyaOIDC.userInfo$.value?.data?.pin) Toast(t("parentalControl.enterPin.error"));
        if (this._newParentalControl === undefined) return false;

        await ParentalControlHelper.setParentalControlOptimistic(this._newParentalControl);
        navigationStack.removePage(this);
    }
    return false;
  }
}

export class VerifyPinPopupPage extends ParentalPopupPage {
  constructor() {
    super(
      [
        ParentalPopupWidgetType.verifyPinDescription,
        ParentalPopupWidgetType.checkPin,
        ParentalPopupWidgetType.validateOnboardingButton,
        ParentalPopupWidgetType.resetPinButton,
      ],
      "validatePin"
    );
  }

  async onSelect(item: ParentalPopupWidgetType) {
    switch (item) {
      case ParentalPopupWidgetType.validateOnboardingButton:
        if (this._pin === APIGigyaOIDC.userInfo$.value?.data?.pin) {
          DevicePreferenceHelper.enableKidsSecurity({
            onBoardingShown: true,
            isSecured: true,
            securedPageShown: true,
          });
          OnboardingPageView.goToKidsAction();
          Toast(t("onBoarding.kids_secured"));
        } else {
          const pinView = this._list.viewFromId(ParentalPopupWidgetType.simplePin);
          pinView instanceof EyePinViewList && pinView.resetPinCode();
          void this._list.setFocusOnId(ParentalPopupWidgetType.simplePin);
          Toast(t("onBoarding.error_text"));
        }
    }
    return false;
  }
}

export class DesactivateParentalPopupPage extends ParentalPopupPage {
  private _successCallback: () => void;
  private _failCallback?: () => void;
  private _desactivationSuccessful = false;

  constructor(successCallback: () => void, failCallback?: () => void) {
    super(
      APIGigyaOIDC.userInfo$.value?.data?.pin !== undefined
        ? [
            ParentalPopupWidgetType.simplePinDesactivate,
            ParentalPopupWidgetType.desactivateParentalButton,
            ParentalPopupWidgetType.sendPinButton,
          ]
        : [ParentalPopupWidgetType.configureParentalButton],
      `checkPin`,
      { callback: successCallback }
    );

    this._successCallback = successCallback;
    this._failCallback = failCallback;

    this.rootElement.classList.add("desactivateParental");

    const subtitle = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: `pinPageSubtitle`,
    });

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: subtitle,
      className: `pinPageSubtitleRow1`,
      innerText: t(`parentalControl.checkPin.pageSubtitle1`),
    });

    const row = DS.DOMHelper.createElement({
      tagName: "div",
      parent: subtitle,
      className: `pinPageSubtitleRow2`,
    });

    DS.DOMHelper.createElement({
      tagName: "span",
      parent: row,
      className: `text1`,
      innerText: t("parentalControl.checkPin.pageSubtitle2"),
    });
    try {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: row,
        className: "pinPageCSA",
        style: {
          backgroundImage: `url(${require(`@assets/images/csa/csa${
            ParentalControlHelper.currentParentalControl() === ParentalControlLevel.all
              ? ParentalControlLevel.sixteen
              : ParentalControlHelper.currentParentalControl()
          }.png`)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
    } catch (error: unknown) {
      Log.app.error("Can't find the csa with: ", ParentalControlHelper.currentParentalControl());
    }
    DS.DOMHelper.createElement({
      tagName: "span",
      parent: row,
      className: `text2`,
      innerText: t(
        `parentalControl.${
          APIGigyaOIDC.userInfo$.value?.data?.pin === undefined ? "checkPin.yearOldByDefault" : "yearsOld"
        }`
      ),
    });

    if (APIGigyaOIDC.userInfo$.value?.data?.pin === undefined) {
      this._list.rootElement.classList.add("noPin");
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: `pageDesc`,
        innerText: t(`parentalControl.checkPin.pageDesc`),
      });
    }
  }

  async onSelect(item: ParentalPopupWidgetType) {
    switch (item) {
      case ParentalPopupWidgetType.desactivateParentalButton:
        if (this._pin === APIGigyaOIDC.userInfo$.value?.data?.pin) {
          void ParentalControlHelper.disableParentalControl().then(() => {
            this._desactivationSuccessful = true;
            navigationStack.removePage(this);
            this._successCallback();
          });
        } else {
          Toast(t("parentalControl.wrongCode"));
          this._resetPin(ParentalPopupWidgetType.simplePinDesactivate);
          void this._list.setFocusOnId(ParentalPopupWidgetType.simplePinDesactivate);
        }
        return true;
      case ParentalPopupWidgetType.configureParentalButton:
        // TODO: Is that condition met sometimes ? (if not remove it)
        navigationStack.removePage(this);
        navigationStack.pushPage(new ParentalControlPage());
        return true;
    }
    return false;
  }

  onRelease(): void {
    super.onRelease();
    if (this._desactivationSuccessful === false && this._failCallback) this._failCallback();
  }
}

export class ExitKidsPopupPage extends ParentalPopupPage {
  private _successCallback: () => void;

  constructor(successCallback: () => void) {
    super(
      [
        ParentalPopupWidgetType.exitKidsPinDescription,
        ParentalPopupWidgetType.simplePin,
        ParentalPopupWidgetType.exitKidsButton,
        ParentalPopupWidgetType.sendPinButton,
      ],
      "exitKids"
    );
    this._successCallback = successCallback;
  }

  async onSelect(item: ParentalPopupWidgetType) {
    switch (item) {
      case ParentalPopupWidgetType.exitKidsButton:
        if (this._pin === APIGigyaOIDC.userInfo$.value?.data?.pin) {
          this._successCallback();
        } else {
          this._resetPin(ParentalPopupWidgetType.simplePin);
          void this._list.setFocusOnId(ParentalPopupWidgetType.simplePin);
          Toast(t("parentalControl.wrongCode"));
        }
        return true;
    }
    return false;
  }
}
