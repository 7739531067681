import { LogLevel } from "../types";
import { outputBuffer } from "./outputBuffer";

class OutputConsole {
  lastTimestamp?: number;

  appendEarlierLogs = () => {
    outputBuffer.buffer.forEach(entry => {
      if (this.lastTimestamp === undefined || entry.timestamp > this.lastTimestamp) {
        switch (entry.level) {
          case LogLevel.trace:
            console.trace(`[${entry.namespace.toUpperCase()}]`, entry.text);
            break;
          case LogLevel.debug:
            console.debug(`[${entry.namespace.toUpperCase()}]`, entry.text);

            break;
          case LogLevel.info:
            console.info(`[${entry.namespace.toUpperCase()}]`, entry.text);

            break;
          case LogLevel.warn:
            console.warn(`[${entry.namespace.toUpperCase()}]`, entry.text);

            break;
          case LogLevel.error:
            console.error(`[${entry.namespace.toUpperCase()}]`, entry.text);
            break;
        }
      }
    });
  };
}
export const outputConsole = new OutputConsole();
