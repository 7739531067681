import "./quickLinkWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GenericPage } from "../../../pages/generic/genericPage";
import { QuickLinkCard, Widget, WidgetQuickLink } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { WidgetView } from "../widgetView";

class QuickLinkTileView extends AcceptsMouseFocusView {
  constructor(quickLink: QuickLinkCard) {
    super(quickLink.id, "tile");
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    const titleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
    });

    Config.locale$.didChange(
      () => {
        // when local changes, we reset our text - which is dependent on this locale
        titleElement.innerText = `${quickLink.label}`;
      },
      this,
      true
    );
  }
}

/**
 * Category widget, built by the GenericPage.
 * @param widget The category widget.
 */
export class QuickLinkWidgetView extends WidgetView {
  focusTracker?: FocusTracker;
  constructor(widget: Widget) {
    super(widget.id, "quickLinkWidget");
    this._setAnalytics(widget);
    // Widget title
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });
    // Widget list of content
    this.delegate = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget))),
        viewFactory: quickLink => new QuickLinkTileView(quickLink),
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          super._analyticsCards({
            card_order: index,
            string_value: card.label,
          });
          navigationStack.pushPage(new GenericPage(card.path));
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this.focusTracker.onRelease);
      }
    );
  }

  /**
   * Fetch quickLink list and return it, with a CTA if contentPath is defined
   * Otherwise it returns the content stored in the widget object in the constructor (used for search)
   *
   * @param {Widget} widget
   * @return {Promise<QuickLinkCard[]>} List of items
   */
  private async _content(widget: Widget): Promise<QuickLinkCard[]> {
    try {
      return (
        widget.contentPath !== undefined
          ? await APIAuvio.widget(widget.contentPath, WidgetQuickLink)
          : WidgetQuickLink.parse(widget)
      ).content;
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }
}
