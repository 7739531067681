import { PlayerEvents } from "~/analytics/constants";
import { getDeviceStats } from "~/analytics/utils/helpers";
import { Config } from "~/datas";

import { BaseConnector, IConnectorOptions } from "./BaseConnector";

type SessionInfo = Partial<{
  playSessionId: string;
  requestId: string;
  playbackFormat: string;
  assetId: string;
  autoplay: boolean;
  startTime: number;
  cdn: Partial<{
    provider: string;
  }>;
  analytics: Partial<{
    postInterval: number;
    bucket: number;
    tag: string;
    baseUrl: string;
    percentage: number;
  }>;
}>;

type PlayerInfo = Partial<{ name: string; version: string }>;

type EventData = Partial<{
  currentTime: number;
  type: string;
  bitrate: number;
  source: string;
  duration: number;
  error: string;
  originalError: string;
  code: number;
  message: string;
}>;

export class PlayerCoreConnector extends BaseConnector {
  private _currentTime = 0;
  private _beat: ReturnType<typeof setInterval> | undefined;
  private _beatInterval = 10_000;
  private _sessionEnded = false;
  private _analyticsEnabled = true;
  private _isBrowserSupported = false;

  private _sessionInfo: SessionInfo = {};
  private _playerInfo: PlayerInfo = {};

  constructor({
    customer,
    businessUnit,
    exposureBaseUrl,
    analyticsBaseUrl,
    sessionToken,
    playerName,
    playerVersion,
    debug,
    device,
  }: IConnectorOptions) {
    super({
      customer,
      businessUnit,
      exposureBaseUrl,
      analyticsBaseUrl,
      sessionToken,
      playerName,
      playerVersion,
      debug,
      device,
    });

    this._onPlayerEvents = this._onPlayerEvents.bind(this);
  }

  connect(sessionInfo?: SessionInfo, playerInfo?: PlayerInfo, isBrowserSupported = false): void {
    this._currentTime = 0;
    this._sessionEnded = false;
    this._isBrowserSupported = isBrowserSupported;

    this._sessionInfo = sessionInfo ?? {};
    this._playerInfo = playerInfo ?? {};
  }

  disconnect(): void {
    this._disableHeartbeat();
    this._sessionEnded = true;
  }

  private _onPlayerEvents(event: PlayerEvents, data: EventData = {}) {
    if (this._sessionEnded || !this._analyticsEnabled) return;
    if (typeof data.currentTime === "number") {
      this._currentTime = data.currentTime;
    }
    switch (event) {
      case PlayerEvents.LOADED: {
        this.rbmAnalytics?.playerReady({
          playSessionId: this._sessionInfo.playSessionId,
          startTime: this._sessionInfo.startTime ?? 0,
          playerTech: this._playerInfo.name ?? "",
          techVersion: this._playerInfo.version,
        });
        break;
      }
      case PlayerEvents.LOADING: {
        const sessionInfo = this._sessionInfo;
        if (
          !(sessionInfo.playSessionId != null && sessionInfo.analytics && sessionInfo.analytics.postInterval != null)
        ) {
          this._analyticsEnabled = false;
          return;
        }

        const postInterval = sessionInfo.analytics.postInterval * 1000;

        if (sessionInfo.analytics.baseUrl != null) {
          this.setAnalyticsBaseUrl(sessionInfo.analytics.baseUrl);
        } else {
          console.warn("No analyticsBaseUrl is set, defaulting to exposureBaseUrl");
          this.setExposureBaseUrl(Config().REDBEE.exposureBaseUrl);
        }
        this._analyticsEnabled =
          sessionInfo.analytics.percentage != null ? sessionInfo.analytics.percentage > Math.random() * 100 : false;
        if (!this._analyticsEnabled) return;
        if (postInterval > this._beatInterval) {
          this._beatInterval = postInterval;
        }
        this.rbmAnalytics?.init(sessionInfo.playSessionId);
        this.rbmAnalytics?.created({
          player: this.playerName,
          playerVersion: this.playerVersion,
          streamingTechnology: sessionInfo.playbackFormat,
          assetId: sessionInfo.assetId,
          autoplay: sessionInfo.autoplay,
          requestId: sessionInfo.requestId,
          cdnProvider: sessionInfo.cdn?.provider,
          analyticsPostInterval: sessionInfo.analytics.postInterval,
          analyticsBucket: sessionInfo.analytics.bucket,
          analyticsTag: sessionInfo.analytics.tag,
          analyticsPercentage: sessionInfo.analytics.percentage,
          analyticsBaseUrl: sessionInfo.analytics.baseUrl ?? "",
          deviceStats: getDeviceStats(),
        });
        this.rbmAnalytics?.assetLoaded({ assetId: sessionInfo.assetId });
        break;
      }
      case PlayerEvents.DRM_UPDATE:
        this.rbmAnalytics?.drmSessionUpdate(data.type ?? "");
        break;
      case PlayerEvents.START: {
        this.rbmAnalytics?.playing({
          bitrate: data.bitrate,
          duration: data.duration,
          mediaLocator: data.source ?? "",
        });
        this._initHeartbeat();
        break;
      }
      case PlayerEvents.PAUSE:
        this.rbmAnalytics?.paused(this._currentTime);
        break;
      case PlayerEvents.SEEKED:
        this.rbmAnalytics?.seeked(this._currentTime);
        break;
      case PlayerEvents.RESUME:
        this.rbmAnalytics?.resume(this._currentTime);
        this._initHeartbeat();
        break;
      case PlayerEvents.BUFFERING:
        this.rbmAnalytics?.buffering(this._currentTime);
        break;
      case PlayerEvents.BUFFERED:
        this.rbmAnalytics?.buffered(this._currentTime);
        break;
      case PlayerEvents.BITRATE_CHANGED:
        this.rbmAnalytics?.bitrateChanged(this._currentTime, data.bitrate ?? 0);
        break;
      case PlayerEvents.ENDED:
        this.rbmAnalytics?.mediaEnded(this._currentTime);
        this.disconnect();
        break;
      case PlayerEvents.STOP:
        this.rbmAnalytics?.dispose(this._currentTime);
        this.disconnect();
        break;
      case PlayerEvents.ERROR: {
        const originalError = data.originalError != null ? ` - ${data.originalError}` : "";
        const error = `${data?.error}, ${data?.message}`;
        const errorMessage = `${error}${originalError}`;
        this.rbmAnalytics?.error({
          currentTime: this._currentTime,
          errorCode: data.code ?? 500,
          errorMessage,
          supportedDevice: this._isBrowserSupported,
          deviceStats: getDeviceStats(),
        });
        this.disconnect();
        break;
      }
      // case PlayerEvents.CAST_START:
      //   this._disableHeartbeat();
      //   this.rbmAnalytics.startCasting();
      //   break;
      // case PlayerEvents.CAST_STOP:
      //   this._initHeartbeat();
      //   this.rbmAnalytics.stopCasting();
      //   break;
      // case PlayerEvents.AIRPLAY_START:
      //   this.rbmAnalytics.startAirPlay();
      //   break;
      // case PlayerEvents.AIRPLAY_STOP:
      //   this.rbmAnalytics.stopAirPlay();
      //   break;
      // case PlayerEvents.AD_START:
      //   this.rbmAnalytics.adStarted(this._currentTime, data.id);
      //   break;
      // case PlayerEvents.AD_COMPLETE:
      //   this.rbmAnalytics.adCompleted(this._currentTime, data.id);
      //   break;
      // case PlayerEvents.DROPPED_FRAMES:
      //   this.rbmAnalytics.droppedFrames(data.droppedFrames);
      //   break;
      // case PlayerEvents.PROGRAM_CHANGED:
      //   this.rbmAnalytics.programChanged(this._currentTime, data.program?.programId || data.program?.asset.assetId);
      //   break;
      default:
        break;
    }
  }

  private _initHeartbeat() {
    if (this._beat) return;
    this._beat = setInterval(() => {
      this.rbmAnalytics?.heartbeat(this._currentTime);
    }, this._beatInterval);
  }

  private _disableHeartbeat() {
    if (!this._beat) return;
    clearInterval(this._beat);
    this._beat = undefined;
  }

  public destroy() {
    this._disableHeartbeat();
    super.destroy();
  }

  public triggerCustomEvents(event: PlayerEvents, data?: EventData) {
    this._onPlayerEvents(event, data);
  }
}
