import "./commonContentView.scss";

import { DS } from "dslib-tv";

import { ContentView } from "../contentView";

export class TitleContentView extends ContentView {
  constructor(title: string) {
    super("", "titleContentView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "contentTitle",
      innerText: title,
    });
  }

  rejectsFocus() {
    return true;
  }
}
